import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

const Dropdown = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div onMouseLeave={() => setIsOpen(false)} className={'relative'}>
      <div
        onMouseEnter={() => setIsOpen(true)}
        className={'cursor-pointer flex items-center text-gold w-full relative rounded-lg shadow-sm py-2'}>
        <div>{label}</div>
        <IoIosArrowDown className={'ml-1'} />
      </div>
        <div className={'absolute z-50 -translate-x-1/2 left-11'}>
            {
                isOpen &&
                <div className={'p-4 bg-bronze shadow border border-gold'}>
                    {children}
                </div>
            }
        </div>

    </div>
  );
};

export default Dropdown;
