import React from 'react';
import Summary from "../molecules/cart/Summary";
import ProgressBar from "../organisms/cart/ProgressBar";

const TemplateCart = ({summaryConfig, progressBarConfig, children, title}) => {

    return (    
            <section className={'bg-gray-50 max-w-screen-xl mx-auto mb-8'}>

                <div className={'mb-8'}>
                    <ProgressBar  config={progressBarConfig}/>
                </div>

                <div className={'md:flex justify-center w-full'}>
                    {children}
                    {summaryConfig?.route === '/wina' ? null : <Summary config={summaryConfig}/>}
                    
                </div>

            </section>
    );
};

export default TemplateCart;
