import Select from "react-select";
import { customStyles, findOption } from "../select/selectp";
export const InputSelectPure = ({
  icon: Icon,
  label,
  isLabelVisible = true,
  placeholder: passedPlaceholder,
  required,
  value,
  onChange,
  innerRef,
  disabled,
  optionFormat,
  name,
  options,
  error,
  className = "",
  disableDefaultStyles,
  ...props
}) => {
  const placeholder = `${passedPlaceholder ?? label ?? ""}${
    required ? "" : ""
  }`;
  return (
    <div
      className={`flex flex-col text-left custom-select ${className} relative`}
    >
      {(label || required) && isLabelVisible && (
        <label className="inline-block text-gray-800 text-xs sm:text-sm mb-2">
          {label}
          {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <div className="relative">
        <Select
          menuPortalTarget={document.body}
          styles={customStyles({ hasIcon: !!Icon, error })}
          value={value === null ? null : findOption(value?.toString(), options)}
          // value={value}
          options={options}
          formatOptionLabel={optionFormat}
          placeholder={placeholder}
          onChange={onChange}
          isDisabled={disabled}
          // menuIsOpen
          {...props}
          classNamePrefix="react-select"
        />

        {error && (
          <div className="text-sm absolute top-full left-0 text-red-800">
            {error?.message}
          </div>
        )}
      </div>
      
    </div>
  );
};
