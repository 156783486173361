import TemplateMain from "../../../templates/TemplateMain";
import TemplateSection from "../../../templates/TemplateSection";
import { useGetBlogs } from "../../../../app/crud/app/blog/blogs";
import BlogList from "../../../organisms/blog-list/BlogList";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import { passParams, routes } from "../../../../app/router/routes";
import React from "react";
import { useLocation } from "react-router-dom";
import Card from "../../../atoms/card/Card";
import { BeatLoader } from "react-spinners";
import dayjs from "dayjs";
import CustomLink from "../../../atoms/ui/buttons/links/Link";
import { truncateText } from "../../../atoms/TruncatText/truncateText";
import photo from "../../../../app/assets/svg/photo.svg";
import Text from "../../../molecules/text/Text";

const ViewBlog = () => {
  const location = useLocation();
  const { data, isLoading } = useGetBlogs(location?.search);
  const bc = [{ label: "Blog", url: routes.blog }];
  return (
    <TemplateMain
      title={"Blog"}
      breadcrumbsRenderer={() => (
        <Breadcrumbs homeRoute={routes.home} settings={bc} />
      )}
    >
      <TemplateSection>
        <div className=" py-6 ">
          <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
            <div className="mb-6">
              <h2 className="text-bronze text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
                Wpisy
              </h2>
            </div>
            {!!isLoading ?
            <div className="w-full flex justify-center">
              <BeatLoader /> 
              </div>
              :
              data?.items?.length === 0 ?
              <Text className={"w-full text-center"}>Brak wpisów do wyświetlenia</Text>
              :
              <div className="grid sm:grid-cols-2 lg:grid-cols-3  gap-4 md:gap-6 xl:gap-8">
              {data?.items?.map((item) => (
                  <Card>
                    <div className="flex flex-col bg-white border md:min-h-[490px] rounded-md overflow-hidden">
                      <CustomLink
                        to={passParams(routes.blogDetails, { id: item?.id })}
                        className="group h-48 md:h-64 block bg-gray-100 overflow-hidden relative"
                      >
                        {item?.image?.url ? (
                          <img
                            src={item?.image?.url}
                            loading="lazy"
                            alt={photo}
                            className="w-full h-full  absolute inset-0 group-hover:scale-105 transition duration-200"
                          />
                        ) : (
                          <img
                            src={photo}
                            loading="lazy"
                            alt={""}
                            className="w-full h-full  absolute inset-0 group-hover:scale-105 transition duration-200"
                          />
                        )}
                      </CustomLink>

                      <div className="flex flex-col flex-1 p-4 sm:p-6">
                        <h2 className="text-gray-800 text-lg font-semibold mb-2">
                          <CustomLink
                            to={passParams(routes.blogDetails, {
                              id: item?.id,
                            })}
                            className="hover:text-gold active:text-gold2 transition duration-100"
                          >
                            {item?.title}
                          </CustomLink>
                        </h2>

                        <p className="text-gray-500 mb-8">
                          {truncateText(
                            item?.text ? item?.text : "Brak treści wpisu",
                            80
                          )}
                        </p>

                        <div className="flex justify-between items-center mt-auto">
                          <div className="flex items-center gap-2">
                            <div className="w-10 h-10 shrink-0 bg-gray-100 rounded-md overflow-hidden">
                              <CustomLink
                                to={passParams(routes.vineyard, {
                                  vineyardId: item?.vineyardId,
                                })}
                              >
                                {item?.logoImage?.url ? (
                                  <img
                                    src={item?.logoImage?.url}
                                    loading="lazy"
                                    alt="Logo winnicy"
                                    className="w-full h-full object-cover object-center"
                                  />
                                ) : (
                                  <img
                                    src={photo}
                                    loading="lazy"
                                    alt="Logo winnicy"
                                    className="w-full h-full object-cover object-center"
                                  />
                                )}
                              </CustomLink>
                            </div>

                            <div>
                              <span className="block text-gold2">
                                <CustomLink
                                className={'text-xs md:text-base'}
                                  to={passParams(routes.vineyard, {
                                    vineyardId: item?.vineyardId,
                                  })}
                                >
                                  {item?.vineyardName}
                                </CustomLink>
                              </span>
                              <span className="block text-gray-400 text-xs md:text-base">
                                {dayjs(item?.createdDateTime).format(
                                  "DD.MM.YYYYr"
                                )}
                              </span>
                            </div>
                          </div>
                          <CustomLink
                            to={passParams(routes.blogDetails, {
                              id: item?.id,
                            })}
                            className="text-gray-500 text-xs text-center md:text-sm border rounded md:px-2 md:py-1"
                          >
                            Czytaj więcej
                          </CustomLink>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))
                          }
            </div>
          }

          </div>
        </div>
      </TemplateSection>
    </TemplateMain>
  );
};

export default ViewBlog;
