import TemplateMain from "../../../templates/TemplateMain";
import TemplateSection from "../../../templates/TemplateSection";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import React from "react";
import { useLocation } from "react-router-dom";
import { useGetAdvertisements } from "../../../../app/crud/vineyard/enoturism/getEnoturisms";
import { passParams, routes } from "../../../../app/router/routes";

import CustomLink from "../../../atoms/ui/buttons/links/Link";
import photo from "../../../../app/assets/svg/photo.svg";
import { truncateText } from "../../../atoms/TruncatText/truncateText";
import dayjs from "dayjs";
import { BeatLoader } from "react-spinners";
import Card from "../../../atoms/card/Card";
import Text from "../../../molecules/text/Text";
import { useGetBookStatus } from "../../../../app/crud/vineyard/enum/getBookStatus";
import EnoturismBadge from "../../../atoms/badge/EnoturismBadge";
import { AiOutlineConsoleSql } from "react-icons/ai";
import ProductCard from "../../../molecules/product-card/ProductCard";
import ProductEnoturism from "../../../organisms/product-details/ProductEnoturism";

const ViewEnoturism = () => {
  const location = useLocation();
  const { data, isLoading } = useGetAdvertisements(location?.search);
  const { data: bookStatus } = useGetBookStatus();
  const bc = [{ label: "Enoturystyka", url: routes.blog }];
  return (
    <TemplateMain
      title={"Enoturystyka"}
      breadcrumbsRenderer={() => (
        <Breadcrumbs homeRoute={routes.home} settings={bc} />
      )}
    >
      <TemplateSection>
        <div className=" py-6 ">
          <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
            <div className="mb-6">
              <h2 className="text-bronze text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
                Wydarzenia
              </h2>
            </div>
            {!!isLoading ?
            // [...Array(3).keys()].map(card => <ProductEnoturism  isLoading={isLoading} />)
<div className={'w-full flex justify-center'}><BeatLoader /> </div>              :
              data?.items?.length === 0 ?
              <Text className={"w-full text-center"}>Brak ogłoszeń do wyświetlenia</Text> :
              <div
              className={
                "grid sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 xl:gap-8"
              }
              
            >
              {data?.items?.map((item) => (
                <div>
              <ProductEnoturism item={item} isLoading={isLoading} />
              </div>
))}
            </div>
            
            }

          </div>
        </div>
      </TemplateSection>
    </TemplateMain>
  );
};

export default ViewEnoturism;
