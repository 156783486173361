import TemplateMain from '../../../templates/TemplateMain';
import TemplateSection from '../../../templates/TemplateSection';
import { useGetWineFilters } from '../../../../app/crud/app/wineFilters';
import TemplateFilters from '../../../templates/TemplateFilters';
import ProductListWithFilters from '../../../organisms/product-list/ProductListWithFilters';
import React from 'react';
import { useGetWines } from '../../../../app/crud/app/wines';
import { routes } from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import {useLocation} from "react-router-dom";
import useFilters from "../../../../app/hooks/useFilters";

const ViewWines = () => {
  const location = useLocation()
  const {data: filters, isLoading: filtersLoading} = useGetWineFilters()
  const {data: items, refetch, isFetching} = useGetWines(location?.search)
  const {filtersState} = useFilters(filters)
  const bc = [{ label: 'Wina', url: routes.wines }];
  return (
    <TemplateMain
      title={'Wina'}
      breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.home} settings={bc} />}
    >
      <div className={'max-w-screen-xl mx-auto mb-8 mt-4'}>
        <TemplateFilters state={filtersState} filters={filters} isLoading={filtersLoading} isItemsFetching={isFetching} items={items}>
          <ProductListWithFilters isFetching={isFetching} items={items} />
        </TemplateFilters>
      </div>
    </TemplateMain>
  );
};

export default ViewWines;
