import PropTypes from 'prop-types';
import LineLoader from '../../atoms/loaders/LineLoader';

const Header2 = ({ children, isLoading, className }) => {
  return (
    <div className={className}>
      <LineLoader isLoading={isLoading} classNames={['w-1/2 h-4 my-1']}>
        <h2 className={'font-bold text-gray-600'}>{children}</h2>
      </LineLoader>
    </div>
  );
};

Header2.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Header2;
