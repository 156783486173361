import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

export const useAddAdvertisementImage = () => {
  const { id } = useParams();
  return useMutation((data) => {
    return axios
      .post(
        `${BASE_URL_API}/api/v1/admin/vineyard/advertisements/${id}/images`,
        data
      )
      .then(({ data }) => data);
  });
};
