import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import { useMutation } from 'react-query';

export const usePutProfile = () => {
  return useMutation((data) => {
    return axios.put(`${BASE_URL_API}/api/v1/admin/vineyard`, data)
      .then(({ data }) => data)
      .then(data => {
        return data;
      })
  });
};
