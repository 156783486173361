import { useMemo } from "react"
import { useCustomerProfile } from "../../../app/crud/customer/getProfileCustomer"
import Card from "../../atoms/card/Card"
import UserAvatar from "../../atoms/UserAvatar/UserAvatar"
import UserFullName from "../../atoms/UserFullName/UserFullName"
const MyAccountInfo = () => {

    return (
        <Card className="p-6 rounded-md ">
        <UserHeader/>
        <UserData/>
        </Card>
    )
}
export default MyAccountInfo

const UserHeader = () => {
    const {data, refetch} = useCustomerProfile()
    return (
        <div className="flex flex-col items-center mb-3">
            <UserAvatar user={data} className="!w-24 !h-24 !rounded mb-2"/>
            <UserFullName user={data}/>
            <div className="text-slate-400 text-sm">{data?.email}</div>
        </div>
    )
}

const UserData = () => {
    const {data, isLoading} = useCustomerProfile()

    const tableData = useMemo(
        () => [
            ['Rola:', data?.roles],
            ['Email:', data?.email],
            ['Numer telefonu:', `+48 669 897 189`],
        ],[data]
    )

    return (
        <div className="mb-10 text-sm">
        {tableData.map(
            (item, index) => item[1] != null && (
                <div className="py-1" key={index}>
                    {item[0]}
                    <strong className="pl-2 text-gray-700 break-words2">{item[1]}</strong>
                </div>
            ),
        )}
        </div>
    )
}