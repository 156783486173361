import Header1 from "../../../molecules/headers/Header1";
import "react-slideshow-image/dist/styles.css";
import Slider from "./Slider";
import Card from "../../../atoms/card/Card";
import Rate from "../../../molecules/rate/Rate";
import EnoturismBadge from "../../../atoms/badge/EnoturismBadge";
import dayjs from "dayjs";
import { FaBed } from "react-icons/fa";
import { MdKitchen, MdMeetingRoom } from "react-icons/md";
import Text from "../../../molecules/text/Text";

const EnoturismDetails = ({ data, isLoading }) => {
  return (
    <div className={`flex flex-col`}>
      <div className="mt-4">
        <Header1 className={"mb-0"} isLoading={isLoading}>
          {data?.name}
        </Header1>
        <p className="text-xs mb-8">Kraków, Małopolskie, Polska</p>
        {!isLoading && data?.images.length > 0 && (
          <Card className={"mb-8 !flex !justify-center !items-center mt-8"}>
            <Slider data={data} />
          </Card>
        )}

        <Card className={"p-8 mb-8 "}>
          <div className={"md:flex justify-between "}>
            <div>
              <div>
                <div className="font-semibold text-2xl">{data?.name}</div>
                <p className="text-sm">{data?.vineyardName}</p>
              </div>
              <div className="mt-8 w-1/2">
                <Text>{data?.description}</Text>
              </div>
              <div className="mt-8">
                <Text className={"font-semibold text-xl mb-2"}>
                  Gdzie będziesz spać
                </Text>

                <div className="md:flex justify-between md:w-1/2">
                  {data?.singlePersonBedsCount && (
                    <div className={"mt-2 border rounded-lg p-4 pl-8 mr-2"}>
                      <FaBed size={"2.25rem"} className={"mr-1 mb-2"} />
                      <Text className={"mb-1"}>Sypialnia 1</Text>
                      <Text>
                        Liczba pojedycznych łóżek: {data?.singlePersonBedsCount}
                      </Text>
                    </div>
                  )}

                  {data?.doublePersonBedsCount && (
                    <div className={"mt-2 border rounded-lg p-4 pl-8"}>
                      <FaBed size={"2.25rem"} className={"mr-1 mb-2"} />
                      <Text className={"mb-1"}>Sypialnia 1</Text>
                      <Text>
                        Liczba podwójnych łóżek: {data?.doublePersonBedsCount}
                      </Text>
                    </div>
                  )}
                </div>
              </div>
                  
            </div>

            <Card className={"md:w-1/2 md:h-48"}>
              <div className={"md:flex justify-between px-4"}>
                <p className="text-gray-500 mb-4">
                  <p>
                    <span className="font-semibold">{data?.price} zł</span> /
                    doba
                  </p>
                </p>
                <div>
                  <Rate rate={"4"} isLoading={isLoading} opinions={3.5} />
                </div>
              </div>
              <div className="mt-2 px-4">
                <p className="text-gray-500 flex justify-between ">
                  <p className="mr-1">Status: </p>
                  <p>
                    {data?.bookStatus === 10 ? (
                      <EnoturismBadge bgClass={"10"}>Wolne</EnoturismBadge>
                    ) : null}
                    {data?.bookStatus === 5 ? (
                      <EnoturismBadge bgClass={"5"}>
                        Zarezerwowane
                      </EnoturismBadge>
                    ) : null}
                    {data?.bookStatus === 1 ? (
                      <EnoturismBadge bgClass={"1"}>Zajęte</EnoturismBadge>
                    ) : null}
                  </p>
                </p>
              </div>
              <div className="mt-2 px-4">
                <p className="text-gray-500 mb-1 flex justify-between">
                  <p className="flex justify-between w-full">
                    Dostępne od:{" "}
                    <span className="font-semibold ml-2">
                      {dayjs(data?.availableFrom).format("DD.MM.YYYYr")}
                    </span>
                  </p>
                </p>
              </div>
              <div className="mt-2 px-4">
                <p className="text-gray-500 mb-1 flex justify-between">
                  <p className="flex justify-between w-full">
                    Dostępne do:{" "}
                    <span className="font-semibold ml-2">
                      {dayjs(data?.availableTo).format("DD.MM.YYYYr")}
                    </span>
                  </p>
                </p>
              </div>
            </Card>
          </div>
        </Card >

        <Card className={'mt-8'}>
        <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">

<div className="mb-10 md:mb-16">
  <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">Najczęściej zadawane pytania</h2>


</div>

<div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-8 sm:gap-y-10">

  <div className="bg-gray-100 rounded-lg relative p-5 pt-8">
    <span className="w-8 h-8 inline-flex justify-center items-center bg-gold2 text-white rounded-full absolute -top-4 left-4">
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </span>

    <h3 className="text-gold2 text-lg md:text-xl font-semibold mb-3">How does the product work?</h3>
    <p className="text-gray-500">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.</p>
  </div>

  <div className="bg-gray-100 rounded-lg relative p-5 pt-8">
    <span className="w-8 h-8 inline-flex justify-center items-center bg-gold2 text-white rounded-full absolute -top-4 left-4">
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </span>

    <h3 className="text-gold2 text-lg md:text-xl font-semibold mb-3">What are the features?</h3>
    <p className="text-gray-500">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.</p>
  </div>

  <div className="bg-gray-100 rounded-lg relative p-5 pt-8">
    <span className="w-8 h-8 inline-flex justify-center items-center bg-gold2 text-white rounded-full absolute -top-4 left-4">
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </span>

    <h3 className="text-gold2 text-lg md:text-xl font-semibold mb-3">What about integrations?</h3>
    <p className="text-gray-500">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.</p>
  </div>

  <div className="bg-gray-100 rounded-lg relative p-5 pt-8">
    <span className="w-8 h-8 inline-flex justify-center items-center bg-gold2 text-white rounded-full absolute -top-4 left-4">
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </span>

    <h3 className="text-gold2 text-lg md:text-xl font-semibold mb-3">Is support available?</h3>
    <p className="text-gray-500">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.</p>
  </div>

  <div className="bg-gray-100 rounded-lg relative p-5 pt-8">
    <span className="w-8 h-8 inline-flex justify-center items-center bg-gold2 text-white rounded-full absolute -top-4 left-4">
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </span>

    <h3 className="text-gold2 text-lg md:text-xl font-semibold mb-3">How does one upgrage a plan?</h3>
    <p className="text-gray-500">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.</p>
  </div>

  <div className="bg-gray-100 rounded-lg relative p-5 pt-8">
    <span className="w-8 h-8 inline-flex justify-center items-center bg-gold2 text-white rounded-full absolute -top-4 left-4">
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </span>

    <h3 className="text-gold2 text-lg md:text-xl font-semibold mb-3">Which payment methods are available?</h3>
    <p className="text-gray-500">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text.</p>
  </div>

</div>
</div>
        </Card>
        <Card className={'mb-8'}>
        <div className="bg-white py-6 sm:py-8 lg:py-12">
  <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
    <div className="mb-10 md:mb-16">
      <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">Co znajdziesz w tym miejscu</h2>

     
    </div>
    <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-12 xl:gap-16">
      <div className="flex flex-col items-center">
        <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center bg-gold2 text-white rounded-lg md:rounded-xl shadow-lg mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
          </svg>
        </div>

        <h3 className="text-lg md:text-xl font-semibold text-center mb-2">Growth</h3>
        <p className="text-gray-500 text-center mb-2">Filler text is dummy text which has no meaning however looks very similar to real text.</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center bg-gold2 text-white rounded-lg md:rounded-xl shadow-lg mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
        </div>

        <h3 className="text-lg md:text-xl font-semibold text-center mb-2">Security</h3>
        <p className="text-gray-500 text-center mb-2">Filler text is dummy text which has no meaning however looks very similar to real text.</p>

      </div>
      <div className="flex flex-col items-center">
        <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center bg-gold2 text-white rounded-lg md:rounded-xl shadow-lg mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
          </svg>
        </div>

        <h3 className="text-lg md:text-xl font-semibold text-center mb-2">Cloud</h3>
        <p className="text-gray-500 text-center mb-2">Filler text is dummy text which has no meaning however looks very similar to real text.</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center bg-gold2 text-white rounded-lg md:rounded-xl shadow-lg mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
        </div>

        <h3 className="text-lg md:text-xl font-semibold text-center mb-2">Speed</h3>
        <p className="text-gray-500 text-center mb-2">Filler text is dummy text which has no meaning however looks very similar to real text.</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center bg-gold2 text-white rounded-lg md:rounded-xl shadow-lg mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </div>

        <h3 className="text-lg md:text-xl font-semibold text-center mb-2">Support</h3>
        <p className="text-gray-500 text-center mb-2">Filler text is dummy text which has no meaning however looks very similar to real text.</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center bg-gold2 text-white rounded-lg md:rounded-xl shadow-lg mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
          </svg>
        </div>

        <h3 className="text-lg md:text-xl font-semibold text-center mb-2">Dark Mode</h3>
        <p className="text-gray-500 text-center mb-2">Filler text is dummy text which has no meaning however looks very similar to real text.</p>
      </div>
    </div>
  </div>
</div>
</Card>
      </div>
    </div>
  );
};

export default EnoturismDetails;
