import Breadcrumbs from "../../../../organisms/breadcrumbs/Breadcrumbs";
import React from "react";
import { useGetContainer } from "../../../../../app/crud/vineyard/containers/getContainer";
import { passParams, routes } from "../../../../../app/router/routes";
import FormCreateEventsContainer from "../../../../organisms/forms/event-containers/FormCreateEventsContainer";
import VineyardSideMenu from "../../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../../templates/TemplatePanel";

const ViewEventsAddContainer = () => {
    const { data, refetch } = useGetContainer();
    const bc = [
        { label: "Zbiorniki", url: routes.vineyardContainers },
        {
          label: data?.name,
          url: passParams(routes.vineyardContainer, { id: data?.id }),
        },
        {
            label: "Dodaj zdarzenie do zbiornika",
            url: passParams(routes.vineyardContainerEventCreate, { id: data?.id })
          },
      ];

    return (
            <TemplatePanel
            title={"Zbiornik"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => (
                <Breadcrumbs
                  homeRoute={routes.vineyardDesktop}
                  settings={bc}
                />
              )}
            >
            <FormCreateEventsContainer refetch={refetch}/>
            </TemplatePanel>
    )
};

export default ViewEventsAddContainer;
