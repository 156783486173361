import * as yup from "yup";
import Submit from "../../atoms/ui/buttons/regular/Submit";
import Input from "../../atoms/ui/inputs/CustomInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "../../atoms/form-error/FormError";
import CustomLink from "../../atoms/ui/buttons/links/Link";
import { routes } from "../../../app/router/routes";
import { RiArrowLeftSLine, RiLockPasswordLine } from "react-icons/ri";
import toast from "react-hot-toast";
import { MdAlternateEmail, MdDriveFileRenameOutline } from "react-icons/md";
import { InputNIP } from "../../atoms/ui/inputs/InputNIP";
import { AiOutlineUser } from "react-icons/ai";
import { InputCheckbox } from "../checkbox/Input_Checkbox";
import { useSignUpImporter } from "../../../app/crud/auth/register";

const FormRegisterImporter = () => {
  const schema = yup.object().shape({
    firstName: yup.string().required("Imię jest wymagana"),
    lastName: yup.string().required("Nazwisko jest wymagane"),
    email: yup
      .string()
      .email("Podany adres e-mail jest niepoprawny")
      .required("Adres e-mail jest wymagany"),
    password: yup
      .string()
      .required("Hasło jest wymagane")
      .min(8, "Hasło musi zawierać minium 8 znaków")
      .max(32, "Hasło może posiadać maksymalnie 32 znaki."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Podane hasła muszą być takie same"),
    companyNip: yup.string().required("Podaj NIP"),
    agreement: yup.bool().oneOf([true], "Zgoda jest wymagana"),
  });

  const onError = () => {
    toast.error(
      "Wystapił błąd przy próbie zarejestrowania winnicy. Spróbuj ponownie później."
    );
  };
  const onSuccess = () => {
    toast.success("Udało się pomyślnie zarejestrować winnice.");
  };
  const statutes = (required) => {
    return (
      <span>
        Oświadczam, iż zapoznałem się z{" "}
        {
          <CustomLink className="contents" to={routes.regulations}>
            Regulaminem
          </CustomLink>
        }{" "}
        oraz{" "}
        {
          <CustomLink className="contents" to={routes.privacyPolicy}>
            Polityką prywatności
          </CustomLink>
        }
        , i je akceptuję.
        {required ? <span className="text-red-700">*</span> : null}
      </span>
    );
  };
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const {mutate, isLoading, error} = useSignUpImporter()
    const onSubmit = (data) => {
      mutate(data, {onError, onSuccess});
    };
  return (
    <FormProvider {...methods}>
      <form className={"w-full"} onSubmit={handleSubmit(onSubmit)}>
        <CustomLink className={"w-max flex items-center"} to={routes.register}>
          <RiArrowLeftSLine />
          Powrót
        </CustomLink>
        <div className="flex flex-col gap-4 text-gray-800 w-full mb-8">
          <Input
            placeholder={"Imię"}
            required
            name={"firstName"}
            label={"Imię"}
            register={register}
            error={errors.name?.message}
            icon={AiOutlineUser}
          />
          <Input
            placeholder={"Nazwisko"}
            required
            name={"lastName"}
            label={"Nazwisko"}
            register={register}
            error={errors.name?.message}
            icon={AiOutlineUser}
          />
          <Input
            placeholder={"Email"}
            required
            name={"email"}
            label={"Email"}
            register={register}
            error={errors.email?.message}
            icon={MdAlternateEmail}
          />
          <Input
            placeholder={"Hasło"}
            required
            type={"password"}
            name={"password"}
            label={"Hasło"}
            register={register}
            error={errors.password?.message}
            icon={RiLockPasswordLine}
          />
          <Input
            placeholder={"Powtórz hasło"}
            required
            type={"password"}
            name={"confirmPassword"}
            label={"Powtórz hasło"}
            register={register}
            error={errors.confirmPassword?.message}
            icon={RiLockPasswordLine}
          />
          <InputCheckbox label={statutes} name={"agreement"} required />
        </div>

        <FormError
            message={error && "Ten email jest już zajęty."}
            className={"mb-7 p-4 text-sm text-red-700 bg-red-100 rounded-lg"}
        />

        <Submit className={"flex w-full py-3"} isLoading={isLoading}>Zarejstruj się</Submit>
      </form>
    </FormProvider>
  );
};

export default FormRegisterImporter;
