import TemplateMain from '../../../templates/TemplateMain';
import TemplateSection from '../../../templates/TemplateSection';

const ViewPrivacyPolicy = () => {
  return (
    <TemplateMain title={'koszyk'}>
      <TemplateSection>
        polityka prywatnosci
      </TemplateSection>
    </TemplateMain>
  );
};

export default ViewPrivacyPolicy;
