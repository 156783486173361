import React from 'react';
import LineLoader from '../../atoms/loaders/LineLoader';
import RangedFilter from "../../molecules/filters/RangedFilter";
import Card from "../../atoms/card/Card";

const FilterSliders = ({className, state, filters, isLoading}) => {
    return (
        <Card className={'w-60'}>
            <div className={`flex flex-col space-y-5 ${className || ''}`}>
                {
                    isLoading ?
                        [...Array(5).keys()].map((i, id) => <LineLoader key={'loader' + id}
                                                                        isLoading={isLoading}/>) :
                        filters?.length > 0 &&
                        Array.isArray(filters) && filters?.map((filter, id) => {
                            const defaultValue = state[filter?.queryParam]?.value?.split(',')

                            return (
                                    <RangedFilter
                                        defaultValue={defaultValue}
                                        name={filter?.prop}
                                        param={filter?.queryParam}
                                        label={filter?.prop}
                                        max={filter?.max}
                                        min={filter?.min}
                                        key={'winefilter' + id}
                                    />
                                )
                            }
                        )
                }
            </div>
        </Card>
    );
};

export default FilterSliders;
