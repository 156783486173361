import dayjs from "dayjs"

const ContainerEventDetails = ({data, isLoading, refetch}) => {

    return (
        <section className="text-gray-600 body-font">
          <div className="container flex flex-col items-start justify-start">
            <div className="sm:w-full ">
              <div className="flex flex-col sm:flex-row ">
                <div className="sm:w-1/2 text-center sm:pl-4 sm:py-4">
                  <div className="flex flex-col text-start justify-start">
                    <h1 className="font-bold title-font   text-gray-900 text-2xl">{data?.value}</h1>
                    <div className="w-12 h-1 bg-gold2 rounded mt-2 mb-4"></div>
                    <div className="flex flex-col items-start justify-start">
                    <p className="text-base"><span className="font-bold">Data rozpoczęcia zdarzenia:</span> {dayjs(data?.dateTime).format('DD.MM.YYYYr.')}</p>
                    <p className={'text-base'}><span className="font-bold">Typ zdarzenia:</span> {!isLoading ? data?.measurementType?.name: <div> Nie znaleziono typu</div>}</p>
                    </div>
                  </div>
                </div>
                <div className="sm:w-2/3 sm:px-4 sm:pl-8 sm:py-4 sm:mr-4 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                  <p className="text-base font-bold">Opis zdarzenia:</p>
                  <p className="leading-relaxed text-lg mb-4 whitespace-pre-wrap	break-words">{data?.description}</p>

                </div>
              </div>
            </div>
          </div>
        </section>
    )
}

export default ContainerEventDetails