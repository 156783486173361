import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useVineyardErrorImage } from "../../../../app/crud/vineyard/Error/addErrorPhoto";
import { useDeleteMessage } from "../../../../app/crud/vineyard/Error/deleteErrorMessage";
import { useDeleteImageError } from "../../../../app/crud/vineyard/Error/deleteErrorPhoto";
import { useGetMessage } from "../../../../app/crud/vineyard/Error/getErrorMessage";
import { passParams, routes } from "../../../../app/router/routes";
import Card from "../../../atoms/card/Card";
import Button from "../../../atoms/ui/buttons/regular/Button";
import Description from "../../../molecules/description/Description";
import GalleryImageVineyard from "../../../molecules/gallery-image/GalleryImageVineyard";
import Header1 from "../../../molecules/headers/Header1";
import ImageUploaderToGallery from "../../../molecules/image-uploader/ImageUploaderToGallery";
import Text from "../../../molecules/text/Text";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import ModalGenericConfirm from "../../../organisms/modals/ModalGenericConfirm";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../templates/TemplatePanel";

const ViewVineyardErrorDetails = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetMessage();
  const { mutate: mutateDeleteMessage } = useDeleteMessage();
  const { mutate: mutateAddImg } = useVineyardErrorImage();
  const { mutate: mutateDeleteImg } = useDeleteImageError();
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const bc = [
    { label: "Błędy", url: routes.vineyardErrors },
    {
      label: data?.title,
      url: passParams(routes.vineyardError, { id: data?.id }),
    },
  ];

  const onDeleteError = () => {
    toast.error(
      "Wystąpił błąd przy próbie usunięcia wiadomości. Spróbuj ponownie później."
    );
  };

  const onDeleteSuccess = () => {
    toast.success("Zgłoszenie zostało usuniętę.");
  };

  const buttonRenderer = () => {
    return (
      <div className={"flex space-x-4"}>
        <div className={"flex justify-end items-center"}>
          {data?.images?.length === undefined || data?.image?.length <= 1 ? (
            <ImageUploaderToGallery
              url={passParams(routes.vineyardError, { id })}
              tooltip={"Dodaj zdjęcie błędu"}
              className={"mr-2"}
              data={data}
              onAddSuccess={refetch}
              isLoading={isLoading}
              mutation={mutateAddImg}
              onDelete={() => mutateDeleteImg()}
              currentImageUrl={
                data?.images?.url !== "default" && data?.images?.url
              }
            />
          ) : (
            <div
              onClick={() => setOpenModal(false)}
              className={"w-full  relative cursor-pointer"}
            >
              <Button
                disabled
                onClick={() => {
                  setOpenModal(false);
                }}
                className={`text-white text-sm  bg-green-700`}
                tooltip={"Możesz dodać jedno zdjęcie do zgłoszenia"}
              >
                <AiOutlinePlus size={"1.25rem"} />
              </Button>
            </div>
          )}
          <ModalGenericConfirm
            isOpen={confirmDeleteMessage}
            setOpen={setConfirmDeleteMessage}
            onConfirm={() =>
              mutateDeleteMessage(
                {},
                { onError: onDeleteError, onSuccess: onDeleteSuccess }
              )
            }
          >
            Czy na pewno chcesz usunąć zgłoszenie?
          </ModalGenericConfirm>
          <Button
            tooltip={"Usuń produkt"}
            onClick={() => setConfirmDeleteMessage(true)}
            className={
              "text-white text-sm flex items-center bg-red-700 hover:bg-red-600"
            }
          >
            <AiOutlineDelete size={"1.25rem"} />
          </Button>
        </div>
      </div>
    );
  };
  return (
    <TemplatePanel
      title={"Błędy"}
      sideMenuRenderer={() => <VineyardSideMenu />}
      breadcrumbsRenderer={() => (
        <Breadcrumbs
          isLoading={isLoading}
          homeRoute={routes.vineyardDesktop}
          settings={bc}
        />
      )}
      actionButtonsRenderer={buttonRenderer}
      isLoading={isLoading}
    >
      <div className={"flex flex-row space-x-4"}>
        <Card className={"p-8 w-full"}>
          <div className={`flex xl:flex-row w-full`}>
            <div className={"flex flex-col lg:ml-4 w-full"}>
              <div
                className={
                  "flex flex-col justify-between items-center sm:flex-row space-y-5 sm:space-y-0"
                }
              >
                <div isLoading={isLoading}>
                  <p className="text-2xl font-semibold">{data?.title}</p>
                </div>
                <div>
                  <Text className={"text-xs !mt-0 mb-4"}>
                    {dayjs(data?.createdDateTime).format("DD.MM.YYYYr")}
                  </Text>
                </div>
              </div>
              <Description className={"mt-5"} isLoading={isLoading}>
                {data?.text}
              </Description>
            </div>
          </div>
          {data?.image === null ? (
            <div></div>
          ) : (
            <div className="mt-4 w-full flex justify-center">
              <div className="w-72 ml-4">
                <GalleryImageVineyard
                  onDeleteSuccess={refetch}
                  item={data?.image}
                  key={id + "gallery-image"}
                  url={data?.image?.url}
                  alt={"Błąd"}
                />
              </div>
            </div>
          )}
        </Card>
      </div>
    </TemplatePanel>
  );
};
export default ViewVineyardErrorDetails;
