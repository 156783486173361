import { useState } from "react";
import { BeatLoader } from "react-spinners";
import Text from "../../molecules/text/Text";
import LoadingImage from "./LoadingImage";
import PhotoModal from "./PhotoModal";

const VineyardGallery = ({ data, isLoading }) => {
  const [openLightbox, setOpenLightbox] = useState(false);
  return (
    <>
      <div className="flex justify-start">
        {!isLoading && data?.images?.length > 0 ? (
          <LoadingImage
            isLoading={isLoading}
            src={data?.images}
            className="rounded-lg w-16 h-16 cursor-pointer flex mr-4 justify-center"
            onClick={() => setOpenLightbox(true)}
          />
        ) : isLoading ? (
          <BeatLoader />
        ) : (
          <div>Brak zdjęć winnicy do wyświetlenia</div>
        )}

        <PhotoModal
          data={data}
          isLoading={isLoading}
          open={openLightbox}
          setOpen={setOpenLightbox}
        />
      </div>
    </>
  );
};

export default VineyardGallery;
