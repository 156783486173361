import {BASE_URL_API} from '../../../../config/env';

export const vineyardAdvertisementMock = (mock) => {
    mock.onGet(`${BASE_URL_API}/admin/enoturism/advertisements/1`).reply(200, {
        ad: {
            id: 18,
            identifier: 'E16',
            dateStart: '19.01.2022',
            dateEnd: '23.01.2022',
            maxPeople: '4',
            bedOne: '2',
            bedTwo: '2',
            people: '2',
            price: '169',
            status: {id: 0, name: 'Zarezerwowane'},
        },
    });
}