import Logo from "../../atoms/logo/Logo";
import PortalWrapper from "../../atoms/portal-wrapper/PortalWrapper";
import MobileNavSeparator from "../../atoms/separators/MobileNavSeparator";
import LoginCartLang from "../../molecules/header-parts/LoginCartLang";
import NavAndSearch from "../../molecules/header-parts/NavAndSearch";
import React, {useState} from 'react';

export const PublicSideMenu = ({setOpen, isOpen}) => {

    return (
        <PortalWrapper
        onBackdropClick={() => {
          setOpen(false);
        }}
        show={isOpen}
        overrideClass={'fixed z-50 opacity-95 w-60 h-screen top-0 left-0 bg-white transition duration-200 bg-bronze text-gold'}
      >
        <div className={'p-4 space-y-4'}>
          <Logo />
          <MobileNavSeparator />
          <NavAndSearch />
          <MobileNavSeparator />
          <LoginCartLang />
        </div>


      </PortalWrapper>
    )
}

