import React from "react";
import Input from "../../../../atoms/ui/inputs/CustomInput";
import { FormProvider, useForm } from "react-hook-form";
import Textarea from "../../../../atoms/ui/textarea/Textarea";
import Submit from "../../../../atoms/ui/buttons/regular/Submit";
import CustomLink from "../../../../atoms/ui/buttons/links/Link";
import { passParams, routes } from "../../../../../app/router/routes";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "../../../../atoms/card/Card";
import FormConfigureStrains from "../../../../molecules/forms/vineyard/FormConfigureStrains";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useAddContainer } from "../../../../../app/crud/vineyard/containers/addContainer";
import { usePutContainer } from "../../../../../app/crud/vineyard/containers/putContainer";
import { useGetStrains } from "../../../../../app/crud/vineyard/enum/getStrains";
import FormConfigureSensors from "../../../../molecules/forms/containers/FormConfigureSensors";
import { useGetSensorType } from "../../../../../app/crud/vineyard/enum/getSensorType";

const FormCreateContainer = ({ mode, initialData }) => {
  const { data: strains, isLoading: isLoadingStrains } = useGetStrains();
  const { data: sensors, isLoading: isLoadingSensors } = useGetSensorType();
  const navigate = useNavigate();
  const { id } = useParams();

  const schema = yup.object().shape({
    name: yup.string().required("Nazwa jest wymagana"),
    description: yup.string().trim(),
    capacity: yup.string().required("Objętość jest wymagana"),
    containerSensorIds: yup
      .array()
      .required("error")
      .of(
        yup.object().shape({
          id: yup
            .number("Podanie szczepu jest wymagane")
            .typeError("Rodzaj jest wymagany")
            .required("Podanie szczepu jest wymagane"),
        })
      ),
    containerProductionStrains: yup
      .array()
      .required("error")
      .of(
        yup.object().shape({
          productionStrainId: yup
            .number("Podanie szczepu jest wymagane")
            .typeError("Rodzaj jest wymagany")
            .required("Podanie szczepu jest wymagane"),
          amount: yup
            .number()
            .typeError("Rodzaj jest wymagany")
            .required("Podanie zawartości jest wymagane"),
        })
      ),
  });
  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie dodania zbiornika. Spróbuj ponownie później."
    );
  };
  const onSuccess = () => {
    toast.success("Udało się pomyślnie dodać zbiornik.");
  };
  const onErrorPut = () => {
    toast.error(
      "Wystąpił błąd przy próbie edycji zbiornika. Spróbuj ponownie później."
    );
  };
  const onSuccessPut = () => {
    toast.success("Udało się pomyślnie edytować zbiornik.");
    navigate(passParams(routes.vineyardContainers, { id }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    ...form
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialData,
    },
  });
  console.log(errors)
  const { mutate, isLoading } = useAddContainer();
  const { mutate: putMutate, isLoading: putIsLoading } = usePutContainer();

  const onSubmitAdd = (data) => {
    mutate(data, { onSuccess, onError });
  };

  const onSubmitPut = (data) => {
    putMutate(data, { onSuccess: onSuccessPut, onError: onErrorPut });
  };

  return (
    <FormProvider
      {...{
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        ...form,
      }}
    >
      <form
        className={"w-full"}
        onSubmit={handleSubmit(mode === "edit" ? onSubmitPut : onSubmitAdd)}
      >
        <div className={"flex flex-col lg:flex-row w-full justify-between"}>
          <Card
            className={"flex flex-col w-full"}
            title={mode === "edit" ? "Edytuj zbiornik" : "Utwórz zbiornik"}
          >
            <div
              className={
                "flex flex-col sm:flex-row w-full space-y-5 sm:space-y-0 sm:space-x-5"
              }
            >
              <div className={"space-y-5 w-full"}>
                <Input
                  name={"name"}
                  label={"Nazwa"}
                  register={register}
                  error={errors.name?.message}
                  required
                />
                <Input
                  type={"number"}
                  name={"capacity"}
                  label={"Pojemność [l]"}
                  register={register}
                  error={errors.capacity?.message}
                  required
                />
              </div>
              <div className={"space-y-5 w-full"}>
                <Input
                  name={"code"}
                  label={"Kod zbiornika"}
                  register={register}
                  error={errors.name?.message}
                  required
                />
              </div>
            </div>
            <div className={"w-full mt-5"}>
              <Textarea
                name={"description"}
                label={"Opis"}
                register={register}
                error={errors.description?.message}
                maxContent={1500}
                rows={1}
                onChange
              />
            </div>
            <FormConfigureStrains
              name={"containerProductionStrains"}
              setBody={setValue}
              strains={strains}
              errors={errors}
              required
              isLoadingStrains={isLoadingStrains}
              initialValues={initialData?.productionStrains}
            />
            <FormConfigureSensors
              name={"containerSensorIds"}
              initialValues={initialData?.sensors}
              setBody={setValue}
              sensors={sensors}
              errors={errors}
              isLoadingSensors={isLoadingSensors}
              required
            />
            <div
              className={
                "flex flex-row w-full justify-end items-center space-x-4 mt-4"
              }
            >
              <CustomLink to={routes.vineyardProducts}>Wróć</CustomLink>
              {mode === "edit" ? (
                <Submit isLoading={putIsLoading}>Zapisz</Submit>
              ) : (
                <Submit isLoading={isLoading}>Dodaj</Submit>
              )}
            </div>
          </Card>
        </div>
      </form>
    </FormProvider>
  );
};

export default FormCreateContainer;
