import styled from 'styled-components'

import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
// import { ICON_SIZE_MD } from 'app/config/sizes'
import { FiX } from 'react-icons/fi'
// import { FormFieldError } from 'components/form/helpers/HookFormFieldError'
import dayjs from 'dayjs';

const StyledReset = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  &:hover .flatpickr-reset {
    stroke: #000;
    transition: stroke 0.2s;
  }
`

const StyledContainer = styled.div`
  input.form-control {
    opacity: 1 !important;
  }
`

export const InputDatepickerPure = ({
  onChange: setValue,
  value: passedValue,
  error,
  name,
  withTime,
  required,
  disabled,
  isClearable = true,
  innerRef,
  icon: Icon,
  ...props
}) => {
  const placeholder = `${props?.placeholder ?? props?.label ?? ''}${required ? '' : ''}`
  const value = passedValue ? dayjs(passedValue).toDate() : null

  const reset = () => setValue(null)

  const handleDateChange = (a, s) => {
    if (!s) {
      setValue(null)
    } else if (a.length > 0) {
      const date = dayjs(a[0]).format()
      setValue(date)
    }
  }
  const timeControls = withTime ? { 'data-enable-time': true, 'data-time_24hr': true } : {}
  return (
    <StyledContainer className="form-label-group mt-1 position-relative has-icon-left form-group ">
        <label className='text-sm'>
        {props?.label ?? props?.placeholder ?? ''}
        {required && <span className="text-danger text-red-700">*</span>}
      </label>
      <div className='flex items-center'>
      <Flatpickr
        value={value}
        name={name}
        onChange={handleDateChange}
        disabled={disabled}
        ref={innerRef}
        {...timeControls}
        {...props}
        placeholder={placeholder}
        className={`form-control border border-gray-400 pl-2 w-64 rounded-lg bg-gray-50  h-8 placeholder:text-sm ${props?.className ?? ''}`}
      />
      <div className="form-control-position ml-1">
        <Icon size={'1.25rem'}/>
      </div>
      </div>
      {isClearable && (
        <StyledReset onClick={reset}>
          <FiX  color={'#000'} className="flatpickr-reset" />
        </StyledReset>
      )}
      {/* <FormFieldError error={error} /> */}
    </StyledContainer>
  )
}
