import { combineReducers } from 'redux';
import { authReducer } from './authReducer/authReducer';
import { cartReducer } from './cartReducer/cartReducer';
import {orderReducer} from './orderReducer/orderReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  order: orderReducer,
});

export default rootReducer;
