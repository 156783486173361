import React from 'react';
import Header1 from "../../molecules/headers/Header1";

const FormCard = ({title, children, bottomContent, disableBorder}) => {
    return (
        <div className={"flex flex-col items-center w-full"}>
            <div className={`max-w-lg bg-gray-50 rounded-lg ${disableBorder? '' : 'border border-gray-100 shadow-sm'}  w-full px-8 py-8`}>
                {title && <Header1 className="text-gray-800 text-2xl lg:text-3xl font-bold mb-4 md:mb-8">{title}</Header1>}

                {children}
            </div>
            {
                bottomContent &&
                <div className="bg-gray-100 p-4 max-w-lg w-full">
                    {bottomContent()}
                </div>
            }

        </div>
    );
};

export default FormCard;
