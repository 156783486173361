import aroma from "../../../app/assets/svg/aroma.png";
const Aroma = ({data, isLoading}) => {
  return (
    data?.wineAromas?.length > 0 ? (
          <div className=" py-6 sm:py-8 lg:py-12">
      <div className="max-w-screen-xl  mx-auto">
        <div>
          <p className="text-gray-800 mb-4 text-xl lg:text-xl  text-start ">
            CHARAKTERYSTYCZNE AROMATY
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-8 ">
        {data?.wineAromas && data?.wineAromas?.map((item, id) => (
        <div key={id} className="flex flex-col items-center  rounded-lg">
          <div className="w-24 md:w-24 h-24 md:h-24 flex justify-center items-center  rounded-full overflow-hidden ">
            <img
              src={item?.imageUrl || aroma}
              loading="lazy"
              alt="Aromat"
              className="flex justify-center items-center"
            />
          </div>
          <div>
            <div className="text-gray-900 md:text-lg font-bold text-center">
              {item?.name}
            </div>
          </div>
        </div>
        ))}

      </div>
      </div>
    </div>
    ) :null

  );
};

export default Aroma;
