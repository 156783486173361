import TemplateMain from "../../../templates/TemplateMain";
import Cart from "../../../organisms/cart/Cart";
import TemplateSection from "../../../templates/TemplateSection";
import ProgressBar from "../../../organisms/cart/ProgressBar";
import { routes } from "../../../../app/router/routes";
import { useSelectProducts } from "../../../../app/redux/cartReducer/cartSelectors";
import TemplateCart from "../../../templates/TemplateCart";
import { useSelectToken } from "../../../../app/redux/authReducer/authSelectors";
import Text from "../../../molecules/text/Text";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import { AiOutlineArrowLeft } from "react-icons/ai";
import React from "react";

const ViewCart = () => {
  const token = useSelectToken();
  const progressBarConfig = [
    { url: routes.cart, done: false, active: true, label: "Koszyk" },
    {
      url: routes.checkout,
      done: false,
      active: false,
      label: "Dostawa i płatność",
    },
    {
      url: routes.cartSummary,
      done: false,
      active: false,
      label: "Podsumowanie",
    },
    { url: routes.cart, done: false, active: false, label: "Gotowe" },
  ];
  const summaryConfig = {
    route: token ? routes.checkout : routes.checkoutAccount,
    label: "Przejdź do dostawy",
    info: "Gwarancja wysyłki w ciągu 48 godzin.",
  };
  const cart = useSelectProducts();
  const products = useSelectProducts();
  return (
    <TemplateMain title={"Koszyk"}>
      {products.length === 0 ? (
        <div className={"flex mt-16 justify-center flex-col items-center"}>
          <Text className={"font-bold text-2xl text-gray-600"}>
            Twój koszyk jest pusty.
          </Text>
          <img
            className={`w-96 rounded-lg mt-8`}
            src={"/png/empty_trolley.png"}
            alt={"/"}
          />
          <ButtonLink className={"flex mt-8 mb-16 items-center"} to={routes.wines}>
            <AiOutlineArrowLeft className={"mr-2"} />
            Wróć do sklepu
          </ButtonLink>
        </div>
      ) : (
        <TemplateCart
          progressBarConfig={progressBarConfig}
          summaryConfig={summaryConfig}
          title={cart?.length > 0 && "Koszyk"}
        >
          <Cart/>
        </TemplateCart>
      )}
    </TemplateMain>
  );
};

export default ViewCart;
