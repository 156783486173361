import { BASE_URL_API } from '../../../../config/env';

export const filtersWinesMock = (mock) => {
  mock.onGet(`${BASE_URL_API}/wine-filters`).reply(200, {
    filters: [
      {
        prop: 'Kolor',
        queryParam: 'color',
        values: [{id: 1, name: 'białe'}, {id: 2, name: 'czerwone'}]
      },
      {
        prop: 'Rodzaj',
        queryParam: 'type',
        values: [{id: 1, name: 'wytrawne'},{id: 2, name: 'słodkie'},{id: 3, name: 'musujące'},{id: 4, name: 'wzmacniane'}]
      },

    ],
    rangedFilters: [
        {
            "name": "Rok produkcji",
            "queryParam": "ProductionYearRange",
            "min": 1,
            "max": 1900
        },
        {
            "name": "Alkohol",
            "queryParam": "AlcoholContentRange",
            "min": 1,
            "max": 55
        }
    ]
  });
};
