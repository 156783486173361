import { BASE_URL_API } from '../../../../config/env';

export const wineMock = (mock) => {
  mock.onGet(`${BASE_URL_API}/v1/api/wines/1`).reply(200, {
    id: 1,
    name: 'CANACE NERO DI TROIA',
    slug: 'canace-nero-di-toria',
    vineyardSlug: 'apulia',
    vineyard: 'APULIA',
    priceGross: 118.0,
    rate: 4.9,
    opinions: 163,
    description:
      'Bogaty nos złożony z aromatów owocowych (dojrzała gruszka) oraz nut kwiatowych, mineralnych i zapachu skórki chleba. Usta doskonale zbudowane, o skoncentrowanym smaku i wyraźnej, świeżej kwasowości oraz długim, rześkim posmaku. Idealny wybór do łososia w ziołach, pieczonego kurczaka z grzybami leśnymi lub do makaronu z kremowym sosem na bazie chardonnay.',
    image: {
      url: 'https://media.istockphoto.com/photos/red-wine-bottle-with-blank-label-picture-id1138615651?s=612x612',
      alt: 'alt'
    },
    wineTaste: {
      id: 0,
      name: 'wytrawne'
    },
    wineColour: {
      id: 0,
      name: 'czerwone'
    },
    quantity: 5,
    productionYear: 1997,
    capacity: 0.7,
    alcoholContent: 13,
    productionRegionName: 'APULIA',
    productionStrainName: 'Szczep1',
    vineyardId: 0,
    vineyardName: 'APULIA'
  });
};
