import { useGetVineyardBlog } from "../../../../app/crud/app/blog/getBlog";
import { passParams, routes } from "../../../../app/router/routes";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import { FormCreateBlog } from "../../../organisms/forms/create-blog/FormCreateBlog";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../templates/TemplatePanel";

const ViewVineyardEditBlog = () => {
  const { data, isLoading } = useGetVineyardBlog();
  const bc = [
    { label: "Wpsiy", url: routes.vineyardPanelBlogs },
    {label: data?.title, url: passParams(routes.vineyardPanelBlog, {id: data?.id})},
    {
      label: "Edytuj",
      url: passParams(routes.vineyardPanelBlogsEdit, { id: data?.id }),
    },
  ];

  return (
    <TemplatePanel
        isLoading={isLoading}
        title={'Edytuj wpis'}
        sideMenuRenderer={() => <VineyardSideMenu/>}
        breadcrumbsRenderer={() => <Breadcrumbs isLoading={isLoading} homeRoute={routes.vineyardDesktop} settings={bc}/>}
    >
        <FormCreateBlog mode={'edit'} initialData={data}/>
    </TemplatePanel>
  )
};

export default ViewVineyardEditBlog