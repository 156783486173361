import TemplateMain from '../../templates/TemplateMain';
import TemplateSection from '../../templates/TemplateSection';
import ForVineyardCard from '../../organisms/for-vineyard/ForVineyardCard';
import IconDescription from '../../molecules/for-vineyard/IconDescription';

const ViewForVineyard = () => {
  return (
    <TemplateMain title={'Dla winnicy'}>
      <TemplateSection >
        <ForVineyardCard
          title={"Sprzedaż"}
          image={{url: "https://domwina.pl/data/include/cms/o_nas/foto16.png", alt: "/"}}
          description={"Dołącz do nas i sprzedawaj swoje produkty na winopasja.pl"}
        >
          <div className={'space-y-5 mt-5'}>
            <IconDescription>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IconDescription>
            <IconDescription>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</IconDescription>
            <IconDescription>when an unknown printer took a galley of type and scrambled it to make a type specimen book</IconDescription>
          </div>
        </ForVineyardCard>
      </TemplateSection>

      <TemplateSection dark>
        <ForVineyardCard
          reverse
          title={"Marketing"}
          image={{url: "https://domwina.pl/data/include/cms/o_nas/foto9.png", alt: "/"}}
          description={"Reklamuj swoje produkty"}
        >
          <div className={'space-y-5 mt-5'}>
            <IconDescription>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</IconDescription>
            <IconDescription>Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur</IconDescription>
            <IconDescription>rom a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</IconDescription>
          </div>
        </ForVineyardCard>
      </TemplateSection>

      <TemplateSection>
        <ForVineyardCard
          title={"Zarządzanie"}
          image={{url: "https://domwina.pl/data/include/cms/o_nas/foto17.png", alt: "/"}}
          description={"Zarządzaj swoją winiarnią w jednym miejscu"}
        >
          <div className={'space-y-5 mt-5'}>
            <IconDescription>Twórz i wystawiaj na sprzedaż swoje produkty w wygodnym panelu.</IconDescription>
            <IconDescription>Zarządzaj swoimi zamówieniami</IconDescription>
            <IconDescription>Gromadź swoją korespondencję w jednym miejscu</IconDescription>
          </div>
        </ForVineyardCard>
      </TemplateSection>
    </TemplateMain>
  );
};

export default ViewForVineyard;
