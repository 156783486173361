import axios from '../../../config/axios';
import {BASE_URL_API} from '../../../config/env';
import {useMutation} from 'react-query';
import Cookies from "universal-cookie";

export const useDeleteProductFromCart = () => {
    const cookies = new Cookies();
    return useMutation((data) => {
        return axios.delete(`${BASE_URL_API}/api/v1/cart/${data?.id}?guestGuid=${cookies.get("guestGuid")}`)
            .then(({data}) => {
                return data
            })
    });
};