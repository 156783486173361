import TemplatePanel from "../../../templates/TemplatePanel";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import React, {useState} from "react";
import {passParams, routes} from "../../../../app/router/routes";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import {useGetVineyardProduct} from "../../../../app/crud/vineyard/wine/getWine";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import {AiFillEdit, AiOutlineDelete} from "react-icons/ai";
import ProductDescription from "../../../organisms/product-details/ProductDescription";
import ProductDetails from "../../../organisms/product-details/ProductDetails";
import Card from "../../../atoms/card/Card";
import ImageUploader from "../../../molecules/image-uploader/ImageUploader";
import {usePutProductImage} from "../../../../app/crud/vineyard/wine/putProductImage";
import ToggleButton from "../../../atoms/ui/buttons/toggle/ToggleButton";
import {usePutProductStatus} from "../../../../app/crud/vineyard/wine/putProductStatus";
import toast from "react-hot-toast";
import Button from "../../../atoms/ui/buttons/regular/Button";
import ModalGenericConfirm from "../../../organisms/modals/ModalGenericConfirm";
import {useDeleteProduct} from "../../../../app/crud/vineyard/wine/deleteVineyardProduct";
import {useDeleteProductImage} from "../../../../app/crud/vineyard/wine/deleteProductImage";
import { ProductComments } from "../../../organisms/comments/Comments";
import { ProductCommentsAdmin } from "../../../organisms/comments/CommentsAdmin";
import TemplateSection from "../../../templates/TemplateSection";
import { useParams } from "react-router-dom";
import { useGetRatings } from "../../../../app/crud/customer/ratings/getRatings";

const ViewVineyardProduct = () => {
    const { id } = useParams();
    const { data: dataRatings, isLoading: isLoadingRatings } = useGetRatings(id);
    const {data, isLoading} = useGetVineyardProduct();
    const [confirmDeleteProductModalOpen, setConfirmDeleteProductModalOpen] =
        useState(false);
    const {mutate: mutateDeleteProduct} = useDeleteProduct();
    const {mutate: mutatePublishStatus} = usePutProductStatus();
    const {mutate: mutateDeleteImage} = useDeleteProductImage();
    const bc = [
        {label: "Wina", url: routes.vineyardProducts},
        {
            label: data?.name,
            url: passParams(routes.vineyardProduct, {id: data?.id}),
        },
    ];
    const {mutate: mutateImage} = usePutProductImage();
    const onDeleteError = () => {
        toast.error(
            "Wystąpił błąd przy próbie usunięcia produkty. Spróbuj ponownie później."
        );
    };
    const onDeleteSuccess = () => {
        toast.success("Produkt został usunięty pomyślnie.");
    };

    const onDeleteImageSuccess = () => {
        toast.success("Zdjęcie został usunięte pomyślnie.");
    };
    const onDeleteImageError = () => {
        toast.error("Nie udało się usunąć zdjęcia. Spróbuj ponownie.");
    };
    const buttonsRenderer = () => {
        return (
            <div className={"flex space-x-4"}>
                <div className={"flex justify-end items-center"}>
                    <ToggleButton
                        label={"Opublikuj"}
                        initVal={data?.publishStatus?.id === 10}
                        onClick={(val) =>
                            mutatePublishStatus({isPublic: val}, {onError, onSuccess})
                        }
                    />
                </div>
                <ModalGenericConfirm
                    isOpen={confirmDeleteProductModalOpen}
                    setOpen={setConfirmDeleteProductModalOpen}
                    onConfirm={() =>
                        mutateDeleteProduct(
                            {},
                            {onSuccess: onDeleteSuccess, onError: onDeleteError}
                        )
                    }
                >
                    Czy na pewno chcesz usunąć produkt?
                </ModalGenericConfirm>
                <ButtonLink
                    tooltip={'Edytuj produkt'}
                    to={passParams(routes.vineyardEditProduct, {id: data?.id})}
                    className={
                        "text-white text-sm flex items-center bg-gold2 hover:bg-gold"
                    }
                >
                    <AiFillEdit size={'1.25rem'} />
                </ButtonLink>
                <Button
                    tooltip={'Usuń produkt'}
                    onClick={() => setConfirmDeleteProductModalOpen(true)}
                    className={
                        "text-white text-sm flex items-center bg-red-700 hover:bg-red-600"
                    }
                >
                    <AiOutlineDelete size={'1.25rem'} />
                    
                </Button>
            </div>
        );
    };

    const onError = () => {
        toast.error(
            "Wystpąpił błąd poczas próby zmiany widoczności wina. Spróbuj ponownie później."
        );
    };
    const onSuccess = () => {
        toast.success("Zmiana statusu widoczności wina przebiegła pomyślnie.");
    };

    return (
        <TemplatePanel
            title={"Produkt"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            className={'block !md:flex h-16 md:h-8'}
            breadcrumbsRenderer={() => (
                <Breadcrumbs
                    isLoading={isLoading}
                    homeRoute={routes.vineyardDesktop}
                    settings={bc}
                />
            )}
            actionButtonsRenderer={buttonsRenderer}
            isLoading={isLoading}
        >
            <div className={"flex flex-row space-x-4"}>
                <Card className={"p-16 md:pt-8 w-full"}>
                    <ProductDescription
                        data={data}
                        isLoading={isLoading}
                        imageRenderer={() => (
                            <ImageUploader
                                onDelete={()=>mutateDeleteImage({}, {onSuccess: onDeleteImageSuccess, onError: onDeleteImageError})}
                                mutation={mutateImage}
                                currentImageUrl={data?.imageUrl || "https://www.vinowineshopnc.com/wp-content/uploads/2015/03/burgundy_wine_bottle_placeholder-300x300.jpg"}
                            />
                        )}
                    />
                    <ProductDetails
                        className={"mt-16"}
                        data={data}
                        isLoading={isLoading}
                    />
                    <TemplateSection title={"Komentarze"} className={'mt-16'}>
                        {data?.wineRatings?.length > 0 ?
                    <ProductCommentsAdmin data={dataRatings} isLoading={isLoadingRatings}/> : <div className="text-center sm:text-start">Brak komentarzy</div>}
                    </TemplateSection>
                </Card>
            </div>
        </TemplatePanel>
    );
};

export default ViewVineyardProduct;
