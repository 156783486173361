import Breadcrumbs from "../../../../organisms/breadcrumbs/Breadcrumbs";
import React from "react";
import { useGetContainer } from "../../../../../app/crud/vineyard/containers/getContainer";
import { passParams, routes } from "../../../../../app/router/routes";
import FormCreateEventsContainer from "../../../../organisms/forms/event-containers/FormCreateEventsContainer";
import VineyardSideMenu from "../../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../../templates/TemplatePanel";
import { useGetEvents2 } from "../../../../../app/crud/vineyard/containersEvent/getEvents2";
import { useGetEvent } from "../../../../../app/crud/vineyard/containersEvent/getEvent";

const ViewVineyardEditContainerEvent = () => {
    const { data } = useGetContainer();
    const { data: dataEvent, isLoading: isLoadingEvents, refetch } = useGetEvent();
    const bc = [
        { label: "Zbiorniki", url: routes.vineyardContainers },
        {
          label: data?.name,
          url: passParams(routes.vineyardContainer, { id: data?.id }),
        },
        {
            label: "Zdarzenia",
            url: passParams(routes.vineyardViewEventContainer, { id: data?.id })
        },
        {
            label: dataEvent?.value,
            url: passParams(routes.vineyardViewSingleEventContainer, {id: data?.id, eventId: dataEvent?.id})
        },
        {
            label: "Edytuj",
            url: passParams(routes.vineyardContainerEventEdit, {id: data?.id, eventId: dataEvent?.id})
        }

    ];

    return (
            <TemplatePanel
            title={"Zbiornik"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => (
                <Breadcrumbs
                  homeRoute={routes.vineyardDesktop}
                  settings={bc}
                />
              )}
            >
            <FormCreateEventsContainer mode={'edit'}  initialData={dataEvent} refetch={refetch}/>
            </TemplatePanel>
    )
};

export default ViewVineyardEditContainerEvent;
