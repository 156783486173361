import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routes } from '../../../app/router/routes';

const Logo = ({ className, disable }) => {
  return (
    <Link to={routes.home} className={`${className} ${disable ? 'pointer-events-none' : ''}`}>
      <img
        className={`w-16 h-16 rounded-lg`}
        src={'/png/logo-03.png'}
        alt={'/'}
      />
    </Link>
  );
};

Logo.propTypes = {
  className: PropTypes.string
};

export default Logo;
