import React from "react";
import { passParams, routes } from "../../../app/router/routes";
import { actionAuthLogout } from "../../../app/redux/authReducer/authActionTypes";
import NavLink from "../../atoms/ui/buttons/links/NavLink";
import { FaSignOutAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import Logo from "../../atoms/logo/Logo";
import BurgerButton from "../../atoms/ui/buttons/regular/Burger";
import { useVineyardProfile } from "../../../app/crud/vineyard/profile/getProfile";
import CustomLink from "../../atoms/ui/buttons/links/Link";
import Text from "../../molecules/text/Text";
import LogoBlack from "../../atoms/logo/Logo-black";

const Header = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useVineyardProfile();
  return (
    <header
      className={
        "flex bg-white items-center w-full p-4 justify-between space-x-5"
      }
    >
      <div className="flex items-center justify-between">
        <div className="lg:hidden">
          <BurgerButton onClick={() => setOpen(true)} />
        </div>
        <NavLink
          className={"items-start flex"}
          to={routes.home}
          disableAnimation
        >
          <div className={"flex items-center space-x-2 ml-4 lg:ml-0"}>
            <LogoBlack />
            <span className="hidden md:block text-black text-lg">
              Wino Pasja
            </span>
          </div>
        </NavLink>
      </div>

      <div className="flex items-center">
        {data?.name && data?.name?.length > 0 ? (
          <div className="flex items-center space-x-2 mr-4">
            <Text>Witaj,</Text>
            <CustomLink
              to={passParams(routes.vineyard, { vineyardId: data?.id })}
            >
              <div className="space-y-1 font-bold text-gray-800 hover:text-gray-700">
                <div>{data?.name}</div>
              </div>
            </CustomLink>
          </div>
        ) : null}

        <div
          className={"flex items-center"}
          onClick={() => dispatch(actionAuthLogout())}
        >
          <NavLink
            disableAnimation
            className={"ml-auto mr-4 text-black flex flex-row items-center"}
            to={routes.login}
          >
            <FaSignOutAlt className={"mr-2 hidden sm:block"} />
            Wyloguj
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
