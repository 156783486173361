import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { createTheme } from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import debounce from "lodash.debounce";

const theme = createTheme({
    palette: {
        primary: {
            main: '#E1C078',
            darker: '#C6A760',
        }
    },
});

export default function RangeSlider({defaultValue, min, max, onMouseUp, onChange, param, valueCallback}) {
    const [value, setValue] = React.useState(null);
    const [eventTriggered, setEventTriggered] = useState(false)
    useEffect(()=>{
        if(defaultValue)
            setValue(defaultValue?.map(val => parseInt(val)))
        else {
            setValue([min, max])
        }
    }, [min, max, defaultValue])

    const handleChange = (e) => {
        valueCallback(e.target.value)
        setValue(e.target.value);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: 208 }}>
                <Slider
                    color={"primary"}
                    max={max}
                    min={min}
                    value={value}
                    onMouseDown={()=>{
                        setEventTriggered(true)
                    }}
                    onMouseUp={()=>{
                        if(eventTriggered){
                            onMouseUp();
                            setEventTriggered(prev=>false)
                        }
                    }}
                    onMouseLeave={()=>{
                        if(eventTriggered){
                            onMouseUp();
                            setEventTriggered(prev=>false)
                        }
                    }}
                    onChange={handleChange}
                />
            </Box>
        </ThemeProvider>

    );
}
