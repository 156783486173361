import PropTypes from 'prop-types';
import LineLoader from '../../atoms/loaders/LineLoader';

const Price = ({ value, className, isLoading, classNames }) => {
  if(!value && value !== 0) return null;
  return (
    <LineLoader className={className} isLoading={isLoading} classNames={classNames || ['w-1/6']}>
      <p className={`text-green-700 font-bold text-xl ${className}`}>{value} zł</p>
    </LineLoader>
  );
};

Price.propTypes = {
  value: PropTypes.number,
  classNames: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Price;
