import React from "react";
import ProductCard from "../../molecules/product-card/ProductCard";
import { ToastContainer } from "react-toastify";
import Text from "../../molecules/text/Text";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
const ProductListVineyard = ({ isFetching, data }) => {
  const options = { perPage: 2, rewind: true, gap: "1rem", pagination: false };
  return (
    <Splide options={options} aria-labelledby="basic-example-heading">
      {isFetching ? (
        [...Array(2).keys()].map((card, index) => (
          <SplideSlide key={index + "slideloader"}>
            <ProductCard isLoading={isFetching} />
          </SplideSlide>
        ))
      ) : data?.items?.length === 0 ? (
        <Text>Brak produktów do wyświetlenia</Text>
      ) : (
        data?.items?.map((product, index) => (
          <SplideSlide key={index}>
            <ProductCard
              className={"mb-4"}
              product={product}
              isLoading={isFetching}
            />
          </SplideSlide>
        ))
      )}
    </Splide>
  );
};

export default ProductListVineyard;
