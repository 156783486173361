import TemplatePanel from "../../../templates/TemplatePanel";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import React, { useMemo } from "react";
import { passParams, routes } from "../../../../app/router/routes";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import { AiFillEdit } from "react-icons/ai";
import { useVineyardProfile } from "../../../../app/crud/vineyard/profile/getProfile";
import Card from "../../../atoms/card/Card";
import ImageUploader from "../../../molecules/image-uploader/ImageUploader";
import { useVineyardProfileImage } from "../../../../app/crud/vineyard/profile/putVineyardPofileImage";
import ToggleButton from "../../../atoms/ui/buttons/toggle/ToggleButton";
import { usePutVineyardStatus } from "../../../../app/crud/vineyard/profile/putVineyardStatus";
import toast from "react-hot-toast";
import { useDeleteProfileImage } from "../../../../app/crud/vineyard/profile/deleteVineyardProfileImage";
import Text from "../../../molecules/text/Text";
import { useVineyardLogoImage } from "../../../../app/crud/vineyard/profile/putVineyardLogoImage";
import { useDeleteLogoImage } from "../../../../app/crud/vineyard/profile/deleteVineyardLogoImage";
import { IoEarthSharp } from "react-icons/io5";
import Table from "../../../atoms/Table/Table";
import blog_mock from "../../../../app/assets/svg/blog_mock.svg"

const ViewVineyardProfile = () => {
  const bc = [{ label: "Profil", url: routes.vineyardProfile }];
  const { data, isLoading } = useVineyardProfile();
  const { mutate: mutateDeleteProfileImage } = useDeleteProfileImage();
  const { mutate: mutateVineyardStatus } = usePutVineyardStatus();
  const { mutate } = useVineyardProfileImage();
  const { mutate: mutateVineyardLogo } = useVineyardLogoImage();
  const { mutate: mutateDeleteVineyardLogo } = useDeleteLogoImage();
  const buttonRenderer = () => {
    return (
      <div className={"flex space-x-4"}>
        <div className={"flex justify-center items-center"}>
          <ToggleButton
            label={"Opublikuj"}
            initVal={data?.publishStatus?.id === 10}
            onClick={(val) =>
              mutateVineyardStatus({ isPublic: val }, { onSuccess, onError })
            }
          />
        </div>
        <ButtonLink
          tooltip={"Edytuj profil"}
          to={passParams(routes.vineyardProfileEdit, { id: data?.id })}
          className={
            "text-white flex flex-row items-center text-sm bg-gold2 hover:bg-gold"
          }
        >
          <AiFillEdit size={"1.25rem"} />
        </ButtonLink>
      </div>
    );
  };
  const onError = () => {
    toast.error(
      "Wystpąpił błąd poczas próby publikacji. Spróbuj ponownie później."
    );
  };
  const onSuccess = () => {
    toast.success("Próba publikacji przebiegła pomyślnie.");
  };

  const VineyardInfo = useMemo(
    () => [
      ["Email:", data?.emial],
      ["Nr telefonu:", data?.phoneNumber],
      ["Kraj pochodzenia winnicy:", data?.originCountryName],
      [
        "Adres",
        <div className="flex items-center gap-2" key={data?.id}>
          <span>
            {data?.address?.street} {data?.address?.houseNumber}{" "}
            {data?.address?.postalCode} {data?.address?.city}
          </span>
          {!!(
            data?.localization?.latitude && data?.localization?.longitude
          ) && (
            <a
              href={`http://www.google.com/maps/place/${data?.localization?.latitude},${data?.localization?.longitude}`}
              target="_blank"
              rel="noreferrer"
              className="ml-1 opacity-40"
              title="Zobacz na mapie"
            >
              <IoEarthSharp />
            </a>
          )}
        </div>,
      ],
      ["Rok załozenia winnicy:", data?.foundYear],

    ],
    [data]
  );

  return (
    <TemplatePanel
      title={"Profil"}
      isLoading={isLoading}
      sideMenuRenderer={() => <VineyardSideMenu />}
      actionButtonsRenderer={buttonRenderer}
      breadcrumbsRenderer={() => (
        <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc} />
      )}
    >
      <div className={"flex flex-row space-x-4"}>
        <Card className={"p-16 w-full"}>
          <div
            className={
              "flex w-full space-x-16 md:flex-row  flex-col justify-start lg:items-start items-center"
            }
          >
            <div
              className={
                "rounded-lg overflow-hidden flex mt-8 lg:mt-0 w-[324px] min-w-[324px]"
              }
            >
              <div>
                <ImageUploader
                  photo={blog_mock}
                  editLogo={"Edytuj logo winnicy"}
                  deleteLogo={"Usuń logo winnicy"}
                  className={"!h-48 !w-48"}
                  isLoading={isLoading}
                  mutation={mutateVineyardLogo}
                  onDelete={() => mutateDeleteVineyardLogo()}
                  currentImageUrl={
                    data?.profileLogoUrl !== "default" && data?.profileLogoUrl
                  }
                />
              </div>
              <div></div>
            </div>
            <div className={"space-y-2  !ml-0 md:mt-0 mt-4"}>
              <div className="text-2xl font-bold break-words2 flex gap-4 items-center">
                {data?.name}
              </div>
              <Text className={"text-justify"}>{data?.descriptionShort}</Text>
              <Table
                data={VineyardInfo}
                firstColumnStyles="text-gray-400"
                conditionColumn={1}
              />
            </div>
          </div>
          <div className={"mt-10 space-y-2"}>
            <Text className={"text-justify"} isLoading={isLoading}>
              {data?.descriptionLong}
            </Text>
          </div>
          <div className="flex justify-center items-center mt-4">
            <div
              className={
                "rounded-lg overflow-hidden flex justify-center items-center  mt-8 lg:mt-0 w-[324px] min-w-[324px]"
              }
            >
              <div>
                <ImageUploader
                photo={blog_mock}
                  editProfilePhoto={"Edyutj zdjęcie profilowe winnicy"}
                  deleteProfilePhoto={"Usuń zdjęcie profilowe winnicy"}
                  isLoading={isLoading}
                  mutation={mutate}
                  onDelete={() => mutateDeleteProfileImage()}
                  currentImageUrl={
                    data?.profilePhotoUrl !== "default" && data?.profilePhotoUrl
                  }
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </TemplatePanel>
  );
};

export default ViewVineyardProfile;
