import React, {useEffect, useState} from 'react';

const Checkbox = ({
                      checked,
                      name,
                      children,
                      className,
                      onChange,
                      readOnly,
                      required,
                      register,
                      error,
                      ...res
                  }) => {
    const [value, setValue] = useState(false)

    useEffect(()=>{
        setValue(checked)
    }, [checked])

    const handleChange = e => {
        onChange && onChange(e?.target?.checked || false)
        setValue(e?.target?.checked)
    }

    return (
        <label>
            <div className={`cursor-pointer flex flex-row items-center ${className}`}>
                <input
                    {...(register ? register(name, {required}) : {})}
                    readOnly={readOnly}
                    {...res}
                    checked={value}
                    onClick={handleChange}
                    className={'mr-4'}
                    type={'checkbox'}
                />
                {children}{required ? <span className='text-red-600'>*</span> : ''}
            </div>

            <p className={'text-xs text-red-500 h-px'}>{error}</p>

        </label>


    );
};

export default Checkbox;
