import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import useQuery2 from '../../../hooks/useQuery2';

const getWineColors = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL_API}/api/v1/enums/wine-colour`
  })
    .then(({ data }) => data)

    export const getColorsHelper = {
      queryKey: ['app-public.wineColors'],
      queryFn: () => getWineColors(),
      isArray: true
    }

export const useGetWineColors = () => {
  return useQuery2({
    queryKey: ['app-public.wineColors'],
    queryFn: () => getWineColors()
  });
};
