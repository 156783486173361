import axios from '../../config/axios';
import {BASE_URL_API} from '../../config/env';
import {useMutation} from 'react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {actionAuthLoginWithEmail} from '../../redux/authReducer/authActionTypes';
import {routes} from '../../router/routes';
import Cookies from "universal-cookie";

export const useSignIn = (redirect) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname


    return useMutation((data) => {
        const cookies = new Cookies()
        const data_with_guestGuid = {...data, guestGuid: cookies.get("guestGuid")}
        return axios.post(`${BASE_URL_API}/api/v1/account/sign-in-order`, data_with_guestGuid)
            .then(({data}) => data)
            .then(data => {
                const redux = {token: data.accessToken, role: data.roles}
                dispatch(actionAuthLoginWithEmail(redux))
                return data;
            })
            .then(data => {
                data.roles.includes('User') ?
                    navigate(redirect || from || routes.customerOrders, {replace: true})
                    :
                    navigate(redirect || from || routes.vineyardProducts, {replace: true});
                return data
            })
    });
};
