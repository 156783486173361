import axios from '../../../config/axios';
import {BASE_URL_API} from '../../../config/env';
import {useMutation} from 'react-query';

export const useAddToCart = () => {
    return useMutation((data) => {
        return axios.post(`${BASE_URL_API}/api/v1/cart`, data)
            .then(({data}) => {
                return data
            })
    });
};