import React from 'react';
import Card from "../../../atoms/card/Card";
import Textarea from "../../../atoms/ui/textarea/Textarea";
import {AiOutlineInfoCircle} from "react-icons/ai";

const CartSummaryCommentCard = () => {
    return (
        <Card title={"Komentarz"}>
            <p className={'flex items-center w-full  italic text-sm mt-4'}>
                <AiOutlineInfoCircle className={'mr-1'}/>
                Jeżeli masz jakieś uwagi do zamówienia, zapisz je tutaj, a na pewno weźmiemy je pod uwagę.
            </p>
            <Textarea placeholder={"Uwagi.."}/>
        </Card>
    );
};

export default CartSummaryCommentCard;
