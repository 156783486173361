import { useGetAdvertisement } from "../../../../app/crud/vineyard/enoturism/getEnoturism"
import { passParams, routes } from "../../../../app/router/routes"
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs"
import TemplateMain from "../../../templates/TemplateMain"
import TemplateSection from "../../../templates/TemplateSection"
import EnoturismDetails from "./EnoturismDetails"

const ViewEnoturismDetails = () => {
    const {data, isLoading} = useGetAdvertisement()
    const bc = [{label: 'Enoturystyka', url: routes.enoturism}, {label: data?.name, url: passParams(routes.enoturismDetails, {id: data?.id})}]
    return (
        <TemplateMain
            title={'Enoturystyka'}
            breadcrumbsRenderer={() => <Breadcrumbs isLoading={isLoading} homeRoute={routes.home} settings={bc}/>}
        >
            <TemplateSection>
                <EnoturismDetails data={data} isLoading={isLoading}/>
            </TemplateSection>
        </TemplateMain>
    )
}

export default ViewEnoturismDetails