import axios from '../../config/axios';
import {BASE_URL_API} from '../../config/env';
import {useMutation} from 'react-query';


export const usePlaceOrder = () => {
    return useMutation((data) => {
        return axios.post(`${BASE_URL_API}/api/v1/cart/place-order`, data)
            .then(({data}) => {
                // dispatch(actionOrderView({value: data}))
                // navigate(routes.cartSummary)
                return data
            })
    });
};