import {Routes, Route, useLocation} from 'react-router-dom';
import {useLayoutEffect} from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';
import {routes} from './app/router/routes';

import ViewLogin from './components/views/app-public/auth/ViewLogin';
import ViewRegister from './components/views/app-public/auth/ViewRegister';
import ViewForgotPassword from './components/views/app-public/auth/ViewForgotPassword';
import ViewChangePassword from './components/views/app-public/auth/ViewChangePassword';
import ViewHome from './components/views/app-public/ViewHome';
import ViewVineyards from './components/views/app-public/vineyard/ViewVineyards';
import ViewContact from './components/views/app-public/ViewContact';
import ViewBlog from './components/views/app-public/blog/ViewBlog';
import ViewProduct from './components/views/app-public/wine/ViewWine';
import ViewVineyard from './components/views/app-public/vineyard/ViewVineyard';
import ViewWines from './components/views/app-public/wine/ViewWines';
import ViewCustomerOrders from './components/views/panel-customer/ViewCustomerOrders';
import ViewCustomerInvoices from './components/views/panel-customer/ViewCustomerInvoices';
import ViewCustomerPassword from './components/views/panel-customer/ViewCustomerPassword';
import ViewCustomerData from './components/views/panel-customer/ViewCustomerData';
import ViewCart from './components/views/app-public/cart/ViewCart';
import ViewCartSummary from './components/views/app-public/cart/ViewCartSummary';
import ViewCartAccount from './components/views/app-public/cart/ViewCartAccount';
import ViewCartCheckout from './components/views/app-public/cart/ViewCartCheckout';
import ViewCartDone from './components/views/app-public/cart/ViewCartDone';
import ViewRegulations from './components/views/app-public/footer-pages/ViewRegulations';
import ViewPrivacyPolicy from './components/views/app-public/footer-pages/ViewPrivacyPolicy';
import ViewVineyardProducts from './components/views/panel-vineyard/product/ViewVineyardProducts';
import ViewVineyardProfile from './components/views/panel-vineyard/profile/ViewVineyardProfile';
import ViewVineyardDesktop from './components/views/panel-vineyard/ViewVineyardDesktop';
import ViewVineyardStore from './components/views/panel-vineyard/ViewVineyardStore';
import ViewVineyardOrders from './components/views/panel-vineyard/orders/ViewVineyardOrders';
import ViewVineyardAccount from './components/views/panel-vineyard/ViewVineyardMyAccount';
import ViewVineyardAddProduct from './components/views/panel-vineyard/product/ViewVineyardAddProduct';
import NotFound from './components/views/app-public/error-handling/NotFound';
import ViewVineyardOrder from './components/views/panel-vineyard/orders/ViewVineyardOrder';
import ViewVineyardProduct from './components/views/panel-vineyard/product/ViewVineyardProduct';
import ViewVineyardEditProduct from './components/views/panel-vineyard/product/ViewVineyardEditProduct';
import ProtectedRoute from './components/atoms/routes/ProtectedRouter';
import ViewVineyardProfileEdit from './components/views/panel-vineyard/profile/ViewVineyardProfileEdit';
import ViewAbout from './components/views/app-public/ViewAbout';
import ViewVineyardContainerEvents from './components/views/panel-vineyard/containers/ViewVineyardContainerEvents'
import VineyardViewSingleEventContainer from './components/views/panel-vineyard/containers/events-containers/ViewVineyardContainerEvent'
import ViewForVineyard from './components/views/app-public/ViewForVineyard';
import ViewBlogDetails from './components/views/app-public/blog/ViewBlogDetails';
import ViewVineyardGallery from './components/views/panel-vineyard/ViewVineyardGallery';
import ViewCustomerOrder from "./components/views/panel-customer/ViewCustomerOrder";
import ViewVineyardContainers from "./components/views/panel-vineyard/containers/ViewVineyardContainers";
import ViewVineyardContainer from "./components/views/panel-vineyard/containers/ViewVineyardContainer";
import ViewVineyardAddContainer from "./components/views/panel-vineyard/containers/ViewVineyardAddContainer";
import ViewVineyardEditContainer from "./components/views/panel-vineyard/containers/ViewVineyardEditContainer";
import ViewEventsAddContainer from './components/views/panel-vineyard/containers/events-containers/ViewEventsAddContainers';
import ViewVineyardEnoturism from "./components/views/panel-vineyard/enoturism/ViewVineyardEnoturism";
import ViewVineyardEnoturismEvents from "./components/views/panel-vineyard/enoturism/ViewVineyardEnoturismEvents";
import ViewVineyardEnoturismAdd from "./components/views/panel-vineyard/enoturism/ViewVineyardEnoturismAdd";
import ViewVineyardEnoturismAdvertisement
    from "./components/views/panel-vineyard/enoturism/ViewVineyardEnoturismAdvertisement";
import ViewEnoturismDetails from './components/views/app-public/enoturism/ViewEnoturismDetails';
import ViewVineyardEnoturismEdit from "./components/views/panel-vineyard/enoturism/ViewVineyardEnoturismEdit";
import ViewVineyardEditContainerEvent from './components/views/panel-vineyard/containers/events-containers/ViewVineyardEditContainerEvent';
import ViewEnoturism from './components/views/app-public/enoturism/ViewEnoturism';
import ViewEditCustomerData from './components/views/panel-customer/ViewEditCustomerData';
import ViewVineyardBlogs from './components/views/panel-vineyard/blogs/ViewVineyardBlogs';
import ViewVineyardAddBlog from './components/views/panel-vineyard/blogs/ViewVineyardAddBlog';
import ViewVineyardBlog from './components/views/panel-vineyard/blogs/ViewVineyardBlog';
import ViewVineyardEditBlog from './components/views/panel-vineyard/blogs/ViewVineyardEditBlog'
import ViewVineyardError from './components/views/panel-vineyard/Error/ViewVineyardError';
import ViewVineyardErrorAdd from './components/views/panel-vineyard/Error/ViewVineyardErrorAdd';
import ViewVineyardErrorDetails from './components/views/panel-vineyard/Error/ViewVineyardErrorDetails';

// scroll to top after changing the page
const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

function App() {

    return (
        <Wrapper>
            <Routes>
                <Route path="*" element={<NotFound/>}/>

                <Route path={routes.home} element={<ViewHome/>}/>
                <Route path={routes.about} element={<ViewAbout/>}/>
                <Route path={routes.forVineyard} element={<ViewForVineyard/>}/>
                <Route path={routes.regulations} element={<ViewRegulations/>}/>
                <Route path={routes.privacyPolicy} element={<ViewPrivacyPolicy/>}/>
                <Route path={routes.cart} element={<ViewCart/>}/>
                <Route path={routes.cartSummary} element={<ViewCartSummary/>}/>
                <Route path={routes.checkout} element={<ViewCartCheckout/>}/>
                <Route path={routes.checkoutAccount} element={<ViewCartAccount/>}/>
                <Route path={routes.orderDone} element={<ViewCartDone/>}/>
                <Route path={routes.wines} element={<ViewWines/>}/>
                <Route path={routes.contact} element={<ViewContact/>}/>
                <Route path={routes.product} element={<ViewProduct/>}/>
                <Route path={routes.vineyard} element={<ViewVineyard/>}/>
                <Route path={routes.blog} element={<ViewBlog/>}/>
                <Route path={routes.blogDetails} element={<ViewBlogDetails/>}/>
                <Route path={routes.enoturism} element={<ViewEnoturism/>}/>
                <Route path={routes.enoturismDetails} element={<ViewEnoturismDetails/>}/>
                <Route path={routes.vineyards} element={<ViewVineyards/>}/>
                <Route path={routes.login} element={<ViewLogin/>}/>
                <Route path={routes.register} element={<ViewRegister/>}/>
                <Route path={routes.registerCustomer} element={<ViewRegister/>}/>
                <Route path={routes.registerVineyard} element={<ViewRegister/>}/>
                <Route path={routes.registerShopRestaurant} element={<ViewRegister/>}/>
                <Route path={routes.registerImporter} element={<ViewRegister/>}/>
                <Route path={routes.resetPassword} element={<ViewForgotPassword/>}/>
                <Route path={routes.customerPassword} element={<ViewChangePassword/>}/>

                <Route element={<ProtectedRoute allowedRoles={['User']}/>}>
                    <Route path={routes.customerEdit} element={<ViewEditCustomerData/>}/>
                    <Route path={routes.customerData} element={<ViewCustomerData/>}/>
                    <Route path={routes.customerOrders} element={<ViewCustomerOrders/>}/>
                    <Route path={routes.customerOrder} element={<ViewCustomerOrder/>}/>
                    <Route path={routes.customerInvoices} element={<ViewCustomerInvoices/>}/>
                    <Route path={routes.customerPassword} element={<ViewCustomerPassword/>}/>
                </Route>

                <Route element={<ProtectedRoute allowedRoles={['VineyardAdmin']}/>}>
                    <Route path={routes.vineyardProducts} element={<ViewVineyardProducts/>}/>
                    <Route path={routes.vineyardProduct} element={<ViewVineyardProduct/>}/>
                    <Route path={routes.vineyardAddProduct} element={<ViewVineyardAddProduct/>}/>
                    <Route path={routes.vineyardEditProduct} element={<ViewVineyardEditProduct/>}/>
                    <Route path={routes.vineyardProfileEdit} element={<ViewVineyardProfileEdit/>}/>
                    <Route path={routes.vineyardProfile} element={<ViewVineyardProfile/>}/>
                    <Route path={routes.vineyardDesktop} element={<ViewVineyardDesktop/>}/>
                    <Route path={routes.vineyardOrders} element={<ViewVineyardOrders/>}/>
                    <Route path={routes.vineyardOrder} element={<ViewVineyardOrder/>}/>
                    <Route path={routes.vineyardAccount} element={<ViewVineyardAccount/>}/>
                    <Route path={routes.vineyardStore} element={<ViewVineyardStore/>}/>
                    <Route path={routes.vineyardPanelGallery} element={<ViewVineyardGallery/>}/>
                    <Route path={routes.vineyardPanelEnoturism} element={<ViewVineyardEnoturism/>}/>
                    <Route path={routes.vineyardPanelEnoturismAdvertisement} element={<ViewVineyardEnoturismAdvertisement/>}/>
                    <Route path={routes.vineyardPanelEnoturismEvent} element={<ViewVineyardEnoturismEvents/>}/>
                    <Route path={routes.vineyardViewEventContainer} element={<ViewVineyardContainerEvents/>}/>
                    <Route path={routes.vineyardPanelEnoturismAdd} element={<ViewVineyardEnoturismAdd/>}/>
                    <Route path={routes.vineyardPanelEnoturismEdit} element={<ViewVineyardEnoturismEdit/>}/>
                    <Route path={routes.vineyardContainers} element={<ViewVineyardContainers/>}/>
                    <Route path={routes.vineyardContainer} element={<ViewVineyardContainer/>}/>
                    <Route path={routes.vineyardContainerEventCreate} element={<ViewEventsAddContainer/>}/>
                    <Route path={routes.vineyardContainerEventEdit} element={<ViewVineyardEditContainerEvent/>}/>
                    <Route path={routes.vineyardViewSingleEventContainer} element={<VineyardViewSingleEventContainer/>}/>
                    <Route path={routes.vineyardAddContainer} element={<ViewVineyardAddContainer/>}/>
                    <Route path={routes.vineyardEditContainer} element={<ViewVineyardEditContainer/>}/>
                    <Route path={routes.vineyardPanelBlogs} element={<ViewVineyardBlogs/>}/>
                    <Route path={routes.vineyardPanelBlogsAdd} element={<ViewVineyardAddBlog/>}/>
                    <Route path={routes.vineyardPanelBlog} element={<ViewVineyardBlog/>}/>
                    <Route path={routes.vineyardPanelBlogsEdit} element={<ViewVineyardEditBlog/>}/>
                    <Route path={routes.vineyardErrors} element={<ViewVineyardError/>}/>
                    <Route path={routes.vineyardError} element={<ViewVineyardErrorDetails/>}/>
                    <Route path={routes.vineyardErrorAdd} element={<ViewVineyardErrorAdd/>}/>
                </Route>
            </Routes>
        </Wrapper>

    );
}

export default App;