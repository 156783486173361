import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import useQuery2 from '../../../hooks/useQuery2';

const getPublishStatus = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL_API}/api/v1/enums/publish-status`
  }).then(({ data }) => data)

export const useGetPublishStatus = () => {
  return useQuery2({
    queryKey: ['app-public.publishStatus'],
    queryFn: () => getPublishStatus()
  });
};
