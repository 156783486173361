import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import useQuery2 from "../../../hooks/useQuery2";
import { useParams } from "react-router-dom";

export const getPostBlog = ([id]) =>
  axios({
    method: "GET",
    url: `${BASE_URL_API}/api/v1/blog/${id}`,
  }).then(({ data }) => data);

export const useGetPostBlog = () => {
  const { id } = useParams();
  return useQuery2({
    queryKey: ["app-public.postBlog", id],
    queryFn: getPostBlog,
  });
};
