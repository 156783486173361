import { faq } from "../../molecules/footer/info";
import FooterNav from "../../molecules/footer/FooterNav";
import NavLink from "../../atoms/ui/buttons/links/NavLink";
import React from "react";
import { routes } from "../../../app/router/routes";
import FooterColumn from "../../molecules/footer/FooterColumn";
import SocialMedia from "../../molecules/footer/SocialMedia";
import { ReactComponent as Wave } from "../../../app/assets/svg/wave.svg";
import Logo from "../../atoms/logo/Logo";
import Text from "../../molecules/text/Text";
import CustomLink from "../../atoms/ui/buttons/links/Link";
import visa from '../../../app/assets/svg/visa.svg'
import apple from '../../../app/assets/svg/apple-pay.svg'
import google from '../../../app/assets/svg/google-pay.svg'
import mastercard from '../../../app/assets/svg/mastercard.svg'

const Footer = () => {
  return (
    <footer className="p-4 bg-bronze text-gold sm:p-6 ">
      <div className="md:flex md:justify-between">
        <div className="mb-6 md:mb-0 ">
          <div className={"flex items-center"}>
            <Logo />
            <Text className={"ml-2"}>WinoPasja</Text>
          </div>
          <div className={'mt-8'}>
            <div className="flex bg-gold rounded-lg">
              <img
                src={visa}
                alt={'Visa'}
                className={"w-16 mr-2 "}
              />
                                          <img
                src={mastercard}
                alt={"Mastercard"}
                className={"w-16 mr-2"}
              />
                            <img
                src={apple}
                alt={'Apple pay'}
                className={"w-16 mr-2"}
              />
                            <img
                src={google}
                alt={'Google pay'}
                className={"w-16 mr-2"}
              />

            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gold2 uppercase ">
            UŻytkownik
            </h2>
            <ul >
              <li className="mb-4">
                <NavLink
                  to={routes.register}
                  className={'text-gold'}
                >
                  Rejestracja
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={routes.login}
                  className={'text-gold'}
                >
                  Logowanie
                </NavLink>
              </li>

            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gold2 uppercase ">
            Klient
            </h2>
            <ul >
              <li className={'mb-4'}>
                <NavLink
                  to={routes.vineyards}
                  className={'text-gold'}
                >
                  Winnice
                </NavLink>
              </li>
              <li className={'mb-4'}>
                <NavLink
                  to={routes.wines}
                  className={'text-gold'}
                >
                  Wina
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={routes.enoturism}
                  className={'text-gold'}
                >
                  Noclegi
                </NavLink>
              </li>

            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gold2 uppercase ">
              Informacje
            </h2>
            <ul >
              <li className="mb-4">
              <NavLink to={routes.privacyPolicy} className={'text-gold'}>
                  Regulamin
                </NavLink>

              </li>
              <li className={'mb-4'}>
              <NavLink to={routes.regulations} className={'text-gold'}>
                  Polityka prywatności
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={routes.contact}
                  className={'text-gold'}
                >
                  Kontakt
                </NavLink>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gold2 uppercase ">
            UŻyteczne linki
            </h2>
            <ul>
              <li className="mb-4">
                <NavLink to={routes.blog} className={'text-gold'}>
                  Blog
                </NavLink>
              </li>
              <li className={'mb-4'}>
                <NavLink to={routes.enoturism} className={'text-gold'}>
                  Oferta
                </NavLink>
              </li>
              <li>
                <NavLink to={routes.about} className={'text-gold'}>
                  O nas
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <hr className="my-6 border-gold2 sm:mx-auto lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-gold sm:text-center ">
        &copy; {new Date().getFullYear()} Winopasja

        </span>
        <SocialMedia/>
      </div>
    </footer>

  );
};

export default Footer;
