import React from 'react';

const RegisterBenefitItem = ({icon, label}) => {
    return (
        <div className={'flex items-center space-x-4'}>
            {icon}
            <p>{label}</p>
        </div>
    );
};

export default RegisterBenefitItem;
