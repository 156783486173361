import React, {useEffect, useState} from 'react';
import Dropdown from '../../atoms/ui/dropdown/Dropdown';
import Checkbox from '../../atoms/ui/buttons/checkbox/Checkbox';
import useSearchParamsObject from "../../../app/hooks/useSearchParamsObject";

const FilterDropdown = ({options, onChange, state}) => {
    const {addIdParam, removeIdParam} = useSearchParamsObject();
    const [numOfActiveFilters, setNumOfActiveFilters] = useState(0);

    useEffect(() => {
        if (state) {
            setNumOfActiveFilters(prev => state.length);
        }
        else {
            setNumOfActiveFilters(0)
        }
    }, [state]);

    return (
        <Dropdown label={`${options.prop} ${numOfActiveFilters > 0 ? `(${numOfActiveFilters})` : ''}`}>
            {
                options.values.map((item, key) => {
                        return (
                            <Checkbox
                                key={item?.name + key}
                                readOnly
                                className={'text-sm text-gray-600 mb-1'}
                                checked={state ? state.includes(item?.id?.toString()) : false}
                                onChange={(val) => {
                                    if (val)
                                        addIdParam(options?.queryParam, item?.id)
                                    else
                                        removeIdParam(options?.queryParam, item?.id)
                                }}
                            >
                                {item?.name}
                            </Checkbox>
                        );
                    }
                )
            }
        </Dropdown>
    );
};

export default FilterDropdown;
