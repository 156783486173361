import axios from '../../config/axios';
import {BASE_URL_API} from '../../config/env';
import useQuery2 from '../../hooks/useQuery2';
import { useParams } from 'react-router-dom';

export const getVineyard = ([id]) =>
    axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/vineyards/${id}`
    }).then(({data}) => data);

export const useGetVineyard = () => {
    const {vineyardId} = useParams()
    return useQuery2({
        queryKey: ['app-public.panel-vineyard', vineyardId],
        queryFn: getVineyard
    });
}

