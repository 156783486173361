export const TYPE_LOGIN_WITH_EMAIL = 'auth.login';
export const TYPE_LOGOUT = 'auth.logout';

export const actionAuthLoginWithEmail = ({ token, role }) => ({
  type: TYPE_LOGIN_WITH_EMAIL,
  payload: { token, role }
});

export const actionAuthLogout = () => ({
  type: TYPE_LOGOUT
});
