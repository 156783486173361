import axios from "../../mock/axios";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL_API } from "../../../config/env";
import { passParams, routes } from "../../../router/routes";

export const useDeleteEvent = () => {
  const {eventId, id} = useParams();
  const navigate = useNavigate();
  return useMutation(() => {
    axios({
      method: "DELETE",
      url: `${BASE_URL_API}/api/v1/admin/vineyard/containers/${id}/events/${eventId}`,
    })
      .then(({ data }) => data)
      .then((data) => {
        navigate(passParams(routes.vineyardViewEventContainer, {id}));
        return data;
      });
  });
};
