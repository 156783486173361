import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const PhotoModal = ({ data, setOpen, open, isLoading, children }) => {
  return (
   !isLoading ? ( data?.images?.map((item) => (
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={setOpen}>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-30"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-30">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-20 transition-opacity" />
            </Transition.Child>
  
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle mx-8">
                  
                <img src={item?.url} className="mx-auto" />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )) 

  ): (null))
}

export default PhotoModal
