import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {TYPE_ORDER_VIEW} from './orderActionTypes'

const initialState = () => ({
    address: {}
});

const reducer = (state = initialState(), {type, payload}) => {

    switch (type) {
        case TYPE_ORDER_VIEW: {
            return {...state, address: payload.value}
        }
        
        case 'persist/REHYDRATE': {
            return {
                ...initialState(),
                ...state
            };
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: 'app-public.order',
    storage
};

export const orderReducer = persistReducer(persistConfig, reducer);