import Input from "./CustomInput";
import { forwardRef, useMemo } from "react";
import ControlledInputMask from "./ControlledInputMaskPhone";

const InputN = forwardRef(({ length, ...props }, ref) => {
  const mask = useMemo(() => {
    return new Array(length).fill("hhh-hhh-hh-hh").join("-");
  }, [length]);

  return (
    <ControlledInputMask
      mask={mask}
      ref={ref}
      {...props}
    />
  );
});

export const InputNIP = ({
  name,
  placeholder,
  label,
  length = 1,
  ...props
}) => {
  return (
    <Input
      component={InputN}
      {...{ name, placeholder, label, length }}
      transformValue={(value) => (value ?? "").toUpperCase()}
      {...props}
    />
  );
};
