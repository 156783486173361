import { AiOutlinePlus } from "react-icons/ai";
import { useGetVineyardBlogs } from "../../../../app/crud/app/blog/getBlogs";
import { passParams, routes } from "../../../../app/router/routes";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../templates/TemplatePanel";
import Card from "../../../atoms/card/Card";
import dayjs from "dayjs";
import { BeatLoader } from "react-spinners";
import CustomLink from "../../../atoms/ui/buttons/links/Link";
import blog_mock from '../../../../app/assets/svg/blog_mock.svg'
import Text from "../../../molecules/text/Text";
const ViewVineyardBlogs = () => {
  const { data, isLoading, refetch } = useGetVineyardBlogs();
  const bc = [{ label: "Moje wpisy", url: routes.vineyardPanelBlogs }];

  return (
    <TemplatePanel
      title={"Blog"}
      sideMenuRenderer={() => <VineyardSideMenu />}
      breadcrumbsRenderer={() => (
        <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc} />
      )}
      actionButtonsRenderer={() => (
        <ButtonLink
          to={routes.vineyardPanelBlogsAdd}
          tooltip={"Dodaj wpis"}
          className={
            "text-white text-sm flex items-center bg-green-700 hover:bg-green-600"
          }
        >
          <AiOutlinePlus size={"1.25rem"} />
        </ButtonLink>
      )}
    >
      <Card>
        {!!isLoading ?
        <div className={'w-full justify-center flex'}>
            <BeatLoader />
        </div>   :
        data?.items?.length === 0 ?
        <Text className={'w-full text-center'}>Brak wpisów do wyświetlenia</Text> :
        <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-4 md:gap-6 mb-6">
          {
            data?.items?.map((item) => {
              return (
                <CustomLink
                  to={passParams(routes.vineyardPanelBlog, {id: item?.id})}
                  className="group h-48 md:h-64 xl:h-96 flex flex-col bg-gray-100 rounded-lg shadow-lg overflow-hidden relative"
                >
                  <img
                    src={item?.image?.url || blog_mock}
                    loading="lazy"
                    alt="Zdjęcie główne wpisu"
                    className="w-full h-full object-cover object-top absolute inset-0 group-hover:scale-110 transition duration-200"
                  />

                  <div className="bg-gradient-to-t from-gold md:via-transparent to-transparent absolute inset-0 pointer-events-none"></div>
                  <div className="relative p-4 mt-auto">
                    <span className="block text-gray-200 text-sm">
                      {dayjs(item?.createdDateTime).format("DD.MM.YYYYr")}
                    </span>
                    <h2 className="text-white text-xl font-semibold transition duration-100 mb-2">
                      {item?.title}
                    </h2>

                    <span className="text-bronze font-semibold hover:cursor-pointer">
                      Czytaj więcej
                    </span>
                  </div>
                </CustomLink>
              );
            })
          }

        </div>
      </div>
      }

      </Card>
    </TemplatePanel>
  );
};

export default ViewVineyardBlogs;
