import React, {useEffect} from 'react';
import VineyardCard from '../../molecules/vineyard-card/VineyardCard';
import Pagination from "../../molecules/pagination/Pagination";
import usePagination from "../../../app/hooks/usePagination";

const VineyardList = ({items, isFetching, refetch}) => {
    // const {items, isFetching, refetch} = useFilterContext()
    const {totalPages, currentPage, onPageChange, setTotalPages, setTotalCount} = usePagination(refetch)
    useEffect(() => {
        setTotalPages(items?.totalPages);
        setTotalCount(items?.totalCount);
    }, [items])
    return (
        <div>
            {
                isFetching ?
                    <div className={'flex flex-col space-y-5'}>
                        {
                            [...Array(10).keys()].map(card => <VineyardCard key={card + 'vineyardloader'}
                                                                            isLoading={isFetching}/>)
                        }
                    </div>
                    :
                    <div className={'flex flex-col space-y-5'}>
                        {items?.map((vineyard) => (
                            <VineyardCard key={vineyard?.id + 'vineyard'} data={vineyard} isLoading={isFetching}/>
                        ))}
                    </div>
            }
            <Pagination
                className={'w-full justify-center mt-4'}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
            />
        </div>

    );
};

export default VineyardList;
