import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import useQuery2 from '../../../hooks/useQuery2';
import { useParams } from 'react-router-dom';

const getWine =([id]) => {
  return axios.get(`${BASE_URL_API}/api/v1/admin/wine-products/${id}`)
    .then(({ data }) => data)
}

export const useGetVineyardProduct = () => {
  const { id } = useParams();
  return useQuery2({
    queryKey: ['app-public.vineyard_wine'+id, id],
    queryFn: getWine
  });
};
