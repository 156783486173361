import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TYPE_ADD, TYPE_DELETE, TYPE_SET_QUANTITY, TYPE_SET_CART_FROM_SERVER, TYPE_CLEAR } from './cartActionTypes';

const initialState = () => ({
  products: []
});

const reducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case TYPE_ADD: {
      const idx = state.products.findIndex(product => product.id === payload.product.id)
      if(idx !== -1) {
        const products = [...state.products]
        products[idx].quantity+=payload.quantity;
        return {...state, products}
      }
      return {...state, products: [...state.products, { ...payload.product, quantity: payload.quantity }]}
    }
    case TYPE_CLEAR: {
      return {...initialState()}
    }
    case TYPE_DELETE: {
      return {...state, products: [...state.products].filter(product => product.id !== payload.id)}
    }
    case TYPE_SET_CART_FROM_SERVER: {
      return {...state, products: payload?.cart?.cartItems}
    }
    case TYPE_SET_QUANTITY: {
      const idx = state.products.findIndex(product => product.id === payload.product.id)
      const products = [...state.products]
      if(idx !== -1){
        products[idx].quantity=payload.quantity;
      }
      return {...state, products}
    }
    case 'persist/REHYDRATE': {
      return {
        ...initialState(),
        ...state
      };
    }
    default:
      return state;
  }
};

const persistConfig = {
  key: 'app-public.cart.products',
  storage
};

export const cartReducer = persistReducer(persistConfig, reducer);
