import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

export const useGetVineyardAdvertisements = () => {
  const [queryParams] = useSearchParams();
  const page = queryParams.get("page");
  const items = queryParams.get("items");
  return useQuery({
    queryKey: ["app-public.vineyard_advertisements"],
    queryFn: () =>
      axios({
        method: "GET",
        url: `${BASE_URL_API}/api/v1/admin/vineyard/advertisements`,
        params: {
          PageNumber: page,
          PageSize: items,
        },
      }).then(({ data }) => (data)),
  });
};
