import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

export const useDeleteImageBlog = () => {
  const { id } = useParams();
  return useMutation(() => {
    return axios
      .delete(`${BASE_URL_API}api/v1/admin/blog/remove-image/${id}`)
  });
};
