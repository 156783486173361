import React from 'react';
import Text from '../text/Text';

const IconDescription = ({children}) => {
  return (
    <div className={'flex items-center space-x-5'}>
      <div className={'min-w-[48px] max-w-[48px] w-full h-12 bg-gray-300 rounded-lg'}/>
      <Text>{children}</Text>
    </div>
  );
};

export default IconDescription;
