import React, {useState} from 'react';
import {BeatLoader} from "react-spinners";
import Header1 from "../../molecules/headers/Header1";
import NavLink from "../../atoms/ui/buttons/links/NavLink";
import {routes} from "../../../app/router/routes";
import {BsArrowRight} from "react-icons/bs";
import CustomLink from '../../atoms/ui/buttons/links/Link';

const HeroImage = () => {
    const [isLoading, setLoading] = useState(true);
    return (

        <div className=" pb-6 sm:pb-8 lg:pb-12 mt-8">
  <div className="max-w-screen-2xl px-4 md:px-8 mx-auto max-w-7xl">


    <section className="flex flex-col lg:flex-row justify-between gap-6 sm:gap-10 md:gap-16">

      <div className="xl:w-5/12 flex flex-col justify-center items-center lg:items-start sm:text-center lg:text-left lg:py-12 xl:py-24">
        <h1 className="text-bronze text-4xl sm:text-5xl md:text-6xl font-bold mb-8 md:mb-12">WinoPasja</h1>

        <p className="lg:w-4/5 text-bronze xl:text-lg leading-relaxed mb-8 md:mb-12">Wino Pasja to jedna z najstarszych i najbardziej doświadczonych firm winiarskich w Polsce. W sklepach Wino Pasji pracują prawdziwi pasjonaci kultury winiarskiej, gotowi służyć swoją wiedzą i doświadczeniem.</p>

        <div className="w-full flex flex-col sm:flex-row justify-center lg:justify-start gap-2.5">
          <CustomLink to={routes.wines} className="inline-block bg-gold hover:bg-gold2 active:bg-gold2 focus-visible:ring ring-gold !text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3">Sprawdź ofertę win</CustomLink>

        </div>
      </div>

      <div className="xl:w-1/2 h-auto bg-gray-100 overflow-hidden shadow-lg rounded-lg">
        
      <img src="https://wallpaperaccess.com/full/751050.jpg" onLoad={() => setLoading(false)} loading="lazy" alt="WinePassion" className={`w-full h-full    ${isLoading ? 'opacity-0' : 'opacity-100'} transition-all`} />
      </div>

    </section>
  </div>
</div>


    );
};

export default HeroImage;
