import PropTypes from 'prop-types';
import LineLoader from '../../atoms/loaders/LineLoader';

const Header1 = ({ children, isLoading, className }) => {
  return (
    <div className={className}>
      <LineLoader isLoading={isLoading} className={['w-2/3 h-6 my-1 ']}>
        <h1 className={'font-bold text-2xl'}>{children}</h1>
      </LineLoader>
    </div>
  );
};

Header1.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Header1;
