import {useEffect, useState} from "react";

function ToggleButton({label, onClick, initVal}) {
    const [toggle, setToggle] = useState(null);
    useEffect(() => {
        setToggle(initVal)
    },[initVal])
    return (
        <div className={'flex space-x-4'}>
            {label && <p>{label}</p>}
            <div
                className={`md:w-14 md:h-6 w-12 h-6 flex items-center ${!toggle ? 'bg-gray-300' : 'bg-gold'} rounded-full p-1 cursor-pointer`}
                onClick={() => {
                    onClick(!toggle)
                    setToggle(!toggle);
                }}>
                <div
                    className={`bg-white md:w-5 md:h-5 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out ${!toggle ? '' : 'transform translate-x-7'}`}/>
            </div>
        </div>

    );
}

export default ToggleButton;