import React, {useRef} from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export const ImageCropper = ({url, setCroppedImgRef}) => {
    const cropperRef = useRef(null);

    return (
        <div id={"image-uploader"}>
            <Cropper
                src={url}
                aspectRatio={1}
                guides={false}
                crop={() => setCroppedImgRef(cropperRef)}
                zoomable={false}
                ref={cropperRef}
            />
        </div>

    );
};
