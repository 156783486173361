import PropTypes from 'prop-types';
import LineLoader from '../../atoms/loaders/LineLoader';

const Description = ({ children, isLoading, className }) => {
  return (
    <div className={className}>
      <LineLoader
        isLoading={isLoading}
        classNames={['w-full h-4 mb-2', 'w-full h-4 mb-2', 'w-full h-4 mb-2', 'w-1/2 h-4 mb-2']}>
        <p className={'text-justify text-gray-900'}>{children}</p>
      </LineLoader>
    </div>
  );
};

Description.propTypes = {
  children: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string
};

export default Description;
