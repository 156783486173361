import React from "react";
import Input from "../../../../atoms/ui/inputs/CustomInput";
import { FormProvider, useForm } from "react-hook-form";
import Textarea from "../../../../atoms/ui/textarea/Textarea";
import { useGetStrains } from "../../../../../app/crud/vineyard/enum/getStrains";
import { getRegionsHelper } from "../../../../../app/crud/vineyard/enum/getRegions";
import Submit from "../../../../atoms/ui/buttons/regular/Submit";
import CustomLink from "../../../../atoms/ui/buttons/links/Link";
import { passParams, routes } from "../../../../../app/router/routes";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddWine } from "../../../../../app/crud/vineyard/wine/addWine";
import { getColorsHelper } from "../../../../../app/crud/vineyard/enum/getWineColors";
import { getTastesHelper } from "../../../../../app/crud/vineyard/enum/getWineTastes";
import Card from "../../../../atoms/card/Card";
import FormConfigureStrains from "../../../../molecules/forms/vineyard/FormConfigureStrains";
import FormConfigureWineAroma from "../../../../molecules/forms/vineyard/FormConfigureAroma";
import toast from "react-hot-toast";
import { usePutWine } from "../../../../../app/crud/vineyard/wine/putWine";
import { useNavigate, useParams } from "react-router-dom";
import { InputAsyncSelect } from "../../../../atoms/ui/inputs/Input_AsyncSelect";
import {
  getWineAromasHelper,
  useGetWineAromas,
} from "../../../../../app/crud/vineyard/wineAroma/getWineAromas";

const FormCreateProduct = ({ mode, initialData }) => {

  const { data: strains, isLoading: isLoadingStrains } = useGetStrains();
  const { data: wineAromas, isLoading: isLoadingWineAromas } =
    useGetWineAromas();
  const navigate = useNavigate();
  const { id } = useParams();

  const schema = yup.object().shape({
    name: yup.string().required("Nazwa jest wymagana"),
    description: yup
      .string()
      .max(1500, "Opis może zawierać max. 500 znaków")
      .trim(),
    wineTaste: yup
      .number()
      .typeError("Rodzaj jest wymagany")
      .required("Rodzaj jest wymagany"),
    wineColour: yup
      .number()
      .typeError("Kolor jest wymagany")
      .required("Kolor jest wymagany"),
    productionYear: yup.number().required("Rok jest wymagany"),
    capacity: yup.string().required("Objętość jest wymagana"),
    priceGross: yup.string().required("Cena jest wymagana"),
    alcoholContent: yup.string().required("Zawartość alkoholu jest wymagana"),
    productionRegionId: yup
      .number()
      .typeError("Region jest wymagany")
      .required("Region jest wymagany"),
    wineAromaIds: yup
      .array()
      .required("error")
      .of(
        yup.object().shape({
          value: yup
            .number("Podanie aromatu wina jest wymagane")
            .typeError("Podanie aromatu wina jest wymagane")
            .required("Podanie aromatu wina jest wymagane"),
        })
      ),
      productionStrains: yup
      .array()
      .required("error")
      .of(
        yup.object().shape({
            id: yup
            .number("Podanie szczepu jest wymagane")
            .typeError("Rodzaj jest wymagany")
            .required("Podanie szczepu jest wymagane"),
          amount: yup
            .number()
            .typeError("Podanie zawartości jest wymagane")
            .required("Podanie zawartości jest wymagane"),
        })
      ),
    quantity: yup.number().required("Liczba dostępnych butelek jest wymagana"),
  });
  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie dodania produktu. Spróbuj ponownie później."
    );
  };
  const onSuccess = () => {
    toast.success("Udało się pomyślnie dodać produkt.");
  };
  const onErrorPut = () => {
    toast.error(
      "Wystąpił błąd przy próbie edycji wina. Spróbuj ponownie później."
    );
  };
  const onSuccessPut = () => {
    toast.success("Udało się pomyślnie edytować produkt.");
    navigate(passParams(routes.vineyardProduct, { id }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    ...form
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialData,
      wineColour: initialData?.wineColour?.id,
      wineTaste: initialData?.wineTaste?.id,
      wineAromaIds: initialData?.wineAromas,
      productionRegionId: initialData?.productionRegion?.id,
    },
  });
  console.log(errors)
  const { mutate, isLoading } = useAddWine();
  const { mutate: putMutate, isLoading: putIsLoading } = usePutWine();

  const onSubmitAdd = (data) => {
    mutate(data, { onSuccess, onError });
  };

  const onSubmitPut = (data) => {
    putMutate(data, { onSuccess: onSuccessPut, onError: onErrorPut });
  };

  return (
    <FormProvider
      {...{
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        ...form,
      }}
    >
      <form
        className={"w-full"}
        onSubmit={handleSubmit(mode === "edit" ? onSubmitPut : onSubmitAdd)}
      >
        <div className={"flex flex-col lg:flex-row w-full justify-between"}>
          <Card className={"flex flex-col w-full"} title={"Dane szczegółowe"}>
            <div
              className={
                "flex flex-col sm:flex-row w-full space-y-5 sm:space-y-0 sm:space-x-5"
              }
            >
              <div className={"space-y-5 w-full"}>
                <Input
                  name={"name"}
                  label={"Nazwa"}
                  register={register}
                  error={errors.name?.message}
                  required
                />
                <Input
                  type={"number"}
                  name={"productionYear"}
                  label={"Rok produkcji"}
                  register={register}
                  error={errors.productionYear?.message}
                  required
                />
                <Input
                  type={"number"}
                  name={"quantity"}
                  label={"Liczba dostępnych butelek"}
                  register={register}
                  error={errors.quantity?.message}
                  required
                />
                <InputAsyncSelect
                  {...getTastesHelper}
                  name={"wineTaste"}
                  label={"Rodzaj "}
                  placeholder={"Wybierz rodzaj wina np. słodkie"}
                  transform={({ id, name }) => ({
                    value: id,
                    label: name,
                  })}
                  required
                />
                <InputAsyncSelect
                  {...getColorsHelper}
                  name={"wineColour"}
                  label={"Kolor "}
                  placeholder={"Wybierz kolor wina np. czerwone"}
                  transform={({ id, name }) => ({
                    value: id,
                    label: name,
                  })}
                  required
                />
              </div>
              <div className={"space-y-5 w-full"}>
                <Input
                  type={"number"}
                  name={"capacity"}
                  label={"Objętość [ml]"}
                  register={register}
                  error={errors.capacity?.message}
                  required
                />
                <Input
                  type={"string"}
                  name={"alcoholContent"}
                  label={"Zawartość alkoholu [%]"}
                  register={register}
                  error={errors.alcoholContent?.message}
                  required
                  step=".01"
                />
                <Input
                  type={"string"}
                  name={"priceGross"}
                  label={"Cena [zł]"}
                  register={register}
                  error={errors.priceGross?.message}
                  required
                />
                <InputAsyncSelect
                  {...getRegionsHelper}
                  name={"productionRegionId"}
                  label={"Region "}
                  // error={errors.productionRegionId?.message}
                  placeholder={"Wybierz region wina np. Andaluzja"}
                  required
                  transform={({ id, name }) => ({
                    value: id,
                    label: name,
                  })}
                />
              </div>
            </div>
            <div className={"w-full mt-5"}>
              <Textarea
                name={"description"}
                label={"Opis"}
                register={register}
                error={errors.description?.message}
                maxContent={1500}
                rows={1}
                onChange
              />
            </div>
            <FormConfigureStrains
              name={"productionStrains"}
              setBody={setValue}
              strains={strains}
              errors={errors}
              isLoadingStrains={isLoadingStrains}
              required
            />
            <FormConfigureWineAroma
              name={"wineAromaIds"}
              setBody={setValue}
              wineAromas={wineAromas}
              isLoadingWineAromas={isLoadingWineAromas}
              required
              errors={errors}
            />
            <div
              className={
                "flex flex-row w-full justify-end items-center space-x-4 mt-4"
              }
            >
              <CustomLink to={routes.vineyardProducts}>Wróć</CustomLink>
              {mode === "edit" ? (
                <Submit isLoading={putIsLoading}>Zapisz</Submit>
              ) : (
                <Submit isLoading={isLoading}>Dodaj</Submit>
              )}
            </div>
          </Card>
        </div>
      </form>
    </FormProvider>
  );
};

export default FormCreateProduct;
