import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from '../../../../molecules/ToolTip/Tooltop';

const Button = ({ disabled, onClick, children, className, importantClasses, tooltip, to }) => {
  return (
    <Tooltip value={tooltip}>
    <button
      disabled={disabled}
      onClick={onClick}
      className={importantClasses || `${disabled ? 'bg-gray-400' : 'bg-gold2 hover:bg-gold'} transition text-white rounded-lg px-4 py-2 ${className}`}>
      {children}
    </button>
    </Tooltip>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default Button;
