import React from 'react';
import Header2 from '../../molecules/headers/Header2';
import Text from '../../molecules/text/Text';

const ForVineyardCard = ({children, reverse, image, title, description}) => {
  return (
    <div className={'py-8'}>
      <div className={`flex flex-col-reverse ${reverse ? 'md:flex-row-reverse' : 'md:space-x-8 md:flex-row lg:space-x-20'}  w-full`}>
        <div className={'w-full'}>
          <img className={`mt-8 md:mt-0 w-full rounded-lg`} src={image?.url} alt={image?.alt} />
        </div>
        <div className={`flex flex-col w-full ${reverse ? 'mr-20': ''}`}>
          <Header2 className={'text-2xl mb-4'}>{title}</Header2>
          <Text>{description}</Text>
          <div>{children}</div>
        </div>

      </div>
    </div>
  );
};

export default ForVineyardCard;
