import React from "react";
import KeyValue from "../../atoms/key-value/KeyValue";
import Aroma from "../../molecules/Aromas/Aroma";
import ForWhat from "../../molecules/ForWhat/ForWhat";

const ProductDetails = ({ data, isLoading, className }) => {
  return (
    <>
    <div className={className}>
      <div className={"flex flex-col space-y-2"}>
        <KeyValue label={"Kolor:"} isLoading={isLoading}>
          {data?.wineColour?.name}
        </KeyValue>
        <KeyValue dark label={"Typ:"} isLoading={isLoading}>
          {data?.wineTaste?.name}
        </KeyValue>
        <KeyValue label={"Zawartość alkoholu [%]:"} isLoading={isLoading}>
          {data?.alcoholContent}
        </KeyValue>
        <KeyValue dark label={"Objętość [ml]:"} isLoading={isLoading}>
          {data?.capacity}
        </KeyValue>
        <KeyValue  label={"Dostępność:"} isLoading={isLoading}>
          {data?.quantity > 100 ? (
            <div className="flex items-center justify-center ">
            <span className="bg-green-700 text-gray-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full mr-2 "></span>
            <span>Produkt dostępny w bardzo dużej ilości</span>
          </div>
          ) : data?.quantity <= 100 && data?.quantity > 0 ? (
            <div className="flex items-center justify-center">
              <span className="bg-orange-400 text-gray-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full mr-2 "></span>
              <span>Produkt dostępny w bardzo małej ilości</span>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <span className="bg-red-700 text-gray-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full mr-2 "></span>
              <span>Brak dostępnego produktu</span>
            </div>
          )}
        </KeyValue>
        <KeyValue dark label={"Region:"} isLoading={isLoading}>
          {data?.productionRegion?.name}
        </KeyValue>
        <KeyValue  label={"Szczepy:"} isLoading={isLoading}>
          <div className={"grid grid-cols-2 gap-x-2"}>
            {data?.productionStrains?.map((item) => (
              <>
                <p>{item?.productionStrainName}</p>
                <p>{item?.amount}%</p>
              </>
            ))}
          </div>
        </KeyValue>
        <KeyValue dark label={"Rok produkcji:"} isLoading={isLoading}>
          {data?.productionYear}
        </KeyValue>
      </div>
    </div>
        <div className="w-full md:flex justify-between items-start">
        <Aroma data={data} isLoading={isLoading}/>
        <ForWhat data={data} isLoading={isLoading}/>
        </div>
        </>
  );
};

export default ProductDetails;
