import { BASE_URL_API } from '../../../../config/env';

export const blogMock = (mock) => {
  mock.onGet(`${BASE_URL_API}/blogs/alvaro-palacios-winiarzem-roku`).reply(config => {
    return ([200,
      {
        id: 1,
        date: '2022-03-30',
        title: 'ÁLVARO PALACIOS WINIARZEM ROKU 2022 WEDŁUG TIMA ATKINA',
        slug: 'alvaro-palacios-winiarzem-roku',
        description: ['Właśnie ukazał się Rioja 2022 Special Report Tima Atkina – jednego z najbardziej znanych i wpływowych krytyków winiarskich, publikującego w magazynach takich jak „Decanter”, „The World of Fine Wine”, „Gourmet Traveller Wine” i „The Drinks Business”.\n', 'Jako dystrybutora cieszy nas ogromnie fakt, że wśród obsypanych medalami podczas tego prestiżowego konkursu win znajdują się zarówno stałe, od lat obecne w ofercie Domu Wina pozycje takie jak Don Reca Cuvée 2018 z Chile, Individo Rara Neagră Malbec Syrah 2019 i Individo Merlot Cabernet Sauvignon 2017 z Mołdawii czy kalifornijskie VDR 2020, jak również nowości w typie hiszpańskiego wina Vivir sin Dormir 2020 czy hiszpańskich win Fantini Cala Rey (białe i czerwone) 2021. Uznanie w oczach wymagającego jury zdobyły także sardyńskie wina Fantini z niewystępującej praktycznie poza wyspą odmiany monica, Barolo 2018 powstałe w winiarni Luca Bosio oraz Grand Tokaj Szamorodni 2018. Warto także zwrócić uwagę na świeży, grecki „narybek” Domu Wina czyli wina rodem z Nemea Winery, wśród których aż cztery – The Signature Collection Nemea 2019, The Signature Collection Assyrtiko 2021, The Varietals Collection Agiorgitiko 2021 i The Signature Collection Mantinia 2021 – zdobyły złoto. Na pewno nie będzie nudy w najbliższym, zbliżającym się już wielkimi, zdecydowanymi krokami wiosenno-letnim sezonie, to pewne!'],
        image: {
          url: 'https://domwina.pl/data/include/img/news/1644936858.png',
          alt: 'alt'
        }
      }
    ]);
  });
};
