import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Textarea from "../../../atoms/ui/textarea/Textarea";
import Input from "../../../atoms/ui/inputs/CustomInput";
import Submit from "../../../atoms/ui/buttons/regular/Submit";
import { usePutProfile } from "../../../../app/crud/vineyard/profile/putProfil";
import Card from "../../../atoms/card/Card";
import CustomLink from "../../../atoms/ui/buttons/links/Link";
import { routes } from "../../../../app/router/routes";
import { useGetPublishStatus } from "../../../../app/crud/vineyard/enum/getWinePublishStatus";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InputAsyncSelect } from "../../../atoms/ui/inputs/Input_AsyncSelect";
import { getCountriesHelper } from "../../../../app/crud/app/countries/getCountries";
import { MapContainer, Marker, TileLayer, useMapEvent } from "react-leaflet";
import LocationModal from "../../../organisms/modals/LocationModal";
import Button from "../../../atoms/ui/buttons/regular/Button";
import { useModal } from "../../../../app/hooks/useModal";
import "leaflet/dist/leaflet.css";
import { InputPhoneNumber } from "../../../atoms/ui/inputs/InputPhoneNumber";
import { InputPostCode } from "../../../atoms/ui/inputs/InputPostCode";
import { getRegionsHelper } from "../../../../app/crud/vineyard/enum/getRegions";
import { useGetStrains } from "../../../../app/crud/vineyard/enum/getStrains";
import FormConfigureStrainsVineyard from "./FormConfigureStrainsVineyard";
const schema = yup.object().shape({
  descriptionLong: yup
    .string()
    .nullable()
    .max(3000, "Opis może zawierać max. 600 znaków")
    .trim()
    .required("Opis długi winnicy jest wymagany"),
  descriptionShort: yup
    .string()
    .nullable()
    .max(1000, "Opis może zawierać max. 300 znaków")
    .trim()
    .required("Opis krótki winnicy jest wymagany"),
  email: yup
    .string()
    .email("Podaj poprawny adres e-mail")
    .required("Podaj e-mail"),
  phoneNumber: yup.string().nullable(),
  name: yup.string().required("Nazwa jest obowiązkowa"),
  productionRegionId: yup.number().typeError('Region jest wymagany').required('Region jest wymagany'),
  landArea: yup.number().required("Powierzchnia winnicy jest wymagana"),
  productionStrainIds: yup
    .array()
    .required("error")
    .of(
      yup.object().shape({
        value: yup
          .number("Podanie szczepów produkowanych w winnicy jest wymagana")
          .typeError("Podanie szczepów produkowanych w winnicy jest wymagana")
          .required("Podanie szczepów produkowanych w winnicy jest wymagana"),
      })
    ),
  localization: yup.object().shape({
    latitude: yup.number(),

    longitude: yup.number(),
  }),
  originCountryId: yup.number(),
  allowPhotoSession: yup.boolean().nullable(),
  address: yup.object().shape({
    streetName: yup.string().required("Adres winnicy jest wymagany"),
    houseNumber: yup.string().required("Adres winnicy jest wymagany"),
    apartmentNumber: yup.string().nullable(),
    cityName: yup.string().required("Kod pocztowy winnicy jest wymagany"),
    zipCode: yup.string().required("Kod pocztowy winnicy jest wymagany"),
  }),
  foundYear: yup
    .number()
    .required("Podaj rok założenia winnicy"),
});

const defaultLocation = [50.04112717883472, 21.99916245955708];

const LocationPickerMarker = ({ location, setLocation }) => {
  useMapEvent("click", (e) => {
    setLocation(Object.values(e.latlng));
  });

  return <Marker position={location} />;
};

const LocationPickerModal = ({ modal, onConfirm }) => {
  const [location, setLocation] = useState(defaultLocation);
  useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);
  const handleConfirm = () => {
    if (typeof onConfirm === "function") {
      onConfirm(location);
      setLocation(defaultLocation);
    }
  };

  return (
    <LocationModal
      setOpen={modal.close}
      open={modal.isOpen}
      title="Wskaż lokalizację winnicy"
    >
      <MapContainer
        center={defaultLocation}
        zoom={13}
        style={{ height: "400px", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationPickerMarker location={location} setLocation={setLocation} />
      </MapContainer>
      <div className={"flex gap-6 justify-end mt-6"}>
        <Button onClick={modal.close}>Anuluj</Button>
        <Button onClick={handleConfirm} className={"bg-gold2"}>
          Zatwierdź
        </Button>
      </div>
    </LocationModal>
  );
};

const FormUpdateProfile = ({ data, isLoading, error }) => {
  const navigate = useNavigate();
  const locationPickerModal = useModal();
  const { data: strains, isLoading: isLoadingStrains } = useGetStrains();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      address: data?.addressDto,
      localization: data?.localizationDto,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    trigger,
  } = methods;
  const { data: publishStatus, isLoading: isLoadingPublishStatus } =
    useGetPublishStatus();
  const { mutate } = usePutProfile();
  console.log(errors)
  const onError = () => {
    toast.error("Wystapił błąd przy próbie edycji. Spróbuj ponownie później.");
  };
  const onSuccess = () => {
    toast.success("Edycja przebiegła pomyślnie.");
    navigate(routes.vineyardProfile);
  };
  const watchFields = watch(["latitude", "longitude"]);
  const handleLocationChange = ([lat, long]) => {
    setValue("latitude", lat);
    setValue("longitude", long);
    locationPickerModal.close();
    methods.trigger();
  };

  const openLocationPicker = (e) => {
    e.preventDefault();

    locationPickerModal.open();
  };

  const onSubmit = (data) => {
    mutate(data, { onError, onSuccess });
  };
  return (
    <FormProvider {...methods}>
      <form
        className={"flex flex-col gap-4 mb-6"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card>
          <div className="flex flex-col sm:flex-row gap-5 mb-2">
            <Input
              name={"name"}
              label={"Nazwa"}
              register={register}
              error={errors.name?.message}
              required
              className="w-full sm:w-1/2"
            />
            <Input
              type={"email"}
              name={"email"}
              label={"Email"}
              register={register}
              error={errors.email?.message}
              required
              className="w-full sm:w-1/2"
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-5 w-full mb-2">
            <InputAsyncSelect
              {...getRegionsHelper}
              className="w-full sm:w-[calc(50%-10px)] mt-1"
              name={"productionRegionId"}
              error={errors?.productionRegionId?.message}
              label={"Region pochodzenia"}
              placeholder={"Wybierz region pochodzenia winnicy"}
              transform={({ id, name }) => ({
                value: id,
                label: name,
              })}
            />

            <Input
              name={"localization"}
              className={"w-auto grow"}
              error={errors?.localization?.longitude?.message}
              label="Wskaż lokalizację winnicy"
              placeholder="Lokalizacja* (wskaż na mapie)"
              required
              disabled
              value={
                watchFields.some((x) => !!x)
                  ? watchFields.join(", ")
                  : undefined
              }
            />

            <div className="flex-1 self-end">
              <Button
                className="!leading-3 my-1 w-full h-[42px]"
                onClick={openLocationPicker}
              >
                Wskaż na mapie
              </Button>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-5 mb-2">
            <Input
              name={"foundYear"}
              label={"Rok założenia"}
              register={register}
              error={errors.foundYear?.message}
              required
            />
            <InputPhoneNumber
              name={"phoneNumber"}
              label={"Numer telefonu"}
              register={register}
              error={errors.phoneNumber?.message}
              required
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-5">
            <Textarea
              name={"descriptionLong"}
              label={"Długi opis"}
              register={register}
              error={errors.descriptionLong?.message}
              maxContent={600}
              rows={1}
            />
            <Textarea
              name={"descriptionShort"}
              label={"Krótki opis"}
              register={register}
              error={errors.descriptionShort?.message}
              maxContent={300}
              rows={1}
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-5 mb-2 w-1/2">
            <Input
              name={"landArea"}
              label={"Powierzchnia winnicy"}
              register={register}
              error={errors.landArea?.message}
              required
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-5">
            <Input
              name={"address.streetName"}
              label={"Ulica"}
              error={errors.address?.streetName?.message}
              required
              register={register}
              className="w-full sm:w-1/5"
            />
            <Input
              name={"address.houseNumber"}
              label={"Numer"}
              register={register}
              error={errors.address?.houseNumber?.message}
              required
              className="w-full sm:w-1/5"
            />
            <Input
              name={"address.apartmentNumber"}
              label={"Numer"}
              register={register}
              error={errors.address?.apartmentNumber?.message}
              className="w-full sm:w-1/5"
            />
            <Input
              name={"address.cityName"}
              label={"Miasto"}
              register={register}
              error={errors.address?.cityName?.message}
              required
              className="w-full sm:w-1/5"
            />

            <InputPostCode
              name={"address.zipCode"}
              register={register}
              error={errors.address?.zipCode?.message}
              label={"Kod pocztowy"}
              required
              className="w-full sm:w-1/5"
            />
          </div>
          <FormConfigureStrainsVineyard
              name={"productionStrainIds"}
              setBody={setValue}
              strains={strains}
              errors={errors}
              isLoadingStrains={isLoadingStrains}
              required
            />
          <div
            className={
              "flex flex-row w-full justify-end items-center space-x-4 mt-4"
            }
          >
            <CustomLink to={routes.vineyardProfile}>Wróć</CustomLink>
            <Submit isLoading={isLoading}>Zapisz</Submit>
          </div>
        </Card>
      </form>
      <LocationPickerModal
        modal={locationPickerModal}
        onConfirm={handleLocationChange}
      />
    </FormProvider>
  );
};

export default FormUpdateProfile;
