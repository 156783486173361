import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import { useMutation } from 'react-query';
import { routes } from '../../../router/routes';
import { useNavigate } from 'react-router-dom';

export const useAddContainer = () => {
  const navigate = useNavigate();
  return useMutation((data) => {
    return axios.post(`${BASE_URL_API}/api/v1/admin/vineyard/containers`, data)
      .then(({ data }) => {
        navigate(routes.vineyardContainers);
        return data
      })
  });
};