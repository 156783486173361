import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import useQuery2 from '../../../hooks/useQuery2';

const getStrains = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL_API}/api/v1/strains`
  }).then(({ data }) => data)

  export const getStrainsHelper = {
    queryKey: ['app-public.strains'],
    queryFn: () => getStrains(),
    isArray: true
  }


  export const useGetStrains = () => {
  return useQuery2({
    queryKey: ['app-public.strains'],
    queryFn: () => getStrains()
  });
};
