import React, { useState } from 'react';
import PortalWrapper from '../../atoms/portal-wrapper/PortalWrapper';
import Card from '../../atoms/card/Card';
import { useDropzone } from 'react-dropzone';
import { ImageCropper } from '../image-cropper/ImageCropper';
import Button from '../../atoms/ui/buttons/regular/Button';
import { AiOutlineClose, AiOutlineUpload } from 'react-icons/ai';
import Text from '../../molecules/text/Text';

const ModalUploadPhoto = ({ isOpen, setOpen, setImageBlobUrl, onChange, value, amountPhoto }) => {
  const [files, setFiles] = useState([]);
  const [croppedImgRef, setCroppedImgRef] = useState(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    // Note how this callback is never invoked if drop occurs on the inner dropzone
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  return (
    <PortalWrapper
      show={isOpen}
      onBackdropClick={() => setOpen(false)}
    >
      <Card title={'Wstaw zdjęcie'}
            className={`flex flex-col ${croppedImgRef ? 'min-h-0' : 'min-h-[400px]'} transition min-w-[900px]`}>
        {
          files[0]?.preview ?
            <div className={'flex-col w-full h-full'}>
              <ImageCropper setCroppedImgRef={setCroppedImgRef} url={files[0]?.preview} />
              <div className={'space-x-4 mt-4 flex justify-end items-center'}>
                {amountPhoto && 
                <Text className={'text-amber-900 text-xs'}>Możesz dodać jeszcze {6 - amountPhoto} zdjęć do winnicy </Text>}
                <Button importantClasses={'flex items-center bg-white text-amber-900 hover:text-amber-700 transition font-bold'} onClick={() => {
                  setFiles([]);
                  setCroppedImgRef(null);
                }}>
                  <AiOutlineClose className={'mr-2'}/>
                  Wyczyść
                </Button>
                <Button className={'ml-auto flex items-center'} onClick={() => {
                  setOpen(false);
                  setImageBlobUrl && setImageBlobUrl(croppedImgRef?.current?.cropper?.getCroppedCanvas()?.toDataURL());
                }}
                >
                  <AiOutlineUpload className={'mr-2'}/>
                  Zapisz
                </Button>
              </div>

            </div>
            :
            <div
              className={'flex-1 hover:bg-gray-100 flex w-full h-full justify-center border border-dashed border-gray-500'} {...getRootProps()}>
              <input {...getInputProps({ onChange, value })} />
              <div className={'flex text-gray-500 items-center flex-col justify-center'}>
                <p className={'text-xl mb-4'}>Przeciągnij obraz</p>
                <p className={'text-sm mb-4'}>lub</p>
                <p className={'text-xl'}>kliknij aby przeglądać pliki</p>
              </div>
            </div>
        }
      </Card>
    </PortalWrapper>
  );
};

export default ModalUploadPhoto;
