import PropTypes from 'prop-types';

const LineLoader = ({ classNames, className, children, isLoading }) => {
  return (
    <div className={`flex flex-col ${isLoading ? 'w-full' : ''} ${className}`}>
      {isLoading ? (
        <div className={className}>
          { classNames ?
            classNames.map((className, id) => (
            <div key={id} className={`bg-gray-100 text-gray-100 animate-pulse rounded-lg${className}`}>.</div>
          )) :
            <div className={`bg-gray-100 text-gray-100 animate-pulse h-9 rounded-lg ${className}`}>.</div>
          }
        </div>
      ) : (
        children
      )}
    </div>
  );
};

LineLoader.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  children: PropTypes.any,
  isLoading: PropTypes.bool
};

export default LineLoader;
