import React from 'react';
import Header2 from '../molecules/headers/Header2';

const TemplateSection = ({children, fullscreen, title, dark, classNameTitle, className}) => {
  return (
    <section className={`pb-8 ${fullscreen ? '' : 'px-4'} w-full ${dark ? 'bg-gray-100' : ''} ${className}`}>
      <div className={`${fullscreen ? '' : ' max-w-screen-xl mx-auto pt-8 overflow-hidden'} `}>
        {
          title &&
          <Header2 className={`text-2xl mb-8 text-center md:text-start ${classNameTitle}`}>{title}</Header2>
        }
        {children}
      </div>
    </section>
  );
};

export default TemplateSection;
