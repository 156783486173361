
const useTailwindGridClasses = () => {
  const grid_columns = (num) => {
    if(num===1) return 'grid-cols-1'
    if(num===2) return 'grid-cols-2'
    if(num===3) return 'grid-cols-3'
    if(num===4) return 'grid-cols-4'
    if(num===5) return 'grid-cols-5'
    if(num===6) return 'grid-cols-6'
    if(num===7) return 'grid-cols-7'
    if(num===8) return 'grid-cols-8'
    if(num===9) return 'grid-cols-9'
    if(num===10) return 'grid-cols-10'
    if(num===11) return 'grid-cols-11'
    if(num===12) return 'grid-cols-12'
  }

  const grid_span = (num) => {
    if(num===1) return 'col-span-1'
    if(num===2) return 'col-span-2'
    if(num===3) return 'col-span-3'
    if(num===4) return 'col-span-4'
    if(num===5) return 'col-span-5'
    if(num===6) return 'col-span-6'
    if(num===7) return 'col-span-7'
    if(num===8) return 'col-span-8'
    if(num===9) return 'col-span-9'
    if(num===10) return 'col-span-10'
    if(num===11) return 'col-span-11'
    if(num===12) return 'col-span-12'
  }

  return {grid_columns, grid_span}
};

export default useTailwindGridClasses;
