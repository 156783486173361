import React, {useEffect, useState} from 'react';
import PortalWrapper from '../../atoms/portal-wrapper/PortalWrapper';
import Card from '../../atoms/card/Card';
import Image from "../../atoms/image/Image";
import Text from "../../molecules/text/Text";
import ProductRatingComponent from "../../molecules/rating-product/ProductRatingComponent";
import Textarea from "../../atoms/ui/textarea/Textarea";
import Submit from "../../atoms/ui/buttons/regular/Submit";
import { useAddRatings } from '../../../app/crud/customer/ratings/addRatings';
import toast from 'react-hot-toast';
import * as yup from "yup";
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlineClose } from 'react-icons/ai';
import { useGetWine } from '../../../app/crud/app/wine';
import { usePutRatings } from '../../../app/crud/customer/ratings/putRatings';

const ModalRateWine = ({isOpen, setOpen, productId}) => {
    const schema = yup.object().shape({
        starsCount: yup.number().required('Aby dodać ocenę, musisz dodać ocenę.'),
        comment: yup.string().required("Komentarz do opisu produktu jest wymagany."),
        wineProductId: yup.number()
    })
    const {data, isLoading} = useGetWine(productId)

    const [rate, setRate] = useState()
    const {mutate, isLoading: isLoadingRatings} = useAddRatings()
    const {mutate: putMutate, isLoading: isLoadingPut} = usePutRatings()
    const onSuccess = () => {
        toast.success("Udało się dodać ocenę produktu")
    }

    const onError = () => {
        toast.error("Wystąpił błąd przy próbie oceny produktu. Spróbuj ponownie później")
    }

    const onSuccessPut = () => {
        toast.success('Udało się edytować ocenę produktu')
    }

    const onErrorPut = () => {
        toast.error('Wystąpił błąd przy próbie oceny produktu. Spróbuj ponownie później')
    }
    const methods = useForm({
        resolver: yupResolver(schema),
        // defaultValues: {
        //     ...initialData
        // }
    })

    const {register, handleSubmit, setValue, formState: {errors}} = methods

    const onSubmitAdd = (data) => {
        mutate(data, {onSuccess, onError})
    }

    const onSubmitPut = (data) => {
        putMutate(data, {onSuccess: onSuccessPut, onError: onErrorPut})
    }

    useEffect(() => {
        setValue('wineProductId', productId)
    },[productId])
    return (
        <FormProvider {...methods}>
        <PortalWrapper
            show={isOpen}
            onBackdropClick={() => setOpen(false)}
        >
            <Card className='px-10'>
                <div className='sm:block lg:hidden'>
                <AiOutlineClose onClick={() => setOpen(false)} className={'float-right cursor-pointer text-end text-2xl text-red-700 hover:text-red-600'}/>
                </div>
                <div className='flex justify-center'>
                <Image isLoading={isLoading}  sizeClasses={'h-80 w-80'} src={data?.imageUrl}/>
                </div>
                <div className={'mt-4'}>
                    <Text className={'text-center font-bold text-xl'} isLoading={isLoading}>{data?.name}</Text>
                </div>
                <form onSubmit={handleSubmit(onSubmitAdd)} className={'my-4'}>
                    <ProductRatingComponent name={"starsCount"} setFormValue={setValue} setRateCallback={setRate} />
                    <Textarea name={"comment"}
                                register={register}
                                error={errors.comment?.message}
                                maxContent={500}
                                rows={1}
                                onChange
                                placeholder={"Podziel się swoją opinią z innymi"} />
                    <Submit disabled={!rate} className={'ml-auto mt-8'}>Opublikuj</Submit>
                </form>
               
            </Card>
        </PortalWrapper>
        </FormProvider>
    );
};

export default ModalRateWine;
