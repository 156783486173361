import React, {useState} from 'react';
import {Link} from "react-router-dom";

const Tab = ({url, children, isActive}) => {
    const [hover, setHover] = useState(false)
    return (
            <Link
                onMouseEnter={()=>{setHover(true)}}
                onMouseLeave={()=>{setHover(false)}}
                className={`relative ${isActive ? 'font-bold text-gray-900': 'text-gray-600 hover:text-black transition'} text-sm pb-2`}
                to={url}
            >
                {children}
                {
                    isActive &&
                    <div className={'absolute h-[2px] -bottom-px bg-bronze w-full'}/>
                }
                {
                    !isActive && hover &&
                    <div className={'absolute h-[2px] -bottom-px bg-gray-500 w-full'}/>
                }
            </Link>

    );
};

export default Tab;
