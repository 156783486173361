import TemplateMain from '../../../templates/TemplateMain';
import FormLogin from "../../../molecules/forms/FormLogin";

const ViewLogin = () => {
    return (
        <TemplateMain title={'Logowanie'}>
            <div className={'flex justify-center my-8 md:my-16'}>
                <FormLogin/>
            </div>
        </TemplateMain>
    );
};

export default ViewLogin;
