import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import useQuery2 from '../../../hooks/useQuery2';

const getProfile = () => {
  return axios.get(`${BASE_URL_API}/api/v1/admin/vineyard`)
    .then(({ data }) => data);
};

export const useVineyardProfile = () => {
  return useQuery2({
    queryKey: ['app-public.profile'],
    queryFn: getProfile
  });
};
