import React from 'react';

const ContactItem = ({icon, children}) => {
    return (
        <div className={'flex items-center space-x-2'}>
            <div>
                {icon && icon()}
            </div>
            <p>{children}</p>
        </div>
    );
};

export default ContactItem;
