import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useMutation } from "react-query";

export const useDeleteLogoImage = () => {
  return useMutation(() => {
    return axios
      .delete(`${BASE_URL_API}/api/v1/admin/vineyard/remove-logo-image`)
      .then(({ data }) => data)
  });
};
