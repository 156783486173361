import axios from '../../config/axios';
import {BASE_URL_API} from '../../config/env';
import {useLocation, useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";

export const useGetWines = () => {
    const location = useLocation()
    const [queryParams] = useSearchParams()
    const page = queryParams.get("page");
    const items = queryParams.get("items")
    return useQuery({
        queryKey: ['app-public.wines', page, location],
        queryFn: () => axios({
            method: 'GET',
            url: `${BASE_URL_API}/api/v1/wine-products${location?.search}`,
            params: {
                PageNumber: page || 1,
                PageSize: items || 16,
            }
        }).then(({data}) => {
            return data
        }),
        refetchOnWindowFocus: false
    });
}
