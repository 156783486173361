import React from 'react';
import Filters from '../organisms/filters/FilerWine';
import ActiveFilters from '../molecules/filters/ActiveFilters';
import MobileFilters from '../organisms/filters/MobileFilters';
import Card from "../atoms/card/Card";

const TemplateFilters = ({children, filters, isLoading, state, items, isItemsFetching}) => {
    return (
        <div className={'flex flex-row md:space-x-4'}>
            <div className={'hidden md:block sticky top-4 self-start'}>
                <Filters filters={filters} isLoading={isLoading} state={state}/>
            </div>

            <div className={'flex relative flex-col w-full'}>
                <ActiveFilters state={state}/>

                <div className={'block md:hidden'}>
                    <MobileFilters filters={filters} isLoading={isLoading} state={state} />
                </div>
                {!isItemsFetching && items?.items?.length === 0 ?
                    <Card className={'text-center'}>Nie znaleziono</Card> : children}

            </div>
        </div>
    );
};

export default TemplateFilters;
