import React from 'react';
import Card from "../../atoms/card/Card";
import CustomDataTable from "../customTable/CustomDataTable";
import usePagination from "../../../app/hooks/usePagination";
import EnoturismBadge from "../../atoms/badge/EnoturismBadge";
import {passParams, routes} from "../../../app/router/routes";
import { useGetVineyardAdvertisements } from '../../../app/crud/vineyard/enoturism/getAdvertisements';
import dayjs from 'dayjs';

const AdvertisementsTable = () => {
    const {data, isLoading} = useGetVineyardAdvertisements()
    const {onPageChange, currentPage, totalPages, rowsPerPage} = usePagination();
    const columns = [
        { name: "Nazwa", cell: (row) =>  <p>{row?.name ? row?.name : "Brak nazwy"}</p>},
        { name: "Dostępne od", cell: (row) => <p>{row?.availableFrom ? dayjs(row?.availableFrom).format('DD.MM.YYYYr') : 'Brak daty'}</p> },
        { name: "Dostępne do", cell: (row) => <p>{row?.availableTo ? dayjs(row?.availableTo).format('DD.MM.YYYYr') : 'Brak daty'}</p> },
        { name: "Liczba pokoi", cell: (row) => <p>{row?.roomsCount}</p> },
        { name: "Status", cell: (row) => <EnoturismBadge bgClass={'5'}>Zarezerwowane</EnoturismBadge> },
    ];
    return (
        <Card>
            <CustomDataTable
                columns={columns}
                isLoading={isLoading}
                onRowClickRedirect={(row) => passParams(routes.vineyardPanelEnoturismAdvertisement, {id: row?.id})}
                data={data?.items}
                pagination={{
                    currentPage: currentPage,
                    totalPages: totalPages,
                    rowsPerPage: rowsPerPage,
                    onPageChange: onPageChange,
                }}
            />
        </Card>
    );
};

export default AdvertisementsTable;
