import axios from '../../../config/axios'
import { BASE_URL_API } from '../../../config/env'
import { useMutation } from 'react-query'

export const useEditProfile = () => {
    return useMutation((data) => {
        return axios.post(`${BASE_URL_API}/api/v1/user/update-profile`, data).then(res => {
            return res
        }).then(({data}) => data);
    })
}