import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useGetContainer } from "../../../../app/crud/vineyard/containers/getContainer";
import { useAddContainersEvent } from "../../../../app/crud/vineyard/containersEvent/addEvent";
import { usePutEvent } from "../../../../app/crud/vineyard/containersEvent/putEvent";
import { passParams, routes } from "../../../../app/router/routes";
import Card from "../../../atoms/card/Card";
import CustomLink from "../../../atoms/ui/buttons/links/Link";
import Submit from "../../../atoms/ui/buttons/regular/Submit";
import Input from "../../../atoms/ui/inputs/CustomInput";
import { InputDatepicker } from "../../../atoms/ui/inputs/Input_DatePicker";
import Textarea from "../../../atoms/ui/textarea/Textarea";
import { getMeasurementHelper } from "../../../../app/crud/vineyard/enum/getMeasurementType";
import { InputAsyncSelect } from "../../../atoms/ui/inputs/Input_AsyncSelect";
const FormCreateEventsContainer = ({ mode, initialData, refetch }) => {
  const { data } = useGetContainer();
  const navigate = useNavigate();
  const { id } = useParams();

  const schema = yup.object().shape({
    description: yup.string().trim(),
    value: yup.string().required("Wartość zdarzenia jest wymagana."),
    dateTime: yup.date(),
    measurementType: yup
      .number()
      .typeError("Typ jest wymagany")
      .required("Typ jest wymagany"),
  });

  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie dodania zdarzenia do zbiornika. Spróbuj ponownie później."
    );
  };

  const onSuccess = () => {
    toast.success("Udało się pomyślnie dodać zdarzenie do zbiornika.");
    refetch();
  };

  const onErrorPut = () => {
    toast.error(
      "Wystapił błąd przy próbie zmiany zdarzenia w zbiorniku. Spróbuj ponownie później."
    );
  };

  const onSuccessPut = () => {
    toast.success("Udało się pomyślnie edytować zdarzenie.");
    refetch();
  };
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialData,
      measurementType: initialData?.measurementType?.id,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    ...form
  } = methods;

  const { mutate, isLoading } = useAddContainersEvent();
  const { mutateAsync: putMutate, isLoading: putIsLoading } = usePutEvent();

  const onSubmitAdd = (data) => {
    // data.dateTime = new Date(data?.dateTime).toLocaleString()
    mutate(data, { onSuccess, onError });
  };

  const onSubmitPut = (data) => {
    putMutate(data, { onSuccess: onSuccessPut, onError: onErrorPut });
  };
  return (
    <FormProvider {...methods}>
      <form
        className={"w-full"}
        onSubmit={handleSubmit(mode === "edit" ? onSubmitPut : onSubmitAdd)}
      >
        <div className={"flex flex-col lg:flex-row w-full justify-between"}>
          <Card
            className={"flex flex-col w-full"}
            title={mode === "edit" ? "Edytuj zdarzenie" : "Utwórz zdarzenie"}
          >
            <div
              className={
                "flex-col sm:flex-row w-full space-y-5 sm:space-y-0 sm:space-x-5"
              }
            >
              <div
                className={
                  "flex flex-col sm:flex-row w-full space-y-5 sm:space-y-0 sm:space-x-5 "
                }
              >
                <div className="space-y-5 w-full">
                  <Input
                    name={"value"}
                    label={"Zdarzenie"}
                    register={register}
                    error={errors?.value?.message}
                    required
                  />

                  <InputDatepicker
                    name={"dateTime"}
                    label={"Data rozpoczęcia"}
                    placeholder="Data rozpoczęcia"
                    // withTime
                    required
                  />
                </div>
                <div className="space-y-5 w-full">
                  <InputAsyncSelect
                    {...getMeasurementHelper}
                    name={"measurementType"}
                    label={"Typ zdarzenia"}
                    placeholder={"Wybierz typ zdarzenia"}
                    transform={({ id, name }) => ({
                      value: id,
                      label: name,
                    })}
                    required
                  />
                </div>
              </div>
            </div>
            <div className={"space-y-5 w-full"}>
              <Textarea
                name={"description"}
                label={"Opis"}
                register={register}
                error={errors.description?.message}
                maxContent={1500}
                rows={1}
                onChange
              />
            </div>
            <div
              className={
                "flex flex-row w-full justify-end items-center space-x-4 mt-4"
              }
            >
              <CustomLink
                to={passParams(routes.vineyardViewEventContainer, {
                  id: data?.id,
                })}
              >
                Wróć
              </CustomLink>
              {mode === "edit" ? (
                <Submit isLoading={putIsLoading}>Zapisz</Submit>
              ) : (
                <Submit isLoading={isLoading}>Dodaj</Submit>
              )}
            </div>
          </Card>
        </div>
      </form>
    </FormProvider>
  );
};

export default FormCreateEventsContainer;
