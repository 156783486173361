import dayjs from "dayjs";
import { useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useGetMessages } from "../../../../app/crud/vineyard/Error/getErrorMessages";
import usePagination from "../../../../app/hooks/usePagination";
import { passParams, routes } from "../../../../app/router/routes"
import Card from "../../../atoms/card/Card";
import { truncateText } from "../../../atoms/TruncatText/truncateText";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import CustomDataTable from "../../../organisms/customTable/CustomDataTable";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../templates/TemplatePanel";

const ViewVineyardError = () => {
    const {data, isLoading, refetch} = useGetMessages();
    const {onPageChange, currentPage, totalPages, rowsPerPage, setTotalCount, setTotalPages} = usePagination(refetch);
    useEffect(() => {
        setTotalPages(data?.totalPages);
        setTotalCount(data?.setTotalCount)
    }, [data])
    const bc = [{label: 'Błędy', url: routes.vineyardError}];
    const columns = [
        {name: 'Nazwa', cell: row => <p className="text-s mr-4">{row?.title}</p>},
        {name: 'Opis', cell: row => <p className="text-s mr-4">{truncateText(row?.text, 40)}</p>},
        {name: 'Data zgłoszenia', cell: row => <p className="text-s mr-4">{dayjs(row?.createdDataTime).format('DD.MM.YYYYr')}</p>}
    ]
    return (
        <TemplatePanel
            actionButtonsRenderer={() => <ButtonLink to={routes.vineyardErrorAdd} className={'text-white text-sm flex items-center bg-green-700 hover:bg-green-600'} tooltip={'Zgłoś błąd'}><AiOutlinePlus size={'1.25rem'}/></ButtonLink>}
            title={'Błędy'}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
            isLoading={isLoading}
        >
            <Card>
                <CustomDataTable
                    onRowClickRedirect={(row) => passParams(routes.vineyardError, {id: row?.id})}
                    columns={columns}
                    isLoading={isLoading}
                    data={data?.items}
                    pagination={{
                        currentPage: currentPage,
                        totalPages: totalPages,
                        rowsPerPage: rowsPerPage,
                        onPageChange: onPageChange
                    }}
                />
            </Card>

        </TemplatePanel>
    )
}

export default ViewVineyardError