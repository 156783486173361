import BurgerButton from '../../atoms/ui/buttons/regular/Burger';
import PortalWrapper from '../../atoms/portal-wrapper/PortalWrapper';
import { useState } from 'react';
import NavAndSearch from '../../molecules/header-parts/NavAndSearch';
import Logo from '../../atoms/logo/Logo';
import LoginCartLang from '../../molecules/header-parts/LoginCartLang';
import MobileNavSeparator from '../../atoms/separators/MobileNavSeparator';
import CartLink from '../../atoms/header-parts/CartLink';
import { PublicSideMenu } from '../modals/PublicSideMenu';

const MobileHeader = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <header className={'relative justify-between bg-bronze text-gold flex flex-row w-full shadow p-4 items-center'}>
      <BurgerButton onClick={() => setOpen(true)} />
      <Logo />
      <CartLink hideLabel />
    <PublicSideMenu setOpen={setOpen} isOpen={isOpen}/>
    </header>
  );
};

export default MobileHeader;
