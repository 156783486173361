import React from 'react';
import Header1 from "../../molecules/headers/Header1";
import Text from "../../molecules/text/Text";
import Header2 from "../../molecules/headers/Header2";
import CustomDataTable from '../customTable/CustomDataTable';
import dayjs from 'dayjs';
import { passParams, routes } from '../../../app/router/routes';
import { truncateText } from '../../atoms/TruncatText/truncateText';

const ContainerEvents = ({data, isLoading}) => {
    const columns = [
        {name: 'Nazwa eventu', cell: row => <p className={'text-xs uppercase mr-4'} >{row?.value ? row?.value : 'Brak nazwy zdarzenia'}</p>},
        {name: 'Opis', cell: row => <p className={'text-xs uppercase mr-4'} >{truncateText(row?.description ? row?.description : 'Brak opisu zdarzenia', 30)}</p>},
        {name: 'Data zdarzenia', cell: row => <p className={'text-xs uppercase mr-4'} >{row?.dateTime ? dayjs(row?.dateTime).format('DD.MM.YYYYr.') : 'Brak daty zdarzenia'}</p>},
    ];
    return (
        <CustomDataTable
                onRowClickRedirect={(row) => passParams(routes.vineyardViewSingleEventContainer, {id: row?.wineContainerId, eventId: row?.id})}
                columns={columns}
                emptyText={"Nie masz jeszcze żadnych zamówień."}
                isLoading={isLoading}
                data={data?.items}
            />
    );
};

export default ContainerEvents;
