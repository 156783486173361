import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tooltip from '../../../../molecules/ToolTip/Tooltop';

const ButtonLink = ({ disabled, to, children, className, tooltip }) => {
  return (
    <Tooltip value={tooltip}>
    <Link
      disabled={disabled}
      className={`${disabled ? 'bg-gray-500 pointer-events-none' : 'bg-gold2 hover:gold'} transition text-white rounded-lg px-4 py-2 ${className}`}
      to={to}>
      {children}
    </Link>
    </Tooltip>
  );
};

ButtonLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
  className: PropTypes.string
};

export default ButtonLink;
