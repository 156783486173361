import React, {useState, useRef, useEffect} from "react";

const Textarea = ({
                      name,
                      placeholder,
                      label,
                      register,
                      required,
                      error,
                      rows,
                      maxContent,
                      ...props
                  }) => {
    const [currentLength, setCurrentLength] = useState(0);
    const handleChange = (e) => {
        setCurrentLength(e.target.value.length);
    };

    return (
        <div className={"relative w-full my-1"}>
            <div className="flex justify-between items-center gap-2">
                <div className={"text-sm mb-1"}>
                    {label} {required && <span className="text-red-600">*</span>}
                </div>
                {!!maxContent && (
                    <small
                        className={`${
                            currentLength > maxContent ? "text-red-600" : ""
                        } font-bold text-gray-400 ml-auto`}
                    >
                        {currentLength}/{maxContent}
                    </small>
                )}
            </div>
            <textarea
                rows={5}
                placeholder={placeholder || label}
                className={
                    `box-border bg-gray-50 text-sm w-full rounded-lg border shadow-sm px-2.5 py-2 ${error ? 'border-red-500 bg-red-50 placeholder-red-700' : 'border-gray-300'}`
                }
                {...(register ? register(name, {required, onChange: handleChange}) : {})}
            />
            {error && (
                <div className={`text-sm absolute top-full left-0 text-red-800 `}>
                    {error}
                </div>
            )}
        </div>
    );
};

export default Textarea;
