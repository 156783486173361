import TemplatePanel from "../../../../templates/TemplatePanel";
import VineyardSideMenu from "../../../../organisms/side-menu/VineyardSideMenu";
import React, { useState } from "react";
import { passParams, routes } from "../../../../../app/router/routes";
import Breadcrumbs from "../../../../organisms/breadcrumbs/Breadcrumbs";
import ButtonLink from "../../../../atoms/ui/buttons/links/ButtonLink";
import { useGetContainer } from "../../../../../app/crud/vineyard/containers/getContainer";
import toast from "react-hot-toast";
import ModalGenericConfirm from "../../../../organisms/modals/ModalGenericConfirm";
import Button from "../../../../atoms/ui/buttons/regular/Button";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { useDeleteContainer } from "../../../../../app/crud/vineyard/containers/deleteContainer";
import { useGetEvent } from "../../../../../app/crud/vineyard/containersEvent/getEvent";
import { useDeleteEvent } from "../../../../../app/crud/vineyard/containersEvent/deleteEvent";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import ContainerDetails from "../../../../organisms/container/ContainerDetails";
import ContainerEventDetails from "../../../../organisms/container/ContainerEventDetails";

const ViewVineyardContainerEvent = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetContainer();
  const {data: dataEvent, isLoading: isLoadingEvent, refetch} = useGetEvent()
  const [confirmDeleteEventModalOpen, setConfirmDeleteEventModalOpen] =
    useState(false);
  const { mutate: deleteEvent} = useDeleteEvent();
  const bc = [
    { label: "Zbiorniki", url: routes.vineyardContainers },
    {
      label: data?.name,
      url: passParams(routes.vineyardContainer, { id: data?.id }),
    },
    {
        label: "Zdarzenia",
        url: passParams(routes.vineyardViewEventContainer, { id: data?.id })
    },
    {
        label: dataEvent?.value,
        url: passParams(routes.vineyardViewSingleEventContainer, {id: data?.id, eventId: dataEvent?.id})
    }

  ];
  const tabConfig = [
    {
      label: "Zbiorniki",
      url: passParams(routes.vineyardContainer, { id: data?.id }),
    },
    {
      label: "Zdarzenia",
      url: passParams(routes.vineyardViewEventContainer, { id: data?.id }),
    },
  ];
  const onDeleteError = () => {
    toast.error(
      "Wystąpił błąd przy próbie usunięcia zdarzenia. Spróbuj ponownie później."
    );
  };
  const onDeleteSuccess = () => {
    toast.success("Zdarzenie zostało usuniętę pomyślnie.");
  };
  const buttonsRenderer = () => {
    return (
      <div className={"flex space-x-4"}>
        <ModalGenericConfirm
          isOpen={confirmDeleteEventModalOpen}
          setOpen={setConfirmDeleteEventModalOpen}
          onConfirm={() =>
            deleteEvent(
              {},
              { onSuccess: onDeleteSuccess, onError: onDeleteError }
            )
          }
        >
          Czy na pewno chcesz usunąć zdarzenie?
        </ModalGenericConfirm>

        <ButtonLink
        tooltip={'Edytuj zdarzenie w zbiorniku'}
          to={passParams(routes.vineyardContainerEventEdit, { id: data?.id, eventId: dataEvent?.id })}
          className={
            "text-white text-sm flex items-center bg-gold2 hover:bg-gold"
          }
        >
          <AiFillEdit size={'1.25rem'} />
        
        </ButtonLink>
        <Button
        tooltip={'Usuń zdarzenie w zbiorniku'}
          onClick={() => setConfirmDeleteEventModalOpen(true)}
          className={
            "text-white text-sm flex items-center bg-red-700 hover:bg-red-600"
          }
        >
          <AiOutlineDelete size={'1.25rem'}/>
        </Button>
      </div>
    );
  };
  return (
    <TemplatePanel
      isLoading={isLoading}
      title={"Zbiornik"}
      sideMenuRenderer={() => <VineyardSideMenu />}
      actionButtonsRenderer={buttonsRenderer}
      tabsConfig={tabConfig}
      breadcrumbsRenderer={() => (
        <Breadcrumbs
          isLoading={isLoading}
          homeRoute={routes.vineyardDesktop}
          settings={bc}
        />
      )}
    >
          <Card >
            <ContainerEventDetails data={dataEvent} isLoading={isLoadingEvent} refetch={refetch}/>
          </Card>

    </TemplatePanel>
  );
};

export default ViewVineyardContainerEvent;
