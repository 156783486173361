import TemplateMain from '../../templates/TemplateMain';
import TemplateSection from '../../templates/TemplateSection';
import Bestsellers from '../../organisms/swipers/Bestsellers';
import Vineyards from '../../organisms/swipers/Vineyards';
import {useGetVineyards} from '../../../app/crud/app/vineyards';
import HeroImage from "../../organisms/hero-image/HeroImage";
import {useGetWines} from "../../../app/crud/app/wines";
import { useGetBlogs } from '../../../app/crud/app/blog/blogs';
import Features from '../../organisms/LandingPageFeatures/Features';

const ViewHome = () => {
    const {data, isLoading: isVineyardsLoading} = useGetVineyards();
    const {data: wines, isLoading: isWinesLoading} = useGetWines();
    const {data: blogs, isLoading: isBlogsLoading} = useGetBlogs();
    return (
        <TemplateMain fullscreen disableBreadcrumbs>
            <TemplateSection  fullscreen>
                <HeroImage/>
            </TemplateSection>
            <TemplateSection classNameTitle={'text-bronze text-2xl lg:text-3xl font-bold !text-center mb-4 md:mb-6'} dark title={'Polecane produkty'}>
                <Bestsellers data={wines} isLoading={isWinesLoading}/>
            </TemplateSection>
            <TemplateSection >
                <Features />
            </TemplateSection>
            <TemplateSection dark classNameTitle={'text-bronze text-2xl lg:text-3xl font-bold !text-center mb-4 md:mb-6'}  title={'Popularne winnice'}>
                <Vineyards data={data} isLoading={isVineyardsLoading}/>
            </TemplateSection>

        </TemplateMain>
    );
};

export default ViewHome;
