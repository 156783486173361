import React, {useEffect} from 'react';
import FormCheckoutCustomerAddress from '../../../../molecules/forms/checkout/FormCheckoutCustomerAddress';
import FormCheckoutDelivery from '../../../../molecules/forms/checkout/FormCheckoutDeliveryMethod';
import FormCheckoutPayment from '../../../../molecules/forms/checkout/FormCheckoutPaymentMethod';
import Summary from '../../../../molecules/cart/Summary';
import Submit from '../../../../atoms/ui/buttons/regular/Submit';
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import Cookies from 'universal-cookie';
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import {actionOrderView} from '../../../../../app/redux/orderReducer/orderActionTypes';
import {routes} from '../../../../../app/router/routes';
import {useNavigate} from "react-router-dom";
import {IoIosArrowForward} from "react-icons/io";
import {useSelectAddress} from "../../../../../app/redux/orderReducer/orderSelectors";
import CartSummary from "../../../../molecules/cart/Summary";


const CheckoutCards = ({summaryConfig}) => {
    const cookies = new Cookies();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const initialData = useSelectAddress()
    const addressSchema = yup.object().shape({
        guestGuid: yup.string().nullable(),
        isCompany: yup.bool().nullable(),
        email: yup.string().email("Podaj poprawny adres e-mail").required("Podaj e-mail"),
        phoneNumber: yup.string().nullable(),
        name: yup.string().required("Podaj imię"),
        surname: yup.string().required("Podaj nazwisko"),
        shippingAddress: yup.object().shape({
            streetName: yup.string().required("Podaj ulicę"),
            houseNumber: yup.string().required("Podaj numer domu"),
            apartmentNumber: yup.string().nullable(),
            cityName: yup.string().required("Podaj miasto"),
            zipCode: yup.string().required("Podaj kod pocztowy"),
        }),
        isUsingOptionalShippingAddress: yup.bool().nullable(),
        optionalShippingAddress: yup.object().when("isUsingOptionalShippingAddress", {
            is: true,
            then: yup.object().shape({
                streetName: yup.string().required("Podaj numer domu"),
                houseNumber: yup.string().required("Podaj numer domu"),
                apartmentNumber: yup.string().nullable(),
                cityName: yup.string().required("Podaj miasto"),
                zipCode: yup.string().required("Podaj kod pocztowy")
            }),
            otherwise: yup.object().shape({
                streetName: yup.string().nullable(),
                houseNumber: yup.string().nullable(),
                apartmentNumber: yup.string().nullable(),
                cityName: yup.string().nullable(),
                zipCode: yup.string().nullable()
            }),
        }),
        companyName: yup.string().nullable("Podaj nazwę firmy"),
        companyNip: yup.string().when("isCompany", {
            is: true,
            then: yup.string().required("Podaj nip")
        })
    });
    const methods = useForm({
        resolver: yupResolver(addressSchema),
        defaultValues: {
            ...initialData,
            
        },
    });
    const {register, handleSubmit, formState: {errors}, setValue} = methods;

    useEffect(() => {
        setValue("guestGuid", cookies.get("guestGuid"))
    }, [])

    const onSubmit = (data) => {
        dispatch(actionOrderView({value: data}))
        navigate(routes.cartSummary)
    };

    return (
        <FormProvider {...methods}>
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={'space-y-5 lg:space-y-0 md:flex flex-col lg:flex-row w-full'}>
            <div className={'w-full space-y-5'}>
                <FormCheckoutDelivery/>
                <FormCheckoutPayment/>
                <FormCheckoutCustomerAddress initialData={initialData} setValue={setValue} register={register} errors={errors}/>
            </div>
            <CartSummary config={summaryConfig}/>
        </form>
        </FormProvider>
    );
};

export default CheckoutCards;
