import { useNavigate, useParams } from "react-router-dom";
import { useAddBlog } from "../../../../app/crud/app/blog/addBlog";
import ImageUploader from "../../../molecules/image-uploader/ImageUploader";
import * as yup from "yup";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { usePutBlog } from "../../../../app/crud/app/blog/putBlog";
import Card from "../../../atoms/card/Card";
import Input from "../../../atoms/ui/inputs/CustomInput";
import Textarea from "../../../atoms/ui/textarea/Textarea";
import CustomLink from "../../../atoms/ui/buttons/links/Link";
import { passParams, routes } from "../../../../app/router/routes";
import Submit from "../../../atoms/ui/buttons/regular/Submit";
import Example from "./TextEditor";
import TextEditor from "./TextEditor";

export const FormCreateBlog = ({ mode, initialData }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    title: yup.string().required("Tytuł wpisu jest wymagany"),
    text: yup.string().required('Treść jest wymagana'),
  });

  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie dodania wpisu na blog. Spróbuj ponownie później."
    );
  };

  const onSuccess = () => {
    toast.success("Udało się pomyślnie dodać wpis na blogu.");
  };

  const onErrorPut = () => {
    toast.error(
      "Wystąpił błąd przy próbie edycji wpisu na blogu. Spróbuj ponownie później."
    );
  };

  const onSuccessPut = () => {
    toast.success("Udało się pomyślnie edytować produkt.");
    navigate(passParams(routes.vineyardPanelBlog, { id }));
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialData,
    },
  });

  const { mutate, isLoading } = useAddBlog();
  const { mutate: putMutate, isLoading: putIsLoading } = usePutBlog();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = methods;

  const onSubmitAdd = (data) => {
    mutate(data, { onSuccess, onError });
  };

  const onSubmitPut = (data) => {
    putMutate(data, { onSuccess: onSuccessPut, onError: onErrorPut });
  };

  return (
    <FormProvider {...methods}>
      <form
        className={"w-full"}
        onSubmit={handleSubmit(mode === "edit" ? onSubmitPut : onSubmitAdd)}
      >
        <div className={"flex flex-col lg:flex-row w-full justify-between"}>
          <Card
            className={"flex flex-col w-full"}
            title={mode === "edit" ? "Edytuj post" : "Dodaj post"}
          >
            <div
              className={
                "flex-col sm:flex-row w-full space-y-5 sm:space-y-0 sm:space-x-5"
              }
            >
              <div
                className={
                  "flex flex-col sm:flex-row w-full space-y-5 sm:space-y-0 sm:space-x-5"
                }
              >
                <div className={"space-y-5 w-full"}>
                  <Input
                    name={"title"}
                    label={"Tytuł"}
                    register={register}
                    error={errors?.title?.message}
                    required
                  />
                </div>
              </div>
            </div>
            <div className={"space-y-5 w-full mt-4"}>
              <TextEditor
                control={control}
                name={"text"}
                label={"Treść"}
                error={errors?.text?.message}
                required
              />
            </div>
            <div
              className={
                "flex flex-row w-full justify-end items-center space-x-4 mt-4"
              }
            >
              <CustomLink to={passParams(routes.vineyardPanelBlogs)}>
                Wróć
              </CustomLink>
              {mode === "edit" ? (
                <Submit isLoading={putIsLoading}>Zapisz</Submit>
              ) : (
                <Submit isLoading={isLoading}>Dodaj</Submit>
              )}
            </div>
          </Card>
        </div>
      </form>
    </FormProvider>
  );
};
