import React, {useState} from 'react';
import {sideNavigationConfigCustomer} from '../../../app/config/navigationConfig';
import { NavLink } from 'react-router-dom';
const CustomerSideMenu = () => {
  const [isNavHidden, setIsNavHidden] = useState(false)
    const toggleNav = () => setIsNavHidden((state) => !state)
  return (
    <>
            {/* <div
                className={`lg:hidden fixed w-full h-full z-20 fade-transition duration-300 bg-{rgba(0,0,0, .5)] ${isNavHidden ? 'opacity-0 invisible' : 'opacity-1 visible'}`}
                onClick={toggleNav}>
            </div> */}
            {/* <div
                className={`h-screen side-nav overflow-auto text-black bg-white body-font p-5 z-3 shadow-lg ${isNavHidden ? '-translate-x-full' : 'translate-x-0'} lg:translate-x-0 transition-transform duration-300 `}> */}

                <nav className='overflow-y-auto overflow-x-hidden mt-2'>
                    <ul>
                        {sideNavigationConfigCustomer.map(({Icon, ...item}) => (
                            item?.route ? (
                                <li key={item?.label}
                                    className="hover:text-gray-600 text-black transition-transform ease-in-out hover:translate-x-1 duration-300 my-1">
                                    <NavLink
                                        end
                                        to={item.route}
                                        className={({isActive}) => (isActive ? 'bg-gray-200 text-gray-600' : "hover:bg-gray-100") + ' flex p-2 items-center rounded-lg'}>
                                        <span className='w-8 font-bold text-xl '>{!!Icon && <Icon/>}</span>
                                        <span>{item?.label}</span>
                                    </NavLink>
                                </li>
                            ) : (
                                <li key={item?.label} className={'uppercase text-gray-400 text-sm my-3'}>
                                    {item?.label}
                                </li>
                            )
                        ))}
                    </ul>
                </nav>
            {/* </div> */}
        </>
  );
};

export default CustomerSideMenu;
