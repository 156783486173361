import { useLocation, useSearchParams } from "react-router-dom"
import axios from "../../../config/axios"
import { BASE_URL_API } from "../../../config/env"
import {useQuery} from 'react-query'

export const useGetMessages = () =>  {
const location = useLocation();
const [queryParams] = useSearchParams()
const page = queryParams.get('page')
const items = queryParams.get("items");
return useQuery({
    queryKey: ['app-public.messages', page, items],
    queryFn: () => axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/admin/vineyard/messages/${location.search}`,
        params: {
            PageNumber: page || 1,
            PageSize: items || 16
        }
    }).then(({data}) => {
        return data
    })
})
}