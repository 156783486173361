import React, {useEffect, useState} from 'react';

const RBGroup = ({ children, className, onChange, initCheckOption }) => {
  const [option, setOption] = useState(null);
  const handleRadioButtonClick = (value) => {
    setOption(value);
    onChange && onChange(value);
  };

  useEffect(()=>{
    handleRadioButtonClick(initCheckOption)
  },[initCheckOption])

  return (
    <div className={className}>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          handleRadioButtonClick,
          selectedValue: option
        })
      )}
    </div>
  );
};

export default RBGroup;
