import Pagination from '../../molecules/pagination/Pagination';
import useTailwindGridClasses from '../../../app/hooks/useTailwindGridClasses';
import Header1 from '../../molecules/headers/Header1';
import {Link} from 'react-router-dom';

const CustomDataTable = ({columns, data, onRowClick, pagination, title, isLoading, onRowClickRedirect, emptyText}) => {
    return (
        <div className={'h-full lg:overflow-auto overflow-scroll '}>
            {
                title &&
                <Header1 >{title}</Header1>
            }
            {
                data && columns && data.length || isLoading ?
                    <Headers  columns={columns}/> : null
            }
            {
                data && columns && data.length || isLoading ?
                    <Rows  data={data} columns={columns} onClick={onRowClick} isLoading={isLoading}
                          pagination={pagination}
                          onRowClickRedirect={onRowClickRedirect}/>
                    :
                    <div className={'pt-2 text-center w-full'}>{emptyText || "Brak rekordów do wyświetlenia"}</div>
            }
            {
                pagination &&
                <div className={'flex w-full justify-center mt-4'}>
                    <Pagination
                        currentPage={pagination.currentPage}
                        totalPages={pagination.totalPages}
                        onPageChange={pagination.onPageChange}
                    />
                </div>

            }
        </div>
    );
};

const Headers = ({columns}) => {
    //suma szerokosci wszystkich kolumn, np mamy 3 kolumny o podanych rozmiarach: (undefined, 1, 2),
    //to grid bedzie mial 4 kolumny (undefined zamienia sie na 1), z czego komorki z ostatniej kolumny
    //zajma dwie kolumny w gridzie
    const columns_number = columns.reduce((prev, column) => prev + (column.size || 1), 0);
    const {grid_columns, grid_span} = useTailwindGridClasses();
    return (
        <div className={`grid ${grid_columns(columns_number)} text-sm border-b py-4`}>
            {
                columns.map((column, id) => <p key={id + 'header'}
                                               className={`uppercase font-bold ${grid_span(column.size || 1)}`}>{column.name}</p>)
            }
        </div>
    );
};

const Rows = ({data, columns, onClick, isLoading, pagination, onRowClickRedirect}) => {
    const columns_number = columns.reduce((prev, column) => prev + (column.size || 1), 0);
    const {grid_columns, grid_span} = useTailwindGridClasses();

    if (isLoading) {
        return <LoadingRows rowsNo={pagination ? pagination.rowsPerPage : 10} columns={columns}
                            columnsNo={columns_number}/>;
    }

    return (
        <div className={`flex flex-col`}>
            {
                data.map((row) => {
                        return <Wrapper key={row.id + 'wrapper'} onRowClickRedirect={onRowClickRedirect} row={row}>
                            <div
                                className={`grid ${grid_columns(columns_number)} border-b py-2 text-sm items-center ${(onClick || onRowClickRedirect) ? 'hover:bg-gray-100 transition cursor-pointer' : ''}`}
                                onClick={onClick ? () => onClick(row) : null}
                            >
                                {
                                    columns.map((column, id) => {
                                            if (column.cell)
                                                return <div key={id + 'column' + row.id}
                                                            className={` min-w-[100px] md:pr-0 pr-12  ${grid_span(column.size || 1)}`}>{column.cell(row)}</div>;
                                            if (column.selector)
                                                return <div   key={id + 'column' + row.id}>{column.selector(row)}</div>;
                                        }
                                    )
                                }
                            </div>
                        </Wrapper>;
                    }
                )
            }
        </div>
    );
};

const LoadingRows = ({rowsNo, columns, columnsNo}) => {
    const {grid_columns, grid_span} = useTailwindGridClasses();

    return (
        <div className={`flex flex-col`}>
            {
                Array.apply(null, Array(rowsNo)).map((item, id) => {
                    return <div key={'loading-row-' + id}
                                className={`grid ${grid_columns(columnsNo)}  border-b px-2 text-sm items-center `}>
                        {
                            columns.map((cell, cell_id) =>
                                <div key={'loading-cell-' + cell_id}
                                     className={`animate-pulse w-1/2 my-2 rounded-lg bg-gray-200 h-4  ${grid_span(cell.size || 1)}`}/>)
                        }
                    </div>;
                })
            }
        </div>
    );
};

const Wrapper = ({children, onRowClickRedirect, row}) => {
    if (onRowClickRedirect)
        return (
            <Link to={onRowClickRedirect(row)}>{children}</Link>
        );
    return (
        <>{children}</>
    );
};

export default CustomDataTable;
