
import WrapButton from '../../atoms/ui/buttons/wrap/WrapButton';
import { BiLeftArrowAlt, BiRightArrowAlt, BiSkipNext, BiSkipPrevious } from "react-icons/bi";

const Pagination = ({currentPage, totalPages, onPageChange, className}) => {
  console.log(currentPage, totalPages, onPageChange)
  const page = parseInt(currentPage)
  if(totalPages===1 || !totalPages)
    return null;
  return (
    <div className={`flex flex-row space-x-4 items-center ${className}`}>
      <p className={"text-sm"}>{"Strona " + (page || 1) + " z " + (totalPages || 1)}</p>
      <div className={"flex flex-row"}>

        <WrapButton
          onClick={onPageChange ? () => onPageChange(1) : null}
          disabled={page === 1}
          className={`flex flex-row items-center p-2 ${page === 1 && "text-gray-300 cursor-auto"}`}
        >
          <BiSkipPrevious className={"w-5 h-5"} />
        </WrapButton>

        <WrapButton
          onClick={onPageChange ? () => onPageChange(page - 1) : null}
          disabled={page === 1}
          className={`flex flex-row items-center p-2 ${page === 1 && "text-gray-300 cursor-auto"}`}
        >
          <BiLeftArrowAlt className={"w-5 h-5"} />
        </WrapButton>

        <WrapButton
          onClick={onPageChange ? () => onPageChange(page + 1) : null}
          disabled={page === totalPages}
          className={`flex flex-row items-center p-2 ${page === totalPages && "text-gray-300 cursor-auto"}`}
        >
          <BiRightArrowAlt className={"w-5 h-5"} />
        </WrapButton>

        <WrapButton
          onClick={() => onPageChange(totalPages)}
          disabled={page === totalPages}
          className={`flex flex-row items-center p-2 ${page === totalPages && "text-gray-300 cursor-auto"}`}
        >
          <BiSkipNext className={"w-5 h-5"} />
        </WrapButton>
      </div>
    </div>
  );
};

export default Pagination;
