import TemplateMain from '../../../templates/TemplateMain';
import TemplateSection from '../../../templates/TemplateSection';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import { passParams, routes } from '../../../../app/router/routes';
import React from 'react';
import BlogDetails from '../../../organisms/blog-details/BlogDetails';
import { useGetPostBlog } from '../../../../app/crud/app/blog/getBlogPost';


const ViewBlogDetails = () => {
  const {data, isLoading} = useGetPostBlog();
  const bc = [{ label: 'Blog', url: routes.blog }, { label: data?.title, url: passParams(routes.blogDetails, {slug: data?.slug}) }];

  return (
    <TemplateMain
      title={'Blog'}
      breadcrumbsRenderer={() => <Breadcrumbs isLoading={isLoading} homeRoute={routes.home} settings={bc} />}
    >
      <TemplateSection>
        <BlogDetails data={data} isLoading={isLoading}/>
      </TemplateSection>
    </TemplateMain>
  );
};

export default ViewBlogDetails;
