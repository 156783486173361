import axios from '../../config/axios';
import { BASE_URL_API } from '../../config/env';
import { useMutation } from 'react-query';

export const useContact = () => {

  return useMutation((data) => {
    return axios.post(`${BASE_URL_API}/v1/contact`, data)
      .then(res => {
        return res;
      })
      .then(({ data }) => data);
  });
};
