import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "../../organisms/modals/ConfirmModal";
import toast from "react-hot-toast";
import { CgDanger } from "react-icons/cg";
import { AiOutlineDelete } from "react-icons/ai";
import Button from "../../atoms/ui/buttons/regular/Button";
import ModalGenericConfirm from "../../organisms/modals/ModalGenericConfirm";
import { useDeleteAdvertisementImage } from "../../../app/crud/vineyard/enoturism/deleteAdvertisementImage";
const GalleryImageEnoturism = ({ url, alt, item, onDeleteSuccess }) => {
  const {id} = item;
  const [open, setOpen] = useState(false);
  const [currentImgId, setCurrentImgId] = useState(null)
  const { mutate: mutateDeleteImageAdvertisement } =
  useDeleteAdvertisementImage(id);


  const onDeleteImageError = () => {
    toast.error(
      "Wystapił błąd przy próbie usunięcia zdjęcia. Spróbuj ponownie później."
    );
  };

  const onDeleteImageSuccess = () => {
    toast.success("Zdjęcie zostało usuniętę pomyślnie");
    onDeleteSuccess && onDeleteSuccess()
  };

  return (
    <>
      <div
        className={
          "rounded-lg overflow-hidden flex justify-center relative group"
        }
      >
        <div
          className={
            "cursor-pointer absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-25 transition"
          }
        />
        <img src={url} alt={alt} />
        <Button
          onClick={(id) => {setOpen(true); setCurrentImgId(id)}}
          className={
            "transition rounded-lg bg-red-600  p-2 invisible group-hover:visible absolute top-4 right-4 hover:bg-red-700 hover:text-white"
          }
          tooltip={"Usuń zdjęcie"}
        >
          <AiOutlineDelete size={"1.25rem"} />
        </Button>
      </div>
      <ModalGenericConfirm isOpen={open} setOpen={setOpen }           onConfirm={() =>
            mutateDeleteImageAdvertisement(
              {},
              { onSuccess: onDeleteImageSuccess, onError: onDeleteImageError }
            )
          }>
        Czy na pewno chcesz usunąć zdjęcie?
      </ModalGenericConfirm>
    </>
  );
};

export default GalleryImageEnoturism;
