import { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { BeatLoader } from 'react-spinners'

const LoadingImage = ({ src, className, isLoading, ...props }) => {
  return (
    <>

      {src?.map((item) => (
              <img
              src={item?.url}
              className={`${className} transition-opacity object-cover  w-full h-full ${
                isLoading ? 'opacity-0 transition-none' : ''
              }`}
              {...props}
            />
      ))}

    </>
  )
}

export default LoadingImage
