import TemplatePanel from "../../../templates/TemplatePanel";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import React from "react";
import {routes} from "../../../../app/router/routes";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import FormCreateContainer from "../../../organisms/forms/panel-vineyard/create-container/FormCreateContainer";

const ViewVineyardAddContainer = () => {
    
    const bc = [
        {label: "Zbiorniki", url: routes.vineyardContainers},
        {label: "Dodaj zbiornik", url: routes.vineyardAddContainer}
    ];
    return (
        <TemplatePanel
            title={"Zbiornik"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => (
                <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>
            )}
        >
            <FormCreateContainer/>
        </TemplatePanel>
    );
};

export default ViewVineyardAddContainer;
