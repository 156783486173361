import Submit from "../../atoms/ui/buttons/regular/Submit";
import {useSignIn} from "../../../app/crud/auth/login";
import Input from "../../atoms/ui/inputs/CustomInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormError from "../../atoms/form-error/FormError";
import {MdAlternateEmail} from "react-icons/md"
import {RiLockPasswordLine} from "react-icons/ri"
import LogInBottomContent from "./bottom-content/LogInBottomContent";
import FormCard from "../../atoms/card/FormCard";


const FormLogin = ({redirect}) => {
    const schema = yup.object().shape({
        email: yup.string().email('Podaj poprawny adres e-mail').required('Podaj e-mail'),
        password: yup.string().required('Podaj hasło'),
    });
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });
    const {mutate, isLoading, error} = useSignIn(redirect);

    const onSubmit = (data) => {
        mutate(data);
    };

    return (
        <FormCard title={"Logowanie"} bottomContent={() => <LogInBottomContent/>}>

            <form onSubmit={handleSubmit(onSubmit)} className={'w-full'}>
                <div className="flex flex-col gap-4 text-gray-800 mb-8">
                    <div>
                        <Input
                            required
                            placeholder={"Email"}
                            name={"email"}
                            label={"Email"}
                            register={register}
                            error={errors.email?.message}
                            icon={MdAlternateEmail}
                        />
                    </div>
                    <div>
                        <Input
                            placeholder={"Wprowadź hasło"}
                            required
                            type={"password"}
                            name={"password"}
                            label={"Hasło"}
                            register={register}
                            error={errors.password?.message}
                            icon={RiLockPasswordLine}
                        />
                    </div>
                </div>

                <FormError
                    message={error && "Nieprawidłowy email lub hasło."}
                    className={"mb-7 p-4 text-sm text-red-700 bg-red-100 rounded-lg"}
                />
                <Submit className={"flex w-full py-3"} isLoading={isLoading}>
                    Zaloguj się
                </Submit>

            </form>
        </FormCard>
    );
};

export default FormLogin;
