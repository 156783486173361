import React, {useState} from 'react';
import {AiOutlineStar, AiFillStar} from "react-icons/ai";

const ProductRatingComponent = ({setRateCallback, setFormValue, name}) => {
    const [rate, setRate] = useState();
    const [currentRate, setCurrentRate] = useState(0);
    return (
        <div onMouseLeave={() => setRate(0)} className={'w-max m-auto flex text-4xl text-orange-500'}>
            {
                [...Array(5).keys()].map(num => {
                    if (rate > num || currentRate > num && rate === 0)
                        return <AiFillStar onClick={()=> {
                            setCurrentRate(num + 1);
                            setRateCallback && setRateCallback(num+1);
                            setFormValue(name, num + 1);
                        }} className={'cursor-pointer'} onMouseEnter={() => setRate(num+1)}/>
                    return <AiOutlineStar onMouseEnter={() => setRate(num+1)}/>
                })
            }
        </div>
    );
};

export default ProductRatingComponent;
