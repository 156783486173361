import React from 'react';

const BadgeWineColor = ({children, bgClass, styleProp}) => {
  const className = bgClass === 'Białe' ? 'bg-yellow-100 text-yellow-800 font-bold' : bgClass === 'Różowe' ? 'bg-rose-300 text-rose-800 font-bold' : 'bg-red-300 text-red-800 font-bold'
  return (
    <div className={`${className} rounded-lg p-1 w-max px-4 flex justify-center uppercase lg:text-xs text-[11px] ${styleProp}`}>
      {children}
    </div>
  );
};

export default BadgeWineColor;
