import TemplatePanel from '../../templates/TemplatePanel';
import CustomerSideMenu from '../../organisms/side-menu/CustomerSideMenu';
import {routes} from '../../../app/router/routes';
import Breadcrumbs from '../../organisms/breadcrumbs/Breadcrumbs';
import React from 'react';
import MyAccountInfo from '../../organisms/forms/MyAccountInfo';
import { ViewEditProfileUser } from '../app-public/auth/ViewEditProfileUser';

const ViewEditCustomerData = () => {
    const bc = [{label: 'Moje konto', url: routes.customerData}, {label: 'Edytuj profil', url: routes.customerEdit}];

    return (
        <TemplatePanel
            title={"Dane"}
            sideMenuRenderer={() => <CustomerSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.customerData} settings={bc}/>}
        >
            <div className='flex flex-col lg:flex-row gap-6 mt-4 items-stretch lg:items-start '>
            <MyAccountInfo/>
            <ViewEditProfileUser/>
            </div>
        </TemplatePanel>
    );
};

export default ViewEditCustomerData;
