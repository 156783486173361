import Card from "../../atoms/card/Card";
import { Link } from "react-router-dom";
import Image from "../../atoms/image/Image";
import PropTypes from "prop-types";
import Text from "../text/Text";
import { truncateText } from "../../atoms/TruncatText/truncateText";

const VineyardCardHome = ({ data, isLoading, imageTop }) => {
  return (
    <Link
      to={`/winnice/${data?.id}`}
      className={`${isLoading && "pointer-events-none"} w-full flex justify-center `}
    >

        <div className="w-full min-h-[525px] max-w-sm bg-white shadow-lg rounded-lg  my-4">
                         <Image
                         sizeClasses={`${imageTop ? 'h-80 w-full' : 'h-40 w-60'} `}
                         src={data?.profileLogoUrl || 'https://cdn1.iconfinder.com/data/icons/business-company-1/500/image-512.png'}
                         alt={data?.image?.alt}
                         isLoading={isLoading}
                     />

        <div className="py-4 px-6">
            <h1 className="text-2xl font-semibold text-gray-800 break-all">{data?.name}</h1>
            <p className="py-2 text-lg text-gray-700">{`${truncateText(data?.descriptionShort, 105)|| ''}`}</p>

        </div>
    </div>


    </Link>


  );
};

VineyardCardHome.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  name: PropTypes.string,
  slug: PropTypes.string,
  country: PropTypes.string,
  isLoading: PropTypes.bool,
  productsQuantity: PropTypes.number,
};

export default VineyardCardHome;
