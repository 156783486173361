import Input from "./CustomInput";
import { forwardRef, useMemo } from "react";
import ControlledInputMask from "./ControlledInputMaskPhone";

const InputCode = forwardRef(({ length, ...props }, ref) => {
  const mask = useMemo(() => {
    return new Array(length).fill("hh-hhh").join("-");
  }, [length]);

  return (
    <ControlledInputMask
    mask={mask}
    ref={ref}
    {...props}
    />
  );
});

export const InputPostCode = ({
  name,
  placeholder,
  label,
  length = 1,
  ...props
}) => {
  return (
    <Input
      component={InputCode}
      {...{ name, placeholder, label, length }}
      transformValue={(value) => (value ?? "").toUpperCase()}
      {...props}
    />
  );
};
