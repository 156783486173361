import React, {useState} from 'react';
import {FiEdit} from 'react-icons/fi';
import {AiOutlineDelete} from 'react-icons/ai';
import ModalUploadPhoto from '../../organisms/modals/ModalUploadPhoto';
import Button from "../../atoms/ui/buttons/regular/Button";
import toast from 'react-hot-toast'
import {dataURLtoFile} from "../../../app/utils/image";
import Image from "../../atoms/image/Image";
import ModalGenericConfirm from "../../organisms/modals/ModalGenericConfirm";


const ImageUploader = ({value, onChange, mutation, currentImageUrl,photo, isLoading, onDelete, className, editLogo, deleteLogo, deleteProfilePhoto, editProfilePhoto}) => {
    const [isOpenModal, setOpenModal] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(currentImageUrl);

    const onError = () => {
        toast.error('Wystąpił błąd przy próbie dodania zdjęcia. Spróbuj ponownie później.')
    }
    const onSuccess = () => {
        toast.success('Udało się pomyślnie dodać zdjęcie.')
    }
    const handleSave = (imageUrl) => {
        setImageUrl(imageUrl)
        const file = dataURLtoFile(imageUrl, "image")
        const formData = new FormData()
        formData.append('image', file)
        mutation(formData, {onError, onSuccess})
    }

    return (
        <div>
            <div
                className={'border-dashed overflow-hidden rounded-lg  border-2 border-gray-500 p-4s'}
            >
                <ModalGenericConfirm onConfirm={()=>{setImageUrl(''); onDelete();}} isOpen={confirmModalOpen} setOpen={setConfirmModalOpen}>Czy na pewno chcesz usunąć zdjęcie?</ModalGenericConfirm>
                <ModalUploadPhoto
                    isOpen={isOpenModal}
                    setOpen={setOpenModal}
                    setImageBlobUrl={handleSave}
                    onChange={onChange}
                    value={value}
                />
                <div onClick={()=>setOpenModal(true)} className={'w-full relative cursor-pointer'}>
                    {
                        imageUrl ?
                            <Image sizeClasses={`h-64 w-64 sm:h-80 sm:w-80 ${className}`}
                                   isLoading={isLoading}
                                   src={imageUrl}
                                   alt=""/>
                            :
                            <Image sizeClasses={`h-64 w-64 sm:h-80 sm:w-80 ${className}`}
                                   isLoading={isLoading}
                                   src={photo}
                                   className={'w-full h-full object-cover object-center absolute inset-0'}
                                   alt=""/>
                    }
                </div>

            </div>

            <div className={'space-x-4 w-full flex justify-between text-white mt-4 text-sm '}>
                <Button
                    tooltip={editLogo || editProfilePhoto || 'Edytuj zdjęcie'}
                    importantClasses={'flex justify-center items-center w-full rounded-lg bg-gold font-bold p-3'}
                    onClick={e => {
                        e.preventDefault();
                        setOpenModal(true);
                    }} className={' p-5 rounded-lg opacity-75 mr-1'}><FiEdit size={'1.25rem'} className={'mr-1'}/><p>Edytuj</p>
                </Button>
                {
                    imageUrl &&
                    <Button
                        tooltip={deleteLogo || deleteProfilePhoto || 'Usuń zdjęcie' }
                        importantClasses={'flex justify-center items-center space-x-1 w-full bg-red-700 rounded-lg p-3 font-bold'}
                        onClick={e => {
                            e.preventDefault();
                            setConfirmModalOpen(true);
                        }} className={'p-2 rounded-lg opacity-75 mr-1'}><AiOutlineDelete size={'1.25rem'}/><p>Usuń</p>
                    </Button>
                }
            </div>

        </div>
    );
};

export default ImageUploader;
