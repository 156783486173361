import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import useQuery2 from "../../../hooks/useQuery2";

const getMeasurementType = () => 
    axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/enums/measurement-type`
    }).then(({data}) => data)

    export const getMeasurementHelper = {
        queryKey: ['app-public.measurementType'],
        queryFn: () => getMeasurementType(),
        isArray: true
    }

export const useMeausrementType = () => {
    return useQuery2({
        queryKey: ['app-public.measurementType'],
        queryFn: () => getMeasurementType()
    });
};