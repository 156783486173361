import axios from '../../config/axios';
import {BASE_URL_API} from '../../config/env';
import useQuery2 from '../../hooks/useQuery2';

const getWineFilters = () =>
    axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/filters`,
        headers: {'accept': "*/*"}
    }).then(({data}) => data)

export const useGetWineFilters = () => {
    return useQuery2({
        queryKey: ['app-public.wine-filters'],
        queryFn: () => getWineFilters(),
        config: {refetchOnWindowFocus: false}
    });
};
