import {passParams, routes} from '../../../../app/router/routes';
import CustomDataTable from '../../../organisms/customTable/CustomDataTable';
import {useGetVineyardProducts} from '../../../../app/crud/vineyard/wine/getWines';
import Price from '../../../molecules/price/Price';
import Badge from '../../../atoms/badge/Badge';
import usePagination from '../../../../app/hooks/usePagination';
import ButtonLink from '../../../atoms/ui/buttons/links/ButtonLink';
import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import React, {useEffect} from 'react';
import Card from '../../../atoms/card/Card';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdLibraryBooks, MdOutlineLibraryBooks } from 'react-icons/md';
import { Datatable } from '../../../organisms/customTable/DataTable';
import { DatatableItem } from '../../../organisms/customTable/DataTableItem';

const ViewVineyardProducts = () => {
    const {data, isLoading, refetch} = useGetVineyardProducts();
    console.log(data)
    const {onPageChange, currentPage, totalPages, rowsPerPage, setTotalCount, setTotalPages} = usePagination(refetch);
    useEffect(() => {
        setTotalPages(data?.totalPages);
        setTotalCount(data?.totalCount);
    }, [data])

    const bc = [{label: 'Wina', url: routes.vineyardProducts}];
    // const headers = [
    //     {
    //       id: 'id' + 'name',
    //       label: (
    //         <>
    //           <MdLibraryBooks className="mr-1" />
    //           Name
    //         </>
    //       ),
    //       colSize: 2,
    //       mobileHidden: false,
    //       mobileGrow: true,
    //     },
      
    //     {
    //       id: 'coursesGroupName',
    //       label: (
    //         <>
    //           <MdLibraryBooks className="mr-1" />
    //           Course group
    //         </>
    //       ),
      
    //       colSize: 2,
    //       mobileHidden: false,
    //       mobileGrow: true,
    //     },
    //     {
    //       id: 'link',
    //       label: (
    //         <>
    //           <MdLibraryBooks className="mr-1" />
    //           Link
    //         </>
    //       ),
    //       colSize: 2,
    //       mobileHidden: false,
    //       mobileGrow: true,
    //     },
    //   ]
      
    const columns = [
        {name: 'Nazwa', cell: row => <p className={'text-s  mr-4'}>{row?.name}</p>},
        {name: 'Kolor', cell: row => <p className={'text-s  mr-4'}>{row?.wineColour?.name}</p>},
        {name: 'Rodzaj', cell: row => <p className={'text-s  mr-4'}>{row?.wineTaste?.name}</p>},
        {name: 'Cena', cell: row => <Price value={row?.priceGross}/>},
        {
            name: 'Status',
            cell: row => <Badge styleProp={'w-1/2'}
                                bgClass={row?.publishStatus?.name}>{row?.publishStatus?.name}</Badge>
        }
    ];
    return (
        <TemplatePanel
            title={"Wina"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
            actionButtonsRenderer={() => <ButtonLink to={routes.vineyardAddProduct} tooltip={'Dodaj produkt'}
                                                     className={'text-white text-sm flex items-center bg-green-700 hover:bg-green-600'}><AiOutlinePlus size={'1.25rem'}/></ButtonLink>}
            isLoading={isLoading}
        >
            <Card className={'w-full h-max'}>
                {/* <>
                    <Datatable headers={headers}>
                        {data?.items?.map((item) => (
                            <DatatableItem></DatatableItem>
                        ))}
                    </Datatable>
                </> */}
                <CustomDataTable
                    onRowClickRedirect={(row) => passParams(routes.vineyardProduct, {id: row?.id})}
                    columns={columns}
                    isLoading={isLoading}
                    data={data?.items}
                    pagination={{
                        currentPage: currentPage,
                        totalPages: totalPages,
                        rowsPerPage: rowsPerPage,
                        onPageChange: onPageChange
                    }}
                />
            </Card>

        </TemplatePanel>
    );
};

export default ViewVineyardProducts;
