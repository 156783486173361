import axios from '../../config/axios';
import { BASE_URL_API } from '../../config/env';
import useQuery2 from '../../hooks/useQuery2';

const getProfileCustomer = () => {
  return axios.get(`${BASE_URL_API}/api/v1/account/me`)
    .then(({ data }) => data);
};

export const useCustomerProfile = () => {
  return useQuery2({
    queryKey: ['app-public.profileCustomer'],
    queryFn: getProfileCustomer
  });
};