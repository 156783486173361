import React from 'react';
import {useSelectProducts} from '../../../app/redux/cartReducer/cartSelectors';
import CartProduct from '../../molecules/cart/CartProduct';
import Card from '../../atoms/card/Card';
import Summary from '../../molecules/cart/Summary';
import ButtonLink from '../../atoms/ui/buttons/links/ButtonLink';
import {routes} from '../../../app/router/routes';
import {useSelectToken} from '../../../app/redux/authReducer/authSelectors';
import Image from "../../atoms/image/Image";
import Text from "../../molecules/text/Text";
import {AiOutlineArrowLeft, AiOutlineInfoCircle} from "react-icons/ai";
import {IoIosArrowForward} from "react-icons/io";

const Cart = () => {
        const token = useSelectToken();
        const products = useSelectProducts();
        return (
            <div className={'flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5'}>
                {/* Products */}
                <div className={'flex flex-col w-full'}>
                    <Card>
                        {products.map(product => <CartProduct key={product.id} product={product}/>)}
                    </Card>
                </div>
            </div>
        );
    }
;

export default Cart;
