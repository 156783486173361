import React from 'react';
import Image from "../../atoms/image/Image";
import Text from "../text/Text";
import Price from "../price/Price";

const SummaryCartProduct = ({data}) => {
    return (
        <div className={'flex items-center text-sm text-gray-700'}>
            <Image sizeClasses={'w-20 h-20'} src={data?.imageUrl}/>
            <Text className={' mx-4 max-w-[300px]'}>{data?.name}</Text>
            <div className={'ml-auto flex space-x-4 items-center'}>
                <Text>{`${data?.quantity} szt.`}</Text>
                <Price value={data?.priceGross}/>
            </div>
        </div>
    );
};

export default SummaryCartProduct;
