import Submit from "../../atoms/ui/buttons/regular/Submit";
import Input from "../../atoms/ui/inputs/CustomInput";
import * as yup from 'yup'
import toast from "react-hot-toast";
import {routes} from "../../../app/router/routes";
import {RiArrowLeftSLine} from "react-icons/ri";
import CustomLink from "../../atoms/ui/buttons/links/Link";
import {MdAlternateEmail} from "react-icons/md";
import FormError from "../../atoms/form-error/FormError";
import FormCard from "../../atoms/card/FormCard";
import ResetPasswordBottomContent from "./bottom-content/RestPasswordBottomContent";

const FormForgotPassword = () => {
    const schema = yup.object().shape({
        email: yup.string().email('Podany adres e-mail jest niepoprawny').required('Adres e-mail jest wymagany')
    })
    const onError = () => {
        toast.error('Wystąpił błąd przy próbie zresetowania hasła. Spróbuj ponownie później.')
    }
    const onSuccess = () => {
        toast.success('Na podany e-mail zostały wysłane dalsze instrukcje.')
    }

    return (
        <FormCard title={'Reset hasła'} bottomContent={() => <ResetPasswordBottomContent/>}>
            <form className={"w-full"}>
                <CustomLink className={'w-max items-center flex'} to={routes.login}>
                    <RiArrowLeftSLine/>
                    Powrót
                </CustomLink>
                <div className="flex flex-col gap-4 text-gray-800 w-full mb-8">
                    <Input
                        required
                        name={'email'}
                        placeholder={'Email'}
                        label={'Email'}
                        icon={MdAlternateEmail}
                    />
                </div>
                <FormError
                    // message={'Ten email nie istnieje w bazie danych aplikacji.'}
                    className={'mb-7 p-4 text-sm text-red-700 bg-red-100 rounded-lg'}
                />

                <Submit className="flex w-full py-3">
                    Zresetuj hasło
                </Submit>
            </form>
        </FormCard>
    )
}

export default FormForgotPassword;