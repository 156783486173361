import TemplatePanel from "../../templates/TemplatePanel";
import VineyardSideMenu from "../../organisms/side-menu/VineyardSideMenu";
import React from "react";
import { routes } from "../../../app/router/routes";
import Breadcrumbs from "../../organisms/breadcrumbs/Breadcrumbs";
import ViewVineyardChangePassword from "./ViewVineyardChangePassword";

const ViewVineyardAccount = () => {
  const bc = [{ label: "Moje konto", url: routes.vineyardAccount }];
  return (
    <TemplatePanel
      title={"Moje konto"}
      sideMenuRenderer={() => <VineyardSideMenu />}
      breadcrumbsRenderer={() => (
        <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc} />
      )}
    >
      <ViewVineyardChangePassword />
    </TemplatePanel>
  );
};

export default ViewVineyardAccount;
