import { BASE_URL_API } from '../../../../config/env';

export const filtersVineyardsMock = (mock) => {
  mock.onGet(`${BASE_URL_API}/vineyard-filters`).reply(200, {
    checkboxFilters: [
      {
        prop: 'Kraj',
        queryParam: 'country',
        values: ['Polska', 'Włochy', 'Hiszpania']
      },
      {
        prop: 'Zwiedzanie',
        queryParam: 'sightseeing',
        values: ['Tak', 'Nie']
      },
      {
        prop: 'Fotografie',
        queryParam: 'photography',
        values: ['Tak', 'Nie']
      },
    ]
  });
};
