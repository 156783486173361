import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

export const usePutAdvertisementStatus = () => {
  const { id } = useParams();
  return useMutation((data) => {
    return axios
      .put(
        `${BASE_URL_API}/api/v1/admin/vineyard/advertisements/toggle-status/${id}`,
        data
      )
      .then(({ data }) => data)
      .then((data) => {
        return data;
      });
  });
};
