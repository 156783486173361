import axios from '../../../config/axios';
import {BASE_URL_API} from '../../../config/env';
import {useMutation} from 'react-query';
import {useNavigate, useParams} from "react-router-dom";
import {routes} from "../../../router/routes";

export const useDeleteContainer = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    return useMutation(() => {
        return axios.delete(`${BASE_URL_API}/api/v1/admin/vineyard/containers/${id}`)
            .then(({data}) => data)
            .then(data => {
                navigate(routes.vineyardContainers);
                return data;
            })
    });
};
