import React from "react";
import Card from "../../../atoms/card/Card";
import { Link } from "react-router-dom";
import { routes } from "../../../../app/router/routes";
import { GrDeliver } from "react-icons/gr";
import { useSelectAddress } from "../../../../app/redux/orderReducer/orderSelectors";
import {
  AiOutlineFieldNumber,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import { GiFactory, GiRoad } from "react-icons/gi";
import { BsPerson, BsSignpostSplit } from "react-icons/bs";

const CartSummaryDeliveryCard = () => {
  const data = useSelectAddress();
  return (
    <Card className={"relative"} title={"Dostawa"}>
      <Link
        className={"absolute right-4 top-4 text-gold2 hover:text-gold"}
        to={routes.checkout}
      >
        Zmień
      </Link>
      <div className={"flex items-center"}>
        <GrDeliver className={"mr-2"} />
        <p>DHL</p>
      </div>
      <div className={"flex"}>
        <div className={"text-sm w-full"}>
          <p className={"font-bold my-4 text-base"}>Dane odbiorcy:</p>

          <p className={"flex items-center"}>
            <BsPerson className={"mr-2"} />
            {`${data?.name} ${data?.surname}`}
          </p>
          <p className={"flex items-center"}>
            <GiRoad className={"mr-2"} />
            {`${data?.shippingAddress?.streetName}, ${
              data?.shippingAddress?.houseNumber
            }${
              data?.shippingAddress?.apartmentNumber
                ? "/" + data?.shippingAddress?.apartmentNumber
                : ""
            }`}
          </p>
          <p className={"flex items-center"}>
            <BsSignpostSplit className={"mr-2"} />
            {`${data?.shippingAddress?.zipCode} ${data?.shippingAddress?.cityName}`}
          </p>
          {data?.email && (
            <p className={"flex items-center"}>
              <AiOutlineMail className={"mr-2"} />
              {`${data?.email}`}
            </p>
          )}
          {data?.phoneNumber && (
            <p className={"flex items-center"}>
              <AiOutlinePhone className={"mr-2"} />
              {`${data?.phoneNumber}`}
            </p>
          )}
        </div>
        {data?.isCompany && (
          <div className={"text-sm w-full"}>
            <p className="font-bold my-4 text-base">Dane firmy:</p>
            {data?.companyName && (
              <p className={"flex items-center"}>
                <GiFactory className={"mr-2"} />
                {`${data?.companyName}`}
              </p>
            )}
            {data?.companyNip && (
              <p className={"flex items-center"}>
                <AiOutlineFieldNumber className={"mr-2"} />
                {`NIP: ${data?.companyNip}`}
              </p>
            )}
          </div>
        )}

        {data?.isUsingOptionalShippingAddress && (
          <div className={"text-sm w-full"}>
            <p className={"font-bold my-4 text-base"}>Adres rozliczeniowy:</p>
            <p className={"flex items-center"}>
              <GiRoad className={"mr-2"} />
              {`${data?.optionalShippingAddress?.streetName}, ${
                data?.optionalShippingAddress?.houseNumber
              }${
                data?.optionalShippingAddress?.apartmentNumber
                  ? "/" + data?.optionalShippingAddress?.apartmentNumber
                  : ""
              }`}
            </p>
            <p className={"flex items-center"}>
              <BsSignpostSplit className={"mr-2"} />
              {`${data?.optionalShippingAddress?.zipCode} ${data?.optionalShippingAddress?.cityName}`}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default CartSummaryDeliveryCard;
