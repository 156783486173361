import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import VineyardCard from "../../molecules/vineyard-card/VineyardCard";

import ProductCard from "../../molecules/product-card/ProductCard";
import VineyardCardHome from "../../molecules/vineyard-card/VineyardCardHome";

const Vineyards = ({ data, isLoading }) => {
  const options = { perPage: 3, rewind: true, gap: "1rem", pagination: false };
  return (
    <Splide options={options} aria-labelledby="basic-example-heading">
      {isLoading
        ? [...Array(3).keys()].map((item, index) => (
            <SplideSlide key={index + "slideloader"}>
              <ProductCard product={item} isLoading={isLoading} />
            </SplideSlide>
          ))
        : data?.items?.map((item, index) => {
            return (
              <SplideSlide key={index + "slideloader"}>
                <VineyardCardHome data={item} isLoading={isLoading} imageTop />
              </SplideSlide>
            );
          })}
    </Splide>
  );
};

export default Vineyards;
