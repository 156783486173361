import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import React, { useState } from 'react';
import {passParams, routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import AddAccomodation from "../../../organisms/forms/panel-vineyard/create-accomodation/AddAccomodation";
import { useGetVineyardAdvertisement } from '../../../../app/crud/vineyard/enoturism/getAdvertisement';
import ImageUploaderToGallery from '../../../molecules/image-uploader/ImageUploaderToGallery';
import { useAddAdvertisementImage } from '../../../../app/crud/vineyard/enoturism/addAdvertisementImage';
import { useDeleteAdvertisementImage } from '../../../../app/crud/vineyard/enoturism/deleteAdvertisementImage';
import Button from '../../../atoms/ui/buttons/regular/Button';
import { AiOutlinePlus } from 'react-icons/ai';


const ViewVineyardEnoturismEdit = () => {
    const {data, isLoading, refetch} = useGetVineyardAdvertisement()
    const { mutate: mutateAddImageAdvertisement } = useAddAdvertisementImage();
    const { mutate: mutateDeleteImageAdvertisement } =
      useDeleteAdvertisementImage();
      const [openModal, setOpenModal] = useState(false);
    
    const bc = [
        {label: 'Enoturystyka', url: routes.vineyardPanelEnoturism},
        {label: 'Edytuj', url: passParams(routes.vineyardPanelEnoturismEdit, {id: data?.id})}
    ];

    const buttonsRenderer = () => {
        return (
          <div className={"flex space-x-4"}>
            {(data?.images?.length === undefined || data?.images?.length <= 6 )  ? (
              <ImageUploaderToGallery
                url={passParams(routes.vineyardPanelEnoturismAdvertisement, { id: data?.id })}
                data={data}
                onAddSuccess={refetch}
                isLoading={isLoading}
                mutation={mutateAddImageAdvertisement}
                onDelete={() => mutateDeleteImageAdvertisement()}
                currentImageUrl={
                  data?.images?.url !== "default" && data?.images?.url
                }
              />
            ) : (
              <div onClick={()=>setOpenModal(false)} className={'w-full relative cursor-pointer'}>
              <Button disabled onClick={() => {
                  setOpenModal(false)
              }} className={`text-white text-sm bg-green-700`} tooltip={'Możesz dodać maksymalnie 6 zdjęć do ogłoszenia'}><AiOutlinePlus size={'1.25rem'}/></Button>
    </div>
            )}
          </div>
        );
      };

    return (
        <TemplatePanel
            title={"Enoturystyka"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            actionButtonsRenderer={buttonsRenderer}
            breadcrumbsRenderer={() => <Breadcrumbs isLoading={isLoading} homeRoute={routes.vineyardDesktop} settings={bc}/>}
        >
            <AddAccomodation mode={'edit'} initialData={data}/>
        </TemplatePanel>
    );
};

export default ViewVineyardEnoturismEdit;
