import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import useQuery2 from '../../../hooks/useQuery2';

const getWineTaste = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL_API}/api/v1/enums/wine-taste`
  }).then(({ data }) => data)

  export const getTastesHelper = {
    queryKey: ['app-public.wineTaste'],
    queryFn: () => getWineTaste(),
    isArray: true
  }


export const useGetWineTaste = () => {
  return useQuery2({
    queryKey: ['app-public.wineTaste'],
    queryFn: () => getWineTaste()
  });
};
