import React from 'react';
import Text from '../../text/Text';
import Price from '../../price/Price';
import Badge from '../../../atoms/badge/Badge';
import Card from '../../../atoms/card/Card';
import dayjs from 'dayjs';

const OrderDetails = ({data, isLoading}) => {
    console.log(data)
    return (
        <Card className={'w-full mr-4'} title={"Informacje"}>
            <div className={'grid grid-cols-2 gap-x-5 gap-y-1'}>
                {
                    data?.companyName &&
                    <>
                        <Text isLoading={isLoading}>{`Nazwa firmy: `}</Text>
                        <Text isLoading={isLoading}>{`${data?.companyName || ''}`}</Text>
                    </>
                }
                {
                    data?.companyNip &&
                    <>
                        <Text isLoading={isLoading}>{`NIP: `}</Text>
                        <Text isLoading={isLoading}>{`${data?.companyNip || ''}`}</Text>
                    </>
                }
                {
                    data?.createdDate &&
                    <>
                        <Text isLoading={isLoading}>{`Data: `}</Text>
                        <Text isLoading={isLoading}>{dayjs(data?.createdDate).format('DD.MM.YYYYr.')}</Text>
                    </>
                }
                {
                    (data?.name || data?.surname) &&
                    <>
                        <Text isLoading={isLoading}>{`Klient: `}</Text>
                        <Text isLoading={isLoading}>{`${data?.name || ''} ${data?.surname || ''}`}</Text>
                    </>
                }
                {
                    data?.totalOrderPriceGross &&
                    <>
                        <Text className={'flex space-x-1'} isLoading={isLoading}>{`Kwota: `}</Text>
                        <Text className={'flex space-x-1'} isLoading={isLoading}><Price value={data?.totalOrderPriceGross}/></Text>
                    </>
                }
                {
                    data?.email &&
                    <>
                        <Text isLoading={isLoading}>{`Email: `}</Text>
                        <Text isLoading={isLoading}>{`${data?.email || ''}`}</Text>
                    </>
                }
                {
                    data?.phoneNumber &&
                    <>
                        <Text isLoading={isLoading}>{`Telefon: `}</Text>
                        <Text isLoading={isLoading}>{`${data?.phoneNumber || ''}`}</Text>
                    </>
                }


                <Text className={'flex space-x-1'} isLoading={isLoading}>{`Status: `}</Text>
                <Text className={'flex space-x-1'} isLoading={isLoading}><Badge
                    bgClass={data?.status}>{data?.orderStatus?.name}</Badge></Text>
            </div>
        </Card>
    );
};

export default OrderDetails;
