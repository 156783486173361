import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../router/routes";

export const useDeleteMessage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  return useMutation(() => {
    return axios
      .delete(`${BASE_URL_API}/api/v1/admin/vineyard/remove-message/${id}`)
      .then(({ data }) => data)
      .then((data) => {
        navigate(routes.vineyardErrors);
        return data;
      });
  });
};
