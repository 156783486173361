import React from 'react';
import Header2 from '../../molecules/headers/Header2';
import Text from '../../molecules/text/Text';

const AboutCard = ({ children, reverse, title, image }) => {
  return (
    <div className={'py-8'}>
      <Header2 className={'text-2xl text-center mb-6'}>{title}</Header2>
      <div className={`flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row md:space-x-10'}`}>
        <img className={`${reverse ? 'md:ml-10': ''} mb-4 md:mb-0 md:w-96 rounded-lg`} src={image?.url} alt={image?.alt} />
        <Text className={'text-justify'}>{children}</Text>
      </div>
    </div>
  );
};

export default AboutCard;
