import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import { useMutation } from 'react-query';
import { routes } from '../../../router/routes';
import { useNavigate } from 'react-router-dom';

export const useAddWine = () => {
  const navigate = useNavigate();

  return useMutation((data) => {

    return axios.post(`${BASE_URL_API}/api/v1/admin/wine-products`, data)
      .then( () => {
        navigate(routes.vineyardProducts);
      })
  });
};