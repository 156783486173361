import Logo from '../../atoms/logo/Logo';
import NavAndSearch from '../../molecules/header-parts/NavAndSearch';
import LoginCartLang from '../../molecules/header-parts/LoginCartLang';

const DesktopHeader = () => {
    return (
        <div className={`w-full relative transition-all duration-300 text-gold`}>
            <header className={` w-full z-20 bg-bronze py-4 px-4 `}>
                <div className={' flex h-full flex-row max-w-screen-xl m-auto justify-between items-center'}>
                    <Logo/>
                    <NavAndSearch/>
                    <LoginCartLang/>
                </div>
            </header>
        </div>
    );
};

export default DesktopHeader;
