import React from 'react';
import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import { routes } from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import FormCreateProduct from "../../../organisms/forms/panel-vineyard/create-wine/FormCreateProduct";

const ViewVineyardAddProduct = () => {
  const bc = [{ label: 'Wina', url: routes.vineyardProducts }, { label: 'Dodaj', url: routes.vineyardAddProduct }];
  return (
    <TemplatePanel
        title={"Dodaj produkt"}
      sideMenuRenderer={()=><VineyardSideMenu/>}
      breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc} />}
    >
      <FormCreateProduct/>
    </TemplatePanel>
  );
};

export default ViewVineyardAddProduct;
