import axios from "../../../config/axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { BASE_URL_API } from "../../../config/env";
import useQuery2 from "../../../hooks/useQuery2";
import { routes } from "../../../router/routes";

export const useAddRatings = () => {
    const navigate = useNavigate();
    return useMutation((data) => {
        return axios.post(`${BASE_URL_API}/api/v1/wine-product-ratings`, data).then(({data}) => {
            navigate(routes.customerOrders);
            return data
        })
    })
}
