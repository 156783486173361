import React from 'react';
import {useSearchParams} from "react-router-dom";

const useSearchParamsObject = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const getSearchParamsObject = () => {
        const obj = {}
        for (const entry of searchParams.entries()) {
            obj[entry[0]] = entry[1]
        }
        return obj;
    }

    const setParam = (name, value) => {
        const params = getSearchParamsObject();
        delete params["PageNumber"]
        setSearchParams({...params, [name]: value})
    }

    const getParam = (name) => {
        return searchParams.get(name);
    }

    const addIdParam = (name, value) => {
        const params = getSearchParamsObject();
        delete params["PageNumber"]
        if (params[name])
            setSearchParams({...params, [name]: params[name] + `,${value}`})
        else {
            setSearchParams({...params, [name]: value})
        }
    }

    const removeIdParam = (name, value) => {
        const params = getSearchParamsObject();
        delete params["PageNumber"]
        const v = params[name]
            ?.split(',')
            ?.filter(id => parseInt(id) !== parseInt(value))
            ?.join(',')
        if (v) {
            setSearchParams({...params, [name]: v})
        } else {
            delete params[name]
            setSearchParams({...params})
        }
    }

    const deleteParam = (name) => {
        if (name) {
            const params = getSearchParamsObject();
            delete params["PageNumber"]
            delete params[name]
            setSearchParams({...params})
        }
    }

    const clearAll = () => {
        setSearchParams({})
    }

    return {setParam, addIdParam, removeIdParam, deleteParam, getParam, clearAll}
};

export default useSearchParamsObject;
