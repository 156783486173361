import { routes } from "../../../../app/router/routes"
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import { FormCreateBlog } from "../../../organisms/forms/create-blog/FormCreateBlog";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../templates/TemplatePanel";

const ViewVineyardAddBlog = () => {
    const bc =[{label: 'Moje wpisy', url: routes.vineyardPanelBlogs}, {label: 'Dodaj', url: routes.vineyardPanelBlogsAdd}];
    return (
        <TemplatePanel
            title={'Dodaj wpis na blogu'}
            sideMenuRenderer={() =><VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
        >
            <FormCreateBlog/>
        </TemplatePanel>
    )
}

export default ViewVineyardAddBlog