import { useQuery } from "react-query";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import useQuery2 from '../../../hooks/useQuery2';

export const useGetRatings = (wineProductId) => {
  const { wineId } = useParams();
    const [queryParams] = useSearchParams()
    const page = queryParams.get('page')
    const items = queryParams.get('items')
return useQuery({
        queryKey: ['app.ratings-wine', page],
        queryFn: () => axios({
            method: 'GET',
            url: `${BASE_URL_API}/api/v1/wine-product-ratings/${wineProductId || wineId}`,
            params: {
                PageNumber: page || 1,
                PageSize: items || 5,
            }
        }).then(({data}) => data),
        refetchOnWindowFocus: false
    })
}

