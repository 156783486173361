import React from "react";
import CustomLink from "../../../atoms/ui/buttons/links/Link";
import { routes } from "../../../../app/router/routes";

const ResetPasswordBottomContent = () => {
  return (
    <div className="flex flex-col gap-4 justify-center items-center mx-auto">
      <p className="text-gray-500 text-sm text-center flex ">
        Nie masz konta?{" "}
        <CustomLink
          className={"  transition duration-100 ml-1 no-underline"}
          to={routes.register}
        >
          Zarejestruj się
        </CustomLink>
      </p>
      <p className="text-gray-500 text-sm text-center flex">
        Masz już konto?{"  "}
        <CustomLink
          className={"transition duration-100 ml-1 no-underline"}
          to={routes.login}
        >
          Zaloguj się
        </CustomLink>
      </p>
    </div>
  );
};

export default ResetPasswordBottomContent;
