import axios from '../../../config/axios';
import {BASE_URL_API} from '../../../config/env';
import Cookies from 'universal-cookie';
import {useDispatch} from "react-redux";
import {actionClearCart, actionSetCartFromServer} from "../../../redux/cartReducer/cartActionTypes";

export const useCartSynchronization = () => {
    const dispatch = useDispatch()
    const cookies = new Cookies();

    return ()=> axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/cart?GuestGuid=${cookies.get("guestGuid")}`
    }).then(({data}) => {
        dispatch(actionSetCartFromServer(data))
        return data;
    }).catch(()=>{
        dispatch(actionClearCart());
    })
}

