import axios from "../mock/axios";
import { useMutation } from "react-query";
import { BASE_URL_API } from "../../config/env";

export const useResetPassword = (data) => {
    return useMutation((data) => {
        return axios.post(`${BASE_URL_API}/api/v1/user/reset-current-password`, data)
          .then(res => {
            return res;
          })
          .then(({ data }) => data);
      });
}