import TemplateMain from '../../../templates/TemplateMain';
import CheckoutCards from '../../../organisms/forms/public/cart/CheckoutCards';
import TemplateSection from '../../../templates/TemplateSection';
import {routes} from "../../../../app/router/routes";
import TemplateCart from "../../../templates/TemplateCart";
import CartSummary from "../../../molecules/cart/Summary";
import ProgressBar from "../../../organisms/cart/ProgressBar";

const ViewCartCheckout = () => {
    const progressBarConfig = [
        {url: routes.cart, done: true, active: true, label: "Koszyk"},
        {url: routes.checkout, done: false, active: true, label: "Dostawa i płatność"},
        {url: routes.cartSummary, done: false, active: false, label: "Podsumowanie"},
        {url: routes.cart, done: false, active: false, label: "Gotowe"},
    ]
    const summaryConfig = {
        route: routes.cartSummary,
        label: "Przejdź do podsumowania",
        submit: true
    }
    return (
        <TemplateMain title={'Płatność'}>
            <ProgressBar config={progressBarConfig}/>
            <div className={'max-w-screen-xl  mx-auto mb-8 mt-8'}>
                <CheckoutCards summaryConfig={summaryConfig}/>
            </div>
        </TemplateMain>
    );
};

export default ViewCartCheckout;
