import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useDeleteBlog } from "../../../../app/crud/app/blog/deleteBlog";
import { useDeleteImageBlog } from "../../../../app/crud/app/blog/deleteImageBlog";
import { useGetVineyardBlog } from "../../../../app/crud/app/blog/getBlog";
import { usePutBlogStatus } from "../../../../app/crud/app/blog/putBlogStatus";
import { useVineyardBlogImage } from "../../../../app/crud/app/blog/putImageBlog";
import { passParams, routes } from "../../../../app/router/routes";
import Card from "../../../atoms/card/Card";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import Button from "../../../atoms/ui/buttons/regular/Button";
import ToggleButton from "../../../atoms/ui/buttons/toggle/ToggleButton";
import Header1 from "../../../molecules/headers/Header1";
import ImageUploader from "../../../molecules/image-uploader/ImageUploader";
import Text from "../../../molecules/text/Text";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import ModalGenericConfirm from "../../../organisms/modals/ModalGenericConfirm";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../templates/TemplatePanel";
import parse from 'html-react-parser';

const ViewVineyardBlog = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetVineyardBlog();
  const { mutate: mutatePublishStatus } = usePutBlogStatus();
  const { mutate: mutateDeleteBlog } = useDeleteBlog();
  const { mutate: mutatePutImageBlog } = useVineyardBlogImage();
  const { mutate: mutateDeleteImageBlog } = useDeleteImageBlog();
  const [confirmDeleteBlogModalOpen, setConfirmDeleteBlogModalOpen] =
    useState(false);

  const bc = [
    { label: "Moje wpisy", url: routes.vineyardPanelBlogs },
    {
      label: data?.title,
      url: passParams(routes.vineyardPanelBlog, { id: data?.id }),
    },
  ];

  const onErrorPublish = () => {
    toast.error(
      "Wystpąpił błąd poczas próby zminy widoczności wpisu. Spróbuj ponownie później."
    );
  };

  const onSuccessPublish = () => {
    toast.success("Zmiana statusu widoczności wpisu przebiegła pomyślnie.");
  };

  const onErrorDeleteBlog = () => {
    toast.error(
      "Wystąpił błąd poczas próby usunięcia wpisu na blogu. Spróbuj ponownie później."
    );
  };

  const onSuccessDeleteBlog = () => {
    toast.success("Usunięcie wpisu na blogu przebiegło pomyślnie.");
  };

  const buttonsRenderer = () => {
    return (
      <div className={"flex space-x-4"}>
        <div className={"flex justify-end items-center"}>
          <ToggleButton
            label={"Opublikuj"}
            initVal={data?.publishStatus === 10}
            onClick={(val) =>
              mutatePublishStatus(
                { isPublic: val },
                { onError: onErrorPublish, onSuccess: onSuccessPublish }
              )
            }
          />
        </div>
        <ModalGenericConfirm
          isOpen={confirmDeleteBlogModalOpen}
          setOpen={setConfirmDeleteBlogModalOpen}
          onConfirm={() =>
            mutateDeleteBlog(
              {},
              { onError: onErrorDeleteBlog, onSuccess: onSuccessDeleteBlog }
            )
          }
        >
          Czy na pewno chcesz usunąć wpis na blogu?
        </ModalGenericConfirm>
        <ButtonLink
          tooltip={"Edytuj wpis"}
          to={passParams(routes.vineyardPanelBlogsEdit, { id: data?.id })}
          className={
            "text-white text-sm flex items-center bg-gold2 hover:bg-gold"
          }
        >
          <AiFillEdit size={"1.25rem"} />
        </ButtonLink>
        <Button
          tooltip={"Usuń wpis"}
          onClick={() => setConfirmDeleteBlogModalOpen(true)}
          className={
            "text-white text-sm flex items-center bg-red-700 hover:bg-red-600"
          }
        >
          <AiOutlineDelete size={"1.25rem"} />
        </Button>
      </div>
    );
  };
  return (
    <TemplatePanel
      title={"Wpis"}
      sideMenuRenderer={() => <VineyardSideMenu />}
      breadcrumbsRenderer={() => (
        <Breadcrumbs
          isLoading={isLoading}
          homeRoute={routes.vineyardDesktop}
          settings={bc}
        />
      )}
      isLoading={isLoading}
      actionButtonsRenderer={buttonsRenderer}
    >
      <div className={"flex flex-row space-x-4"}>
        <Card className={"p-16 w-full"}>
          <div
            className={
              "flex w-full space-x-16 md:flex-row flex-col justify-start lg:items-start items-center"
            }
          >
            <div
              className={
                "rounded-lg overflow-hidden flex mt-8 lg:mt-0 w-[324px] min-w-[324px]"
              }
            >
              <ImageUploader
                isLoading={isLoading}
                mutation={mutatePutImageBlog}
                onDelete={() => mutateDeleteImageBlog()}
                currentImageUrl={
                  data?.image?.url !== "default" && data?.image?.url
                }
              />
            </div>
            <div className={'space-y-2 md:!ml-12 !ml-0 md:mt-0 mt4'}>
                <Header1>{data?.title}</Header1>
                <p className="text-sm">Data utworzenia: {dayjs(data?.createdDateTime).format('DD.MM.YYYYr')}</p>
                <Text className={'text-justify'} isLoading={isLoading}>{data?.text && parse(data?.text)}</Text>
            </div>
          </div>
        </Card>
      </div>
    </TemplatePanel>
  );
};

export default ViewVineyardBlog;
