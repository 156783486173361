import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

const Slider = ({data}) => (
  <Carousel autoPlay showArrows={true} infiniteLoop >
    {data?.images?.map((item) => (
      <>
          <div>
          <img
            className="!h-1/2 !w-1/2 "
            alt=""
            src={item?.url}
          />
        </div>
          </>
    ))}
  </Carousel>
);
export default  Slider