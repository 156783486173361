import Card from "../../atoms/card/Card";
import { Link } from "react-router-dom";
import Image from "../../atoms/image/Image";
import Price from "../price/Price";
import PropTypes from "prop-types";
import IconButton from "../../atoms/ui/buttons/regular/IconButton";
import { FaCartPlus } from "react-icons/fa";
import useCart from "../../../app/hooks/useCart";
import Text from "../text/Text";
import Rate from "../rate/Rate";
import { useAddToCart } from "../../../app/crud/app/cart/postProduct";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import spain from "../../../app/assets/svg/spain.png";
import italy from "../../../app/assets/svg/italy.png";
import none from "../../../app/assets/svg/circle.png";
import Badge from "../../atoms/badge/Badge";
import BadgeWineColor from "../../atoms/badge/WineColorBadge";

const ProductCard = ({ product, isLoading, className }) => {
  const {
    imageUrl,
    averageRating,
    ratingsCount,
    wineTaste,
    wineColour,
    name,
    id,
    priceGross,
    vineyardId,
    vineyardName,
    countryName
  } = product || {};
  const { addToCart } = useCart();
  const { mutate: addToServerSideCart, isLoading: isFetching } = useAddToCart();
  const cookies = new Cookies();

  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie dodania produktu. Spróbuj ponownie później."
    );
  };
  const onSuccess = () => {
    toast.success("Produkt został dodany do koszyka.");
  };

  return (
    <div className={`flex flex-col mb-4 ${className}`}>
      <Link to={`/winnice/${vineyardId}/${id}`}>
        <div className=" flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl  p-3 max-w-xs md:max-w-3xl mx-auto rounded-b-none border border-white bg-white">
          <div
            className={
              " relative group-hover:scale-110 transition-all duration-500 ease-in-out flex items-center"
            }
          >
            <Image
              sizeClasses={"h-60 w-60"}
              src={
                imageUrl ||
                "https://www.vinowineshopnc.com/wp-content/uploads/2015/03/burgundy_wine_bottle_placeholder-300x300.jpg"
              }
              alt={"Zdjęcie wina"}
              isLoading={isLoading}
            />
          </div>
          <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
            <div className="flex justify-between item-center">
              <p className="text-gray-600 font-medium hidden md:block">
                {vineyardName}
              </p>
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-yellow-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <p className="text-gray-600 font-bold text-sm ml-1">
                  {ratingsCount}
                </p>
              </div>
            </div>
            <div>
            <h3 className="font-black text-gray-800 md:text-2xl text-xl break-all">
              {name}
            </h3>
            </div>
            <div className="flex flex-col">
              <div className="flex mb-2">
                {countryName === "Hiszpania" ? (
                  <div className="flex justify-center items-center">
                    <img src={spain} alt="spain" className="w-6 mr-2" />
                    <Text>{countryName}</Text>
                  </div>
                ) : countryName === "Włochy" ? (
                  <div className="flex justify-center items-center">
                    <img src={italy} alt="italy" className="w-6 mr-2" />
                    <Text>{countryName}</Text>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <img src={none} alt="none" className="w-6 mr-2" />
                    <Text>Brak kraju pochodzenia</Text>
                  </div>
                )}
              </div>
              <div className="mb-2">
                <Text className={'text-base font-semibold'}>{wineTaste?.name}</Text>
              </div>
              <div className="mb-2">
                {wineColour?.name === "Czerwone" ? (
                  <BadgeWineColor bgClass="Czerwone">{wineColour?.name}</BadgeWineColor>
                ) : wineColour?.name === "Różowe" ? (
                  <BadgeWineColor bgClass="Różowe">{wineColour?.name}</BadgeWineColor>
                ) : (
                  <BadgeWineColor bgClass="Białe">{wineColour?.name}</BadgeWineColor>
                )}
              </div>

            </div>

          </div>
        </div>
      </Link>
      <div className="flex justify-end items-center md:flex-row w-full rounded-t-none rounded-xl shadow-lg px-4 p-3   border border-white bg-white">
        <Price
          classNames={"w-1/3 text-lg"}
          value={priceGross}
          isLoading={isLoading}
        />
          <IconButton
            className={'ml-8'}
            tooltip={"Dodaj produkt do koszyka"}
            disabled={isLoading || isFetching}
            onClick={() => {
              addToCart(product, 1);
              addToServerSideCart(
                {
                  productId: id,
                  quantity: 1,
                  guestGuid: cookies.get("guestGuid"),
                },
                { onSuccess, onError }
              );
            }}
          >
            <FaCartPlus className={"text-white font-bold"} />
          </IconButton>

      </div>
    </div>

  );
};

ProductCard.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  name: PropTypes.string,
  price: PropTypes.number,
  vineyard: PropTypes.string,
  taste: PropTypes.string,
  color: PropTypes.string,
  vineyardSlug: PropTypes.string,
  slug: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default ProductCard;
