import TemplatePanel from "../../../templates/TemplatePanel";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import React from "react";
import {routes} from "../../../../app/router/routes";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import FormCreateContainer from "../../../organisms/forms/panel-vineyard/create-container/FormCreateContainer";
import {useGetContainer} from "../../../../app/crud/vineyard/containers/getContainer";

const ViewVineyardEditContainer = () => {
    const bc = [
        {label: "Zbiorniki", url: routes.vineyardContainers},
        {label: "Edytuj zbiornik", url: routes.vineyardEditContainer}
    ];
    const {data, isLoading} = useGetContainer();
    return (
        <TemplatePanel
            isLoading={isLoading}
            title={"Edytuj zbiornik"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => (
                <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>
            )}
        >
            <FormCreateContainer mode={'edit'} initialData={data}/>
        </TemplatePanel>
    );
};

export default ViewVineyardEditContainer;
