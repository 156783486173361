import {useEffect, useState} from 'react';
import {useLocation, useSearchParams} from "react-router-dom";

const useFilters = (filters) => {
    const [filtersState, setFiltersState] = useState({})
    const [searchParams] = useSearchParams();
    const {search} = useLocation()

    useEffect(() => {
        filters?.checkboxFilters?.forEach(item => {
            const value = searchParams.get(item.queryParam);
            const checkboxValues = [];
            if (value) {
                const filter = filters?.checkboxFilters?.find(fil => fil?.queryParam === item.queryParam)
                value?.split(',')?.forEach(v => {
                    const obj = filter?.values?.find(fv => fv?.id === parseInt(v))
                    if (obj)
                        checkboxValues.push(obj)
                })
            }
            setFiltersState(prev => ({
                ...prev,
                [item.queryParam]: {
                    name: item.prop,
                    value,
                    type: 'checkbox',
                    checkboxValues
                }
            }))
        })

        filters?.rangeFilters?.forEach(item => {
            setFiltersState(prev => ({
                ...prev,
                [item.queryParam]: {
                    name: item.prop,
                    value: searchParams.get(item.queryParam),
                    type: 'ranged'
                }
            }))
        })
    }, [filters, search])

    return {filtersState}
};

export default useFilters;
