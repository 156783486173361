import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import React from 'react';
import {routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import AddAccomodation from "../../../organisms/forms/panel-vineyard/create-accomodation/AddAccomodation";

const ViewVineyardEnoturismAdd = () => {
    const bc = [
        {label: 'Enoturystyka', url: routes.vineyardPanelEnoturism},
        {label: 'Dodaj', url: routes.vineyardPanelEnoturismAdd}
    ];

    return (
        <TemplatePanel
            title={"Enoturystyka"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
        >
            <AddAccomodation/>
        </TemplatePanel>
    );
};

export default ViewVineyardEnoturismAdd;
