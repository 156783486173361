import { BASE_URL_API } from '../../../../config/env';

export const vineyardMock = (mock) => {
  mock.onGet(`${BASE_URL_API}/v1/api/vineyard/1`).reply(200, {
    id: 1,
    name: 'APULIA',
    slug: 'apulia',
    country: 'Hiszpania',
    rate: 4.9,
    opinions: 163,
    description:
      'Apulia to położona w gminie Sardón de Duero w prowincji Valladolid winiarnia, w której powstają wina cieszące się sławą jednych z najlepszych w Hiszpanii. Została założona w 1996 roku i praktycznie od samego początku było wiadomo, że tworzone w niej będą wina niezwykłe. Zakrojony na szeroką skalę winiarski projekt został dopracowany w najdrobniejszych szczegółach. Chociaż bodega nazwę swą wzięła od znajdującego się w obrębie posiadłości opactwa – Abadía de Santa María de Retuerta – którego budynek stanowi wybitny przykład hiszpańskiej sztuki romańskiej XII wieku, to wzniesioną po drugiej stronie słynnej drogi N-122 winiarnię naszpikowano najnowszymi zdobyczami techniki. Zanim jednak przystąpiono do wyrobu wina, z wielką skrupulatnością przeprowadzono analizy poszczególnych siedlisk, dzieląc winnice na niewielkie działki – pagos. Proces ten nadzorował Pascal Delbeck – wielki admirator siedliskowych win i wyjątkowy specjalista w tej dziedzinie, a także główny enolog w Château Ausone w Bordeaux. Obecnie tak nad winnicami należącymi do Abadía Retuerta jak i powstającymi w bodedze winami z powodzeniem czuwa Ángel Anocíbar.',
    image: {
      url: 'https://domwina.pl/data/include/cms/producers/Abadia-Retuerta_foto_producent.png',
      alt: 'alt'
    }
  });
};
