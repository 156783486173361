import axios from '../../config/axios';
import { BASE_URL_API } from '../../config/env';
import useQuery2 from '../../hooks/useQuery2';

const getVineyardFilters = () =>
  axios({
    method: 'GET',
    url: `${BASE_URL_API}/vineyard-filters`
  }).then(({ data }) => data)

export const useGetVineyardFilters = () => {
  return useQuery2({
    queryKey: ['app-public.panel-vineyard-filters'],
    queryFn: () => getVineyardFilters()
  });
};
