import React from 'react';
import Card from '../../atoms/card/Card';
import Price from '../price/Price';
import {useSelectProducts} from '../../../app/redux/cartReducer/cartSelectors';
import Input from "../../atoms/ui/inputs/CustomInput";
import {AiOutlineInfoCircle} from "react-icons/ai";
import ButtonLink from "../../atoms/ui/buttons/links/ButtonLink";
import {IoIosArrowForward} from "react-icons/io";
import Submit from "../../atoms/ui/buttons/regular/Submit";
import Button from '../../atoms/ui/buttons/regular/Button';
import ButtonFinishOrder from '../../atoms/ui/buttons/regular/ButtonFinishOrder';

const CartSummary = ({config}) => {
    const products = useSelectProducts();
    const totalCost = products.reduce((prev, curr) => curr.priceGross * curr.quantity + prev, 0)

    return (
        <div className={'sticky top-4 h-max self-start ml-8'}>
            <Card noPadding className={'h-max overflow-hidden w-full text-sm md:text-base lg:w-96'}>
                <img  src="https://cdn-m2.neonet.pl/media/wysiwyg/1-jak-przechowywac-wino-landscape.jpeg" alt=""/>

                <div className={'p-4 text-md'}>
                    <Input textMd label={"Kod rabatowy"} placeholder={"Wpisz swój kod rabatowy"}/>
                    <div className={'flex flex-row pt-4 justify-between'}>
                        <p>Cena:</p>
                        <Price value={totalCost}/>
                    </div>
                    <div className={'flex flex-row pt-4 justify-between'}>
                        <p>Rabat:</p>
                        <Price value={0}/>
                    </div>
                    <div className={'flex font-bold flex-row border-t pt-4 mt-4 justify-between'}>
                        <p>Całkowity koszt:</p>
                        <Price value={totalCost}/>
                    </div>
                </div>

            </Card>
            {
                config?.info &&
                <p className={'flex items-center w-full justify-center italic text-sm mt-4 text-center'}>
                    <AiOutlineInfoCircle className={'mr-1'}/>
                    {config?.info}
                </p>
            }

            {
                !config?.submit && config?.route ?
                    <ButtonLink disabled={config?.disabled} className={`  justify-center w-full flex mt-4 items-center`} to={config?.route}>
                        {config?.label  || "Kontynuuj"}
                        <IoIosArrowForward className={'ml-2 text-2xl'}/>
                    </ButtonLink>
                    :
                    config?.submit &&
                    <div className={'w-full mt-4  '}>
                        <ButtonFinishOrder onClick={() => { config?.onClick()}} disabled={config?.disabled} className={'w-full flex justify-center items-center'} to={config?.route}>
                            {config?.label || "Kontynuuj"}
                            <IoIosArrowForward className={'ml-2 text-2xl'}/>
                        </ButtonFinishOrder>

                    </div>

            }
        </div>

    );
};

export default CartSummary;
