import TemplateMain from '../../../templates/TemplateMain';
import RegisterCard from '../../../organisms/forms/public/register/RegisterCard';
import FormCard from "../../../atoms/card/FormCard";
import RegisterBottomContent from "../../../molecules/forms/bottom-content/RegisterBottomContent";

const ViewRegister = () => {
  return (
    <TemplateMain title={'Rejestracja'}>
      <div className={'flex justify-center my-8 md:my-16'}>
          <FormCard title={"Rejestracja"} bottomContent={()=><RegisterBottomContent/>}>
              <RegisterCard />
          </FormCard>
      </div>
    </TemplateMain>
  );
};

export default ViewRegister;
