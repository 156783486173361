import { BASE_URL_API } from '../../../../config/env';

export const vineyardGalleryMock = (mock) => {
  mock.onGet(`${BASE_URL_API}/admin/gallery`).reply(200, [
    {
      url: 'https://domwina.pl/data/include/cms/regions/Apulia_region_foto.jpg',
      alt: '/'
    },
    {
      url: 'https://domwina.pl/data/include/cms/regions/Apulia_region_foto.jpg',
      alt: '/'
    },
    {
      url: 'https://domwina.pl/data/include/cms/regions/Apulia_region_foto.jpg',
      alt: '/'
    },
    {
      url: 'https://domwina.pl/data/include/cms/regions/Apulia_region_foto.jpg',
      alt: '/'
    },
    {
      url: 'https://domwina.pl/data/include/cms/regions/Apulia_region_foto.jpg',
      alt: '/'
    },
  ]);
};
