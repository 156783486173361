export const routes = {
  home: '/',
  unauthorized: '/unauthorized',
  blog: '/blog',
  blogDetails: '/blog/:id',
  forVineyard: '/dla-winnicy',
  enoturism: '/enoturystyka',
  enoturismDetails: '/enoturystyka/:id',
  about: '/o-nas',
  login: '/logowanie',
  forgotPassword: '/zapomnienie-hasla',
  resetPassword: '/reset-hasla',
  register: '/rejestracja',
  registerCustomer: '/rejestracja/klient',
  registerVineyard: '/rejestracja/winnica',
  registerShopRestaurant: '/rejestracja/sklep-restauracja',
  registerImporter: '/rejestracja/importer',
  cart: '/koszyk',
  cartSummary: '/koszyk/podsumowanie',
  checkoutAccount: '/koszyk/platnosc/konto',
  checkout: '/koszyk/platnosc',
  orderDone: '/koszyk/gotowe',
  contact: '/kontakt',
  wines: '/wina',
  vineyards: '/winnice',
  vineyard: '/winnice/:vineyardId',
  product: '/winnice/:vineyardId/:wineId',

  customerOrders: '/konto/uzytkownik/zamowienia',
  customerOrder: '/konto/uzytkownik/zamowienia/:id',
  customerPassword: '/konto/uzytkownik/haslo',
  customerInvoices: '/konto/uzytkownik/faktury',
  customerData: '/konto/uzytkownik/dane',
  customerEdit: '/konto/uzytkownik/dane/edytuj',
  customerPanel: 'konto',

  vineyardProducts: '/konto/winnica/produkty',
  vineyardProduct: '/konto/winnica/produkty/:id',
  vineyardAddProduct: '/konto/winnica/produkty/dodaj',
  vineyardEditProduct: '/konto/winnica/produkty/edytuj/:id',
  vineyardDesktop: '/konto/winnica/pulpit',
  vineyardOrders: '/konto/winnica/zamowienia',
  vineyardOrder: '/konto/winnica/zamowienia/:id',
  vineyardProfile: '/konto/winnica/profil',
  vineyardAccount: '/konto/winnica/konto',
  vineyardStore: '/konto/winnica/magazynbutelek',
  vineyardContainers: '/konto/winnica/zbiorniki',
  vineyardContainer: '/konto/winnica/zbiorniki/:id',
  vineyardViewEventContainer: '/konto/winnica/zbiorniki/:id/event',
  vineyardViewSingleEventContainer: '/konto/winnica/zbiorniki/:id/event/:eventId',
  vineyardSingleEventContainerEdit: '/konto/winnica/zbiorniki/:id/event/:eventId/edytuj',
  vineyardContainerEventCreate: '/konto/winnica/zbiorniki/:id/event/stworz',
  vineyardContainerEventEdit: '/konto/winnica/zbiorniki/:id/event/:eventId/edytuj',
  vineyardAddContainer: '/konto/winnica/zbiorniki/dodaj',
  vineyardEditContainer: '/konto/winnica/zbiorniki/edytuj/:id',
  vineyardProfileEdit: '/konto/winnica/profil/edycja',
  vineyardPanelGallery: '/konto/winnica/galeria',
  vineyardPanelEnoturism: '/konto/winnica/enoturystyka',
  vineyardPanelEnoturismAdvertisement: '/konto/winnica/enoturystyka/:id',
  vineyardPanelEnoturismAdd: '/konto/winnica/enoturystyka/dodaj',
  vineyardPanelEnoturismEdit: '/konto/winnica/enoturystyka/edytuj/:id',
  vineyardPanelEnoturismEvent: '/konto/winnica/enoturystyka/wydarzenia',
  vineyardPanelBlogs: '/konto/winnica/blog/moje-wpisy',
  vineyardPanelBlog: '/konto/winnica/blog/wpis/:id',
  vineyardPanelBlogsAdd: '/konto/winnica/blog/dodaj',
  vineyardPanelBlogsEdit: '/konto/winnica/blog/edytuj/:id',
  vineyardPanel: 'konto',
  vineyardErrors: '/konto/winnica/zglos-blad',
  vineyardErrorAdd: '/konto/winnica/zglos-blad/dodaj',
  vineyardError: '/konto/winnica/zglos-blad/:id',

  regulations: '/regulamin',
  privacyPolicy: '/polityka-prywatnosci',
  //
};
// funkcja przyjmuje obiekt zwracany przez hooka useParams
// dla przykladowego routa "/exercises/:id" oraz paramsów "{id: 1}"
// funkcja zwraca "/exercises/1"
export const passParams = (route, params) => {
  const parts = route.split('/')
  let newRoute = '';
  for (let i = 1; i < parts.length; i++) {
    newRoute += '/'
    if(parts[i].includes(':')){
      newRoute += params[parts[i].replace(':', '')];
    }
    else {
      newRoute += parts[i];
    }
  }
  return newRoute;
}
