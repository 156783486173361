import {useVineyardOrder} from '../../../../app/crud/vineyard/order/getOrder';
import OrderDetails from '../../../organisms/vineyard-panel/OrderDetails';
import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import React from 'react';
import {passParams, routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import dayjs from 'dayjs';
const ViewVineyardOrder = () => {
    const {data, isLoading} = useVineyardOrder();

    const bc = [{label: 'Zamówienia', url: routes.vineyardOrders}, {
        label: dayjs(data?.createdDate).format('DD.MM.YYYY, HH:mm'),
        url: passParams(routes.vineyardOrder, {id: data?.id})
    }]
    return (
        <TemplatePanel
            title={"Zamówienie"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs isLoading={isLoading} homeRoute={routes.vineyardDesktop}
                                                    settings={bc}/>}
            isLoading={isLoading}
        >
            <OrderDetails data={data} isLoading={isLoading}/>
        </TemplatePanel>
    );
};

export default ViewVineyardOrder;
