import React from 'react';
import Text from '../../molecules/text/Text';

const KeyValue = ({ label, children, isLoading, dark }) => {
  const cn = 'text-gray-700 col-span-2 uppercase';
  return (
    <Text isLoading={isLoading} className={`flex space-x-4 ${dark ? 'bg-gray-200' : ''}`}>
      <div className={'w-full flex md:justify-end items-center'}>
        <div>{label}</div>
      </div>
      <div className={'w-full flex md:justify-start items-center'}>
        <div
          className={cn}>
          {children}
        </div>
      </div>

    </Text>
  );
};

export default KeyValue;
