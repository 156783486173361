import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

const Dropdown = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={'cursor-pointer text-gray-500 text-sm w-full relative rounded-lg border shadow-sm px-4 py-2 bg-white'}>
        <label>{label}</label>
        <IoIosArrowDown className={'absolute right-3 top-3'} />
      </div>
      {
        isOpen &&
        <div className={'p-4 bg-white shadow'}>
          {children}
        </div>
      }
    </div>
  );
};

export default Dropdown;
