import React from 'react';
import ActiveFilter from '../../atoms/filter/ActiveFilter';
import Card from '../../atoms/card/Card';
import useSearchParamsObject from "../../../app/hooks/useSearchParamsObject";

const ActiveFilters = ({state, className}) => {
    const {removeIdParam, deleteParam, clearAll} = useSearchParamsObject()
    if (!state) return null;
    const length = Object.keys(state)?.reduce((prev, curr) => {
        return prev + (state[curr]?.value ? 1 : 0)
    }, 0)
    if(length === 0) return null;
    return (
        <Card className={`hidden md:block ${length === 0 ? 'hidden' : ''} mb-4`}>
            <div className={`flex flex-col ${className || ''}`}>
                <p className={'w-max mb-2'}>Aktywne filtry:</p>

                <div className={'flex flex-wrap'}>
                    {Object.keys(state)?.map((queryParam, id) =>
                        {
                            if(state[queryParam]?.type === "ranged"){
                                return(
                                    <ActiveFilter
                                        show={state[queryParam]?.value}
                                        key={id + 'rangedfilters'}
                                        name={`${state[queryParam]?.name} ${state[queryParam]?.value?.replace(',', '-')}`}
                                        onClick={() => {
                                            deleteParam(queryParam)
                                        }}
                                        className={`mr-2 mb-2`}
                                    />
                                )
                            }
                            else {
                                if(state[queryParam]?.checkboxValues?.length > 0)
                                    return state[queryParam]?.checkboxValues?.map((val, id2) => (
                                        <ActiveFilter
                                            show={state[queryParam]?.value}
                                            key={id + 'checkboxfilters' + id2}
                                            name={`${state[queryParam]?.name}: ${val?.name}`}
                                            onClick={() => {
                                                removeIdParam(queryParam, val?.id)
                                            }}
                                            className={`mr-2 mb-2`}
                                        />
                                    ))
                                else
                                    return null;
                            }

                        }
                    )}

                    {
                        length > 1 &&
                        <button onClick={clearAll} className={'text-sm p-2 mb-2 text-gray-600'}>wyczyść wszystko</button>
                    }
                </div>
            </div>
        </Card>

    );
};

export default ActiveFilters;
