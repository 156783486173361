import PropTypes from 'prop-types';

const Image = ({ src, alt, isLoading, sizeClasses }) => {
  return (
    <div className={`rounded-lg overflow-hidden ${isLoading && 'bg-gray-100 animate-pulse w-full'} ${sizeClasses}`}>
      <div
        className={`min-w-max transition-opacity duration-1000 ${
          isLoading ? 'opacity-50' : 'opacity-100'
        } ${sizeClasses}`}>
        {!isLoading && (
          <img
            className={`border-none appearance-none object-cover ${sizeClasses}`}
            src={src}
            alt={alt}
          />
        )}
      </div>
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  sizeClasses: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Image;
