import React from 'react';
import ProgressBarItem from "../../molecules/cart/ProgressBarItem";
import ProgressBarSeparator from "../../molecules/cart/ProgressBarSpearator";

const ProgressBar = ({config, isFinished}) => {
    console.log(isFinished)
    return (
        <div  className={'flex mx-auto justify-center mt-4 mb-8 md:mb-0 w-[304px] md:w-[640px] '}>
            {
                config?.map((item, id) => {
                    return(
                        <div  className={`flex relative ${isFinished === true ? 'pointer-events-none' : ''}`} key={"progressBarItem"+id}>
                            <ProgressBarItem id={id+1} item={item}/>
                            {
                                id !== config.length-1 &&
                                <div className={'w-full relative px-2'}>
                                    <ProgressBarSeparator/>
                                </div>
                            }

                            <p className={`${item?.active && !item?.done ? 'text-black' : ''} absolute text-xs text-gray-400 -bottom-9 left-3 -translate-x-1/2 text-center h-8`}>{item?.label}</p>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default ProgressBar;
 