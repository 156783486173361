import { routes } from "../../../../app/router/routes"
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import FormReportError from "../../../organisms/forms/reportError/FormReportError";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import TemplatePanel from "../../../templates/TemplatePanel";

const ViewVineyardErrorAdd = () => {
    const bc = [
        {label: 'Błędy', url: routes.vineyardError},
        {label: 'Zgłoś błąd', url: routes.vineyardErrorAdd}
    ];

    return (
    <TemplatePanel
        title={'Błędy'}
        sideMenuRenderer={()=> <VineyardSideMenu/>}
        breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
    >
        <FormReportError/>
    </TemplatePanel>
        )
}
export default ViewVineyardErrorAdd