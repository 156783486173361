import React from 'react';
import Card from '../../../atoms/card/Card';
import RBGroup from '../../../atoms/ui/buttons/radio/RBGroup';
import RadioButton from '../../../atoms/ui/buttons/radio/RadioButton';
import {GrDeliver} from "react-icons/gr";

const FormCheckoutAddress = () => {
  const radioClassName=`relative flex items-center`
  return (
    <Card title={`Sposób dostawy`} >
      <RBGroup
          className={'flex flex-col text-sm lg-text-'}
          onChange={val => console.log(val)}
          initCheckOption={'dhl'}>
      <RadioButton className={radioClassName} value={'dhl'}>
            <div className={'absolute right-0 text-xl'}>
                <GrDeliver/>
            </div>
          <p>DHL</p>
          <p className={'ml-2 text-gray-400'}>(bezpłatnie)</p>
        </RadioButton>

      </RBGroup>
    </Card>
  );
};

export default FormCheckoutAddress;
