import Card from '../../atoms/card/Card';
import {Link} from 'react-router-dom';
import Image from '../../atoms/image/Image';
import PropTypes from 'prop-types';
import Text from '../text/Text';
import { truncateText } from '../../atoms/TruncatText/truncateText';

const VineyardCard = ({data, isLoading, imageTop}) => {

    return (
        <Link to={`/winnice/${data?.id}`} className={`${isLoading && 'pointer-events-none'} w-full`}>
            <Card
                className={`group w-full relative overflow-hidden md:flex items-center ${imageTop ? 'flex flex-col space-y-5' : ''}`}
                noPadding>
                <div className={`flex min-w-[240px] justify-center overflow-hidden ${imageTop ? 'h-80' : 'h-40'}`}>
                    <div className={'min-w-[240px] group-hover:scale-110  transition-all duration-500 ease-in-out'}>
                        <Image
                            sizeClasses={`${imageTop ? 'h-80 w-full' : 'h-40 w-60'} `}
                            src={data?.profileLogoUrl || 'https://cdn1.iconfinder.com/data/icons/business-company-1/500/image-512.png'}
                            alt={data?.image?.alt}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                <div className={`px-4 w-full ${imageTop ? 'pb-4' : ''}`}>
                    <Text isLoading={isLoading} className={'font-bold'}>{data?.name}</Text>
                    <div className={'flex h-[84px]'}>
                        <Text isLoading={isLoading}
                              className={'text-xs xl:text-sm text-gray-900 uppercase mt-1'}>{`${truncateText(data?.descriptionShort, 105)|| ''}`}</Text>
                    </div>
                </div>
            </Card>
        </Link>
    );
};

VineyardCard.propTypes = {
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
    }),
    name: PropTypes.string,
    slug: PropTypes.string,
    country: PropTypes.string,
    isLoading: PropTypes.bool,
    productsQuantity: PropTypes.number
};

export default VineyardCard;
