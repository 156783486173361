import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import React from 'react';
import {routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';

const ViewVineyardEnoturismEvents = () => {
    const bc = [
        {label: 'Enoturystyka', url: routes.vineyardPanelEnoturism},
        {label: 'Wydarzenia', url: routes.vineyardPanelEnoturismEvent}
    ];
    const tabConfig=[
        {label: 'Noclegi', url: routes.vineyardPanelEnoturism},
        {label: 'Wydarzenia', url: routes.vineyardPanelEnoturismEvent},
    ];
    return (
        <TemplatePanel
            tabsConfig={tabConfig}
            title={"Enoturystyka"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
        >
        </TemplatePanel>
    );
};

export default ViewVineyardEnoturismEvents;
