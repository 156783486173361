import axios from '../../../config/axios'
import { BASE_URL_API } from '../../../config/env'
import {useMutation} from 'react-query'
import { useParams } from 'react-router-dom'

export const useVineyardErrorImage = () => {
    const {id} = useParams();
    return useMutation((data) => {
        return axios.put(`${BASE_URL_API}/api/v1/admin/vineyard/update-message-image/${id}`, data).then(({data}) => data)
    })
}