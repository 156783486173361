import React from 'react';
import Card from "../../../atoms/card/Card";
import {TbBrandPaypal} from "react-icons/tb";
import {Link} from "react-router-dom";
import {routes} from "../../../../app/router/routes";

const CartSummaryPaymentCard = () => {
    return (
        <Card className={'relative'} title={"Płatność"}>
            <Link className={'absolute right-4 top-4 text-gold2 hover:text-gold'} to={routes.checkout}>Zmień</Link>
            <div className={'flex items-center'}>
                <TbBrandPaypal className={'mr-2'}/>
                <p>PayPal</p>
            </div>
        </Card>
    );
};

export default CartSummaryPaymentCard;
