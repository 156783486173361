import React from 'react';
import ButtonLink from '../../atoms/ui/buttons/links/ButtonLink';
import {routes} from '../../../app/router/routes';
import FormCard from "../../atoms/card/FormCard";
import {Link} from "react-router-dom";
import RegisterBenefits from "../../organisms/cart/RegisterBenefits";

const GuestCard = () => {
    return (
        <div className={'w-full max-w-lg'}>
            <FormCard disableBorder title={"Nie masz konta?"}>

                <ButtonLink className={'flex justify-center mt-auto w-full bg-gold2 hover:bg-gold'} to={routes.checkout}>Kontynuuj
                    jako gość</ButtonLink>
                <div className={'text-sm text-gray-400 relative my-6'}>
                    <p className={'bg-gray-50 m-auto relative z-10 w-max px-2'}>lub</p>
                    <div className={'border border-b absolute w-full top-2.5 z-0'}/>
                </div>
                <Link className={'text-gold2 p-2 rounded-lg border border-gold flex justify-center mt-auto bg-gray-50 hover:bg-white'} to={routes.register + '?checkout=1'}>Zarejestruj się</Link>
            </FormCard>
            <RegisterBenefits/>
        </div>

    );
};

export default GuestCard;
