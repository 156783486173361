import React, {useCallback, useState} from 'react';
import QuantityButtons from '../../atoms/quantity-buttons/QuantityButtons';
import {AiFillDelete} from 'react-icons/ai';
import Price from '../price/Price';
import IconButton from '../../atoms/ui/buttons/regular/IconButton';
import {useDispatch} from 'react-redux';
import {actionRemoveFromCart, actionSetCartProductQuantity} from '../../../app/redux/cartReducer/cartActionTypes';
import {usePutCartProductQuantity} from "../../../app/crud/app/cart/putProductQuantity";
import toast from "react-hot-toast";
import Cookies from 'universal-cookie';
import debounce from 'lodash.debounce';
import ModalGenericConfirm from "../../organisms/modals/ModalGenericConfirm";
import {useDeleteProductFromCart} from "../../../app/crud/app/cart/deleteProduct";

const CartProduct = ({product}) => {
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const {mutate: removeProductFromServerCart} = useDeleteProductFromCart()
    const [confirmDeleteProductModalOpen, setConfirmDeleteProductModalOpen] = useState(false)
    const onError = () => {
        toast.error(
            "Wystąpił błąd przy próbie zmiany liczby produktów. Spróbuj ponownie później."
        );
    };
    const onSuccess = () => {
        toast.success(
            "Ilość produktów została zmieniona."
        );
    };
    const onRemoveError = () => {
        toast.error(
            "Wystąpił błąd przy próbie usunięcia produktu. Spróbuj ponownie."
        );
    };
    const onRemoveSuccess = () => {
        toast.success(
            "Produkt został usunięty z koszyka."
        );
    };
    const {mutate: updateQuantity} = usePutCartProductQuantity()

    const debouncedCallback = useCallback(debounce((quantity) => updateQuantity({
        productId: product?.id,
        quantity: quantity,
        guestGuid: cookies.get("guestGuid")
    }, {onSuccess, onError}), 300), []);

    const onQuantityChange = (quantity) => {
        if(quantity){
            dispatch(actionSetCartProductQuantity(product, quantity));
            debouncedCallback(quantity)
        }
    }
    return (
        <div
            className={'border-b py-4 relative flex flex-col space-y-4 sm:space-y-0 items-center sm:grid sm:grid-cols-5 sm:gap-y-5 xl:gap-y-0 xl:grid-cols-6'}>
            <div className={'min-w-max'}>
                <div className={'w-32 flex justify-center'}>
                    <img className={'h-32'}
                         src={product?.imageUrl || "https://www.vinowineshopnc.com/wp-content/uploads/2015/03/burgundy_wine_bottle_placeholder-300x300.jpg"}
                         alt={"Zdjęcie wina"}/>
                </div>
            </div>

            <div className={'flex flex-col col-span-4 xl:col-span-2 xl:mr-4 w-full text-center '}>
                <p className={'text-sm'}>{product?.name}</p>
                <div className={'w-full justify-center text-gray-500  text-xs uppercase flex flex-row space-x-2'}>
                    {product?.wineColour?.name.length > 0 ? <p>{product?.wineColour?.name},</p> : null}
                    
                    <p>{product?.wineTaste?.name}</p>
                </div>
            </div>
            <QuantityButtons className={'xl:ml-auto'} initVal={product?.quantity} onZeroChange={()=>setConfirmDeleteProductModalOpen(true)} onChange={(quantity)=>{
                onQuantityChange(quantity);
            }}/>
            <Price className={'w-full text-center col-span-4 xl:col-span-1 xl:w-max xl:ml-auto xl:col-span-1'}
                   value={product?.priceGross * product?.quantity}/>
            <div>
                <div className={'flex justify-end top-4 right-0 absolute xl:top-0 xl:relative'}>
                    <ModalGenericConfirm
                        isOpen={confirmDeleteProductModalOpen}
                        setOpen={setConfirmDeleteProductModalOpen}
                        onConfirm={() => {
                            dispatch(actionRemoveFromCart(product));
                            removeProductFromServerCart({id: product?.id}, {onSuccess: onRemoveSuccess, onError: onRemoveError})
                        }}
                    >
                        Czy na pewno chcesz usunąć produkt z koszyka?
                    </ModalGenericConfirm>
                    <button className={'p-4 text-bronze'} onClick={() => setConfirmDeleteProductModalOpen(true)}><AiFillDelete/>
                    </button>
                </div>
            </div>

        </div>
    );
};

export default CartProduct;
