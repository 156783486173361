import axios from "../../mock/axios";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL_API } from "../../../config/env";
import { passParams, routes } from "../../../router/routes";

export const usePutEvent = () => {
  const { id, eventId } = useParams();
  const navigate = useNavigate();
  return useMutation((data) => 
    axios({
      method: "PUT",
      url: `${BASE_URL_API}/api/v1/admin/vineyard/containers/${eventId}/events/${id}`,
      data,
    })
      .then(({ data }) => { 
        return (data)
      })
      .then(() => {
        navigate(
          passParams(routes.vineyardViewSingleEventContainer, {
            id: id,
            eventId: eventId,
          })
        )}
      )
  );
};
