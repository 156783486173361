import React, {useEffect, useState} from "react";
import Card from "../../../atoms/card/Card";
import RBGroup from "../../../atoms/ui/buttons/radio/RBGroup";
import RadioButton from "../../../atoms/ui/buttons/radio/RadioButton";
import Input from "../../../atoms/ui/inputs/CustomInput";
import Checkbox from "../../../atoms/ui/buttons/checkbox/Checkbox";
import {InputPhoneNumber} from "../../../atoms/ui/inputs/InputPhoneNumber";
import {InputNIP} from "../../../atoms/ui/inputs/InputNIP";
import {InputPostCode} from "../../../atoms/ui/inputs/InputPostCode";
import {MdAlternateEmail, MdApartment} from "react-icons/md";
import {HiOutlinePhone} from "react-icons/hi";
import {BsFillPersonFill, BsHouseDoorFill, BsFillSignpostSplitFill} from "react-icons/bs";
import {FaRoad, FaCity} from "react-icons/fa";
import {GiFactory} from "react-icons/gi"
import {AiOutlineFieldNumber} from "react-icons/ai";

const FormCheckoutCustomerAddress = ({register, errors, setValue, initialData}) => {
    const [showDeliveryAddressForm, setDeliveryAddressForm] = useState(false);
    const [orderAs, setOrderAs] = useState(null);

    useEffect(()=>{
        setValue("isCompany", orderAs==="company")
    }, [orderAs])

    useEffect(()=>{
        setOrderAs(initialData?.isCompany ? "company" : "")
        setDeliveryAddressForm(initialData?.isUsingOptionalShippingAddress)
    }, [initialData])

    return (
        <Card title={"Dane odbiorcy"}>
            {/*Person / Company*/}
            <RBGroup className={"space-x-5 my-2 flex"} onChange={setOrderAs}>
                <RadioButton checked={!initialData?.isCompany} value={"panel-customer"}>Osoba prywatna</RadioButton>
                <RadioButton checked={initialData?.isCompany} value={"company"}>Firma</RadioButton>
            </RBGroup>

            {/*Billing address*/}
            <div className={"space-y-5"}>
                {orderAs === "company" && (
                    <>
                        <Input
                            register={register}
                            error={errors.companyName?.message}
                            name={"companyName"}
                            placeholder={"Nazwa firmy"}
                            label={"Nazwa firmy"}
                            icon={GiFactory}
                        />
                        <InputNIP
                            register={register}
                            error={errors.companyNip?.message}
                            name={"companyNip"}
                            placeholder={"Numer NIP"}
                            label={"NIP"}
                            icon={AiOutlineFieldNumber}
                            required
                        />
                    </>
                )}
                <Input
                    register={register}
                    error={errors.email?.message}
                    name={"email"}
                    placeholder={"Email"}
                    label={"Email"}
                    icon={MdAlternateEmail}
                    required
                />
                <InputPhoneNumber
                    register={register}
                    error={errors.phoneNumber?.message}
                    name={"phoneNumber"}
                    placeholder={"Telefon"}
                    label={"Telefon"}
                    icon={HiOutlinePhone}
                />
                <div className={"flex w-full space-x-5"}>
                    <Input
                        register={register}
                        error={errors.name?.message}
                        name={"name"}
                        placeholder={"Imię"}
                        label={"Imię"}
                        icon={BsFillPersonFill}
                        required
                    />
                    <Input
                        register={register}
                        error={errors.surname?.message}
                        name={"surname"}
                        placeholder={"Nazwisko"}
                        label={"Nazwisko"}
                        icon={BsFillPersonFill}
                        required
                    />
                </div>
                <Input
                    register={register}
                    error={errors.shippingAddress?.streetName?.message}
                    name={"shippingAddress.streetName"}
                    placeholder={"Ulica"}
                    label={"Ulica"}
                    icon={FaRoad}
                    required
                />
                <div className={"flex w-full space-x-5"}>
                    <Input
                        register={register}
                        error={errors.shippingAddress?.houseNumber?.message}
                        name={"shippingAddress.houseNumber"}
                        placeholder={"Nr domu"}
                        type={"number"}
                        label={"Nr domu"}
                        icon={BsHouseDoorFill}
                        required
                    />
                    <Input
                        register={register}
                        error={errors.shippingAddress?.apartmentNumber?.message}
                        name={"shippingAddress.apartmentNumber"}
                        placeholder={"Nr lokalu"}
                        type={"number"}
                        label={"Nr lokalu"}
                        icon={MdApartment}
                    />
                </div>
                <div className={"flex w-full space-x-5"}>
                    <InputPostCode
                        register={register}
                        error={errors.shippingAddress?.zipCode?.message}
                        name={"shippingAddress.zipCode"}
                        placeholder={"Kod pocztowy"}
                        label={"Kod pocztowy"}
                        icon={BsFillSignpostSplitFill}
                        required
                    />
                    <Input
                        register={register}
                        error={errors.shippingAddress?.cityName?.message}
                        name={"shippingAddress.cityName"}
                        placeholder={"Miasto"}
                        label={"Miasto"}
                        icon={FaCity}
                        required
                    />
                </div>
            </div>

            <Checkbox
                register={register}
                name={"isUsingOptionalShippingAddress"}
                checked={initialData?.isUsingOptionalShippingAddress}
                onChange={setDeliveryAddressForm}
                className={"my-4"}
            >
                Wybierz inny adres dostawy
            </Checkbox>

            {/*Delivery address*/}
            {showDeliveryAddressForm && (
                <div className={"space-y-5"}>
                    <Input
                        register={register}
                        error={errors.optionalShippingAddress?.streetName?.message}
                        name={"optionalShippingAddress.streetName"}
                        placeholder={"Ulica"}
                        label={"Ulica"}
                        icon={FaRoad}
                        required
                    />
                    <div className={"flex w-full space-x-5"}>
                        <Input
                            register={register}
                            error={errors.optionalShippingAddress?.houseNumber?.message}
                            name={"optionalShippingAddress.houseNumber"}
                            placeholder={"Nr domu"}
                            type={"number"}
                            label={"Nr domu"}
                            icon={BsHouseDoorFill}
                            required
                        />
                        <Input
                            register={register}
                            error={errors.optionalShippingAddress?.apartmentNumber?.message}
                            name={"optionalShippingAddress.apartmentNumber"}
                            placeholder={"Nr lokalu"}
                            type={"number"}
                            label={"Nr lokalu"}
                            icon={MdApartment}
                        />
                    </div>
                    <div className={"flex w-full space-x-5"}>
                        <InputPostCode
                            register={register}
                            error={errors.optionalShippingAddress?.zipCode?.message}
                            name={"optionalShippingAddress.zipCode"}
                            placeholder={"Kod pocztowy"}
                            label={"Kod pocztowy"}
                            icon={BsFillSignpostSplitFill}
                            required
                        />
                        <Input
                            register={register}
                            error={errors.optionalShippingAddress?.cityName?.message}
                            name={"optionalShippingAddress.cityName"}
                            placeholder={"Miasto"}
                            label={"Miasto"}
                            icon={FaCity}
                            required
                        />
                    </div>
                </div>
            )}
            {orderAs === "company" && (
                <Checkbox className={"my-4"}>Chcę otrzymać fakturę VAT</Checkbox>
            )}
        </Card>
    );
};

export default FormCheckoutCustomerAddress;
