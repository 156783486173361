import React from 'react';
import Header1 from "../../molecules/headers/Header1";
import Text from "../../molecules/text/Text";
import Header2 from "../../molecules/headers/Header2";

const ContainerDetails = ({data}) => {
    return (
        <section className="text-gray-600 body-font">
        <div className="container  justify-start items-start flex flex-col">
          <div className="lg:w-full ">
            <div className="flex flex-col sm:flex-row ">
              <div className="sm:w-1/2 text-center sm:pl-4 sm:py-4">
                <div className="flex flex-col justify-start items-start">
                  <h1 className="font-bold title-font text-gray-900 text-2xl">{data?.name}</h1>
                  <div className="w-12 h-1 bg-gold2 rounded mt-2 mb-4"></div>
                  <div className='flex flex-col justify-start items-start'>
                  <p className="text-base "><span className='font-bold'>Pojemność: </span>{data?.capacity} l</p>
                  <p className='text-base'><span className='font-bold'>Kod zbiornika: </span>{data?.code}</p>
                  <p className='text-base font-bold'>Zawartość szczepów:</p>
                  {
                    data?.containerProductionStrains?.map((item, id)=> (
                        <div key={'containerStrain'+id} className={'grid grid-cols-2 w-max gap-4'}>
                            <Text>{item?.productionStrainName}</Text>
                            <Text>{`${item?.amount} %`}</Text>
                        </div>
                    ))
                }
                  </div>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-4 sm:py-4 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className='text-base font-bold'>Opis zdarzenia:</p>
                <p className="leading-relaxed text-lg mb-4 whitespace-pre-wrap	break-words">{data?.description}</p>

              </div>
            </div>
          </div>
        </div>
      </section>
       
    );
};

export default ContainerDetails;
