import PropTypes from 'prop-types';

const BurgerButton = ({ onClick }) => {
  return (
    <button className={'space-y-1'} onClick={onClick}>
      <span className={'block w-5 h-0.5 bg-gold'} />
      <span className={'block w-5 h-0.5 bg-gold'} />
      <span className={'block w-5 h-0.5 bg-gold'} />
    </button>
  );
};

BurgerButton.propTypes = {
  onClick: PropTypes.func
};

export default BurgerButton;
