import React from 'react';

const EnoturismBadge = ({children, bgClass, styleProp}) => {
  const className = bgClass === '10' ? 'bg-green-300 text-green-800 font-bold' : bgClass === '5' ? 'bg-yellow-300 text-yellow-800 font-bold' : 'bg-red-300 text-red-800 font-bold'
  return (
    <div className={`${className} rounded-lg p-1 w-max px-4 flex justify-center uppercase lg:text-xs text-[11px] ${styleProp}`}>
      {children}
    </div>
  );
};

export default EnoturismBadge;
