import React, { useState } from 'react';
import PortalWrapper from '../../atoms/portal-wrapper/PortalWrapper';
import Card from '../../atoms/card/Card';
import Checkbox from '../../atoms/ui/buttons/checkbox/Checkbox';
import Logo from '../../atoms/logo/Logo';
import Button from '../../atoms/ui/buttons/regular/Button';
import Link from '../../atoms/ui/buttons/links/Link';
import Cookies from 'universal-cookie';

const ModalConfirmAge = () => {
  const cookies = new Cookies();
  const [isOpen, setOpen] = useState(cookies.get('confirmAge') !== 'true');
  return (
    <PortalWrapper>
      onBackdropClick={() => {
        setOpen(false);
      }}
      show={isOpen}
      <Card title={'Potwierdź swój wiek'}>
        <div className={'flex justify-center mt-4'}>
          <Logo disable />
        </div>
        <p className={'text-amber-900 text-center mt-8'}>Witaj na stronie winopasja.pl</p>
        <p className={'text-amber-900 text-center'}>Strona przeznaczona tylko dla osób pełnoletnich. Zawiera między
          innymi opisy napojów alkoholowych.</p>
        <p className={'text-center font-bold text-xl my-8'}>Czy masz ukończone 18 lat?</p>
        <p className={'text-sm ml-8 mb-4'}>* Oświadczam, że jestem osobą pełnoletnią i wyrażam zgodę na przedstawienie
          treści dotyczących produktów alkoholowych (W przypadku braku zgody lub braku pełnoletności, prosimy o
          kliknięcie "Anuluj")</p>
        <Checkbox className={'text-sm'}>** Oświadczam, że zapoznałam/em się i akceptuję regulamin i potwierdzam chęć
          zapoznania się z ofertą napojów alkoholowych.</Checkbox>
        <div className={'flex flex-row items-center space-x-5 mt-4 justify-end'}>
          <Link className={'w-max'} to={'/'}>Anuluj</Link>
          <Button onClick={() => {
            cookies.set('confirmAge', 'true');
            setOpen(false);
          }}>Przejdź do serwisu</Button>
        </div>
      </Card>
    </PortalWrapper>
  );
};

export default ModalConfirmAge;
