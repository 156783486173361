import TemplateMain from '../../templates/TemplateMain';
import TemplateSection from '../../templates/TemplateSection';
import React from "react";
import ContactInfo from "../../organisms/contact/ContactInfo";
import Card from "../../atoms/card/Card";
import FormContact from "../../molecules/forms/FormContact";
import Header1 from "../../molecules/headers/Header1";

const ViewContact = () => {
    return (
        <TemplateMain title={'Kontakt'}>
            <TemplateSection>
                <div className={'mt-16'}>
                    <Card className={'md:p-16'}>
                        <Header1 className={'text-center mb-8'}>Skontaktuj się z nami</Header1>
                        <div
                            className={'flex overflow-hidden flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 justify-center'}>
                            <FormContact/>
                            <div className={'flex w-full justify-center'}>
                                <ContactInfo/>
                            </div>
                        </div>
                    </Card>
                </div>
            </TemplateSection>
        </TemplateMain>
    );
};

export default ViewContact;
