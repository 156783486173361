import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useAddErorr } from "../../../../app/crud/vineyard/Error/addError";
import { routes } from "../../../../app/router/routes";
import Card from "../../../atoms/card/Card";
import CustomLink from "../../../atoms/ui/buttons/links/Link";
import Submit from "../../../atoms/ui/buttons/regular/Submit";
import Input from "../../../atoms/ui/inputs/CustomInput";
import Textarea from "../../../atoms/ui/textarea/Textarea";

const FormReportError = () => {
  const schema = yup.object().shape({
    title: yup.string().required("Nazwa błędy jest wymagana"),
    text: yup.string().required("Opis błędu jest wymagany"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie utworzenia zgłoszenia. Spróbuj ponownie później."
    );
  };

  const onSuccess = () => {
    toast.success("Twoje zgłoszenie zostało wysłane.");
  };

  const { mutate, isLoading } = useAddErorr();

  const onSubmitAdd = (data) => {
    mutate(data, { onError, onSuccess });
  };
  return (
    <FormProvider {...methods}>
      <Card>
        <form onSubmit={handleSubmit(onSubmitAdd)} className={"flex flex-col"}>
          <div>
            <Input
              register={register}
              error={errors?.title?.message}
              name={"title"}
              label={"Tytuł"}
            />
          </div>
          <div className={"mt-4"}>
            <Textarea
              name={"text"}
              label={"Opis"}
              register={register}
              error={errors.description?.message}
              maxContent={1500}
              rows={1}
              onChange
            />
          </div>
          <div className={'mt-4'}>

          </div>
          <div className={"ml-auto flex items-center space-x-4 mt-4"}>
            <CustomLink to={routes.vineyardErrors}>Wróć</CustomLink>
            <Submit isLoading={isLoading}>Zgłoś</Submit>
          </div>
        </form>
      </Card>
    </FormProvider>
  );
};

export default FormReportError;
