import FormRegisterCustomer from '../../../../molecules/forms/FormRegisterCustomer';
import {routes} from '../../../../../app/router/routes';
import {useEffect, useState} from 'react';
import FormRegisterVineyard from '../../../../molecules/forms/FormRegisterVineyard';
import RegisterOption from '../../../../molecules/register/RegisterOption';
import {useLocation} from 'react-router-dom';
import {BsFillPersonFill, BsShop} from "react-icons/bs"
import {FaWineBottle} from "react-icons/fa"
import {AiFillShop} from "react-icons/ai"
import { MdOutlineImportExport } from 'react-icons/md';
import FormRegisterShopRestarant from '../../../../molecules/forms/FormRegisterShopRestaurant';
import FormRegisterImporter from '../../../../molecules/forms/FormRegisterImporter';

const RegisterCard = () => {
    const [type, setType] = useState('');
    const {pathname} = useLocation();
    useEffect(() => {
        if (pathname === routes.register)
            setType('');
        else if (pathname === routes.registerCustomer)
            setType('panel-customer');
        else if (pathname === routes.registerVineyard)
            setType('vineyard');
        else if (pathname === routes.registerShopRestaurant)
            setType('shop-restaurant');
        else if (pathname === routes.registerImporter)
            setType('importer')
    }, [pathname]);
    return (
        <div>
            <div className=" bg-gray-50 flex flex-col gap-4 text-gray-800">
                {type === '' && <SelectRegisterType setType={setType}/>}
                {type === 'panel-customer' && <FormRegisterCustomer/>}
                {type === 'vineyard' && <FormRegisterVineyard/>}
                {type === 'shop-restaurant' && <FormRegisterShopRestarant/>}
                {type === 'importer' && <FormRegisterImporter/>}
            </div>

        </div>
    );
};

const SelectRegisterType = ({setType, className}) => {
    return (
        <div className={` space-y-4 ${className}`}>
            <p className={'text-sm text-gray-700 text-center'}>Wybierz typ konta, które chcesz stworzyć</p>
            <div className='grid grid-cols-2 gap-4'>
            <RegisterOption
                to={routes.registerCustomer}
                iconRenderer={() => <div className={'bg-gold w-10 h-10 flex items-center justify-center'}>
                    <BsFillPersonFill/></div>}
                onClick={() => setType('Customer')}>
                Osoba prywatna
            </RegisterOption>
            <RegisterOption
                to={routes.registerVineyard}
                iconRenderer={() => <div className={'bg-gold w-10 h-10 flex items-center justify-center'}>
                    <FaWineBottle/></div>}
                onClick={() => setType('Vineyard')}>
                Winnica
            </RegisterOption>
            <RegisterOption
                to={routes.registerShopRestaurant}
                iconRenderer={() => <div className={'bg-gold w-10 h-10 flex items-center justify-center'}>
                    <AiFillShop/></div>}
                onClick={() => setType('ShopRestaurant')}>
                Sklep/Restauracja
            </RegisterOption>
            <RegisterOption
                to={routes.registerImporter}
                iconRenderer={() => <div className={'bg-gold w-10 h-10 flex items-center justify-center'}>
                    <MdOutlineImportExport/></div>}
                onClick={() => setType('Importer')}>
                Importer
            </RegisterOption>
            </div>
        </div>
    );
};
export default RegisterCard;
