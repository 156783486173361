import TemplateMain from "../../../templates/TemplateMain";
import { routes } from "../../../../app/router/routes";
import ProgressBar from "../../../organisms/cart/ProgressBar";
import CartSummaryDeliveryCard from "../../../organisms/cart/summary/CartSummaryDeliveryCard";
import CartSummaryPaymentCard from "../../../organisms/cart/summary/CartSummaryPaymentCard";
import CartSummaryProductsCard from "../../../organisms/cart/summary/CartSummaryProductsCard";
import CartSummaryCommentCard from "../../../organisms/cart/summary/CartSummaryCommentCard";
import React from "react";
import TemplateCart from "../../../templates/TemplateCart";
import Summary from "../../../molecules/cart/Summary";
import { usePlaceOrder } from "../../../../app/crud/app/placeOrder";
import toast from "react-hot-toast";
import { FormProvider, useForm } from "react-hook-form";
import { useSelectAddress } from "../../../../app/redux/orderReducer/orderSelectors";
import { data } from "autoprefixer";
const ViewCartSummary = () => {
  const { mutate: mutatePlaceOrder } = usePlaceOrder();
  const initialData = useSelectAddress();
  const onSuccessOrder = () => {
    toast.success("Zamówienie zostało złozone");
  };

  const onErrorOrder = () => {
    toast.error(
      "Wystąpił błąd podczas składania zamówienia. Spróbuj ponownie później."
    );
  };
  const progressBarConfig = [
    { url: routes.cart, done: true, active: true, label: "Koszyk" },
    {
      url: routes.checkout,
      done: true,
      active: true,
      label: "Dostawa i płatność",
    },
    {
      url: routes.cartSummary,
      done: false,
      active: true,
      label: "Podsumowanie",
    },
    { url: routes.cart, done: false, active: false, label: "Gotowe" },
  ];

  const summaryConfig = {
    label: "Zamawiam i płacę",
    // info: "Zamówienia tymczasowo nie są dostępne.",
    disabled: false,
    onClick: () => {
      mutatePlaceOrder(initialData, {
        onError:onErrorOrder,
        onSuccess: onSuccessOrder,
      });
    },
    submit: true,
    route: routes.orderDone,
  };
  const methods = useForm({
    defaultValues: {
      ...initialData,
    },
  });

  const { register, handleSubmit, setValue, ...form } = methods;


  return (
    <TemplateMain title={"Podsumowanie zamówienia"}>

          <TemplateCart
            progressBarConfig={progressBarConfig}
            summaryConfig={summaryConfig}
            title={"Podsumowanie"}

          >
            <div className={"flex space-x-5 w-full"}>
              <div className={"w-full space-y-5"}>
                <CartSummaryDeliveryCard />
                <CartSummaryPaymentCard />
                <CartSummaryProductsCard />

                <CartSummaryCommentCard />

              </div>
            </div>
          </TemplateCart>

    </TemplateMain>
  );
};

export default ViewCartSummary;
