import { BASE_URL_API } from '../../../../config/env';

export const blogsMock = (mock) => {
  mock.onGet(`${BASE_URL_API}/blogs`).reply(config => {
    return ([200, {
      items: [
        {
          id: 1,
          date: '2022-03-30',
          title: 'ÁLVARO PALACIOS WINIARZEM ROKU 2022 WEDŁUG TIMA ATKINA',
          slug: 'alvaro-palacios-winiarzem-roku',
          description: 'Właśnie ukazał się Rioja 2022 Special Report Tima Atkina – jednego z najbardziej znanych i wpływowych krytyków winiarskich, publikującego w magazynach takich jak „Decanter”, „The World of Fine Wine”, „Gourmet Traveller Wine” i „The Drinks Business”.',
          image: {
            url: 'https://domwina.pl/data/include/img/news/1644936858.png',
            alt: 'alt'
          }
        },
        {
          id: 2,
          date: '2022-03-17',
          title: 'GONZÁLEZ BYASS "NAJLEPSZYM PRODUCENTEM HISZPAŃSKIM"',
          slug: 'alvaro-palacios-winiarzem-roku',
          description: 'Z ogromną radością spieszymy z doniesieniem, że podczas tegorocznego konkursu Mundus Vini – jednego z najważniejszych na świecie – winiarnia González Byass została wybrana "Najlepszym Producentem Hiszpanii".',
          image: {
            url: 'https://domwina.pl/data/include/img/news/1647510052.png',
            alt: 'alt'
          }
        },
        {
          id: 3,
          date: '2022-03-15',
          title: 'LAURY DLA FANTINI!',
          slug: 'alvaro-palacios-winiarzem-roku',
          description: 'Pasmo sukcesów Fantini Group zdaje się nie mieć końca! Oto kolejny sukces na koncie tej prężnie działającej i od dłuższego czasu święcącej triumfy firmy z południa Włoch – tym razem to tytuł Best Italian Producer 2022 (Najlepszy Włoski Producent 2022 roku), przyznany podczas Mundus Vini.',
          image: {
            url: 'https://domwina.pl/data/include/img/news/1647340291.png',
            alt: 'alt'
          }
        },
      ]
    }]);
  });
};
