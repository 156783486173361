import axios from '../../config/axios';
import {BASE_URL_API} from '../../config/env';
import useQuery2 from '../../hooks/useQuery2';
import {useParams} from "react-router-dom";

export const getWine = ([id]) =>
    axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/wine-products/${id}`
    }).then(({data}) => data);

export const useGetWine = (productId) => {
    const {wineId} = useParams();
    return useQuery2({
        queryKey: ['app-public.wine', productId || wineId],
        queryFn: getWine
    });
}

