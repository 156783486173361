import React from 'react';
import {ReactComponent as IconOrder} from "../../../app/assets/svg/order 1.svg";
import {ReactComponent as IconPrevOrder} from "../../../app/assets/svg/prev_order 1.svg";
import {ReactComponent as IconPromo} from "../../../app/assets/svg/promo 1.svg";
import {ReactComponent as IconStatus} from "../../../app/assets/svg/status 1.svg";

import Header2 from "../../molecules/headers/Header2";
import RegisterBenefitItem from "../../molecules/cart/RegisterBenefitItem";

const RegisterBenefits = () => {
    return (
        <div className={'px-8 space-y-4'}>
            <Header2>Dlaczego warto założyć konto</Header2>
            <RegisterBenefitItem icon={<IconOrder/>} label={"zamawiaj szybciej"}/>
            <RegisterBenefitItem icon={<IconPrevOrder/>} label={"sprawdzaj poprzednie zamówienia"}/>
            <RegisterBenefitItem icon={<IconStatus/>} label={"śledź status zamówienia"}/>
            <RegisterBenefitItem icon={<IconPromo/>} label={"korzystaj z rabatów i promocji"}/>
        </div>
    );
};

export default RegisterBenefits;
