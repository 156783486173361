import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { passParams, routes } from "../../../router/routes";

export const usePutAdvertisement = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return useMutation((data) => {
    return axios
      .put(`${BASE_URL_API}/api/v1/admin/vineyard/advertisements/${id}`, data)
      .then(({ data }) => data)
      .then(() => {
        navigate(
          passParams(routes.vineyardPanelEnoturismAdvertisement, {
            id: data?.id,
          })
        );
      });
  });
};
