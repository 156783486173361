import React, { useEffect } from 'react';

const RadioButton = ({ children, handleRadioButtonClick, value, selectedValue, checked, className }) => {
  useEffect(()=>{
    if(checked)
      handleRadioButtonClick(value);
  },[])

  return (
    <label className={`cursor-pointer flex items-center py-2 ${className}`}>
      <input
        readOnly
        checked={selectedValue ? selectedValue === value : !!checked}
        onClick={() => handleRadioButtonClick(value)}
        type={'radio'}
        className={'mr-4'}
      />
      {children}
    </label>
  )
    ;
};

export default RadioButton;
