import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import useSearchParamsObject from "./useSearchParamsObject";

const usePagination = (refetch) => {
    const {setParam, getParam} = useSearchParamsObject();
    const location = useLocation();
    const [totalPages, setTotalPages] = useState(null);
    const [totalCount, setTotalCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 16

    useEffect(() => {
        refetch && refetch();
        setCurrentPage(getParam("PageNumber") || 1);
    }, [location.search])

    const onPageChange = (page) => {
        setParam("PageNumber", parseInt(page))
    }

    return {onPageChange, currentPage, totalPages, rowsPerPage, totalCount, setTotalPages, setTotalCount}
};

export default usePagination;
