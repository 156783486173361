import React from 'react';
import SearchInput from '../search-input/SearchInput';
import HeaderNav from '../navigations/HeaderNav';

const NavAndSearch = () => {
  return (
    <div className={'space-y-4'}>
      {/*<SearchInput/>*/}
      <HeaderNav />
    </div>
  );
};

export default NavAndSearch;
