import Price from "../../molecules/price/Price";
import Header1 from "../../molecules/headers/Header1";
import Header2 from "../../molecules/headers/Header2";
import Description from "../../molecules/description/Description";
import { ToastContainer } from "react-toastify";
import Rate from "../../molecules/rate/Rate";
import { useGetRatings } from "../../../app/crud/customer/ratings/getRatings";
import { useParams } from "react-router-dom";
import Text from "../../molecules/text/Text";
import Aroma from "../../molecules/Aromas/Aroma";
import ForWhat from "../../molecules/ForWhat/ForWhat";

const ProductDescription = ({
  data,
  isLoading,
  buttonRenderer,
  imageRenderer,
}) => {
  const { id } = useParams();
  const { data: dataRatings, isLoading: isLoadingRatings } = useGetRatings(id);

  return (
    <>
    <div className={` md:flex justify-between  xl:flex-row w-full`}>
      <div
        className={
          "rounded-lg flex mt-8 xl:mt-0 w-full md:w-1/2 justify-start md:min-w-96 min-w-70"
        }
      >
        {imageRenderer && imageRenderer()}
      </div>

      <div className={"flex flex-col lg:ml-4 w-1/2"}>
        <div className={"flex flex-col  space-y-5 sm:space-y-0"}>
          <div className={"flex flex-col space-y-4"}>
            <Header1 isLoading={isLoading}>{data?.name}</Header1>
            {data?.vineyardName && (
              <Header2 className={'!mt-0 md:mt-2'} isLoading={isLoading}>{data?.vineyardName}</Header2>
            )}
            {data?.wineRatings?.length > 0 ? (
              <Rate
                isLoading={isLoading}
                rate={data?.averageRating}
                opinions={data?.wineRatings?.length}
              />
            ) : null}
            <Price
              isLoading={isLoading}
              className={"text-xl"}
              value={data?.priceGross}
            />
          </div>
        </div>
        <Description className={"mt-2 sm:mt-0"} isLoading={isLoading}>
          {data?.description}
        </Description>
      </div>
      
      {buttonRenderer && (
        <div
          className={
            "flex flex-col w-full sm:w-64 space-y-3 sm:ml-2 lg:ml-auto min-w-[13rem] mt-4 md:mt-0"
          }
        >
          {buttonRenderer()}
          <ToastContainer
            hideProgressBar
            closeButton
            autoClose={1000}
            limit={1}
          />
        </div>
      )}
    </div>

    </>
  );
};

export default ProductDescription;
