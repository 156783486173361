import { BASE_URL_API } from '../../../../config/env';

export const vineyardEnoturismMock = (mock) => {
  mock.onGet(`${BASE_URL_API}/admin/enoturism/advertisements`).reply(200, [
    {
      id: 16,
      identifier: 'E14',
      maxPeople: '4',
      status: {id: 0, name: 'Aktywne'},
    },
    {
      id: 17,
      identifier: 'E25',
      maxPeople: '6',
      status: {id: 0, name: 'Ukryte'},
    },
    {
      id: 18,
      identifier: 'E16',
      dateStart: '19.01.2022',
      dateEnd: '23.01.2022',
      maxPeople: '4',
      people: '2',
      status: {id: 0, name: 'Zarezerwowane'},
    },
  ]);
};
