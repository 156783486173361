import React, {useEffect} from 'react';
import RangeSlider from "../../atoms/ui/slider/RangedSlider";
import Text from "../text/Text";
import useSearchParamsObject from "../../../app/hooks/useSearchParamsObject";

const RangedFilter = ({defaultValue, label, min, max, unit, param}) => {
    const [value, setValue] = React.useState(defaultValue || [min, max]);
    const {setParam} = useSearchParamsObject()

    useEffect(() => {
        if (defaultValue)
            setValue(defaultValue)
        else {
            setValue([min, max])
        }
    }, [min, max, defaultValue])

    return (
        <div>
            <Text className={'text-sm text-gray-500'}>{`${label}`}</Text>
            <RangeSlider
                defaultValue={defaultValue}
                valueCallback={setValue}
                min={min}
                max={max}
                onMouseUp={()=>{setParam(param, value.join(','))}}
                param={param}/>
            <Text className={'text-xs text-center text-gray-500'}>{`(${value[0]}-${value[1]}) ${unit || ''}`}</Text>
        </div>
    );
};

export default RangedFilter;
