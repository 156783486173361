import dayjs from "dayjs";
import { BeatLoader } from "react-spinners";


export const ProductCommentsAdmin = ({data, isLoading}) => {
  // const { id } = useParams();
  // const { data, isLoading } = useGetRatings(id);
  return (
    <div>
      {!isLoading ? (
        data?.items?.map((item) => (
          <div className="bg-grey-50 rounded-lg">
            <div className="px-0">
              <div className="divide-y">
                <div className="flex flex-col gap-3 py-4 md:py-8 bg-gray-50 px-5 mb-5">
                  <div>
                    <span className="block text-sm font-bold">
                      {item?.userName ? item?.userName : "Anonim"}
                    </span>
                    <span className="block text-gray-500 text-sm">
                      {dayjs(item?.createdAt).format("DD.MM.YYYYr.")}
                    </span>
                  </div>
                  <div className="flex ">
                    {[...new Array(item?.starsCount).keys()].map(() => (
                      <div className="flex  mr-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 text-yellow-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>
                    ))}
                  </div>
                  <p className="text-gray-800">
                    {item?.comment ? item?.comment : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className={"flex w-full items-center justify-center"}>
        <BeatLoader />
      </div>
      )}
    </div>
  );
};
