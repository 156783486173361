import React, {useState} from "react";
import Input from "../../../atoms/ui/inputs/CustomInput";
import Button from "../../../atoms/ui/buttons/regular/Button";
import {AiFillDelete} from "react-icons/ai";
import {InputAsyncSelect} from "../../../atoms/ui/inputs/Input_AsyncSelect";
import {getStrainsHelper} from "../../../../app/crud/vineyard/enum/getStrains";
import {useFieldArray, useFormContext} from "react-hook-form";

const FormConfigureStrains = ({
                                  errors,
                                  name,
                              }) => {
    const {register, control} = useFormContext();
    const {fields, append, remove} = useFieldArray({control, name})

    return (
        <div>
            <p className={"font-bold text-xl mt-4"}>Szczepy</p>
            {fields.map((item, arrayIndex) => (
                <div key={item.id + "strain"} className={"flex space-x-5 items-center mt-3 "}>
                    <div className="w-1/2">
                        <InputAsyncSelect
                            {...getStrainsHelper}
                            placeholder={"Wybierz rodzaj szczepu np. szczep"}
                            name={`${name}.${arrayIndex}.id`}
                            required
                            label={"Rodzaj szczepu"}
                            transform={({id, name}) => ({
                                value: id,
                                label: name
                            })}
                        />
                    </div>
                    <div className={"w-1/2"}>
                        <Input
                            required
                            register={register}
                            className={"w-full"}
                            type={"number"}
                            name={`${name}.${arrayIndex}.amount`}
                            label={"Zawartość [%]"}
                            error={
                                errors?.[name]?.[arrayIndex]?.amount?.message
                            }
                        />
                    </div>
                    <button
                        type="button"
                        onClick={() => {
                            remove(arrayIndex)
                        }}
                        className={"pt-7"}
                    >
                        <AiFillDelete/>
                    </button>
                </div>
            ))}
            <Button
                className={"mt-4 px-4"}
                type="button"
                onClick={e => {
                    e.preventDefault();
                    append({productionStrainsId: undefined, amount: undefined})
                }}
            >
                +
            </Button>
        </div>
    )
}


export default FormConfigureStrains;
