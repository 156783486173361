import React, {useEffect} from 'react';
import ProductCard from '../../molecules/product-card/ProductCard';
import {ToastContainer} from 'react-toastify';
import Pagination from "../../molecules/pagination/Pagination";
import usePagination from "../../../app/hooks/usePagination";

const ProductListWithFilters = ({isFetching, items, refetch}) => {
    const {totalPages, currentPage, onPageChange, setTotalPages, setTotalCount} = usePagination(refetch)

    useEffect(()=> {
        setTotalPages(items?.totalPages);
        setTotalCount(items?.totalCount);
    }, [items])

    return (
        <div>
            <div className={'w-full grid grid-cols-1 sm:grid-cols-2  gap-x-4'}>
                {isFetching ?
                    [...Array(16).keys()].map(card =>
                        <div key={card + 'mockwineproduct'} className={'w-full  mb-4'}>
                            <ProductCard isLoading={isFetching}/>
                        </div>
                    )
                    :
                    items?.items?.map((product, id) => (
                        <div key={id + 'wineproduct'} className={'w-full  mb-4'}>
                            <ProductCard product={product} isLoading={isFetching}/>
                        </div>
                    ))}
                <ToastContainer hideProgressBar closeButton autoClose={1000} limit={1}/>

            </div>
            <Pagination
                className={'w-full justify-center mt-4'}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
            />
        </div>

    );
};

export default ProductListWithFilters;
