import FormError from '../../atoms/form-error/FormError'
import MessageError from '../../atoms/form-error/MessageError'

export const InputCheckboxPure = ({ name, value, error, label, onChange = () => {}, innerRef, required }) => {
  const handleChange = (e) => {
    return onChange(e.target.checked)
  }
  return (
    <div>
    <div className='flex '>
      <label className="custom-control-Primary custom-checkbox custom-control align-items-center d-flex cursor-pointer contents">
        <input
          name={name}
          ref={innerRef}
          type="checkbox"
          aria-invalid="false"
          className="custom-control-input mt-1"
          checked={value}
          onChange={handleChange}
        />
        <span className=" flex ml-2 text-sm custom-control-label">{label(required)} </span>
      </label>
    </div>
    <MessageError className="block" message={error?.message} />
    </div>
  )
}
