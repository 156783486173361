import { FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';

const Submit = ({ children, className, isLoading, disabled }) => {
  const tw_classes = `relative flex flex-row justify-center items-center 
  ${disabled ? 'bg-gray-500 cursor-default' : 'bg-gold2 transition hover:bg-gold'} 
  py-2 px-8 rounded-lg ${isLoading ? 'text-transparent' : 'text-white'} ${className}`;

  return (
    <button  disabled={isLoading || disabled} type={'submit'} className={tw_classes}>
      <div className={'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'}>
        <FaSpinner className={`${isLoading ? 'animate-spin text-white' : 'text-transparent'}`} />
      </div>
      {children}
    </button>
  );
};

Submit.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Submit;
