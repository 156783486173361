import React, { useState } from 'react';
import Card from '../../atoms/card/Card';
import PortalWrapper from '../../atoms/portal-wrapper/PortalWrapper';
import { FaSlidersH } from 'react-icons/fa';
import Button from '../../atoms/ui/buttons/regular/Button';
import FilterDropdowns from './FilterDropdowns';
import ActiveFilters from '../../molecules/filters/ActiveFilters';
import { AiOutlineClose } from 'react-icons/ai';
import FilterSliders from './FilterSliders';

const MobileFilters = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Card>
      <Button className={'flex flex-row items-center'} onClick={() => setOpen(true)}>
        <FaSlidersH className={'mr-2'} />
        Filtruj
      </Button>
      <ActiveFilters className={'mt-4'} />
      <MobileFiltersModal isOpen={isOpen} setOpen={setOpen} />
    </Card>
  );
};

const MobileFiltersModal = ({setOpen, isOpen, filters, isLoading, state}) => {
  return (
    <PortalWrapper
      onBackdropClick={() => {
        setOpen(false);
      }}
      show={isOpen}
      overrideClass={'overflow-scroll fixed pb-4 z-50 opacity-95 w-60 h-screen top-0 left-0 bg-white'}>
      <div>
        <div className={'px-4 pt-4 flex justify-between'}>
          <p>Filtruj</p>
          <button onClick={()=>setOpen(false)}>
            <AiOutlineClose/>
          </button>
        </div>
        <FilterDropdowns state={state} filters={filters?.checkboxFilters} isLoading={isLoading}/>
            <FilterSliders state={state} filters={filters?.rangeFilters} isLoading={isLoading}/>
      </div>
      <div className={'flex flex-col space-y-4'}>
            <FilterDropdowns state={state} filters={filters?.checkboxFilters} isLoading={isLoading}/>
            <FilterSliders state={state} filters={filters?.rangeFilters} isLoading={isLoading}/>
        </div>
    </PortalWrapper>
  );
};

export default MobileFilters;
