import React from 'react';
import { Link } from 'react-router-dom';

const RegisterOption = ({children, iconRenderer, onClick, to}) => {
  return (
    <div onClick={onClick}>
      <Link to={to} className={'flex w-full shadow rounded p-4 items-center space-x-4 hover:bg-gray-100 transition'}>
        <div className={'rounded-lg overflow-hidden '}>{iconRenderer && iconRenderer()}</div>
        <div className={'text-sm'}>{children}</div>
      </Link>
    </div>

  );
};

export default RegisterOption;
