import axios from '../../../config/axios';
import {BASE_URL_API} from '../../../config/env';
import {useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";

export const useGetEvents2 = () => {
    const {id} = useParams()
    const [queryParams] = useSearchParams()
    const page = queryParams.get("page");
    const items = queryParams.get("items")
    return useQuery({
        queryKey: ['app-public.container-event', page],
        queryFn: () => axios({
            method: 'GET',
            url: `${BASE_URL_API}/api/v1/admin/vineyard/containers/${id}/events`,
            params: {
                PageNumber: page,
                PageSize: items
            }
        }).then(({data}) => {
            return data
        }),
    });
}
