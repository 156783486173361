import React from 'react';
import Card from "../../../atoms/card/Card";
import {Link} from "react-router-dom";
import {routes} from "../../../../app/router/routes";
import {useSelectProducts} from "../../../../app/redux/cartReducer/cartSelectors";
import SummaryCartProduct from "../../../molecules/cart/SummaryCartProduct";

const CartSummaryProductsCard = () => {
    const products = useSelectProducts()
    return (
        <Card className={'relative'} title={"Koszyk"}>
            <Link className={'absolute right-4 top-4 text-gold2 hover:text-gold'} to={routes.cart}>Zmień</Link>
            <div className={'space-y-4'}>
                {
                    products.map(product => <SummaryCartProduct data={product}/> )
                }
            </div>

        </Card>
    );
};

export default CartSummaryProductsCard;
