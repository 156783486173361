import NavLink from '../../atoms/ui/buttons/links/NavLink';
import {routes} from '../../../app/router/routes';
import HeaderDropdown from "../../atoms/ui/dropdown/HeaderDropdown";

const HeaderNav = () => {
    const ac = 'px-2 lg:px-0'
    const cn = 'py-2 rounded-lg lg:px-6'
    return (
        <nav className={'flex flex-col justify-center lg:space-x-2 lg:flex-row z-50'}>
            <NavLink className={cn} activeClass={ac} to={routes.wines}>Wina</NavLink>
            <NavLink className={cn} activeClass={ac} to={routes.vineyards}>Winnice</NavLink>
            <HeaderDropdown  label={"Enoturystyka"} className={'z-50'}>
                <NavLink className={cn} activeClass={ac} to={routes.enoturism}>Wydarzenia</NavLink>
                {/* <NavLink className={cn} activeClass={ac} to={routes.enoturism}>Noclegi</NavLink>
                <NavLink className={cn} activeClass={ac} to={routes.enoturism}>Oferta</NavLink> */}
            </HeaderDropdown>

            <NavLink className={cn} activeClass={ac} to={routes.blog}>Blog</NavLink>
            <NavLink className={cn} activeClass={ac} to={routes.forVineyard}>Dla winnicy</NavLink>
            <HeaderDropdown label={"Menu"} className={'z-50'}>
                <NavLink className={cn} activeClass={ac} to={routes.contact}>Kontakt</NavLink>
                <NavLink className={cn} activeClass={ac} to={routes.about}>O nas</NavLink>
            </HeaderDropdown>
        </nav>
    );
};

export default HeaderNav;
