import React  from 'react';
import { useSelectRole, useSelectToken } from '../../../app/redux/authReducer/authSelectors';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { routes } from '../../../app/router/routes';

const ProtectedRoute = ({ allowedRoles }) => {
  const token = useSelectToken();
  const roles = useSelectRole();
  const location = useLocation();

  return (
    roles?.find(role => allowedRoles?.includes(role))
      ? token
        ? <Outlet />
        : <Navigate to={routes.unauthorized} state={{ from: location }} replace />
      : <Navigate to={routes.login} state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
