import { useGetWines } from "../../../app/crud/app/wines";
import ProductCard from "../../molecules/product-card/ProductCard";
import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";

const Bestsellers = ({ isLoading, data }) => {
  const options = { perPage: 2,  breakpoints: {
		930: {
			perPage: 1,
		},

  }, rewind: true, gap: "1rem", pagination: false };
  return (
    <Splide options={options} aria-labelledby="basic-example-heading">
      {isLoading
        ? [...Array(3).keys()].map((item, index) => (
            <SplideSlide key={index + "slideloader"}>
              <ProductCard product={item} isLoading={isLoading} />
            </SplideSlide>
          ))
        : data?.items?.map((item, index) => (
            <SplideSlide key={index + "slideloader"}>
              <ProductCard product={item} isLoading={isLoading} />
            </SplideSlide>
          ))}
    </Splide>
  );
};

export default Bestsellers;
