import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TYPE_LOGIN_WITH_EMAIL, TYPE_LOGOUT } from './authActionTypes';

const initialState = () => ({
  token: null,
  role: null
});

const reducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case TYPE_LOGIN_WITH_EMAIL: {
      return {
        role: payload.role,
        token: payload.token
      };
    }
    case TYPE_LOGOUT: {
      return initialState();
    }
    case 'persist/REHYDRATE': {
      return {
        ...initialState(),
        ...state
      };
    }
    default:
      return state;
  }
};

const persistConfig = {
  key: 'app-public.auth.userToken',
  storage
};

export const authReducer = persistReducer(persistConfig, reducer);
