import {useEffect, useState} from "react";
import {matchPath} from "react-router";

const useReactPath = () => {
    const [path, setPath] = useState(window.location.pathname);

    useEffect(() => {
        window.addEventListener("popstate", listenToPopstate);
        return () => {
            window.removeEventListener("popstate", listenToPopstate);
        };
    }, []);

    const listenToPopstate = () => {
        const winPath = window.location.pathname;
        setPath(winPath);
    };

    const compare = (pattern, path) => {
        return !!matchPath(pattern, path)
    }

    return {path, compare};
};

export default useReactPath;