import TemplatePanel from "../../templates/TemplatePanel";
import CustomerSideMenu from "../../organisms/side-menu/CustomerSideMenu";
import {routes} from "../../../app/router/routes";
import Breadcrumbs from "../../organisms/breadcrumbs/Breadcrumbs";
import React, {useState} from "react";
import {useGetCustomerOrder} from "../../../app/crud/customer/getCustomerOrder";
import Card from "../../atoms/card/Card";
import CustomDataTable from "../../organisms/customTable/CustomDataTable";
import usePagination from "@mui/material/usePagination/usePagination";
import Price from "../../molecules/price/Price";
import CustomerOrderDetails from "../../molecules/order/CustomerOrderDetails";
import {AiOutlineEdit} from "react-icons/ai";
import ModalRateWine from "../../organisms/modals/ModalRateWine";
import { SummaryOrder } from "../../molecules/order/SummaryOrder";


const ViewCustomerOrder = () => {
    const {data, isLoading} = useGetCustomerOrder();
    console.log(data)
    const {onPageChange, currentPage, totalPages, rowsPerPage} = usePagination();
    const bc = [{label: "Moje zamówienia", url: routes.customerOrders}];
    const [isRateModalOpen, setRateModalOpen] = useState(false)
    const [rateProductId, setRateProductId] = useState(false)
    const columns = [
        {name: "Nazwa wina", cell: (row) => <p>{row?.wineProductName}</p>},
        {name: "Nazwa winnicy", cell: (row) => <p>{row?.vineyardName}</p>},
        {name: "Ilość", cell: (row) => <p>{row?.quantity} szt.</p>},

        {
            name: "Koszt",
            cell: (row) => (
                <Price
                    className={"text-xs uppercase mr-4"}
                    value={row?.totalPriceGross}
                />
            ),
        },
        {name: "Oceń produkt", cell: (row) => <button onClick={()=>{setRateProductId(row?.wineProductId); setRateModalOpen(true);}} className={'flex items-center'}><AiOutlineEdit className={'mr-2'}/>Oceń</button>},
    ];
    return (
        <TemplatePanel
            title={"Zamówienia"}
            sideMenuRenderer={() => <CustomerSideMenu/>}
            breadcrumbsRenderer={() => (
                <Breadcrumbs homeRoute={routes.customerData} settings={bc}/>
            )}
        >
            {rateProductId && <ModalRateWine isOpen={isRateModalOpen} setOpen={setRateModalOpen} productId={rateProductId}/>}
            <Card>
                <CustomerOrderDetails data={data} date={data?.createdDate} order_num={data?.id} status={data?.orderStatus?.name}/>
                <div className="text-gray-600 text-xl">Szczegółowe dane zamówienia</div>
                <CustomDataTable
                    columns={columns}
                    isLoading={isLoading}
                    data={data?.orderItems}
                    pagination={{
                        currentPage: currentPage,
                        totalPages: totalPages,
                        rowsPerPage: rowsPerPage,
                        onPageChange: onPageChange,
                    }}
                />
                <div className="flex justify-end">
                <SummaryOrder  data={data}/>
                </div>
            </Card>
        </TemplatePanel>
    );
};

export default ViewCustomerOrder;
