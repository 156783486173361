import { Controller, useFormContext } from "react-hook-form"
import InputMask from "react-input-mask";

const ControlledInputMask = ({name, defaultValue, required, mask, ref, formatChars, ...props}) => {
    const {control} = useFormContext()
    return (
        <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={{required}}
        render={({field}) => (
            <InputMask
            mask={mask}
            inputRef={ref}
            {...props}
            formatChars={{ h: "[0-9]" }}
            value={field?.value}
            onChange={field?.onChange}
            />
    )}
        />
    )
}

export default ControlledInputMask