import React from 'react';
import Card from "../../atoms/card/Card";
import LineLoader from "../../atoms/loaders/LineLoader";
import FilterDropdown from "../../molecules/filters/FilterDropdown";

const FilterDropdowns = ({className, state, filters, isLoading}) => {
    return (
        <Card className={'w-60'}>
            <div className={`flex flex-col space-y-5 ${className || ''}`}>
                {
                    isLoading ?
                        [...Array(5).keys()].map((i, id) => <LineLoader key={'loader' + id}
                                                                        isLoading={isLoading}/>) :
                        Array.isArray(filters) && filters?.map((filter, id) => {
                                return (
                                    <FilterDropdown
                                        state={state[filter?.queryParam]?.value?.split(",")}
                                        onChange={()=>{}}
                                        key={'winefilter' + id}
                                        options={filter}
                                    />
                                )
                            }
                        )
                }
            </div>
        </Card>

    );
};

export default FilterDropdowns;
