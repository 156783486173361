import React from "react";
import {
  AiOutlineFieldNumber,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import { BsPerson, BsSignpostSplit } from "react-icons/bs";
import { GiFactory, GiRoad } from "react-icons/gi";
import { GrDeliver } from "react-icons/gr";
import { TbBrandPaypal } from "react-icons/tb";

const CustomerOrderDetails = (data) => {

  return (
    <div className={"space-y-4 mb-4"}>
      <div>
        <div className="text-gray-600 text-2xl">
          Zamówienie nr <span className="text-gray-800">{data?.order_num}</span>
        </div>
        <div>
          <div className="text-gray-600">
            Złożone{" "}
            <span className="text-gray-800">
              {new Date(data?.date).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
      <div className="xl:flex  justify-center items-center">
        <div className="text-xl text-gray-600 w-full">
          Dostawa
          <div className="mt-2 p-2 border xl:w-4/5 flex items-center">
            <GrDeliver className="mr-2" size={"2.25rem"} />{" "}
            <span className={"text-gray-800 text-lg"}>
              Kurier – InPost, UPS, FedEx, DTS
            </span>
          </div>
        </div>
        <div className="text-xl text-gray-600 w-full mt-3 xl:mt-0">
          Płatność
          <div className="mt-2 p-2 border xl:w-4/5 flex items-center">
            <TbBrandPaypal className="text-gray-800 mr-2" size={"2.25rem"} />{" "}
            <span className={"text-gray-800 text-lg"}>PayPal</span>
          </div>
        </div>
      </div>
      <div>
        <div className="text-gray-600 text-xl">
          Status zamówienia:{" "}
          <span className={"text-gray-800 text-lg"}>{data?.orderStatus?.name}</span>
        </div>
      </div>
      <div>
      <p className={"text-gray-600 text-xl mb-2"}>Dane odbiorcy</p>
        <div className="xl:flex block ">
        <div className={"flex border p-2 w-full xl:w-1/3 mr-2"}>
          <div className={"text-lg w-full"}>
            <p className={"flex items-center text-lg mt-2 mb-2"}>
              <BsPerson size={"1.25rem"} className={"mr-2"} />
              {`${data?.data?.name} ${data?.data?.surname}`}
            </p>
            <p className={"flex items-center mb-2"}>
              <GiRoad size={"1.25rem"} className={"mr-2"} />
              {`${data?.data?.shippingAddressDto?.streetName}, ${
                data?.data?.shippingAddressDto?.houseNumber
              }${
                data?.data?.shippingAddressDto?.apartmentNumber
                  ? "/" + data?.data?.shippingAddressDto?.apartmentNumber
                  : ""
              }`}
            </p>
            <p className={"flex items-center mb-2"}>
              <BsSignpostSplit size={"1.25rem"} className={"mr-2"} />
              {`${data?.data?.shippingAddressDto?.zipCode}, ${data?.data?.shippingAddressDto?.cityName}`}
            </p>
            {data?.data?.email && (
              <p className={"flex items-center mb-2"}>
                <AiOutlineMail size={"1.25rem"} className={"mr-2"} />
                {`${data?.data?.email}`}
              </p>
            )}
            {data?.data?.phoneNumber && (
              <p className={"flex items-center mb-2"}>
                <AiOutlinePhone size={"1.25rem"} className={"mr-2"} />
                {`${data?.data?.phoneNumber}`}
              </p>
            )}
          </div>
        </div>
          {data?.data?.isCompany && (
            <div className={"flex border p-2 w-full xl:w-1/3  mr-2"}>
            <div className={"text-sm w-full"}>
              {data?.data?.companyName && (
                <p className={"flex items-center text-lg mb-2 mt-2"}>
                  <GiFactory size={"1.25rem"} className={"mr-2"} />
                  {`${data?.data?.companyName}`}
                </p>
              )}
              {data?.data?.companyNip && (
                <p className={"flex items-center text-lg mb-2"}>
                  <AiOutlineFieldNumber size={"1.25rem"} className={"mr-2"} />
                  {`NIP: ${data?.data?.companyNip}`}
                </p>
              )}
            </div>
            </div>
          )}
          {data?.data?.isUsingOptionalShippingAddress && (
                    <div className={"flex border p-2 w-full xl:w-1/3 mr-2"}>
            <div className={"text-sm w-full"}>
              <p className={"flex items-center text-lg mb-2 mt-2"}>
                <GiRoad size={"1.25rem"} className={"mr-2"} />
                {`${data?.data?.optionalShippingAddressDto?.streetName}, ${
                  data?.data?.optionalShippingAddressDto?.houseNumber
                }${
                  data?.data?.optionalShippingAddressDto?.apartmentNumber
                    ? "/" + data?.data?.optionalShippingAddressDto?.apartmentNumber
                    : ""
                }`}
              </p>
              <p className={"flex items-center text-lg mb-2"}>
                <BsSignpostSplit size={"1.25rem"} className={"mr-2"} />
                {`${data?.data?.optionalShippingAddressDto?.zipCode} ${data?.data?.optionalShippingAddressDto?.cityName}`}
              </p>
            </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderDetails;
