import TemplateMain from '../../../templates/TemplateMain';
import TemplateSection from '../../../templates/TemplateSection';
import TemplateFilters from '../../../templates/TemplateFilters';
import VineyardList from '../../../organisms/vineyard-list/VineyardList';
import { useGetVineyardFilters } from '../../../../app/crud/app/vineyardsFilters';
import { useGetVineyards } from '../../../../app/crud/app/vineyards';
import { routes } from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import React from 'react';
import useFilters from '../../../../app/hooks/useFilters';

const ViewVineyards = () => {
  const {data, isLoading} = useGetVineyardFilters()
  const {data: vineyardsData, isFetching, refetch} = useGetVineyards()
  // const {filtersState} = useFilters(filters)
  const bc = [{ label: 'Winnice', url: routes.vineyards }];
  return (
    <TemplateMain
      title={'Winnice'}
      breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.home} settings={bc} />}
    >
      <TemplateSection>
        {/* <TemplateFilters filters={data} filtersLoading={isLoading} items={vineyardsData} refetch={refetch} itemsFetching={isFetching}> */}
          <VineyardList items={vineyardsData?.items} isFetching={isFetching} refetch={refetch} />
        {/* </TemplateFilters> */}
      </TemplateSection>
    </TemplateMain>
  );
};

export default ViewVineyards;
