import React from 'react';
import FilterDropdowns from './FilterDropdowns';
import FilterSliders from "./FilterSliders";


const Filters = ({filters, isLoading, state}) => {
    return (
        <div className={'flex flex-col space-y-4'}>
            <FilterDropdowns state={state} filters={filters?.checkboxFilters} isLoading={isLoading}/>
            <FilterSliders state={state} filters={filters?.rangeFilters} isLoading={isLoading}/>
        </div>

    );
};

export default Filters;
