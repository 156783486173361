import React from 'react';
import Tab from "../../molecules/tab/Tab";
import {useLocation} from "react-router-dom";

const Tabs = ({config}) => {
    const {pathname} = useLocation();
    return (
        <div className={'flex space-x-8 px-4'}>
            {
                config?.map(item => {
                    return (
                        <Tab isActive={pathname === item?.url} url={item?.url} >{item.label}</Tab>
                    )
                })
            }
        </div>
    );
};

export default Tabs;
