import React from 'react';
import Card from '../../../atoms/card/Card';
import RBGroup from '../../../atoms/ui/buttons/radio/RBGroup';
import RadioButton from '../../../atoms/ui/buttons/radio/RadioButton';
import {TbBrandPaypal} from "react-icons/tb";

const FormCheckoutAddress = () => {
    const radioClassName = ``
    return (
        <Card title={"Metoda płatności"}>
            <RBGroup
                className={'flex relative flex-col text-sm lg:text-base'}
                onChange={val => console.log(val)}
                initCheckOption={'paypal'}>
                <RadioButton className={radioClassName} value={'paypal'}>
                    <p>PayPal</p>
                    <p className={'ml-2 text-gray-400 text-sm'}>(bezpłatnie)</p>
                    <div className={'absolute right-0 text-xl'}>
                        <TbBrandPaypal/>
                    </div>
                </RadioButton>
            </RBGroup>
        </Card>
    );
};

export default FormCheckoutAddress;
