import VineyardProfileEdit from '../../../organisms/forms/panel-vineyard/profile/VineyardProfile';
import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import React from 'react';
import {useVineyardProfile} from '../../../../app/crud/vineyard/profile/getProfile';
import {routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';

const ViewVineyardProfileEdit = () => {
    const {data, isLoading, error} = useVineyardProfile();
    const bc = [{label: 'Profil', url: routes.vineyardProfile}, {label: 'Edytuj', url: routes.vineyardProfileEdit}]

    return (
        <TemplatePanel
            title={"Edytuj profil"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            isLoading={isLoading}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
        >
            <VineyardProfileEdit data={data} isLoading={isLoading} error={error}/>
        </TemplatePanel>
    );
};

export default ViewVineyardProfileEdit;
