import TemplatePanel from "../../templates/TemplatePanel";
import VineyardSideMenu from "../../organisms/side-menu/VineyardSideMenu";
import React, { useState } from "react";
import { routes } from "../../../app/router/routes";
import Breadcrumbs from "../../organisms/breadcrumbs/Breadcrumbs";
import { useVineyardAddImageToGallery } from "../../../app/crud/vineyard/profile/addGalleryImage";
import { useVineyardDeleteImageToGallery } from "../../../app/crud/vineyard/profile/deleteGalleryImage";
import { useVineyardProfile } from "../../../app/crud/vineyard/profile/getProfile";
import GalleryImageVineyard from "../../molecules/gallery-image/GalleryImageVineyard";
import ImageUploaderToGalleryVineyard from "../../molecules/image-uploader/ImageUploaderToGalleryVineyard";
import Card from "../../atoms/card/Card";
import ProductCard from "../../molecules/product-card/ProductCard";
import Text from "../../molecules/text/Text";
const ViewVineyardGallery = () => {
  const bc = [{ label: "Galeria", url: routes.vineyardPanelGallery }];
  const { data, isLoading, refetch } = useVineyardProfile();
  const { mutate: mutateAddImgToGallery } = useVineyardAddImageToGallery();
  const { mutate: mutateDeleteImgToGallery } =
    useVineyardDeleteImageToGallery();
  return (
    <TemplatePanel
      title={"Galeria"}
      isLoading={isLoading}
      sideMenuRenderer={() => <VineyardSideMenu />}
      actionButtonsRenderer={() => (
        <ImageUploaderToGalleryVineyard
          data={data}
          onAddSuccess={refetch}
          isLoading={isLoading}
          mutation={mutateAddImgToGallery}
          onDelete={() => mutateDeleteImgToGallery()}
          currentImageUrl={data?.images?.url !== "default" && data?.images?.url}
        />
      )}
      breadcrumbsRenderer={() => (
        <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc} />
      )}
    >
      <Card className={'w-full p-8'}>
        {!!isLoading ?
         [...Array(4).keys()].map(card => <ProductCard data={data} isLoading={isLoading} />)  
         :
         data?.images?.length === 0 ?
         <Text className={'text-center'}>Brak zdjęć do wyświetlenia</Text>
         :
         <div className={"grid lg:grid-cols-3 grid-cols-2 gap-4 mt-4"}>
         {data &&
           data?.images?.map((item, id) => (
             <>
               <GalleryImageVineyard
                 onDeleteSuccess={refetch}
                 item={item}
                 key={id + "gallery-image"}
                 url={item?.url}
                 alt={item?.alt}
               />
             </>
           ))}
       </div>
      }

      </Card>
    </TemplatePanel>
  );
};

export default ViewVineyardGallery;
