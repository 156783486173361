import React from "react";
import Submit from "../../../../atoms/ui/buttons/regular/Submit";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import Input from "../../../../atoms/ui/inputs/CustomInput";
import { IoBed } from "react-icons/io5";
import { AiFillCalendar, AiFillHome } from "react-icons/ai";
import { MdOutlineAttachMoney } from "react-icons/md";
import Card from "../../../../atoms/card/Card";
import { routes } from "../../../../../app/router/routes";
import CustomLink from "../../../../atoms/ui/buttons/links/Link";
import ControlledCustomInput from "../../../../atoms/ui/inputs/ControlledCustomInput";
import Textarea from "../../../../atoms/ui/textarea/Textarea";
import { useAddAdvertisement } from "../../../../../app/crud/vineyard/enoturism/addAdvertisement";
import { usePutAdvertisement } from "../../../../../app/crud/vineyard/enoturism/putAdvertisement";
import { InputDatepicker } from "../../../../atoms/ui/inputs/Input_DatePicker";

const AddAccomodation = ({ mode, initialData }) => {
  const accomodationSchema = yup.object().shape({
    name: yup.string().required("Nazwa jest wymagana"),
    roomsCount: yup.string().required("Liczba pokoi jest wymagana"),
    singlePersonBedsCount: yup.string().required("Liczba łóżek jest wymagana"),
    doublePersonBedsCount: yup.string().required("Liczba łóżek jest wymagana"),
    price: yup.string().required("Cena jest wymagana"),
    availableFrom: yup.date().required("Data jest wymagana"),
    availableTo: yup.date().required("Data jest wymagana"),
    description: yup.string(),
  });
  const methods = useForm({
    resolver: yupResolver(accomodationSchema),
    defaultValues: {
      ...initialData,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods;

  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie utworzenia zdarzenia. Spróbuj ponownie później"
    );
  };
  const onSuccess = () => {
    toast.success("Utworzenie zdarzenia przebiegło pomyślnie");
  };

  const onErrorPut = () => {
    toast.error(
      "Wystąpił błąd przy próbie edycji zdarzenia. Spróbuj ponownie później."
    );
  };

  const onSuccessPut = () => {
    toast.success("Edycja zdarzenia przebiegła pomyślnie");
  };

  const { mutate, isLoading } = useAddAdvertisement();
  const { mutate: putMutate, isLoading: putIsLoading } = usePutAdvertisement();

  const onSubmitAdd = (data) => {
    mutate(data, { onSuccess, onError });
  };

  const onSubmitPut = (data) => {
    putMutate(data, { onSuccess: onSuccessPut, onError: onErrorPut });
  };

  return (
    <FormProvider {...methods}>
      <Card>
        <form
          onSubmit={handleSubmit(mode === "edit" ? onSubmitPut : onSubmitAdd)}
          className={"flex flex-col"}
        >
          <div>
            <Input
              register={register}
              error={errors.name?.message}
              name={"name"}
              label={"Nazwa zakwaterowania"}
              icon={AiFillHome}
            />
          </div>
          <div className={"w-full grid grid-cols-2 gap-4"}>
            <InputDatepicker
              className={"!w-full"}
              name={"availableFrom"}
              label={"Dostępne od"}
              placeholder="Dostępne od"
              // withTime
              required
            />
            <InputDatepicker
              className={"!w-full"}
              name={"availableTo"}
              label={"Dostępne do"}
              placeholder="Dostępne do"
              // withTime
              required
            />
            <Input
              register={register}
              error={errors.rooms?.message}
              name={"roomsCount"}
              label={"Liczba pokoi"}
              icon={IoBed}
            />
            <Input
              register={register}
              error={errors.price?.message}
              name={"price"}
              label={"Cena za dobę"}
              icon={MdOutlineAttachMoney}
            />
            <Input
              register={register}
              error={errors.bedOne?.message}
              name={"singlePersonBedsCount"}
              label={"Liczba łóżek jednoosobowych"}
              icon={IoBed}
            />

            <Input
              register={register}
              error={errors.bedTwo?.message}
              name={"doublePersonBedsCount"}
              label={"Liczba łóżek dwuosobowych"}
              icon={IoBed}
            />
          </div>
          <div className={"mt-4"}>
            <Textarea
              name={"description"}
              label={"Opis"}
              register={register}
              error={errors.description?.message}
              maxContent={1500}
              rows={1}
              onChange
            />
          </div>

          <div className={"ml-auto flex items-center space-x-4 mt-4"}>
            <CustomLink to={routes.vineyardProducts}>Wróć</CustomLink>
            {mode === "edit" ? (
              <Submit isLoading={putIsLoading}>Zapisz</Submit>
            ) : (
              <Submit isLoading={isLoading}>Dodaj</Submit>
            )}
          </div>
        </form>
      </Card>
    </FormProvider>
  );
};

export default AddAccomodation;
