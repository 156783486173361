import TemplateMain from "../../../templates/TemplateMain";
import FormForgotPassword from "../../../molecules/forms/FormForgotPassword";

const ViewForgotPassword = () => {
  return (
    <TemplateMain title={"Reset hasła"}>
      <div className={"flex justify-center my-8 md:my-16"}>
        <FormForgotPassword />
      </div>
    </TemplateMain>
  );
};

export default ViewForgotPassword;
