import axios from "../../mock/axios";
import { useSearchParams } from "react-router-dom";
import { BASE_URL_API } from "../../../config/env";
import useQuery2 from "../../../hooks/useQuery2";

const getWineAromas = () =>
    axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/wine-aromas`
    }).then(({data}) => data?.items)

    export const getWineAromasHelper = {
        queryKey: ['app-public.wineAromas'],
        queryFn: () => getWineAromas(),
        isArray: true
    }

    export const useGetWineAromas = () => {
        return useQuery2({
            queryKey: ['app-public.wineAromas'],
            queryFn: () => getWineAromas()
        });
    };