import React from 'react';
import { BiError } from 'react-icons/bi';

const MessageError = ({message, className}) => {
  if(!message)
    return null;
  return (
    <div className={`flex flex-row text-sm  text-red-700 ${className}`}>
      {message}
    </div>
  );
};

export default MessageError;
