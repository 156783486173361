import Submit from "../../atoms/ui/buttons/regular/Submit";
import Input from "../../atoms/ui/inputs/CustomInput";
import { FormProvider, useForm } from "react-hook-form";
import { useSignUpCustomer } from "../../../app/crud/auth/register";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "../../atoms/form-error/FormError";
import Checkbox from "../../atoms/ui/buttons/checkbox/Checkbox";
import CustomLink from "../../atoms/ui/buttons/links/Link";
import { routes } from "../../../app/router/routes";
import { RiArrowLeftSLine, RiLockPasswordLine } from "react-icons/ri";
import toast from "react-hot-toast";
import { MdAlternateEmail } from "react-icons/md";
import { InputCheckbox } from "../checkbox/Input_Checkbox";

const FormRegisterCustomer = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Podany adres e-mail jest niepoprawny")
      .required("Adres e-mail jest wymagany"),
    password: yup
      .string()
      .required("Hasło jest wymagane")
      .min(8, "Hasło musi zawierać minium 8 znaków")
      .max(32, "Hasło może posiadać maksymalnie 32 znaki."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Podane hasła muszą być takie same"),
    agreement: yup.boolean().required("Zgoda jest wymagana"),
    age: yup.boolean().required("Musisz potiwerdzić wiek"),
    checkAge: yup
      .boolean()
      .required("Musisz potwierdzić swój wiek")
      .default(false),
  });
  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie zarejestrowania. Spróbuj ponownie później."
    );
  };
  const onSuccess = () => {
    toast.success("Udało się pomyślnie zarejestrować.");
  };
  const statutes = (required) => {
    return (
      <span>
        Oświadczam, iż zapoznałem się z{" "}
        {
          <CustomLink className="contents" to={routes.regulations}>
            Regulaminem
          </CustomLink>
        }{" "}
        oraz{" "}
        {
          <CustomLink className="contents" to={routes.privacyPolicy}>
            Polityką prywatności
          </CustomLink>
        }
        , i je akceptuję.
        {required ? <span className="text-red-700">*</span> : null}
      </span>
    );
  };
  const age = (required) => {
    return (
      <span>
        Oświadczam, że mam 18 lat
        {required ? <span className="text-red-700">*</span> : null}
      </span>
    );
  };
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { mutate, isLoading, error } = useSignUpCustomer();
  const onSubmit = (data) => {
    mutate(data, { onSuccess, onError });
  };

  return (
    <FormProvider {...methods}>
      <form className={"w-full"} onSubmit={handleSubmit(onSubmit)}>
        <CustomLink className={"w-max flex items-center"} to={routes.register}>
          <RiArrowLeftSLine />
          Powrót
        </CustomLink>
        <div className="flex flex-col gap-4 text-gray-800 w-full mb-8">
          <Input
            required
            name={"email"}
            placeholder={"Email"}
            label={"Email"}
            register={register}
            error={errors.email?.message}
            icon={MdAlternateEmail}
          />
          <Input
            required
            type={"password"}
            placeholder={"Hasło"}
            name={"password"}
            label={"Hasło"}
            register={register}
            error={errors.password?.message}
            icon={RiLockPasswordLine}
          />
          <Input
            required
            type={"password"}
            placeholder={"Potwórz hasło"}
            name={"confirmPassword"}
            label={"Powtórz hasło"}
            register={register}
            error={errors.confirmPassword?.message}
            icon={RiLockPasswordLine}
          />
          <InputCheckbox label={statutes} name={"agreement"} required />
          <InputCheckbox label={age} name={"age"} required />
        </div>

        <FormError
          message={error && "Ten email jest już zajęty."}
          className={"mb-7 p-4 text-sm text-red-700 bg-red-100 rounded-lg"}
        />

        <Submit className={"flex w-full py-3"} isLoading={isLoading}>
          Zarejstruj się
        </Submit>
      </form>
    </FormProvider>
  );
};

export default FormRegisterCustomer;
