export const SummaryOrder = ({data}) => {
    return (
        <div className="mt-4 pr-12 w-96 h-48">
            <p className="text-xl text-gray-600 mb-2">Podsumowanie zamówienia:</p>
            <div className="flex justify-between">
                <span className="text-gray-600">Wartość koszyka: </span> {data?.totalOrderPriceGross} zł
            </div>
            {/* <div className="flex justify-between">
                <span className="text-gray-600">Wartość rabatów: </span> 20 zł
            </div>
            <div className="flex justify-between">
                <span className="text-gray-600">Koszt dostawy: </span> 20 zł
            </div> */}
            <div className="my-4 border"></div>
            <div className="flex justify-between">
                <span className="text-gray-600">Razem: </span> {data?.totalOrderPriceGross} zł
            </div>
        </div>
    )
}