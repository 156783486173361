export const findOption = (value, options) => {
    if (!value) return null
    let option = null
    const a = options?.forEach((item) => {
      if (option) return
      if (item?.options) findOption(value, item.options)
      if (item?.value?.toString() === value) option = item
    })
    return option
  }
  
  export const findMultipleOptions = (values, options) => {
    let selected = []
    const a = options?.forEach((item) => {
      if (item?.options) findOption(values, item.options)
      if (values?.map(({ value }) => value?.toString()).indexOf(item?.value?.toString()) !== -1)
        selected.push(item)
    })
    return selected
  }
  
  export const customStyles = ({ hasIcon = false, customFontSize = '0.875rem', error } = {}) => ({
    
    control: (provided, { isFocused }) => ({
      ...provided,
      boxShadow: isFocused ? '0 3px 10px 0 border-primary' : 'none',
      borderWidth: '1px !important',
      borderColor: error ? 'rgb(239 68 68)' : 'rgb(209 213 219)',
      outline: 'none',
      backgroundColor: error ? 'rgb(254 242 242)' : 'rgb(249 250 251)',
      height: '42px',
      borderRadius: '0.5rem'
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: hasIcon ? '0px' : provided?.paddingLeft,
      fontSize: customFontSize,
      
    }),
    placeholder: (provided, { isFocused }) => ({
      ...provided,
      paddingLeft: '0px',
      fontSize: '0.85rem',
      color: isFocused ? 'rgb(17 24 39)' : 'rgb(156 163 175)',
      transition: 'all 0.2s ease',
      color: error ? 'rgb(185 28 28)' : 'rgb(156 163 175)'
    }),
    input: (provided) => ({
      ...provided,
      paddingLeft: '0px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: '5',
    }),
    menuPortal: provided => ({
      ...provided,
      zIndex: '100'
    }),
  })
  