import TemplatePanel from "../../../templates/TemplatePanel";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import React, { useState } from "react";
import { passParams, routes } from "../../../../app/router/routes";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import Card from "../../../atoms/card/Card";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";

import { useGetContainer } from "../../../../app/crud/vineyard/containers/getContainer";
import ContainerDetails from "../../../organisms/container/ContainerDetails";
import toast from "react-hot-toast";
import ModalGenericConfirm from "../../../organisms/modals/ModalGenericConfirm";
import Button from "../../../atoms/ui/buttons/regular/Button";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { useDeleteContainer } from "../../../../app/crud/vineyard/containers/deleteContainer";
import ContainerEvents from "../../../organisms/container/ContainerEvents";
import { useGetEvents2 } from "../../../../app/crud/vineyard/containersEvent/getEvents2";
import {AiFillEdit} from 'react-icons/ai'
const ViewVineyardContainer = () => {
  const { data, isLoading } = useGetContainer();

  const [confirmDeleteProductModalOpen, setConfirmDeleteProductModalOpen] =
    useState(false);
  const { mutate: deleteContainer } = useDeleteContainer();
  const bc = [
    { label: "Zbiorniki", url: routes.vineyardContainers },
    {
      label: data?.name,
      url: passParams(routes.vineyardContainer, { id: data?.id }),
    },
  ];
  const tabConfig = [
    {
      label: "Zbiorniki",
      url: passParams(routes.vineyardContainer, { id: data?.id }),
    },
    {
      label: "Zdarzenia",
      url: passParams(routes.vineyardViewEventContainer, { id: data?.id }),
    },
  ];
  const onDeleteError = () => {
    toast.error(
      "Wystąpił błąd przy próbie usunięcia produkty. Spróbuj ponownie później."
    );
  };
  const onDeleteSuccess = () => {
    toast.success("Produkt został usunięty pomyślnie.");
  };
  const buttonsRenderer = () => {
    return (
      <div className={"flex space-x-4"}>
        <ModalGenericConfirm
          isOpen={confirmDeleteProductModalOpen}
          setOpen={setConfirmDeleteProductModalOpen}
          onConfirm={() =>
            deleteContainer(
              {},
              { onSuccess: onDeleteSuccess, onError: onDeleteError }
            )
          }
        >
          Czy na pewno chcesz usunąć produkt?
        </ModalGenericConfirm>

        <ButtonLink
          tooltip={"Edytuj zbiornik"}
          to={passParams(routes.vineyardEditContainer, { id: data?.id })}
          className={
            "text-white text-sm flex items-center bg-gold2 hover:bg-gold"
          }
        >
          <AiFillEdit size={'1.25rem'} />
          
        </ButtonLink>
        <Button
          tooltip={'Usuń zbiornik'}
          onClick={() => setConfirmDeleteProductModalOpen(true)}
          className={
            "text-white text-sm flex items-center bg-red-700 hover:bg-red-600"
          }
        >
          <AiOutlineDelete size={'1.25rem'} />
        </Button>
      </div>
    );
  };
  return (
    <TemplatePanel
      isLoading={isLoading}
      title={"Zbiornik"}
      sideMenuRenderer={() => <VineyardSideMenu />}
      actionButtonsRenderer={buttonsRenderer}
      tabsConfig={tabConfig}
      breadcrumbsRenderer={() => (
        <Breadcrumbs
          isLoading={isLoading}
          homeRoute={routes.vineyardDesktop}
          settings={bc}
        />
      )}
    >

          <Card >
            <ContainerDetails data={data} />
          </Card>

    </TemplatePanel>
  );
};

export default ViewVineyardContainer;
