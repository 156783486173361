import React from 'react';
import {Link} from "react-router-dom";
import {AiOutlineCheck} from "react-icons/ai";

const ProgressBarItem = ({item, id}) => {
    return (
        <Link to={item?.url} 
              className={`${item?.done ? '':'pointer-events-none'}   flex text-sm  ${item?.active ? `bg-bronze text-white` : 'text-gray-400'}
              justify-center items-center min-w-[24px] w-6 h-6 rounded-lg border border-gray-400`}>
            {item?.done ? <AiOutlineCheck/> : id}
        </Link>
    );
};

export default ProgressBarItem;
