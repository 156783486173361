import Image from '../../atoms/image/Image';
import Header1 from '../../molecules/headers/Header1';
import Header2 from '../../molecules/headers/Header2';
import Description from '../../molecules/description/Description';
import Card from '../../atoms/card/Card';
import Rate from '../../molecules/rate/Rate';
import Text from "../../molecules/text/Text";
import {MdEmail} from "react-icons/md"
import {AiFillPhone} from "react-icons/ai"
import { useMemo } from 'react';
import { IoEarthSharp } from 'react-icons/io5';
import Table from '../../atoms/Table/Table';

const VineyardDetails = ({data, isLoading}) => {
    const VineyardInfo = useMemo(
        () => [
          ["Email:", data?.emial],
          ["Nr telefonu:", data?.phoneNumber],
          ["Kraj pochodzenia winnicy:", data?.originCountryName],
          [
            "Adres",
            <div className="flex items-center gap-2" key={data?.id}>
              <span>
                {data?.address?.streetName} {data?.address?.houseNumber}{" "} {data?.address?.apartmentNumber ? `${data?.address?.apartmentNumber} ` : ""}
                {data?.address?.zipCode} {data?.address?.cityName}
              </span>
              {(
                data?.localizationDto?.latitude && data?.localizationDto?.longitude
              ) && (
                <a
                  href={`http://www.google.com/maps/place/${data?.localizationDto?.latitude},${data?.localizationDto?.longitude}`}
                  target="_blank"
                  rel="noreferrer"
                  className="ml-1 opacity-40"
                  title="Zobacz na mapie"
                >
                  <IoEarthSharp />
                </a>
              )}
            </div>,
          ],
          ["Rok załozenia winnicy:", data?.foundYear],

        ],
        [data]
      );

    if (!isLoading && data?.length === 0)
        return (
            <Card>
                Brak produktów.
            </Card>
        )
    return (
        <div className={'flex flex-col'}>

            <div className={'flex flex-col lg:flex-row lg:space-x-5 mt-4'}>
                <div className={'flex justify-center'}>
                    <Image
                        sizeClasses={'h-80 w-80 sm:w-96 sm:h-96'}
                        src={data?.profilePhotoUrl !== "default" ? data?.profilePhotoUrl : 'https://cdn1.iconfinder.com/data/icons/business-company-1/500/image-512.png'}
                        alt={"Zdjęcie profilowe winnicy"}
                        isLoading={isLoading}
                    />
                </div>
                <div className={'w-full'}>
                    <Header1 className={'text-center lg:text-left'} isLoading={isLoading}>
                        {data?.name}
                    </Header1>
                    <Header2 className={'text-center lg:text-left'} isLoading={isLoading}>
                        {data?.country}
                    </Header2>
                    <div className={'mt-2'}>
                        <Rate rate={data?.rate} isLoading={isLoading} opinions={data?.opinions}/>
                    </div>
                    <div className={'mt-2'}>
                      <Description className={'mt-4 text-sm w-full lg:text-base lg:mt-0'} isLoading={isLoading}>
                        {data?.descriptionShort}
                      </Description>
                    </div>
                    <div className={'mt-2'}>
                    <Table
                data={VineyardInfo}
                firstColumnStyles="text-gray-400"
                conditionColumn={1}
              />
                    </div>
                    <div className={'mt-4'}>
                        <Description className={'mt-4 text-sm w-full lg:text-base lg:mt-0'} isLoading={isLoading}>
                            {data?.descriptionLong}
                        </Description>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default VineyardDetails;
