import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useGetVineyardBlogs = () => {
  const [queryParams] = useSearchParams();
  const page = queryParams.get("page");
  const items = queryParams.get("items");
  return useQuery({
    queryKey: ["app-public.vineyard_blogs", page],
    queryFn: () =>
      axios({
        method: "GET",
        url: `${BASE_URL_API}/api/v1/admin/blog`,
        params: {
          PageNumber: page,
          PageSize: items,
        },
      }).then(({ data }) => {
        return data;
      }),
  });
};
