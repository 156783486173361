import React from 'react';
import ContactItem from "../../molecules/contact/ContactItem";
import {AiFillMail, AiFillPhone} from 'react-icons/ai';
import {RiMapPin2Fill} from 'react-icons/ri';
import FormCard from "../../atoms/card/FormCard";
import { HiOutlinePhone } from 'react-icons/hi';
import { MdAlternateEmail, MdOutlinePlace } from 'react-icons/md';
import Image from "../../atoms/image/Image";

const ContactInfo = () => {
    return (
        <div className={'w-max items-center justify-center'}>
                <div className={'text-bronze w-max h-full flex flex-col items-center mb-4'}>
                    <div className={'mb-auto space-y-2'}>
                        <ContactItem icon={()=><MdOutlinePlace size={20}/>}>Matuszczaka 14,<br/> 35-083 Rzeszów</ContactItem>
                        <ContactItem icon={()=><MdAlternateEmail size={20}/>}>info@winopasja.pl</ContactItem>
                    </div>
                    {/*<ContactItem icon={()=><HiOutlinePhone size={20}/>}>+48 123 456 789</ContactItem>*/}
                    <div className={'mt-auto'}>
                        <Image sizeClasses={'w-80 h-80'} src={'/png/map.png'}/>
                    </div>
                </div>
        </div>
    );
};

export default ContactInfo;
