import TemplateMain from '../../../templates/TemplateMain';
import TemplateSection from '../../../templates/TemplateSection';

const ViewRegulations = () => {
  return (
    <TemplateMain title={'koszyk'}>
      <TemplateSection>
        regulamin
      </TemplateSection>
    </TemplateMain>
  );
};

export default ViewRegulations;
