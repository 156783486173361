import TemplatePanel from "../../../templates/TemplatePanel";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import React from "react";
import {passParams, routes} from "../../../../app/router/routes";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import Card from "../../../atoms/card/Card";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import {useGetContainers} from "../../../../app/crud/vineyard/containers/getContainers";
import CustomDataTable from "../../../organisms/customTable/CustomDataTable";

import usePagination from "../../../../app/hooks/usePagination";
import { AiOutlinePlus } from "react-icons/ai";

const ViewVineyardContainers = () => {
    const bc = [{label: "Zbiorniki", url: routes.vineyardContainers}];
    const {data, isLoading, refetch} = useGetContainers()
    const {onPageChange, currentPage, totalPages, rowsPerPage, setTotalCount, setTotalPages} = usePagination(refetch);
    const columns = [
        {name: 'Kod', cell: row => <p className={'text-s  mr-4'}>{row?.code}</p>},
        {name: 'Nazwa zbiornika', cell: row => <p className={'text-s  mr-4'}>{row?.name}</p>},
        {name: 'Pojemność [l]', cell: row => <p className={'text-s  mr-4'}>{row?.capacity}</p>},

    ];
    return (
        <TemplatePanel
            title={"Zbiorniki"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            actionButtonsRenderer={() => <ButtonLink to={routes.vineyardAddContainer} tooltip={'Dodaj zbiornik'} className={'text-white text-sm flex items-center bg-green-700 hover:bg-green-600"'}><AiOutlinePlus size={'1.25rem'}/></ButtonLink>}
            breadcrumbsRenderer={() => (
                <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>
            )}
        >
            <Card className={'w-full h-max'}>
                <CustomDataTable
                    onRowClickRedirect={(row) => passParams(routes.vineyardContainer, {id: row?.id})}
                    columns={columns}
                    isLoading={isLoading}
                    data={data?.items}
                    pagination={{
                        currentPage: currentPage,
                        totalPages: totalPages,
                        rowsPerPage: rowsPerPage,
                        onPageChange: onPageChange
                    }}
                />
            </Card>
        </TemplatePanel>
    );
};

export default ViewVineyardContainers;
