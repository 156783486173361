import React from "react";
import Input from "../../atoms/ui/inputs/CustomInput";
import Submit from "../../atoms/ui/buttons/regular/Submit";
import Textarea from "../../atoms/ui/textarea/Textarea";
import { useForm } from "react-hook-form";
import { useContact } from "../../../app/crud/app/contact";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone";
import FormError from "../../atoms/form-error/FormError";
import { validChars } from "../../atoms/ui/textarea/validCharsRegex";
import { HiOutlinePhone } from "react-icons/hi";
import { InputPhoneNumber } from "../../atoms/ui/inputs/InputPhoneNumber";
import { MdAlternateEmail, MdOutlineTopic } from "react-icons/md";
import toast from "react-hot-toast";
import {AiOutlineInfoCircle} from "react-icons/ai";

const FormContact = () => {
  const schema = yup.object().shape({
    topic: yup.string().required("Temat wiadomości jest wymagany."),
    email: yup
      .string()
      .email("Podaj poprawny adres e-mail")
      .required("Podaj e-mail"),
    phone: yup.string().nullable(),
    message: yup
      .string()
      .max(1000, "Wiadomość może zawierać max. 1000 znaków")
      .required("Treść wiadomości jest wymagana")
      .matches(validChars, "Treść wiadomości zawiera niedozwolone znaki")
      .trim(),
  });

  const handleSucess = () => {
    toast.success("Hasło zostało zmienione pomyślnie.");
  };
  const handleError = () => {
    toast.error(
      "Wystąpił błąd przy próbie resetu hasła. Spróbuj ponownie później."
    );
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { mutate, isLoading, error } = useContact();
  const onSubmit = (data) => {
    mutate(data, {handleSucess, handleError});
  };

  return (
    <>
      <FormError message={error && "Wystąpił błąd."} className={"mb-2"} />
      <form
        className={"flex flex-col space-y-5 w-full"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name={"email"}
          placeholder={"Email"}
          label={"Email"}
          required
          register={register}
          error={errors.email?.message}
          icon={MdAlternateEmail}
        />
        <Input
          name={"topic"}
          placeholder={"Temat"}
          label={"Temat"}
          required
          register={register}
          error={errors.topic?.message}
          icon={MdOutlineTopic}
        />
        {/*<InputPhoneNumber*/}
        {/*  name={"phone"}*/}
        {/*  placeholder={"Telefon"}*/}
        {/*  label={"Telefon"}*/}
        {/*  register={register}*/}
        {/*  error={errors.phone?.message}*/}
        {/*  icon={HiOutlinePhone}*/}
        {/*/>*/}
        <Textarea
          name={"message"}
          placeholder={"Wiadomość"}
          label={"Wiadomość"}
          required
          register={register}
          error={errors.message?.message}
          maxContent={1000}
          rows={1}
        />
        <p className={'flex items-center w-full justify-center italic text-sm mt-4 text-center'}>
          <AiOutlineInfoCircle className={'mr-1'}/>
          Funkcjonalność chwilowo nie jest dostępna.
        </p>
        <Submit disabled isLoading={isLoading}>Wyślij</Submit>
      </form>
    </>
  );
};

export default FormContact;
