import { useParams, useSearchParams } from "react-router-dom";
import axios from "../../mock/axios";
import { BASE_URL_API } from "../../../config/env";
import { useQuery } from "react-query";


export const useGetEvent = () => {
    const {eventId, id} = useParams()
    const [queryParams] = useSearchParams();
    const page = queryParams.get('page');
    const items = queryParams.get('items');
    return useQuery({
        queryKey: ['api.public.containers.event', page],
        queryFn:  () => {
            return axios({
            method: 'GET',
            url: `${BASE_URL_API}/api/v1/admin/vineyard/containers/${id}/events/${eventId}`,
            params: {
                PageNumber: page,
                PageSize: items
            }
        }).then(({data}) => {return data})}
    })
}