import { useDispatch } from 'react-redux';
import { actionAddToCart } from '../redux/cartReducer/cartActionTypes';

const useCart = () => {
  const dispatch = useDispatch();

  const addToCart = (data, quantity) => {
    dispatch(actionAddToCart({ product: data, quantity }));
  };

  return { addToCart };
};

export default useCart;
