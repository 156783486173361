import CustomDataTable from '../../../organisms/customTable/CustomDataTable';
import {useVineyardOrders} from '../../../../app/crud/vineyard/order/getOrders';
import Price from '../../../molecules/price/Price';
import usePagination from '../../../../app/hooks/usePagination';
import Badge from '../../../atoms/badge/Badge';
import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import React, {useEffect} from 'react';
import {passParams, routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import Card from '../../../atoms/card/Card';

const ViewVineyardOrders = () => {

    const {data, isLoading} = useVineyardOrders();
    const {onPageChange, currentPage, totalPages, rowsPerPage, setTotalPages, setTotalCount} = usePagination();
    useEffect(() => {
        setTotalPages(data?.totalPages);
        setTotalCount(data?.totalCount);
    }, [data])

    const bc = [{label: 'Zamówienia', url: routes.vineyardOrders}]
    const columns = [
        {name: 'Numer zamówienia', cell: row => <p className={'text-xs  uppercase mr-4'}>{row?.id}</p>},
        {name: 'Data', cell: row => <p className={'text-xs uppercase mr-4'}>{new Date(row.createdDate).toLocaleDateString()}</p>},
        {name: 'Cena', cell: row => <Price className={'text-xs uppercase mr-4'} value={row.totalOrderPriceGross}/>},
        {name: 'Status', cell: row => <Badge bgClass={row.orderStatus?.name}>{row?.orderStatus?.name}</Badge>}
    ];

    return (
        <TemplatePanel
            title={"Zamówienia"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
            isLoading={isLoading}
        >
            <Card className={'w-full h-max'}>
                <CustomDataTable
                    onRowClickRedirect={(row) => passParams(routes.vineyardOrder, {id: row?.id})}
                    columns={columns}
                    emptyText={"Nie masz jeszcze żadnych zamówień."}
                    isLoading={isLoading}
                    data={data?.items}
                    pagination={{
                        currentPage: currentPage,
                        totalPages: totalPages,
                        rowsPerPage: rowsPerPage,
                        onPageChange: onPageChange
                    }}
                />
            </Card>
        </TemplatePanel>
    );
};

export default ViewVineyardOrders;
