import TemplatePanel from '../../templates/TemplatePanel';
import CustomerSideMenu from '../../organisms/side-menu/CustomerSideMenu';
import {passParams, routes} from '../../../app/router/routes';
import Breadcrumbs from '../../organisms/breadcrumbs/Breadcrumbs';
import React, {useEffect} from 'react';
import {useGetCustomerOrders} from "../../../app/crud/customer/getCustomerOrders";
import CustomDataTable from "../../organisms/customTable/CustomDataTable";
import Card from "../../atoms/card/Card";
import Price from "../../molecules/price/Price";
import Badge from "../../atoms/badge/Badge";
import usePagination from "../../../app/hooks/usePagination";
import BadgeOrderStatus from '../../atoms/badge/BadgeOrderStatus';

const ViewCustomerOrders = () => {
    const {data, isLoading} = useGetCustomerOrders()
    console.log(data)
    const {onPageChange, currentPage, totalPages, rowsPerPage, setTotalPages, setTotalCount} = usePagination();
    useEffect(() => {
        setTotalPages(data?.totalPages);
        setTotalCount(data?.totalCount);
    }, [data])
    const bc = [{label: 'Moje zamówienia', url: routes.customerOrders}];
    const columns = [
        {name: 'Data', cell: row => <p className={'text-xs uppercase mr-4'}>{new Date(row?.createdDate).toLocaleDateString()}</p>},
        {name: 'Koszt', cell: row => <Price className={'text-xs uppercase mr-4'} value={row?.totalOrderPriceGross}/>},
        {name: 'Status', cell: row => <p className={'text-xs uppercase mr-4'}><BadgeOrderStatus bgClass={row?.orderStatus?.id}>{row?.orderStatus?.name}</BadgeOrderStatus></p>}
    ];
    return (
        <TemplatePanel
            title={"Zamówienia"}
            sideMenuRenderer={() => <CustomerSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.customerData} settings={bc}/>}
        >
            <Card className={'w-full h-max'}>
                <CustomDataTable
                    onRowClickRedirect={(row) => passParams(routes.customerOrder, {id: row?.id})}
                    columns={columns}
                    emptyText={"Nie masz jeszcze żadnych zamówień."}
                    isLoading={isLoading}
                    data={data?.items}
                    pagination={{
                        currentPage: currentPage,
                        totalPages: totalPages,
                        rowsPerPage: rowsPerPage,
                        onPageChange: onPageChange
                    }}
                />
            </Card>
        </TemplatePanel>
    );
};

export default ViewCustomerOrders;
