import React from 'react';
import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import {passParams, routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import {useGetVineyardProduct} from '../../../../app/crud/vineyard/wine/getWine';
import FormCreateProduct from "../../../organisms/forms/panel-vineyard/create-wine/FormCreateProduct";

const ViewVineyardEditProduct = () => {
    const {data, isLoading} = useGetVineyardProduct();
    const bc = [
        {label: 'Wina', url: routes.vineyardProducts},
        {label: data?.name, url: passParams(routes.vineyardProduct, {id: data?.id})},
        {label: 'Edytuj', url: passParams(routes.vineyardEditProduct, {id: data?.id})}
    ];

    return (
        <TemplatePanel
            isLoading={isLoading}
            title={"Edytuj produkt"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs isLoading={isLoading} homeRoute={routes.vineyardDesktop}
                                                    settings={bc}/>}
        >
            <FormCreateProduct mode={'edit'} initialData={data}/>
        </TemplatePanel>
    );
};

export default ViewVineyardEditProduct;
