import React from 'react';
import { actionAuthLogout } from '../../../app/redux/authReducer/authActionTypes';
import NavLink from '../ui/buttons/links/NavLink';
import { routes } from '../../../app/router/routes';
import { FaSignOutAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

const LogoutLink = () => {
  const dispatch = useDispatch();

  return (
    <div onClick={() => dispatch(actionAuthLogout())}>
      <NavLink disableAnimation className={'flex flex-row items-center'} to={routes.login}><FaSignOutAlt className={'mr-2'} />Wyloguj</NavLink>
    </div>
  );
};

export default LogoutLink;
