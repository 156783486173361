import TemplatePanel from '../../../templates/TemplatePanel';
import VineyardSideMenu from '../../../organisms/side-menu/VineyardSideMenu';
import React from 'react';
import {routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import AdvertisementsTable from "../../../organisms/enoturism/AdvertisementsTable";
import { AiOutlinePlus } from 'react-icons/ai';

const ViewVineyardEnoturism = () => {
    const bc = [{label: 'Enoturystyka', url: routes.vineyardPanelEnoturism}];
    const tabConfig=[
        {label: 'Noclegi', url: routes.vineyardPanelEnoturism},
        {label: 'Wydarzenia', url: routes.vineyardPanelEnoturismEvent},
    ];
    return (
        <TemplatePanel
            actionButtonsRenderer={() => <ButtonLink to={routes.vineyardPanelEnoturismAdd} className={'text-white text-sm flex items-center bg-green-700 hover:bg-green-600'} tooltip={'Dodaj nocleg'}><AiOutlinePlus size={'1.25rem'}/></ButtonLink>}
            tabsConfig={tabConfig}
            title={"Enoturystyka"}
            sideMenuRenderer={() => <VineyardSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc}/>}
        >
            <AdvertisementsTable/>
        </TemplatePanel>
    );
};

export default ViewVineyardEnoturism;
