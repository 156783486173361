// import delivery from '../../../app/assets/svg/delivery.svg'

import { BiSupport } from "react-icons/bi"
import { FaGraduationCap, FaWineGlassAlt } from "react-icons/fa"
import { FiShoppingCart } from "react-icons/fi"
import { GiCellarBarrels } from "react-icons/gi"
import { TbTruckDelivery } from "react-icons/tb"

const Features = () => {
    return (<div className=" py-6 sm:py-8 lg:py-12">
    <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">

      <div className="mb-10 md:mb-16">
        <h2 className="text-bronze text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">WINO PASJA – SKLEP WINIARSKI DLA KONESERÓW</h2>
  
        <p className="max-w-screen-md text-bronze md:text-xl text-center mx-auto">Jeśli kochasz wino i chcesz lepiej poznać kulturę winiarską, to jesteś w odpowiednim miejscu.</p>
      </div>

  
      <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-12 xl:gap-16">
        <div className="flex flex-col items-center">
          <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center text-gold mb-2 sm:mb-4">
            <FaWineGlassAlt size={'3.5rem'}/>
          </div>
  
          <h3 className="text-bronze text-lg md:text-xl font-semibold text-center mb-2">Wino łączy ludzi</h3>
          <p className="text-bronze text-center mb-2">Wino to więcej niż napój – to styl życia dla milionów ludzi na świecie</p>

        </div>

        <div className="flex flex-col items-center">
          <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center text-gold mb-2 sm:mb-4">
          <GiCellarBarrels size={'3.5rem'}/>
          </div>
  
          <h3 className="text-bronze text-lg md:text-xl font-semibold text-center mb-2">O naszych winach</h3>
          <p className="text-bronze text-center mb-2">Każdą nową markę wina kierowaną do sprzedaży wielokrotnie sprawdzamy i degustujemy</p>
         
        </div>

        <div className="flex flex-col items-center">
          <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center text-gold mb-2 sm:mb-4">
            <FiShoppingCart size={'3.5rem'}/>
          </div>
  
          <h3 className="text-bronze text-lg md:text-xl font-semibold text-center mb-2">Dystrybuja i sklepy</h3>
          <p className="text-bronze text-center mb-2">Dostarczą je nasi handlowcy. Klienci indywidualni, restauracje oraz sklepy mogą bezpośrednio korzystać z naszej oferty</p>

        </div>

        <div className="flex flex-col items-center">
          <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center text-gold mb-2 sm:mb-4">
            <FaGraduationCap size={'3.5rem'}/>
          </div>
  
          <h3 className="text-bronze text-lg md:text-xl font-semibold text-center mb-2">Edukcja winiarska</h3>
          <p className="text-bronze text-center mb-2">Aby w pełni docenić barwę, zapach i smak wina, trzeba o nim coś wiedzieć</p>

        </div>

        <div className="flex flex-col items-center">
          <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center text-gold mb-2 sm:mb-4">
            <BiSupport size={'3.5rem'}/>
          </div>
  
          <h3 className="text-bronze text-lg md:text-xl font-semibold text-center mb-2">Wsparcie</h3>
          <p className="text-bronze text-center mb-2">Nasz zespół wsparcia jest zawsze do dyspozycji</p>

        </div>

        <div className="flex flex-col items-center">
          <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center text-gold mb-2 sm:mb-4">
            <TbTruckDelivery size={'3.5rem'} />
          </div>
  
          <h3 className="text-bronze text-lg md:text-xl font-semibold text-center mb-2">Dostawa</h3>
          <p className="text-bronze text-center mb-2">Staranna dostawa prosto do Twoich drzwi</p>

        </div>

      </div>
    </div>
  </div>)
}

export default Features