import React from 'react';
import PortalWrapper from "../../atoms/portal-wrapper/PortalWrapper";
import Button from "../../atoms/ui/buttons/regular/Button";
import Card from "../../atoms/card/Card";

const ModalGenericConfirm = ({isOpen, setOpen, children, onConfirm}) => {
    return (
        <PortalWrapper
            show={isOpen}
            onBackdropClick={() => setOpen(false)}
        >
            <Card className='p-10'>
                <div>
                    {children}
                </div>
                <div className={'w-full flex justify-end space-x-4 mt-10'}>
                    <button className={'text-sm text-gold2'} onClick={()=>setOpen(false)}>Anuluj</button>
                    <Button className={'text-sm bg-red-600 hover:bg-red-800'} onClick={()=> {
                        onConfirm();
                        setOpen(false);
                    }}>Potwierdź</Button>
                </div>
            </Card>
        </PortalWrapper>

    );
};

export default ModalGenericConfirm;
