import axios from "../../config/axios";
import { BASE_URL_API } from "../../config/env";
import { useMutation } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionAuthLoginWithEmail } from "../../redux/authReducer/authActionTypes";
import { routes } from "../../router/routes";

export const useSignUpCustomer = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();

  return useMutation((data) => {
    return axios
      .post(`${BASE_URL_API}/api/v1/account/sign-up`, data)
      .then((res) => {
        const redux = { token: res?.data?.accessToken, role: res?.data?.roles };
        dispatch(actionAuthLoginWithEmail(redux));
        return res;
      })
      .then(({ data }) => {
        naviagte(routes.customerOrders);
        return data;
      });
  });
};

export const useSignUpVineyard = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();

  return useMutation((data) => {
    return axios
      .post(`${BASE_URL_API}/api/v1/account/sign-up-vineyard`, data)
      .then((res) => {
        const redux = { token: res?.data?.accessToken, role: res?.data?.roles };
        dispatch(actionAuthLoginWithEmail(redux));
        return res;
      })
      .then((res) => {
        naviagte(routes.vineyardDesktop);
        return res;
      });
  });
};

export const useSignUpRestaurant = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();

  return useMutation((data) => {
    return axios
      .post(`${BASE_URL_API}/api/v1/account/sign-up-restaurant`, data)
      .then((res) => {
        const redux = { token: res?.data?.accessToken, role: res?.data?.roles };
        dispatch(actionAuthLoginWithEmail(redux));
        return res;
      })
      .then((res) => {
        naviagte(routes.vineyardDesktop);
        return res;
      });
  });
};

export const useSignUpImporter = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();

  return useMutation((data) => {
    return axios
      .post(`${BASE_URL_API}/api/v1/account/sign-up-importer`, data)
      .then((res) => {
        const redux = { token: res?.data?.accessToken, role: res?.data?.roles };
        dispatch(actionAuthLoginWithEmail(redux));
        return res;
      }).then((res) => {
        naviagte(routes.vineyardDesktop)
        return res;
      });
  });
};
