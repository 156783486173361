export const TYPE_ADD = 'cart.add';
export const TYPE_CLEAR = 'cart.clear';
export const TYPE_DELETE = 'cart.delete';
export const TYPE_SET_QUANTITY = 'cart.set_quantity';
export const TYPE_SET_CART_FROM_SERVER = 'cart.set_cart_from_server';

export const actionAddToCart = ({ product, quantity }) => ({
  type: TYPE_ADD,
  payload: { product, quantity }
});

export const actionClearCart = () => ({
  type: TYPE_CLEAR,
});

export const actionRemoveFromCart = (product) => ({
  type: TYPE_DELETE,
  payload: product
});

export const actionSetCartProductQuantity = (product, quantity) => ({
  type: TYPE_SET_QUANTITY,
  payload: { product, quantity }
});

export const actionSetCartFromServer = (cart) => ({
  type: TYPE_SET_CART_FROM_SERVER,
  payload: { cart }
});