import React from 'react';
import Card from '../../../atoms/card/Card';
import CustomDataTable from "../../../organisms/customTable/CustomDataTable";
import {passParams, routes} from "../../../../app/router/routes";
import Price from "../../price/Price";

const OrderProducts = ({data, isLoading}) => {
    const columns = [
        {name: 'Nazwa', cell: row => <p className={'text-xs uppercase mr-4'} >{row?.wineProductName}</p>},
        {name: 'Koszt 1 szt', cell: row => <Price className={'text-xs uppercase mr-4'} value={row?.priceGross}/>},
        {name: 'Ilość', cell: row => <p className={'text-xs uppercase mr-4'} >{row?.quantity}</p>},
        {name: 'Stan magazynowy', cell: row => <p className={'text-xs uppercase mr-4'} >{999}</p>},
    ];
    return (
        <Card title={"Produkty"} className={'w-full h-max'}>
            <CustomDataTable
                columns={columns}
                emptyText={"Nie masz jeszcze żadnych zamówień."}
                isLoading={isLoading}
                data={data}
            />
        </Card>
    );
};

export default OrderProducts;
