import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

export const usePutBlog = () => {
  const { id } = useParams();
  return useMutation((data) => {
    return axios({
      method: "PUT",
      url: `${BASE_URL_API}/api/v1/admin/blog/${id}`,
      data,
    }).then(({ data }) => data);
  });
};
