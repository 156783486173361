import fruit from "../../../app/assets/svg/blueberries.png";
const ForWhat = () => {
  return (
    <div className=" py-6 sm:py-8 lg:py-12">
      <div className="max-w-screen-xl px-4 md:px-8 mx-auto">
        <div>
          <p className="text-gray-800 mb-4 text-xl lg:text-xl ">
          NAJLEPIEJ PODAWAĆ DO
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-8 ">
          <div className="flex flex-col items-center  rounded-lg">
            <div className="w-24 md:w-24 h-24 md:h-24 flex justify-center items-center  rounded-full overflow-hidden ">
              <img
                src={fruit}
                loading="lazy"
                alt="Aromaty"
                className="flex justify-center items-center"
              />
            </div>

            <div>
              <div className="text-gray-900 md:text-lg font-bold text-center">
                Jagoda
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center  rounded-lg">
            <div className="w-24 md:w-24 h-24 md:h-24 flex justify-center items-center  rounded-full overflow-hidden ">
              <img
                src={fruit}
                loading="lazy"
                alt="Aromaty"
                className="flex justify-center items-center"
              />
            </div>

            <div>
              <div className="text-gray-900 md:text-lg font-bold text-center">
                Jagoda
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center  rounded-lg">
            <div className="w-24 md:w-24 h-24 md:h-24 flex justify-center items-center  rounded-full overflow-hidden ">
              <img
                src={fruit}
                loading="lazy"
                alt="Aromaty"
                className="flex justify-center items-center"
              />
            </div>

            <div>
              <div className="text-gray-900 md:text-lg font-bold text-center">
                Jagoda
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center  rounded-lg">
            <div className="w-24 md:w-24 h-24 md:h-24 flex justify-center items-center  rounded-full overflow-hidden ">
              <img
                src={fruit}
                loading="lazy"
                alt="Aromaty"
                className="flex justify-center items-center"
              />
            </div>

            <div>
              <div className="text-gray-900 md:text-lg font-bold text-center">
                Jagoda
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForWhat;
