import Input from "./CustomInput";
import { forwardRef, useMemo } from "react";
import ControlledInputMask from "./ControlledInputMaskPhone";

const InputPhone = forwardRef(({ length, ...props }, ref) => {
  const mask = useMemo(() => {
    return new Array(length).fill("+48 hhh hhh hhh").join(" ");
  }, [length]);

  return (
    <ControlledInputMask
      mask={mask}
      ref={ref}
      {...props}
    />
  );
});

export const InputPhoneNumber = ({
  name,
  placeholder,
  label,
  length = 1,
  ...props
}) => {
  return (
    <Input
      component={InputPhone}
      {...{ name, placeholder, label, length }}
      transformValue={(value) => (value ?? "").toUpperCase()}
      {...props}
      {...(props?.register ? props?.register(name, {required: props?.required}) : {})}
    />
  );
};
