import React from 'react';

const MobileNavSeparator = () => {
  return (
    <div className={'border-b w-full'}/>

  );
};

export default MobileNavSeparator;
