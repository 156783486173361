import React, { useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { Controller } from "react-hook-form";


const config = {
  language: "pl",
  limitHTML: true,
  buttons:
    "|,bold,strikethrough,underline,italic,eraser,|,,ul,ol,,outdent,indent,,|,link,,align,undo,redo,,,,,|,hr,fullsize",
  uploader: {
    url: "none",
    insertImageAsBase64URI: false,
  },
  defaultActionOnPaste: "insert_only_text ",
  defaultActionOnPasteFromWord: "insert_only_text ",
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  enableDragAndDropFileToEditor: false,
  disabledPlugins: ["image"],
};
const TextEditor = ({ control, name, label, placeholder, error, required }) => {
  const editor = useRef(null);

  return (
    <>
      <div className={"relative w-full my-1"}>
        <div className="flex justify-between items-center gap-2">
          <div className={"text-sm mb-1"}>
            {label} {required && <span className="text-red-600">*</span>}
          </div>
        </div>
        <Controller
          render={({ field }) => (
            <JoditEditor
              placeholder={placeholder || label}
              className={`box-border bg-gray-50 text-sm w-full rounded-lg border shadow-sm px-2.5 py-2 ${
                error
                  ? "border-red-500 bg-red-50 placeholder-red-700"
                  : "border-gray-300"
              }`}
              ref={editor}
              config={config}
              tabIndex={1}
              {...field}
            />
          )}
          name={name}
          control={control}
          defaultValue=""
        />
        {error && (
          <div className={`text-sm absolute top-full left-0 text-red-800 `}>
            {error}
          </div>
        )}
      </div>
    </>
  );
};

export default TextEditor;
