import PropTypes from 'prop-types';

const Card = ({ children, title, noPadding, className, icon }) => {

  return (
    <div className={`shadow-md md:mt-0 mt-6 bg-white rounded-lg ${!noPadding && 'p-4'} ${className}`}>
        {title && <h1 className={'flex items-center font-bold text-xl mb-4 '}>{title}{icon && icon()}</h1>}
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  noPadding: PropTypes.bool,
  className: PropTypes.string
};

export default Card;
