import React from 'react';
import NavLink from '../../atoms/ui/buttons/links/NavLink';
import { routes } from '../../../app/router/routes';
import { FaShoppingCart, FaSignOutAlt, FaUser } from 'react-icons/fa';
import LanguageChanger from '../language-changer/LanguageChanger';
import { useSelectRole, useSelectToken } from '../../../app/redux/authReducer/authSelectors';
import CartLink from '../../atoms/header-parts/CartLink';
import LogoutLink from '../../atoms/header-parts/LogoutLink';
import VineyardAccountLink from '../../atoms/header-parts/VineyardAccoutLink';
import CustomerAccountLink from '../../atoms/header-parts/CustomerAccoutLink';
import LoginLink from '../../atoms/header-parts/LoginLink';

const LoginCartLang = () => {
  const token = useSelectToken();
  const role = useSelectRole();
  const isOnUserPanel = () => {
    const pathname = window.location.pathname.split('/');
    return pathname.includes(routes.customerPanel);
  };

  return (
    <div className={'flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4'}>
      <CartLink />
      {
        token && isOnUserPanel() ? <LogoutLink /> :
          token && role.includes('VineyardAdmin') && !isOnUserPanel() ? <VineyardAccountLink /> :
            token && !role.includes('VineyardAdmin') && !isOnUserPanel() ? <CustomerAccountLink /> :
              <LoginLink />
      }
      {/* <LanguageChanger /> */}
    </div>
  );
};

export default LoginCartLang;
