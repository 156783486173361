import axios from '../../config/axios';
import {BASE_URL_API} from '../../config/env';
import {useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";

export const useGetVineyardWines = () => {
    const [queryParams] = useSearchParams()
    const {vineyardId} = useParams();
    const page = queryParams.get("page");
    const items = queryParams.get("items")
    return useQuery({
        queryKey: ['app-public.vineyardWines', page],
        queryFn: () => axios({
            method: 'GET',
            url: `${BASE_URL_API}/api/v1/vineyards/${vineyardId}/wine-products`,
            params: {
                PageNumber: page,
                PageSize: items
            }
        }).then(({data}) => {
            return data
        }),
        config: {refetchOnWindowFocus: false}
    });
}
