import TemplatePanel from '../../templates/TemplatePanel';
import CustomerSideMenu from '../../organisms/side-menu/CustomerSideMenu';
import {routes} from '../../../app/router/routes';
import Breadcrumbs from '../../organisms/breadcrumbs/Breadcrumbs';
import React from 'react';

const ViewCustomerInvoices = () => {
    const bc = [{label: 'Moje faktury', url: routes.customerInvoices}];

    return (
        <TemplatePanel
            title={"Faktury"}
            sideMenuRenderer={() => <CustomerSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.customerData} settings={bc}/>}
        >

        </TemplatePanel>
    );
};

export default ViewCustomerInvoices;
