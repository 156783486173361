import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../router/routes";

export const useAddErorr = () => {
  const navigate = useNavigate();
  return useMutation((data) => {
    return axios
      .post(`${BASE_URL_API}/api/v1/admin/vineyard/send-message`, data)
      .then(({ data }) => {
        navigate(routes.vineyardErrors);
        return data;
      });
  });
};
