import axios from '../../../config/axios'
import { BASE_URL_API } from '../../../config/env'
import useQuery2 from '../../../hooks/useQuery2'
import { useParams } from 'react-router-dom'

export const getMessage = (id) =>
    axios({
        methd: 'GET',
        url: `${BASE_URL_API}/api/v1/admin/vineyard/messages/${id}`
    }).then(({data}) => data);

export const useGetMessage = () => {
    const {id} = useParams();
    return useQuery2({
        queryKey: ['app-public.message', id],
        queryFn: getMessage
    })
}