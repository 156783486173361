import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import useQuery2 from "../../../hooks/useQuery2";
import { useParams } from "react-router-dom";

const getAdvertisement = ([id]) => {
  return axios
    .get(`${BASE_URL_API}/api/v1/admin/vineyard/advertisements/${id}`)
    .then(({ data }) => data);
};

export const useGetVineyardAdvertisement = () => {
  const { id } = useParams();
  return useQuery2({
    queryKey: ["app-public.vineyard_advertisement" + id, id],
    queryFn: getAdvertisement,
  });
};
