import TemplateMain from "../../../templates/TemplateMain";
import TemplateSection from "../../../templates/TemplateSection";
import ProductDescription from "../../../organisms/product-details/ProductDescription";
import ProductDetails from "../../../organisms/product-details/ProductDetails";
import { useGetWine } from "../../../../app/crud/app/wine";
import useCart from "../../../../app/hooks/useCart";
import Button from "../../../atoms/ui/buttons/regular/Button";
import { FaCartPlus } from "react-icons/fa";
import ProductList from "../../../organisms/product-list/ProductList";
import { passParams, routes } from "../../../../app/router/routes";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import React from "react";
import Image from "../../../atoms/image/Image";
import { useAddToCart } from "../../../../app/crud/app/cart/postProduct";
import toast from "react-hot-toast";
import Cookies from "universal-cookie";
import { useGetVineyardWines } from "../../../../app/crud/app/vineyardWines";
import { ProductComments } from "../../../organisms/comments/Comments";
import { useGetRatings } from "../../../../app/crud/customer/ratings/getRatings";

const ViewProduct = () => {
  const cookies = new Cookies();
  const { data, isLoading } = useGetWine();
  const { data: dataRatings, isLoading: isLoadingRatings } = useGetRatings();
  const { data: wineList, isLoading: isWinesLoading } = useGetVineyardWines();
  const bc = [
    { label: "Winnice", url: routes.vineyards },
    {
      label: data?.vineyardName,
      url: passParams(routes.vineyard, { vineyardId: data?.vineyardId }),
    },
    {
      label: data?.name,
      url: passParams(routes.product, {
        vineyardId: data?.vineyardId,
        wineId: data?.id,
      }),
    },
  ];
  const { addToCart } = useCart();
  const { mutate: addToServerSideCart, isLoading: isFetching } = useAddToCart();

  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie dodania produktu. Spróbuj ponownie później."
    );
  };
  const onSuccess = () => {
    toast.success("Produkt został dodany do koszyka.");
  };

  const renderAddToCart = () => {
    return (
      <Button
        disabled={isLoading}
        onClick={() => {
          addToCart(data, 1);
          addToServerSideCart(
            {
              productId: data?.id,
              quantity: 1,
              guestGuid: cookies.get("guestGuid"),
            },
            { onSuccess, onError }
          );
        }}
        className={
          "min-w-max flex flex-row justify-center items-center bg-gold2 hover:bg-gold"
        }
      >
        <FaCartPlus className={"mr-2"} />
        Dodaj do koszyka
      </Button>
    );
  };

  const imageRenderer = () => {
    return (
      <Image
        sizeClasses={"h-80 md:h-96 w-80 md:w-96"}
        src={
          data?.imageUrl ||
          "https://www.vinowineshopnc.com/wp-content/uploads/2015/03/burgundy_wine_bottle_placeholder-300x300.jpg"
        }
        alt={data?.imageAlt || "Zdjęcie wina"}
        isLoading={isLoading}
      />
    );
  };
  return (
    <TemplateMain
      title={data?.name}
      breadcrumbsRenderer={() => (
        <Breadcrumbs
          isLoading={isLoading}
          homeRoute={routes.home}
          settings={bc}
        />
      )}
    >
      <TemplateSection>
        <ProductDescription
          data={data}
          isLoading={isLoading}
          buttonRenderer={renderAddToCart}
          imageRenderer={imageRenderer}
        />
      </TemplateSection>

      <TemplateSection title={"Szczegóły produktu"} dark className={"mt-8"}>
        <ProductDetails  data={data} isLoading={isLoading} className={'text-cetner'}/>
      </TemplateSection>

      <TemplateSection
        title={"Inne produkty tego producenta"}
        className={"mt-8 mb-4"}
      >
        <ProductList data={data} items={wineList} isFetching={isWinesLoading} />
      </TemplateSection>
      <TemplateSection title={`Komentarze (${dataRatings?.items?.length})`} className={"mt-2"}>
        {data?.wineRatings?.length > 0 ? (
          <ProductComments data={dataRatings} isLoading={isLoadingRatings} />
        ) : (
          <div className={'text-center md:text-start'}>Brak komentarzy</div>
        )}
        
      </TemplateSection>
    </TemplateMain>
  );
};

export default ViewProduct;
