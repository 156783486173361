import React from 'react';
import Tooltip from '../../../../molecules/ToolTip/Tooltop';

const IconButton = ({children, onClick, className, disabled, tooltip}) => {
  return (
    <Tooltip value={tooltip}>
    <button
      disabled={disabled}
      onClick={onClick}
      className={`text-white shadow hover:shadow-md rounded-lg bg-gold2 w-10 h-10 flex items-center justify-center disabled:pulse disabled:bg-gray-100 hover:bg-gold transition ${className}`}>
      {children}
    </button>
    </Tooltip>
  );
};

export default IconButton;
