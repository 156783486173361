import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { wineMock } from './requests/app/wine';
import { vineyardMock } from './requests/app/vineyard';
import { filtersWinesMock } from './requests/app/filtersWines';

import { actionAuthLogout } from '../../redux/authReducer/authActionTypes';
import { filtersVineyardsMock } from './requests/app/filtersVineyards';
import { blogsMock } from './requests/app/blogs';
import { blogMock } from './requests/app/blog';
import { vineyardGalleryMock } from './requests/vineyard/vineyardGallery';
import {vineyardEnoturismMock} from "./requests/vineyard/vineyardEnoturism";
import {vineyardAdvertisementMock} from "./requests/vineyard/vineyardAdvertisement";

const axiosInstance = axios.create();

export const mock = new MockAdapter(axiosInstance, {
  delayResponse: 100
});

export const setupAxios = (store) => {
  // add user token to axios if provided
  axiosInstance.interceptors.request.use(
    (config) => {
      const {
        auth: { token },
      } = store.getState()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (err) => Promise.reject(err),
  );

  axiosInstance.interceptors.response.use(
    response => {
      if(response && response.headers && response.headers['content-type'] ==='text/html'){
        return Promise.reject(`Expected 'application/json' got 'text/html'`);
      }
      return response;
    },
    error => {
      if(error.request.status === 401) {
        store.dispatch(actionAuthLogout());
      }
      return Promise.reject(error);
    }
  );
}


export const setupAxiosMocks = () => {
  vineyardMock(mock);
  wineMock(mock);
  filtersWinesMock(mock);
  filtersVineyardsMock(mock);

  blogsMock(mock);
  blogMock(mock);
  vineyardGalleryMock(mock);
  vineyardEnoturismMock(mock);
  vineyardAdvertisementMock(mock);
  mock.onAny().passThrough();
};

export default axiosInstance;
