import React from 'react';
import { routes } from '../../../app/router/routes';
import { FaShoppingCart } from 'react-icons/fa';
import NavLink from '../ui/buttons/links/NavLink';
import { useSelectProducts } from '../../../app/redux/cartReducer/cartSelectors';

const CartLink = ({hideLabel}) => {
  const quantity = useSelectProducts().reduce((prev, curr) => prev + curr.quantity, 0);

  return (
    <NavLink disableAnimation className={'flex  flex-row items-center relative '} to={routes.cart}>
      <FaShoppingCart className={'mr-2'} />
      {
        hideLabel ? null : "Koszyk"
      }

      {
        quantity > 0 &&
        <p className={'absolute  text-white bg-amber-700 rounded-full text-xs w-4 h-4 flex items-center justify-center -top-1 left-2'}>{quantity}</p>
      }
    </NavLink>
  );
};

export default CartLink;
