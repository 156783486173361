import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import usePath from '../../../../../app/hooks/usePath';
import { useEffect, useState } from 'react';

const NavLink = ({ to, children, className, disableAnimation, activeClass }) => {
  const {path, compare} = usePath();
  const [isActive, setActive] = useState(false);

  useEffect(()=>{
    setActive(compare(path, to))
  },[path])

  return (
    <div className={`justify-center flex flex-col min-w-max ${isActive ? activeClass : ''}`}>
      <Link className={`peer ${className}`} to={to}>
        {children}
      </Link>
      {!disableAnimation && (
        <span
          className={
            `cursor-pointer  hover:w-full peer-hover:w-full transition-width h-px`
          }
        />
      )}
    </div>
  );
};

NavLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
  className: PropTypes.string,
  disableAnimation: PropTypes.bool
};

export default NavLink;
