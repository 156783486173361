import { routes } from "../router/routes"
import { BsFillCartFill, BsFillChatLeftTextFill } from 'react-icons/bs';
import { ImDatabase } from 'react-icons/im';
import { FaUserEdit, FaWineBottle } from 'react-icons/fa';
import { BsBasketFill, BsFileEarmarkPersonFill, BsFillPersonFill } from "react-icons/bs";
import { MdError, MdInventory } from "react-icons/md";
import { FaImage } from "react-icons/fa";
import { GiGrapes } from "react-icons/gi";
import { IoBed } from "react-icons/io5";


export const sideNavigationConfig = [
    // {
    //     label: 'Pulpit',
    //     route: routes.vineyardDesktop,
    //     Icon: AiFillHome
    // },
    {
        label: 'Sprzedaż'
    },
    {
        label: 'Wina',
        route: routes.vineyardProducts,
        Icon: FaWineBottle
    },
    {
        label: 'Zamówienia',
        route: routes.vineyardOrders,
        Icon: BsFillCartFill
    },

    {
        label: 'Produkcja'
    },
    {
        label: 'Magazyn butelek',
        route: routes.vineyardStore,
        Icon: MdInventory
    },
    {
        label: 'Zbiorniki',
        route: routes.vineyardContainers,
        Icon: ImDatabase
    },
    {
        label: 'Winnica'
    },
    {
        label: 'Moja winnica',
        route: routes.vineyardProfile,
        Icon: GiGrapes
    },
    {
        label: 'Enoturystyka',
        route: routes.vineyardPanelEnoturism,
        Icon: IoBed
    },
    {
        label: 'Galeria',
        route: routes.vineyardPanelGallery,
        Icon: FaImage
    },
    {
        label: 'Konto'
    },
    {
        label: 'Moje konto',
        route: routes.vineyardAccount,
        Icon: BsFileEarmarkPersonFill
    },
    {
        label: 'Zgłoś problem',
        route: routes.vineyardErrors,
        Icon: MdError
    },
    {
        label: 'Blog'
    },
    {
        label: 'Moje wpisy',
        route: routes.vineyardPanelBlogs,
        Icon: BsFillChatLeftTextFill
    },
]

export const sideNavigationConfigCustomer = [
    {
        label: 'Moje zamówienia',
        route: routes.customerOrders,
        Icon: BsBasketFill
    },
    {
        label: 'Moje konto',
        route: routes.customerData,
        Icon: BsFillPersonFill
    },
    {
        label: 'Edytuj konto',
        route: routes.customerEdit,
        Icon: FaUserEdit
    },
    // {
    //     label: 'Moje faktury',
    //     route: routes.customerInvoices,
    //     Icon: FaFileInvoiceDollar
    // },
]