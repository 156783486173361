import TemplatePanel from '../../templates/TemplatePanel';
import VineyardSideMenu from '../../organisms/side-menu/VineyardSideMenu';
import React from 'react';

const ViewVineyardDesktop = () => {
  return (
    <TemplatePanel title={"Pulpit"} sideMenuRenderer={()=><VineyardSideMenu/>}>

    </TemplatePanel>
  );
};

export default ViewVineyardDesktop;
