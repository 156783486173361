import React from 'react';
import {AiOutlineClose} from 'react-icons/ai';

const ActiveFilter = ({name, className, onClick, show}) => {
    if (!show) return null;
    return (
        <div
            onClick={onClick}
            className={`text-sm flex justify-between p-2 w-max rounded-lg items-center cursor-pointer border ${className}`}>
            {name}
            <AiOutlineClose className={'ml-2'}/>
        </div>
    );
};

export default ActiveFilter;
