import React, { useEffect, useState } from 'react';
import IconButton from '../ui/buttons/regular/IconButton';

const QuantityButtons = ({ initVal, className, onChange, onZeroChange }) => {
  const [quantity, setQuantity] = useState(initVal || 1);

  return (
    <div className={`flex flex-row items-center ${className}`}>
      <IconButton className={'border w-10 h-10'}
                  onClick={() => {
                      if(quantity===1) onZeroChange()
                      else {
                          onChange(quantity === 1 ? 1 : quantity - 1)
                          setQuantity(prev => prev === 1 ? 1 : prev - 1)
                      }
                  }}>-
      </IconButton>
      <input
        onBlur={()=>{(!quantity || quantity < 1) && setQuantity(1) }}
        onChange={e=>{
            setQuantity(parseInt(e.target.value) || '')
            if(e.target.value)
                onChange(parseInt(e.target.value))
        }}
        type={"number"}
        value={quantity}
        className={'w-12 text-center appearance-none h-9'} />
      <IconButton className={'border w-10 h-10'}
                  onClick={() => {
                      onChange(quantity+1)
                      setQuantity(prev => prev + 1)
                  }}>+
      </IconButton>
    </div>
  );
};

export default QuantityButtons;
