import { useFieldArray, useFormContext } from "react-hook-form";
import { InputAsyncSelect } from "../../../atoms/ui/inputs/Input_AsyncSelect";
import { getWineAromasHelper } from "../../../../app/crud/vineyard/wineAroma/getWineAromas";
import { AiFillDelete } from "react-icons/ai";
import Button from "../../../atoms/ui/buttons/regular/Button";

const FormConfigureWineAroma = ({ errors, name, }) => {
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  return (
    <div>
      <p className={"font-bold text-xl mt-4"}>Aromaty</p>
      {fields.map((item, arrayIndex) => (
        
        <div
          key={item?.value + "aroma"}
          className={"flex space-x-5 items-center mt-3"}
        >
          <div className={"w-1/2"}>
            <InputAsyncSelect
              {...getWineAromasHelper}
              placeholder={"Wybierz aromat do wina"}
              name={`${name}.${arrayIndex}.value`}
              required
              label={"Rodzaj aromatu"}
              transform={({ id, name }) => ({
                value: id,
                label: name,
              })}
            />
          </div>
          <button
            type={"button"}
            onClick={() => {
              remove(arrayIndex);
            }}
            className={"pt-7"}
          >
            <AiFillDelete />
          </button>
        </div>
      ))}
      <Button
        className={'mt-4 px-4'}
        type="button"
        onClick={e => {
            e.preventDefault();
            append({value: undefined})
        }}
      >+</Button>
    </div>
  );
};

export default FormConfigureWineAroma