import PropTypes from 'prop-types';
import DesktopHeader from '../organisms/header/DesktopHeader';
import MobileHeader from '../organisms/header/MobileHeader';
import Footer from '../organisms/footer/Footer';
import {Helmet} from 'react-helmet';
import ModalConfirmAge from '../organisms/modals/ModalConfirmAge';
import Cookies from 'universal-cookie';
import {useEffect} from "react";
import {useCreateGuestId} from "../../app/crud/app/createGuestId";
import {useCartSynchronization} from "../../app/crud/app/cart/synchronizeClientServerCart";
import {useDispatch} from "react-redux";
import {actionClearCart} from "../../app/redux/cartReducer/cartActionTypes";

const TemplateMain = ({children, title, breadcrumbsRenderer, disableHeader, disableFooter}) => {
    const {mutate: createGuestId} = useCreateGuestId()
    const cookies = new Cookies();
    const dispatch = useDispatch()
    const synchronizeWithServerCart = useCartSynchronization()

    useEffect(() => {
        if (!cookies.get('guestGuid')){
            createGuestId();
            dispatch(actionClearCart())
        }
        if (cookies.get('guestGuid'))
            synchronizeWithServerCart();

    }, [])

    return (
        <div className={'min-h-screen !overflow-x-hidden flex flex-col w-full bg-gray-50'}>
            <Helmet>
                <title>{title ? `${title} | Winopasja.pl` : 'Winopasja.pl'}</title>
            </Helmet>
            {
                !disableHeader &&
                <div className={'w-full relative z-50'}>
                    <div className={'hidden lg:flex'}>
                        <DesktopHeader/>
                    </div>
                    <div className={'lg:hidden'}>
                        <MobileHeader/>
                    </div>
                </div>
            }

            <main className={`relative w-full h-full`}>
                <ModalConfirmAge/>
                <div className={'w-full'}>
                    <div className={'px-4'}>
                        {
                            breadcrumbsRenderer &&
                            <div className={'max-w-screen-xl m-auto mt-4'}>
                                {breadcrumbsRenderer()}
                            </div>
                        }
                    </div>
                    {children}
                </div>
            </main>
            {
                !disableFooter &&
                <div className={'mt-auto'}>
                    <Footer/>
                </div>
            }

        </div>
    );
};

TemplateMain.propTypes = {
    children: PropTypes.any
};

export default TemplateMain;
