import axios from '../../../config/axios';
import {BASE_URL_API} from '../../../config/env';
import useQuery2 from '../../../hooks/useQuery2';
import {useParams} from "react-router-dom";

const getOrder = ([id]) => {
    return axios.get(`${BASE_URL_API}/api/v1/admin/orders/${id}`)
        .then(({data}) => data)
};


export const useVineyardOrder = () => {
    const {id} = useParams();
    return useQuery2({
        queryKey: ['app-public.order' + id, id],
        queryFn: getOrder
    });
};

