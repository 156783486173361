import React from 'react';
import NavLink from '../ui/buttons/links/NavLink';
import { routes } from '../../../app/router/routes';
import { FaUser } from 'react-icons/fa';

const LoginLink = () => {
  return (
    <NavLink
      disableAnimation
      className={'flex flex-row items-center'}
      to={routes.login}
    >
      <FaUser className={'mr-2'} />
      Zaloguj
    </NavLink>

  );
};

export default LoginLink;
