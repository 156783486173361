import React, { useState } from "react";
import ModalUploadPhoto from "../../organisms/modals/ModalUploadPhoto";
import Button from "../../atoms/ui/buttons/regular/Button";
import toast from "react-hot-toast";
import { dataURLtoFile } from "../../../app/utils/image";
import Image from "../../atoms/image/Image";
import ModalGenericConfirm from "../../organisms/modals/ModalGenericConfirm";
import { BsCardImage, BsFillImageFill } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { passParams, routes } from "../../../app/router/routes";

const ImageUploaderToGallery = ({
  value,
  onChange,
  mutation,
  currentImageUrl,
  isLoading,
  onDelete,
  className,
  onAddSuccess,
  disabled,
  data,
  tooltip,url
}) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(currentImageUrl);
  const navigate = useNavigate();

  const onError = () => {
    toast.error(
      "Wystąpił błąd przy próbie dodania zdjęcia. Spróbuj ponownie później."
    );
  };
  const onSuccess = () => {
    toast.success("Udało się pomyślnie dodać zdjęcie.");
    onAddSuccess && onAddSuccess();
    navigate(
      url
    );
  };
  const handleSave = (imageUrl) => {
    setImageUrl(imageUrl);
    const file = dataURLtoFile(imageUrl, "image");
    const formData = new FormData();
    formData.append("image", file);
    mutation(formData, { onError, onSuccess });
  };

  return (
    <div>
      <div className={"border-dashed overflow-hidden rounded-lg   p-4s"}>
        <ModalGenericConfirm
          onConfirm={() => {
            setImageUrl("");
            onDelete();
          }}
          isOpen={confirmModalOpen}
          setOpen={setConfirmModalOpen}
        >
          Czy na pewno chcesz usunąć zdjęcie?
        </ModalGenericConfirm>
        <ModalUploadPhoto
          isOpen={isOpenModal}
          setOpen={setOpenModal}
          setImageBlobUrl={handleSave}
          onChange={onChange}
          value={value}
        />
        <div
          onClick={() => setOpenModal(true)}
          className={"w-full relative cursor-pointer"}
        >
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            className={`text-white text-sm px-2 bg-green-700 hover:bg-green-600 ${className}  ${disabled} `}
            tooltip={tooltip || "Dodaj zdjęcie do galerii"}
          >
                        <div className={'flex items-center justify-between'}>
            <AiOutlinePlus className="mr-1" size={'1.25rem'}/>
            <BsFillImageFill size={"1.25rem"} />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageUploaderToGallery;
