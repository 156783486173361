import React from 'react';
import CustomLink from "../../../atoms/ui/buttons/links/Link";
import {routes} from "../../../../app/router/routes";

const RegisterBottomContent = () => {
    return (
        <CustomLink
            className={'flex flex-col gap-4 justify-center items-center bg-gray-100 p-4 mx-auto max-w-lg'}
            to={routes.login}>
            Masz już konto?
        </CustomLink>
    );
};

export default RegisterBottomContent;
