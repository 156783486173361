import React from 'react';
import TemplateMain from '../../../templates/TemplateMain';
import TemplateSection from '../../../templates/TemplateSection';
import Card from '../../../atoms/card/Card';

const NotFoundView = () => {
  return (
    <TemplateMain disableBreadcrumbs title={'404'}>
      <TemplateSection>
        <Card className={'flex justify-center'}>Taka strona nie istnieje.</Card>
      </TemplateSection>
    </TemplateMain>
  );
};

export default NotFoundView;
