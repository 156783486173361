import React from 'react';
import ChangeOrderStatus from '../../molecules/vineyard-panel/order-details/ChangeOrderStatus';
import OrderInfo from '../../molecules/vineyard-panel/order-details/OrderInfo';
import OrderProducts from '../../molecules/vineyard-panel/order-details/OrderProducts';

const OrderDetails = ({data, isLoading}) => {
    return (
        <div className={'space-y-5 flex flex-col items-center w-full'}>
            <div className='flex justify-between flex-row w-full'>
            <OrderInfo data={data} isLoading={isLoading}/>
            {/* <ChangeOrderStatus data={data}/> */}
            </div>
            <OrderProducts data={data?.orderItems} isLoading={isLoading}/>
        </div>
    );
};

export default OrderDetails;
