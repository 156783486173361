import { Controller, useFormContext } from 'react-hook-form'
import { FiCalendar } from 'react-icons/fi'
import { InputDatepickerPure } from './Input_DatePicker.pure'

export const InputDatepicker = ({ icon = FiCalendar, name, ...props }) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...field }, fieldState }) => {
        const { error } = fieldState
        return (
          <InputDatepickerPure innerRef={ref} {...{ name, icon, error }} {...field} {...props} />
        )
      }}
    />
  )
}