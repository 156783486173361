import React from 'react';
import LineLoader from '../../atoms/loaders/LineLoader';

const Text = ({ children, isLoading, className, classNames, hidden, title }) => {

  if(!isLoading && hidden)
    return null;

  return (
    <LineLoader className={className} isLoading={isLoading} classNames={classNames || [`w-full`]}>
      {title && <p className={'font-bold mb-2'}>{title}</p>}
      <div className={className}>{children}</div>
    </LineLoader>
  );
};

export default Text;
