import { routes } from "../../../../app/router/routes";
import Logo from "../../../atoms/logo/Logo";
import LogoBlack from "../../../atoms/logo/Logo-black";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import TemplateCart from "../../../templates/TemplateCart";
import TemplateMain from "../../../templates/TemplateMain";

const ViewCartDone = () => {
  const progressBarConfig = [
    { url: routes.cart, done: true, active: true, label: "Koszyk" },
    {
      url: routes.checkout,
      done: true,
      active: true,
      label: "Dostawa i płatność",
    },
    {
      url: routes.cartSummary,
      done: true,
      active: true,
      label: "Podsumowanie",
    },
    { url: routes.orderDone, done: true, active: true, label: "Gotowe" },
  ];

  const summaryConfig = {
    route: routes.wines,
    label: "Zakończ",
  };
  return (
    <TemplateMain title={"Podsumowanie zamówienia"}>
      <TemplateCart
        isFinished={true}
        progressBarConfig={progressBarConfig}
        summaryConfig={summaryConfig}
        title={"Podsumowanie"}
      >

<div className=" py-6 sm:py-8 lg:py-12 !flex !justify-center">
  <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
    <div className="flex flex-col items-center justify-center">

      <LogoBlack className={'w-18 mb-8'}/>

      <h1 className="text-bronze text-2xl md:text-3xl font-bold text-center mb-2">Zamówienie zostało złożone!</h1>

      <p className="max-w-screen-md text-gray-500 md:text-lg text-center mb-8">Status zamówienia, możesz sprawdzić na swoim koncie w zakładce Moje zamówienia</p>

    <ButtonLink className={'flex justify-center mt-auto w-full bg-gold2 hover:bg-gold'} to={routes.home}>
        Strona główna
    </ButtonLink>

    </div>
  </div>
</div>

      </TemplateCart>
    </TemplateMain>
  );
};

export default ViewCartDone;
