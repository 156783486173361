import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const CustomLink = ({ className, children, to, disableUnderline, ...props }) => {
  return (
    <Link
      className={`flex justify-center ${disableUnderline ? '' : 'hover:no-underline'}  transition text-gold2 hover:text-gold ${className}`}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  children: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string
};

export default CustomLink;
