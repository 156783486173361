import TemplateMain from '../../../templates/TemplateMain';
import GuestCard from '../../../molecules/cart/GuestCard';
import {routes} from '../../../../app/router/routes';
import TemplateSection from '../../../templates/TemplateSection';
import FormLogin from "../../../molecules/forms/FormLogin";

const ViewCartAccount = () => {
    return (
        <TemplateMain title={'Konto'}>
            <TemplateSection>
                <div
                    className={"flex-col-reverse md:flex-row md:space-x-8 flex justify-center w-full md:my-16"}>
                    <div className={'w-full md:flex md:justify-center'}>
                        <GuestCard/>
                    </div>
                    <div className={'w-full flex justify-center items-start mb-8 md:mb-0'}>
                        <FormLogin redirect={routes.checkout}/>
                    </div>
                </div>
            </TemplateSection>
        </TemplateMain>
    );
};

export default ViewCartAccount;
