import axios from '../../../config/axios'
import { BASE_URL_API } from '../../../config/env'
import useQuery2 from '../../../hooks/useQuery2'

export const getBookStatus = () =>
    axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/enums/book-status`
    }).then(({data}) => data)

    export const getBookStatusHelper = {
        queryKey: ['app-public.bookStatus'],
        queryFn: () => getBookStatus(),
        isArray: true
    }

export const useGetBookStatus = () => {
    return useQuery2({
        queryKey: ['app-public.bookStatus'],
        queryFn: () => getBookStatus()
    })
}