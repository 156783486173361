import axios from '../../../config/axios';
import { BASE_URL_API } from '../../../config/env';
import useQuery2 from '../../../hooks/useQuery2';
import { useParams } from 'react-router-dom';

const getContainer = ([id]) => {
  return axios.get(`${BASE_URL_API}/api/v1/admin/vineyard/containers/${id}`)
    .then(({ data }) => data)
}

export const useGetContainer = () => {
  const { id } = useParams();
  return useQuery2({
    queryKey: ['app-public.vineyard_container'+id, id],
    queryFn: getContainer
  });
};
