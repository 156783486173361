export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mimeTxt = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mimeTxt});
}
