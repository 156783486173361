import { useResetPassword } from '../../../app/crud/auth/resetPassword';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from '../../atoms/form-error/FormError';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as yup from 'yup'
import { routes } from '../../../app/router/routes';
import Input from "../../atoms/ui/inputs/CustomInput";
import Submit from "../../atoms/ui/buttons/regular/Submit";
import { RiLockPasswordLine } from 'react-icons/ri';
import Card from '../../atoms/card/Card';

const schema = yup.object().shape({
    currentPassword: yup.string().required('Podaj hasło'),
    password: yup.string().required('Podaj nowe hasło'),
    confirmPassword: yup.string().required('Podaj hasło jeszcze raz').oneOf([yup.ref('password'), null], 'Podane hasła nie są takie same'),
})

const FormChangePassword = () => {
       const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {...schema.default()},
    });

    const onSuccess = () => {
        toast.success('Hasło zostało zmienione pomyślnie.');
        reset()
    }
    const onError = () => {
        toast.error('Wystąpił błąd przy próbie resetu hasła. Spróbuj ponownie później.')
    }

    const {mutate, isLoading, error} = useResetPassword()
    const onSubmit = (data) => {
        mutate(data, {onSuccess, onError});
      };


    return (
        <Card title={'Zmień hasło'} className={'w-full'}>
        <form
            className={"w-full"}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="flex flex-col gap-4 text-gray-800 w-full mb-4">
                <Input
                    required
                    name={'currentPassword'}
                    placeholder={'Aktualne hasło'}
                    label={'Podaj aktualne hasło'}
                    icon={RiLockPasswordLine}
                    error={errors.currentPassword?.message}
                    register={register}
                    type='password'
                />
                <Input
                    required
                    name={'password'}
                    placeholder={'Nowe hasło'}
                    label={'Podaj nowe hasło'}
                    icon={RiLockPasswordLine}
                    register={register}
                    error={errors.password?.message}
                    type='password'
                />
                <Input
                    required
                    name={'confirmPassword'}
                    placeholder={'Podaj ponownie nowe hasło'}
                    label={'Powtórz nowe hasło'}
                    icon={RiLockPasswordLine}
                    register={register}
                    error={errors.confirmPassword?.message}
                    type='password'
                />
           
                <Submit isLoading={isLoading} className=" w-1/6 flex justify-end self-end" >
                Zapisz
            </Submit>
            </div>
            <FormError message={error && 'Wystąpił błąd'}
                className={'mb-7 p-4 text-sm text-red-700 bg-red-100 rounded-lg'}
            />
        </form>
        </Card>
    )
}

export default FormChangePassword