import TemplatePanel from '../../templates/TemplatePanel';
import CustomerSideMenu from '../../organisms/side-menu/CustomerSideMenu';
import {routes} from '../../../app/router/routes';
import Breadcrumbs from '../../organisms/breadcrumbs/Breadcrumbs';
import React from 'react';

const ViewCustomerPassword = () => {
    const bc = [{label: 'Zmiana hasła', url: routes.customerData}];

    return (
        <TemplatePanel
            title={"Hasło"}
            sideMenuRenderer={() => <CustomerSideMenu/>}
            breadcrumbsRenderer={() => <Breadcrumbs homeRoute={routes.customerPassword} settings={bc}/>}
        >

        </TemplatePanel>
    );
};

export default ViewCustomerPassword;
