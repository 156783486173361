import axios from "../../../config/axios";
import { BASE_URL_API } from "../../../config/env";
import { useParams } from "react-router-dom";
import useQuery2 from "../../../hooks/useQuery2";
const getBlog = ([id]) => {
  return axios
    .get(`${BASE_URL_API}/api/v1/admin/blog/${id}`)
    .then(({ data }) => data);
};

export const useGetVineyardBlog = () => {
  const { id } = useParams();
  return useQuery2({
    queryKey: ["app-public.vineyard_blog"+id, id],
    queryFn: getBlog,
  });
};
