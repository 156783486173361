import axios from "../../mock/axios";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL_API } from "../../../config/env";
import { passParams, routes } from "../../../router/routes";
import { useGetContainer } from "../containers/getContainer";


export const useAddContainersEvent = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    return useMutation((data) => {
        return axios({
            method: 'POST',
            url: `${BASE_URL_API}/api/v1/admin/vineyard/containers/${id}/events`,
            data
        }).then(() => { navigate(passParams(routes.vineyardViewEventContainer, {id: id}))})
    })
}