import React, {useState} from "react";
import {FormTooltip} from "../../tooltip/Tooltip";

const Input = ({
                   textMd,
                   className,
                   name,
                   type,
                   label,
                   register,
                   required,
                   error,
                   placeholder,
                   icon: Icon = null,
                   size = 'md',
                   tooltip,
                   disabled,
                   step,
                   innerRef,
                   transformValue = (value) => value,
                   component: Component = 'input',
                   ...props
               }) => {


    return (
        <div className={`relative w-full my-1 ${className}`}>
            <div className={`inline-block text-gray-800 ${textMd ? '' : 'text-xs sm:text-sm'} mb-1`}>
                {label} {required && <span className="text-red-600">*</span>}
            </div>
            <div className="relative">
                {Icon && (
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                        <Icon color="currentColor" size={size === 'md' ? 20 : size === 'sm' ? '14' : 22}/>
                    </div>
                )}
                {tooltip && (
                    <div className="absolute inset-y-0 right-3 pl-3 flex items-center text-gray-400">
                        <FormTooltip value={tooltip} {...props}/>
                    </div>
                )}
                <Component
                    className={`
           ${
                        error
                            ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 rounded-lg focus:ring-red-500 focus:border-red-500'
                            : 'bg-gray-50 border border-gray-300 text-gray-900 focus:ring-primary focus:border-primary '
                    } sm:text-sm rounded-lg block w-full ${tooltip ? 'pr-10' : ''} ${Icon ? 'pl-10' : ''} ${
                        size === 'md' ? 'p-2.5' : size === 'sm' ? 'p-1' : ''
                    } ${disabled ? 'text-gray-400' : ''}`}
                    {...(register ? register(name, {required}) : {})}
                    type={type}
                    step={step}
                    disabled={disabled}
                    {...props}
                    placeholder={placeholder || label}
                />
            </div>
            {error && (
                <div className="text-sm absolute top-full left-0 text-red-800">{error}</div>
            )}
        </div>
    );
};

export default Input;
