import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'
import { useEditProfile } from '../../../app/crud/customer/editProfile/addName'
import { useCustomerProfile } from '../../../app/crud/customer/getProfileCustomer'
// import { usePutEditProfile } from '../../../app/crud/customer/editProfile/putName'
import Card from '../../atoms/card/Card'
import FormError from '../../atoms/form-error/FormError'
import Submit from '../../atoms/ui/buttons/regular/Submit'
import Input from '../../atoms/ui/inputs/CustomInput'

const schema = yup.object().shape({
    firstName: yup.string().required('Imię jest wymagane'),
    lastName: yup.string().required('Nazwisko jest wymagane')
})

export const FormEditProfile = ({mode, initialData}) => {
    const { refetch} = useCustomerProfile()
    const onError = () => {
        toast.error('Wystpąił błąd przy próbie dodania personaliów. Spróbuj ponownie później')
    }

    const onSuccess = () => {
        toast.success('Udało się pomyślnie dodać imię oraz nazwisko')
        refetch()
    }

    // const onErrorPut = () => {
    //     toast.error('Wystąpił błąd przy próbie edycji personaliów. Spróbuj ponownie później')
    // }

    // const onSuccessPut = () => {
    //     toast.success('Udało się pomyślnie edytować imię oraz nazwisko')
    // }

    const methods = useForm({
        resolver: yupResolver(schema),
        // defaultValues: {
        //     ...initialData,
        // }
    })

    const {register, handleSubmit, formState: {errors}, setValue} = methods

    const {mutate, isLoading, error} = useEditProfile();
    // const {mutate: putMutate, isLoading: putIsLoading} = usePutEditProfile();

    const onSubmitAdd = (data) => {
        mutate(data, {onSuccess, onError})
    }

    // const onSubmitPut = (data) => {
    //     putMutate(data, {onSuccess: onSuccessPut, onError: onErrorPut})
    // }
    return (
        <FormProvider {...methods}>
            <Card className={'flex flex-col w-full'} title={'Edytuj profil'}>
                <form className={'w-full'} onSubmit={handleSubmit(onSubmitAdd)}>
                    <div className={'flex flex-col gap-4 text-gray-800 mb-4 w-full'}>
                        <Input name={'firstName'} label={'Imię'} register={register} error={errors?.firstName?.message} required/>
                        <Input name={'lastName'} label={'Nazwisko'} register={register} error={errors?.lastName?.message} required/>
                        <Submit isLoading={isLoading} className={'w-1/6 flex justify-end self-end'}>
                            Zapisz
                        </Submit>
                    </div>
                    <FormError message={error && 'Wystąpił błąd'} classNam={'mb-7 p-4 text-sm text-red-700 bg-red-100 rounded-lg'}/>
                </form>
            </Card>
        </FormProvider>
    )
}