import TemplateMain from '../../../templates/TemplateMain';
import TemplateSection from '../../../templates/TemplateSection';
import ProductList from '../../../organisms/product-list/ProductList';
import {passParams, routes} from '../../../../app/router/routes';
import Breadcrumbs from '../../../organisms/breadcrumbs/Breadcrumbs';
import React from 'react';
import VineyardDetails from '../../../organisms/vineyard-details/VineyardDetails';
import {useGetVineyard} from '../../../../app/crud/app/vineyard';
import {useGetVineyardWines} from "../../../../app/crud/app/vineyardWines";
import VineyardGallery from '../../../organisms/vineyard-details/VineyardGallery';
import ProductListVineyard from '../../../organisms/product-list/ProductListVineyard';
import { ProductComments } from '../../../organisms/comments/Comments';

const ViewVineyard = () => {
    const {data, isFetching} = useGetVineyardWines()
    const {data: vineyard, isLoading: isVineyardLoading} = useGetVineyard()
    const bc = [
        {label: 'Winnice', url: routes.vineyards},
        {label: vineyard?.name, url: passParams(routes.vineyard, {vineyardId: vineyard?.id})},
    ];
    return (
        <TemplateMain
            title={'Winnica'}
            breadcrumbsRenderer={() => <Breadcrumbs isLoading={isFetching} homeRoute={routes.home} settings={bc}/>}
        >
            <TemplateSection>
                <VineyardDetails data={vineyard} isLoading={isVineyardLoading}/>
            </TemplateSection>
            <TemplateSection dark title={'Zdjęcia winnicy'}>
                <VineyardGallery  data={vineyard}   isLoading={isVineyardLoading}/>
            </TemplateSection>
            <TemplateSection title={'Produkty z tej winnicy:'}>
                <ProductListVineyard data={data} isFetching={isFetching}/>
            </TemplateSection>
            <TemplateSection title={'Komentarze'}>
            {data?.wineRatings?.length > 0 ? (
          <ProductComments  />
        ) : (
          <div className={'text-center md:text-start'}>Brak komentarzy</div>
        )}
        
            </TemplateSection>
        </TemplateMain>
    );
};

export default ViewVineyard;
