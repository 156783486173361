import axios from "../../../config/axios"
import { BASE_URL_API } from "../../../config/env"
import useQuery2 from "../../../hooks/useQuery2"

export const getSensorType = () => 
    axios({
        method: 'GET',
        url: `${BASE_URL_API}/api/v1/enums/sensor-type`,
    }).then(({data}) => data)

    export const getSensorHelper = {
        queryKey: ['app-public.sensorType'],
        queryFn: () => getSensorType(),
        isArray: true
    }

export const useGetSensorType = () => {
    return useQuery2({
        queryKey: ['app-public.sensorType'],
        queryFn: () => getSensorType()
    })
}