import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import PanelHeader from "../organisms/header/PanelHeader";
import { Helmet } from "react-helmet";
import PortalWrapper from "../atoms/portal-wrapper/PortalWrapper";
import Tabs from "../organisms/tabs/Tabs";

const TemplatePanel = ({
  title,
  isLoading,
  children,
  sideMenuRenderer,
  tabsConfig,
  breadcrumbsRenderer,
  actionButtonsRenderer,
  className
}) => {
    const [isOpen, setOpen] = useState(false);
  return (
    <div className={"flex bg-gray-100 min-h-screen pt-20"}>
      <Helmet>
        <title>{title ? `${title} | Winopasja.pl` : "Winopasja.pl"}</title>
      </Helmet>

      <div className={""}>
        <PortalWrapper
          onBackdropClick={() => {
            setOpen(false);
          }}
          show={isOpen}
          overrideClass={
            "fixed z-50 opacity-95 w-60 h-screen top-0 left-0 bg-white transition duration-200"
          }
        >
         <div className="p-4">{sideMenuRenderer && sideMenuRenderer()}</div>   
        </PortalWrapper>
        <div className="lg:block fixed hidden top-0 left-0 min-h-screen w-80 bg-white p-4 z-10 pt-[81px] ">
        {sideMenuRenderer && sideMenuRenderer()}
        </div>
      </div>
      <div className={"w-full min-h-screen flex flex-col"}>
        <div className={"fixed z-20 top-0 w-screen bg-white shadow-sm"}>
          <div className={"m-auto"}>
            <PanelHeader isOpen={isOpen} setOpen={setOpen}/>
          </div>
        </div>

        <div className={` lg:pl-80 h-full w-full flex flex-col`}>


          <div className={`flex border-t bg-gray-100 h-full w-full`}>
            <div
              className={"flex flex-col mx-auto max-w-screen-xl p-4 w-full "}
            >
              <div className={`items-center border-b pb-2 flex flex-row mb-0 md:mb-4  h-8 ${className}`}>
                {breadcrumbsRenderer && breadcrumbsRenderer()}
                <div className={"ml-auto "}>
                  {actionButtonsRenderer && actionButtonsRenderer()}
                </div>
              </div>

              {
                tabsConfig &&
                  <div className={"max-w-screen-xl border-b w-full mx-auto mb-4"}>
                    <Tabs config={tabsConfig}/>
                  </div>
              }


              {isLoading ? (
                <div className={"flex w-full items-center justify-center"}>
                  <BeatLoader />
                </div>
              ) : (
                <div className={"pb-4"}>{children}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatePanel;
