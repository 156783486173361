import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routes } from '../../../app/router/routes';

const LogoBlack = ({ className, disable }) => {
  return (
    <Link to={routes.home} className={`${className} ${disable ? 'pointer-events-none' : ''}`}>
      <img
        className={`w-12 h-12 rounded-lg`}
        src={'/png/logo-01.png'}
        alt={'/'}
      />
    </Link>
  );
};

LogoBlack.propTypes = {
  className: PropTypes.string
};

export default LogoBlack;
