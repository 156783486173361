import React from 'react';
import FormUpdateProfile from '../../../../molecules/forms/vineyard/FormUpdateProfile';

const VineyardProfile = ({ data, isLoading, error }) => {
  return (
      <FormUpdateProfile data={data} isLoading={isLoading} error={error}/>
  );
};

export default VineyardProfile;
