import ReactDom from 'react-dom';
import React from 'react';

const PortalWrapper = ({ children, show, onBackdropClick, disableBackdrop, overrideClass }) => {
  if (!show) return null;

  return ReactDom.createPortal(
    <div>
      <div className={`${overrideClass || ' z-50 fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'}`}>{children}</div>
      {
        !disableBackdrop &&
        <div
          onClick={onBackdropClick}
          className={
            'z-40 cursor-pointer bg-gray-500 bg-opacity-70 fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center'
          }
        />
      }

    </div>,
    document.getElementById('modal')
  );
};

export default PortalWrapper;
