import React from 'react';

const ProgressBarSeparator = () => {
    return (
        <div className={'border-b w-[50px] md:w-[100px] h-px relative top-3'}/>

    );
};

export default ProgressBarSeparator;
