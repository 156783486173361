import axios from '../../config/axios';
import {BASE_URL_API} from '../../config/env';
import {useMutation} from 'react-query';
import Cookies from 'universal-cookie';

export const useCreateGuestId = () => {
    const cookies = new Cookies();
    return useMutation(() => {
        return axios.post(`${BASE_URL_API}/api/v1/account/get-guest-guid`)
            .then(({data}) => {
                cookies.set('guestGuid', data?.guestGuid);
                return data
            })
    });
};