import TemplatePanel from "../../../templates/TemplatePanel";
import VineyardSideMenu from "../../../organisms/side-menu/VineyardSideMenu";
import React, { useMemo, useState } from "react";
import { passParams, routes } from "../../../../app/router/routes";
import Breadcrumbs from "../../../organisms/breadcrumbs/Breadcrumbs";
import ButtonLink from "../../../atoms/ui/buttons/links/ButtonLink";
import { useGetVineyardAdvertisement } from "../../../../app/crud/vineyard/enoturism/getAdvertisement";
import { AiFillEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { usePutAdvertisementStatus } from "../../../../app/crud/vineyard/enoturism/putAdvertisementStatus";
import { useAddAdvertisementImage } from "../../../../app/crud/vineyard/enoturism/addAdvertisementImage";
import { useDeleteAdvertisementImage } from "../../../../app/crud/vineyard/enoturism/deleteAdvertisementImage";
import { useDeleteAdvertisement } from "../../../../app/crud/vineyard/enoturism/deleteAdvertisement";
import toast from "react-hot-toast";
import Card from "../../../atoms/card/Card";
import ToggleButton from "../../../atoms/ui/buttons/toggle/ToggleButton";
import ModalGenericConfirm from "../../../organisms/modals/ModalGenericConfirm";
import Button from "../../../atoms/ui/buttons/regular/Button";
import Header1 from "../../../molecules/headers/Header1";
import Description from "../../../molecules/description/Description";
import photo from "../../../../app/assets/svg/photo.svg";
import ImageUploaderToGallery from "../../../molecules/image-uploader/ImageUploaderToGallery";
import dayjs from "dayjs";
import { BsFillCalendarFill } from "react-icons/bs";
import Text from "../../../molecules/text/Text";
import { FaBed } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import { MdDescription, MdMeetingRoom } from "react-icons/md";
import GalleryImageEnoturism from "../../../molecules/gallery-image/GalleryImageEnoturism";
import Table from "../../../atoms/Table/Table";

const ViewVineyardEnoturismAdvertisement = () => {
  const { data, isLoading, refetch } = useGetVineyardAdvertisement();
  const [
    confirmDeleteAdvertisementModalOpen,
    setconfirmDeleteAdvertisementModalOpen,
  ] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { mutate: mutatePublishAdvertisement } = usePutAdvertisementStatus();
  const { mutate: mutateAddImageAdvertisement } = useAddAdvertisementImage();
  const { mutate: mutateDeleteImageAdvertisement } =
    useDeleteAdvertisementImage();
  const { mutate: mutateDeleteAdvertisement } = useDeleteAdvertisement();


  const bc = [
    { label: "Enoturystyka", url: routes.vineyardPanelEnoturism },
    {
      label: data?.name ? data?.name : data?.id,
      url: passParams(routes.vineyardPanelEnoturismAdvertisement, {
        id: data?.id,
      }),
    },
  ];

  const onPublishError = () => {
    toast.error(
      "Wystąpił błąd przy próbie zmiany widoczności ogłoszenia. Spróbuj ponownie później."
    );
  };

  const onPublishSuccess = () => {
    toast.success(
      "Zmiana statusu widoczności ogłoszenia przebiegła pomyślnie."
    );
  };

  const onDeleteError = () => {
    toast.error(
      "Wystąpił błąd przy próbie usunięcie ogłoszenia. Spróbuj ponownie później"
    );
  };

  const onDeleteSuccess = () => {
    toast.success("Ogłoszenie zostało usuniętę pomyślnie");
  };

  const buttonsRenderer = () => {
    return (
      <div className={"flex space-x-4"}>
        <div className={"flex justify-end items-center"}>
          <ToggleButton
            label={"Opublikuj"}
            initVal={data?.publishStatus === 10}
            onClick={(val) =>
              mutatePublishAdvertisement(
                { isPublic: val },
                { onError: onPublishError, onSuccess: onPublishSuccess }
              )
            }
          />
        </div>
        <ModalGenericConfirm
          isOpen={confirmDeleteAdvertisementModalOpen}
          setOpen={setconfirmDeleteAdvertisementModalOpen}
          onConfirm={() =>
            mutateDeleteAdvertisement(
              {},
              { onError: onDeleteError, onSuccess: onDeleteSuccess }
            )
          }
        >
          Czy na pewno chcesz usunąć ogłoszenie?
        </ModalGenericConfirm>
        <ButtonLink
          tooltip={"Edytuj ogłoszenie"}
          to={passParams(routes.vineyardPanelEnoturismEdit, { id: data?.id })}
          className={
            "text-white text-sm flex items-center bg-gold2 hover:bg-gold"
          }
        >
          <AiFillEdit size={"1.25rem"} />
        </ButtonLink>
        <Button
          tooltip={"Usuń ogłoszenie"}
          onClick={() => setconfirmDeleteAdvertisementModalOpen(true)}
          className={
            "text-white text-sm flex items-center bg-red-700 hover:bg-red-600"
          }
        >
          <AiOutlineDelete size={"1.25rem"} />
        </Button>
      </div>
    );
  };

  const EnoturismInfo = useMemo(
    () => [
      ['Dostępne od:', dayjs(data?.availableFrom).format("DD.MM.YYYYr")],
      ['Dostępne do:', dayjs(data?.availableTo).format("DD.MM.YYYYr")],
      ['Liczba pokoi:', data?.roomsCount],
      ['Liczba pojedynczych łózek:', data?.singlePersonBedsCount],
      ['Liczba podwójnych łózek:', data?.doublePersonBedsCount],
      ['Cena za pokój:', data?.price],
    ],
    [data],
  )


  return (
    <TemplatePanel
      isLoading={isLoading}
      actionButtonsRenderer={buttonsRenderer}
      title={"Enoturystyka"}
      sideMenuRenderer={() => <VineyardSideMenu />}
      breadcrumbsRenderer={() => (
        <Breadcrumbs
          isLoading={isLoading}
          homeRoute={routes.vineyardDesktop}
          settings={bc}
        />
      )}
    >
      <div className="flex">
      <Card className={"p-16 w-full mb-8"}>
        <div
          className={`flex justify-evenly lex-col-reverse xl:flex-row w-full`}
        >
          <div className={"rounded-lg flex mt-8 xl:mt-0 w-1/2 justify-start"}>
            {!isLoading ? (
              <img
                src={data?.images ? data?.images[0]?.url : photo}
                loading="lazy"
                alt="Zdjęcie ogłoszenia"
                className="w-96 h-96 rounded-lg"
              />
            ) : (
              <img
                src={photo}
                loading="lazy"
                alt="Zdjęcie ogłoszenia"
                className="w-96 h-96 rounded-lg"
              />
            )}
          </div>

          <div className={"flex flex-col w-1/2"}>
            <div className={"flex flex-col space-y-5 sm:space-y-0"}>
            <div className="text-2xl font-bold break-words2 flex gap-4 items-center mb-2">
                {data?.name}
              </div>
              <Table data={EnoturismInfo} firstColumnStyles="text-gray-400"
                conditionColumn={1}/>
            </div>
            <div className={"flex items-center mt-4"}>
              <Description isLoading={isLoading}>
                {data?.description}
              </Description>
            </div>
          </div>
        </div>

      </Card>
      </div>
      <Card >
          <Header1 className={"mt-4"} isLoading={isLoading}>
            Inne zdjęcia ogłoszenia ({data?.images?.length ? data?.images?.length : 0}/6)
          </Header1>

          <div className={"grid grid-cols-3 gap-4 mt-4"}>
            {data &&
              data?.images?.map((item, id) => (
                <GalleryImageEnoturism
                  onDeleteSuccess={refetch}
                  item={item}
                  key={id + "gallery-image"}
                  url={item?.url}
                  alt={item?.alt}
                />
              ))}
          </div>
        </Card>
    </TemplatePanel>
  );
};

export default ViewVineyardEnoturismAdvertisement;
