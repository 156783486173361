import { passParams, routes } from "../../../app/router/routes"
import EnoturismBadge from "../../atoms/badge/EnoturismBadge"
import Card from "../../atoms/card/Card"
import CustomLink from "../../atoms/ui/buttons/links/Link"
import dayjs from "dayjs";
import photo from "../../../app/assets/svg/photo.svg"
import { truncateText } from "../../atoms/TruncatText/truncateText";

const ProductEnoturism = ({item, isLoading}) => {

    return (
             
              <Card>
                <div className="flex  flex-col bg-white border md:min-h-[490px] rounded-md overflow-hidden">
                  <CustomLink
                    to={passParams(routes.enoturismDetails, {
                      id: item?.id,
                    })}
                    className="group h-48  md:h-64 block bg-gray-100 overflow-hidden relative "
                  >
                    {item?.images ? (
                      <img
                        src={item?.images[0]?.url}
                        loading="lazy"
                        alt={"enoturism img"}
                        className="w-full h-full absolute inset-0 group-hover:scale-105 transition duration-200"
                      />
                    ) : (
                      <img
                        src={photo}
                        loading="lazy"
                        alt={""}
                        className="w-full h-full absolute inset-0 group-hover:scale-105 transition duration-200"
                      />
                    )}
                  </CustomLink>

                  <div className="flex flex-col flex-1 p-4 sm:p-6">
                    <h2 className="text-gray-800 text-lg font-semibold mb-2">
                      <CustomLink
                        to={passParams(routes.enoturismDetails, {
                          id: item?.id,
                        })}
                        className="hover:text-gold active:text-gold2 transition duration-100"
                      >
                        {item?.name}
                      </CustomLink>
                    </h2>

                    <p className="text-gray-500 mb-1">
                      {truncateText(
                        item?.description
                          ? item?.description
                          : "Brak treści wpisu",
                        30
                      )}
                    </p>
                    <p className="text-gray-500 mb-1">
                      <p>
                        Dostępne od:{" "}
                        <span className="font-semibold">
                          {dayjs(item?.availableFrom).format("DD.MM.YYYYr")}
                        </span>
                      </p>
                      <p>
                        Dostępne do:{" "}
                        <span className="font-semibold">
                          {dayjs(item?.availableTo).format("DD.MM.YYYYr")}
                        </span>
                      </p>
                    </p>
                    <p className="text-gray-500 mb-1 flex ">
                      <p>
                      {item?.bookStatus === 10 ? <EnoturismBadge bgClass={'10'}>Wolne</EnoturismBadge> : null}
                      {item?.bookStatus === 5 ? <EnoturismBadge bgClass={'5'}>Zarezerwowane</EnoturismBadge> : null}
                      {item?.bookStatus === 1 ? <EnoturismBadge bgClass={'1'}>Zajęte</EnoturismBadge> : null}
                      </p>
                    </p>
                    <p className="text-gray-500 mb-4">
                      <p>
                        <span className="font-semibold">
                          {item?.price} zł
                        </span>{" "}
                        / doba
                      </p>
                    </p>

                    <div className="flex justify-between items-center mt-auto">
                      <div className="flex items-center gap-2">
                        <div className="w-10 h-10 shrink-0 bg-gray-100 rounded-md overflow-hidden">
                          <CustomLink
                            to={passParams(routes.vineyard, {
                              vineyardId: item?.vineyardId,
                            })}
                          >
                            {item?.logoImage?.url ? (
                              <img
                                src={item?.logoImage?.url}
                                loading="lazy"
                                alt="Logo winnicy"
                                className="w-full h-full object-cover object-center"
                              />
                            ) : (
                              <img
                                src={photo}
                                loading="lazy"
                                alt="Logo winnicy"
                                className="w-full h-full"
                              />
                            )}
                          </CustomLink>
                        </div>

                        <div>
                          <span className="block text-gold2">
                            <CustomLink
                              to={passParams(routes.vineyard, {
                                vineyardId: item?.vineyardId,
                              })}
                            >
                              {item?.vineyardName}
                            </CustomLink>
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Card>
    )
}

export default ProductEnoturism