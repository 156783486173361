import axios from '../../../config/axios';
import {BASE_URL_API} from '../../../config/env';
import {useMutation} from 'react-query';
import {useParams} from 'react-router-dom';

export const usePutProductStatus = () => {
    const {id} = useParams();
    return useMutation((data) => {
        return axios.put(`${BASE_URL_API}/api/v1/admin/wine-products/${id}/toggle-publish-status`, data)
            .then(({data}) => data).then(data => {
                return data;
            })
    });
};
