import React from 'react';
import { AiFillFacebook, AiFillInstagram, AiFillTwitterSquare } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const SocialMedia = ({className}) => {
  return (
    <div className={`flex space-x-2 ${className || ''}`}>
      <Link to={'/'}>
        <AiFillFacebook className={'w-6 h-6 cursor-pointer hover:text-colorFb duration-200'}/>
      </Link>
      <Link to={'/'}>
        <AiFillTwitterSquare className={'w-6 h-6 cursor-pointer hover:text-colorTwitter duration-200'}/>
      </Link>
      <Link to={'/'}>
        <AiFillInstagram className={'w-6 h-6 cursor-pointer hover:text-colorInstagram duration-200'}/>
      </Link>
    </div>
  );
};

export default SocialMedia;
