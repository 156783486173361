import Image from "../../atoms/image/Image";
import Header1 from "../../molecules/headers/Header1";
import Header2 from "../../molecules/headers/Header2";
import Description from "../../molecules/description/Description";
import dayjs from "dayjs";
import parse from 'html-react-parser';

const BlogDetails = ({ data, isLoading }) => {
  return (
    <div className={`flex flex-col`}>
      <Header1 className={"mb-4"} isLoading={isLoading}>
        {data?.title}
      </Header1>
      <Header2 className={"mb-4"} isLoading={isLoading}>
        {dayjs(data?.createdDateTime).format("DD.MM.YYYYr")}
      </Header2>
      <div className={"w-full justify-center flex mt-8 lg:mt-0"}>
        {data?.image?.url && (
          <Image
            sizeClasses={"h-80 md:h-96"}
            src={data?.image?.url}
            alt={data?.image?.alt}
            isLoading={isLoading}
          />
        )}
      </div>
      <div className={"flex flex-col w-full"}>
        {
          <Description
            key={data?.id + "blog"}
            className={"mt-5"}
            isLoading={isLoading}
          >
            {data?.text && parse(data?.text)}
          </Description>
        }
      </div>
    </div>
  );
};

export default BlogDetails;
