import TemplatePanel from "../../templates/TemplatePanel";
import VineyardSideMenu from "../../organisms/side-menu/VineyardSideMenu";
import React from "react";
import { routes } from "../../../app/router/routes";
import Breadcrumbs from "../../organisms/breadcrumbs/Breadcrumbs";
import Card from "../../atoms/card/Card";

const ViewVineyardStore = () => {
  const bc = [{ label: "Magazyn butelek", url: routes.vineyardStore }];
  return (
    <TemplatePanel
      title={"Magazyn butelek"}
      sideMenuRenderer={() => <VineyardSideMenu />}
      breadcrumbsRenderer={() => (
        <Breadcrumbs homeRoute={routes.vineyardDesktop} settings={bc} />
      )}
    >
      <Card className="text-center">
        <div>Pracę nad stroną w toku</div>
      </Card>
    </TemplatePanel>
  );
};

export default ViewVineyardStore;
